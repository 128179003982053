import React, { useEffect, useState } from 'react';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import settingsIcon from '@shared/assets/icons/settings_icon.svg';
import classes from '../../styles.module.scss';
import { ProgressViewCardsWithTitle } from '../../../../ProgressViewCardsWithTitle/ProgressViewCardsWithTitle';
import { translationService } from '../../../../../index';
import { IActivityByProgress } from '../../../../../interfaces/IActivityByProgress';
import { addLocationAndReportDataToActivitiesByProgress } from '../../../../../utils/activities.utils';
import { IActivityRealtimeInfo } from '../../../../../interfaces/IActivityRealtimeInfo';
import { IProfessionWithActiveTags } from '../../../../../interfaces/IProfessionWithActiveTags';
import { ActivityPageSortOptions } from '../../../../../models/ActivityPageSortOptions';
import { IMyWorkAroundData } from '../../../../../interfaces/IMyWorkAroundData';
import { EmptyStateComponent } from '../../../../EmptyStateComponent/EmptyStateComponent';

interface IMyWalkAroundActivitiesByProgressProps {
	myWalkAroundData: IMyWorkAroundData | undefined;
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[] | undefined;
	activitiesLocationData: IProfessionWithActiveTags[] | undefined;
	refetchAllData: () => Promise<void>;
	personalViewState: ActivityPageSortOptions;
}

export const MyWalkAroundActivitiesByProgress = (props: IMyWalkAroundActivitiesByProgressProps) => {
	const [startedTodayActivities, setStartedTodayActivities] = useState<IActivityByProgress[]>([]);
	const [progressedTodayActivities, setProgressedTodayActivities] = useState<IActivityByProgress[]>([]);

	const getMyWalkAroundActivitiesData = (
		activities: IActivityRealtimeInfo[] | undefined
	): IActivityByProgress[] | null => {
		if (!activities || !props.activitiesReportData || !props.activitiesLocationData) return null;
		// add condition for view by floors or by progress. now the default is by progress.
		return addLocationAndReportDataToActivitiesByProgress(
			activities,
			props.activitiesReportData,
			props.activitiesLocationData
		);
	};

	useEffect(() => {
		const progressedTodayActivitiesByProgress: IActivityByProgress[] | null = getMyWalkAroundActivitiesData(
			props.myWalkAroundData?.progressedToday
		);
		if (!progressedTodayActivitiesByProgress) {
			return;
		}

		setProgressedTodayActivities(progressedTodayActivitiesByProgress);
	}, [props.myWalkAroundData?.progressedToday, props.activitiesLocationData, props.activitiesReportData]);

	useEffect(() => {
		const startedTodayActivitiesByProgress: IActivityByProgress[] | null = getMyWalkAroundActivitiesData(
			props.myWalkAroundData?.startedToday
		);
		if (!startedTodayActivitiesByProgress) {
			return;
		}
		setStartedTodayActivities(startedTodayActivitiesByProgress);
	}, [props.myWalkAroundData?.startedToday, props.activitiesLocationData, props.activitiesReportData]);

	if ([...progressedTodayActivities, ...startedTodayActivities].length === 0) {
		return (
			<EmptyStateComponent
				icon={settingsIcon}
				text={translationService.get('noProgressActivitiesEmptyStateText')}
				style={{ maxWidth: '250px', marginTop: 10 }}
			/>
		);
	}

	return (
		<div className={classes.activitiesContainer}>
			<ProgressViewCardsWithTitle
				title={translationService.get('startedToday')}
				refetchDataCallback={props.refetchAllData}
				activities={startedTodayActivities}
				isMyWalkAroundTab
			/>
			<ProgressViewCardsWithTitle
				title={translationService.get('progressedToday')}
				refetchDataCallback={props.refetchAllData}
				activities={progressedTodayActivities}
				isMyWalkAroundTab
			/>
		</div>
	);
};
