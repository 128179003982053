import React, { useEffect, useState } from 'react';
import { IFloor } from '@shared/interfaces/IFloor';
import { SORT_ORDER } from '@shared/constants/constants';
import { floorTypes } from '@shared/constants/floors.constants';
import { sortFloors } from '@shared/utils/sort.utils';
import classes from './styles.module.scss';
import { translationService } from '../../servicesInitializer';
import { useFloorDataQuery } from '../../hooks/queries/floors.queries.hooks';
import { FloorTypeCardClickable } from './FloorTypeCardClickable';
import { MobileFloorsSelection } from './MobileFloorsSelection/MobileFloorsSelection';
import { FLOOR_SELECTION_TYPE } from '../../models/FloorSelectionTypes';

interface IFloorsSelectionSectionProps {
	setSelectedFloors: (selectedFloors: IFloor[]) => void;
	selectedFloors: IFloor[];
	shouldSetInitialSelectionType?: boolean;
}

const FloorsSelectionSection = (props: IFloorsSelectionSectionProps) => {
	const [floorSelectionType, setFloorSelectionType] = useState<FLOOR_SELECTION_TYPE | null>(null);
	const floors: IFloor[] = useFloorDataQuery().filter((floor: IFloor) => floor.floorId !== floorTypes.storage);
	const isRangeMode: boolean = floorSelectionType === FLOOR_SELECTION_TYPE.RANGE;

	useEffect(() => {
		if (!props.shouldSetInitialSelectionType) return;
		const isInitialAllFloorsSelected: boolean = props.selectedFloors?.length === floors.length;
		setFloorSelectionType(
			isInitialAllFloorsSelected ? FLOOR_SELECTION_TYPE.ALL_FLOORS : FLOOR_SELECTION_TYPE.RANGE
		);
		props.setSelectedFloors(isInitialAllFloorsSelected ? floors : []);
	}, [floors.length]);

	const handleRangeSelect = () => {
		if (isRangeMode) return;
		setFloorSelectionType(FLOOR_SELECTION_TYPE.RANGE);
		props.setSelectedFloors([]);
	};

	const handleAllFloorsSelect = () => {
		if (!isRangeMode) return;
		setFloorSelectionType(FLOOR_SELECTION_TYPE.ALL_FLOORS);
		props.setSelectedFloors(floors);
	};

	return (
		<div className={classes.floorsSelectionContainer}>
			<FloorTypeCardClickable
				handleClick={handleAllFloorsSelect}
				isSelected={floorSelectionType === FLOOR_SELECTION_TYPE.ALL_FLOORS}
				text={translationService.get('allFloors')}
				counterText={floors.length.toString()}
			/>
			<FloorTypeCardClickable
				handleClick={handleRangeSelect}
				isSelected={floorSelectionType === FLOOR_SELECTION_TYPE.RANGE}
				text={translationService.get('range')}
			/>
			{isRangeMode && (
				<MobileFloorsSelection
					deleteChipOnClick
					translationService={translationService}
					floors={sortFloors(floors, SORT_ORDER.DESCENDING)}
					isFloorSectionEnabled
					changeFunction={props.setSelectedFloors}
					selectedFloors={props.selectedFloors}
				/>
			)}
		</div>
	);
};

export { FloorsSelectionSection };
