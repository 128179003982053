import React, { useId } from 'react';
import { IconPatch } from '@shared/components/IconPatch/IconPatch';
import { COLORS } from '@shared/constants/colors.constants';
import classnames from 'classnames';
import classes from './styles.module.scss';

interface IProfessionCardClickableProps {
	mainText?: string;
	secondaryText?: string;
	thirdText?: string;
	color: string;
	isSelected: boolean;
	handleClick: (any: any) => void;
	totalProfessionsLength?: number;
}

const ProfessionCardClickable = (props: IProfessionCardClickableProps) => {
	const id: string = useId();

	return (
		<div
			className={classnames(classes.clickableCardContainer, { [classes.selected]: props.isSelected })}
			onClick={props.handleClick}
		>
			<div className={classes.iconContainer}>
				<IconPatch
					color={COLORS.primaryColor}
					containerHeight={16}
					containerWidth={16}
					isSelected={props.isSelected}
					vTextOnSelected
				/>
			</div>
			<div className={classes.textContainer}>
				<div className={classes.shorTextContainer}>
					{props.secondaryText && <p className={classes.contractorText}>{props.secondaryText}</p>}
					<div id={id} className={classes.cardText}>
						{props.mainText && (
							<div className={classes.professionText} style={{ backgroundColor: props.color }}>
								{props.mainText}
							</div>
						)}
						{props.thirdText ? <div className={classes.specialtyText}>{props.thirdText}</div> : ''}
					</div>
				</div>
			</div>
			{props.totalProfessionsLength && <div className={classes.totalNumber}>{props.totalProfessionsLength}</div>}
		</div>
	);
};

export { ProfessionCardClickable };
