import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { selectProjectId } from '@store/slices/project.slice';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { useWorkersTagsQuery } from '@src/hooks/queries/workers.queries.hooks';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { useManagersMergedTagsByLocationQuery } from '@src/hooks/queries/managers.queries.hooks';
import { useEquipmentInLocationQuery } from '@src/hooks/queries/equipment.queries.hooks';
import { IMergedEquipmentView } from '@shared/interfaces/Equipment/IMergedEquipmentView';
import {
	IssuesQueryKeysEnum,
	UseGetIssuesProps,
	useProjectIssuesByLocationQuery,
	useProjectIssuesByLocationQueryInvalidation,
} from '@src/hooks/queries/issues.queries.hooks';
import { IIssue } from '@interfaces/IIssue';
import { MonitorMainPageContext } from '@src/components/pages/MonitorPage/MonitorMainPage/MonitorMainPage';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { IUser } from '@shared/interfaces/IUser';
import { selectIsLimitedUser, selectLoggedUserDetails } from '@store/slices/login.slice';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { getPermittedMonitorResources } from '@utils/permissions.utils';
import { useAreaSequenceItemsByAreaIdQuery, useProjectSequenceItems } from './queries/sequenceItems.hooks';
import { IResourceCountData, useResourceCountByLocationQuery } from './queries/resourceCountByLocation.queries.hooks';

export interface IMonitorResourcesData {
	areAllResourcesLoaded: boolean;
	resourcesCount: Record<MonitorResourcesEnum, IResourceCountData>;
}

export const useCustomizedProfessions = (): null | string[] => {
	const isLimitedUser: boolean = useSelector(selectIsLimitedUser);
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const defaultProfessionIds: string[] | null = isLimitedUser ? user.permissions.permittedProfessionsIds! : null;
	const { isCustomizedSelected } = useContext(MonitorMainPageContext)!;
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	if (isCustomizedSelected || isCustomizedSelected === undefined) {
		return userPreferences?.mobile?.filters?.professionIds || defaultProfessionIds;
	}
	return defaultProfessionIds;
};

export const useMonitorResources = (floorIds?: string[]): IMonitorResourcesData => {
	const professionIds: string[] | null = useCustomizedProfessions();

	const projectId: string = useSelector(selectProjectId)!;
	const [isTimeoutExceeded, setIsTimeoutExceedingLoaded] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsTimeoutExceedingLoaded(true);
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	const resources: MonitorResourcesEnum[] = getPermittedMonitorResources();
	const resourcesCount: Record<MonitorResourcesEnum, IResourceCountData> = resources.reduce(
		(acc, resource) => {
			acc[resource] = useResourceCountByLocationQuery(resource, projectId, floorIds, professionIds);
			return acc;
		},
		{} as Record<MonitorResourcesEnum, IResourceCountData>
	);

	const areAllResourcesLoaded: boolean = resources.every((resource) => resourcesCount[resource].isLoaded);

	return {
		areAllResourcesLoaded: areAllResourcesLoaded || isTimeoutExceeded,
		resourcesCount,
	};
};

export interface IPersonnelAreaData {
	workersTags?: IWorkerMergedTag[];
	managerTags?: IManagerMergedTag[];
	mergedEquipments: IMergedEquipmentView[];
	personnelDataCount: number;
	isLoading: boolean;
}

export const useMonitorAreaPersonnelData = (areaId: string): IPersonnelAreaData => {
	const { workersTags, isWorkersLoading } = useWorkersTagsQuery(undefined, areaId);
	const { managers, isManagersLoading } = useManagersMergedTagsByLocationQuery(undefined, areaId);
	const { mergedEquipments, mergedEquipmentsLoading } = useEquipmentInLocationQuery(undefined, areaId);
	const personnelDataCount: number = managers.length + workersTags.length;
	const loadingState: boolean = isWorkersLoading || isManagersLoading || mergedEquipmentsLoading;
	return { workersTags, managerTags: managers, mergedEquipments, personnelDataCount, isLoading: loadingState };
};

export interface IIssuesAreaData {
	personnelData: IPersonnelAreaData;
	issues: IIssue[];
	isIssuesInitialLoading: boolean;
	getIssuesProps: UseGetIssuesProps;
	queryKey: IssuesQueryKeysEnum;
	issueCreatedQueryInvalidation: () => void;
}

export const useMonitorAreaIssuesData = (
	areaId: string
): {
	issueCreatedQueryInvalidation: () => void;
	queryKey: IssuesQueryKeysEnum.getIssuesByFloor;
	getIssuesProps: UseGetIssuesProps;
	issues: IIssue[];
	personnelData: IPersonnelAreaData;
	isIssuesInitialLoading: boolean;
} => {
	const projectId: string = useSelector(selectProjectId)!;
	const professionIds: string[] | null = useCustomizedProfessions();

	const personnelData: IPersonnelAreaData = useMonitorAreaPersonnelData(areaId);
	const queryKey: IssuesQueryKeysEnum = IssuesQueryKeysEnum.getIssuesByFloor;
	const getIssuesProps: UseGetIssuesProps = {
		queryKey,
		projectId,
		areaId,
		professionIds,
		notCompleted: true,
	};
	const issueCreatedQueryInvalidation = useProjectIssuesByLocationQueryInvalidation(getIssuesProps);

	const { issues, isLoading: isIssuesInitialLoading } = useProjectIssuesByLocationQuery(getIssuesProps);
	return {
		personnelData,
		issues,
		isIssuesInitialLoading,
		getIssuesProps,
		queryKey,
		issueCreatedQueryInvalidation,
	};
};

export interface IMonitorAreaActivitiesData {
	areaSequenceItems: IAreaSequenceItem[];
	isLoading: boolean;
	ongoingAreaSequenceItems: IAreaSequenceItem[];
}

export const useMonitorAreaActivitiesData = (areaId: string): IMonitorAreaActivitiesData => {
	const professionIds: string[] | null = useCustomizedProfessions();
	const { areaSequenceItems, isLoading: areaAsisLoading } = useAreaSequenceItemsByAreaIdQuery(areaId);
	const { sequenceItems, isFetched: areaProjectSequenceItemsFetched } = useProjectSequenceItems();

	const filteredSequenceItemsByProfessionIds: ISequenceItem[] = useMemo(
		() =>
			professionIds?.length
				? sequenceItems.filter((sequenceItem: ISequenceItem) =>
						professionIds!.includes(sequenceItem.profession._id)
					)
				: sequenceItems,
		[professionIds, sequenceItems]
	);

	const filteredAreaSequenceItems: IAreaSequenceItem[] = useMemo(
		() =>
			areaSequenceItems.filter((asi: IAreaSequenceItem) =>
				filteredSequenceItemsByProfessionIds.some((si: ISequenceItem) => si._id === asi.sequenceItemId)
			),
		[areaSequenceItems, filteredSequenceItemsByProfessionIds]
	);

	const ongoingAreaSequenceItems: IAreaSequenceItem[] = useMemo(
		() =>
			filteredAreaSequenceItems.filter(
				(asi: IAreaSequenceItem) =>
					asi.status !== AreaSequenceItemStatus.unplanned && asi.status !== AreaSequenceItemStatus.complete
			),
		[filteredAreaSequenceItems]
	);

	return {
		areaSequenceItems: filteredAreaSequenceItems,
		isLoading: areaAsisLoading || !areaProjectSequenceItemsFetched,
		ongoingAreaSequenceItems,
	};
};
