import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import React from 'react';
import classes from './styles.module.scss';

interface IBottomActionButtonProps {
	isDisabled: boolean;
	text: string;
	handleClick: () => void;
}

export const BottomActionButton = (props: IBottomActionButtonProps) => (
	<section className={classes.bottomActionsContainer}>
		<TrusstorButton
			disabled={props.isDisabled}
			text={props.text}
			className={classes.primaryButton}
			handleClick={props.handleClick}
		/>
	</section>
);
