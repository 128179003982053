import { IFloor } from '@shared/interfaces/IFloor';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { selectProjectId } from '../../store/slices/project.slice';
import { requestService } from '../../index';

export const useFloorDataQuery = (): IFloor[] => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const history = useHistory();
	const { data: floors } = useQuery<IFloor[]>(
		['floorsData', projectId],
		() => requestService.get(`/projectConfig/floor?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
			onSuccess: (data) => {
				if (data.length === 0) {
					history.push('/projectNotReady');
				}
			},
		}
	);
	return floors;
};

export const useFloorViewDataQuery = (
	projectId: string,
	floorId: string
): { floorViewSvg: string | undefined; isLoading: boolean; error } => {
	const {
		data: floorViewSvg,
		isLoading,
		error,
	} = useQuery<string>(
		['floorView', projectId, floorId],
		async () => {
			const floorView = await requestService.get(`/storage/projects/${projectId}/floorView?floorId=${floorId}`);
			return floorView;
		},
		{}
	);
	return { floorViewSvg, isLoading, error };
};
