import leftArrow from '@shared/assets/icons/leftArrow.svg';
import rightArrow from '@shared/assets/icons/rightArrow.svg';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IssueUpsertPage } from '@src/components/pages/IssuesPage/IssueUpsertPage/IssueUpsertPage';
import { useActivityLocationsQuery } from '@src/hooks/queries/activities.queries.hooks';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { translationService, userAccessLevelService } from '../../index';
import { IActivityByProgress } from '../../interfaces/IActivityByProgress';
import { selectProjectId } from '../../store/slices/project.slice';
import { goToIssuesPage } from '../../utils/activities.utils';
import { workplanActivityActions_BI } from '../../utils/bi.utils';
import { ActivityDescriptionWithEndDate } from '../ActivityDescriptionWithEndDate/ActivityDescriptionWithEndDate';
import { ActivityMarkAsCompleteActionDialog } from '../Dialogs/ActivityMarkAsCompleteActionDialog/ActivityMarkAsCompleteActionDialog';
import { ActivityStartActionDialog } from '../Dialogs/ActivityStartActionDialog/ActivityStartActionDialog';
import { AreaSequenceMarkAllAsDoneDialog } from '../Dialogs/AreaSequenceMarkAllAsDoneDialog/AreaSequenceMarkAllAsDoneDialog';
import { WorkersOnSite } from '../pages/WorkersOnSitePage/WorkersOnSite';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { ProgressCards } from './ProgressCards/ProgressCards';
import classes from './styles.module.scss';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';

interface IProgressViewProfessionCardProps {
	activity: IActivityByProgress;
	refetchDataCallback: () => Promise<void>;
	isMyWalkAroundTab?: boolean;
}

export const ProgressViewActivityCard = (props: IProgressViewProfessionCardProps) => {
	const [workersOnSitePageProfessionId, setWorkersOnSitePageProfessionId] = useState<string>('');
	const [showStartDialog, setShowStartDialog] = useState<boolean>(false);
	const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false);
	const [showSequenceMarkAllDoneDialog, setShowSequenceMarkAllDoneDialog] = useState<boolean>(false);
	const [showIssueModal, setShowIssueModal] = React.useState<boolean>(false);
	const projectId: string = useSelector(selectProjectId)!;
	const { activityLocations } = useActivityLocationsQuery(props.activity);
	const issueLocations: IIssueLocation[] = activityLocations.map((location: IBaseAreaBaseFloor) => ({
		area: {
			areaId: location.areaId,
			areaNick: location.areaNick,
		},
		floor: {
			floorId: location.floorId,
			floorNick: location.floorNick,
		},
	}));
	const history = useHistory();

	const getOnSiteArrowIcon = (): JSX.Element => {
		const arrowIconSrc = translationService.getIsRtl() ? leftArrow : rightArrow;
		const arrowClassName: string = props.activity.workersOnSite.length === 0 ? classes.halfOpacity : '';
		return <img alt="" src={arrowIconSrc} className={arrowClassName} />;
	};

	const getActionButton = (): JSX.Element => {
		if (props.activity.linkedSequenceType) {
			return <div />;
		}
		if (props.activity.groupStatus === ActivityGroupStatus.delayed) {
			return (
				<div className={`${classes.button} ${classes.actionButton}`} onClick={() => setShowStartDialog(true)}>
					{translationService.get('start')}
				</div>
			);
		}

		const isActivityCompleted: boolean = props.activity.groupStatus === ActivityGroupStatus.complete;
		return (
			<div
				className={`${classes.button} ${classes.actionButton} ${isActivityCompleted ? classes.hide : ''}`}
				onClick={() => setShowCompleteDialog(true)}
			>
				{translationService.get('complete')}
			</div>
		);
	};

	const clearWorkersOnSiteId = useCallback(() => setWorkersOnSitePageProfessionId(''), []);

	const goToWorkersOnSitePage = () => {
		if (props.activity.workersOnSite.length > 0) {
			setWorkersOnSitePageProfessionId(props.activity.profession._id);
		}
	};

	const shouldShowDisclaimerTextForComplete = (): boolean =>
		props.activity.floors.some((floor) => floor.todayHours <= 0);

	const getTodayProgressTitle = (): JSX.Element => {
		const totalProgress: number = props.activity.floors.reduce((acc, floor) => acc + floor.todayHours, 0);
		if (totalProgress) {
			return (
				<div className={classes.title}>
					{totalProgress}
					{translationService.get('h')} {translationService.get('todayProgress')}
				</div>
			);
		}

		return (
			<div className={`${classes.title} ${classes.disabledColor}`}>
				{translationService.get('noProgressToday')}
			</div>
		);
	};

	const getTitleText = () => {
		const translationText: string = props.activity.linkedSequenceType
			? `${props.activity.linkedSequenceType}_plural`
			: 'floors';
		return translationService.get(translationText);
	};

	const sendActionBiEvent = (action: AreaSequenceItemStatus) => {
		workplanActivityActions_BI({
			groupStatus: props.activity.groupStatus,
			assignee: props.activity.assignee,
			description: props.activity.description,
			action,
		});
	};

	const onCreateIssueClick = () => {
		setShowIssueModal(true);
		history.push('/activities/issue/create');
	};

	return (
		<>
			{workersOnSitePageProfessionId && (
				<WorkersOnSite professionId={workersOnSitePageProfessionId} goBack={clearWorkersOnSiteId} />
			)}
			<ActivityStartActionDialog
				sendActionBiEventOnSubmit={sendActionBiEvent}
				activityGroupId={props.activity.groupId}
				show={showStartDialog}
				onClose={() => setShowStartDialog(false)}
				onAction={() => props.refetchDataCallback()}
			/>
			<ActivityMarkAsCompleteActionDialog
				sendActionBiEventOnSubmit={sendActionBiEvent}
				activityGroupId={props.activity.groupId}
				show={showCompleteDialog}
				onClose={() => setShowCompleteDialog(false)}
				showNoWorkDetectedDisclaimerText={shouldShowDisclaimerTextForComplete()}
				onAction={() => {
					props.refetchDataCallback();
				}}
			/>
			<AreaSequenceMarkAllAsDoneDialog
				show={showSequenceMarkAllDoneDialog}
				onClose={() => setShowSequenceMarkAllDoneDialog(false)}
				activityGroupId={props.activity.groupId}
				onAction={() => {
					props.refetchDataCallback();
				}}
			/>
			<div
				className={classes.card}
				style={{ borderColor: getProfessionBackgroundColor(props.activity.profession, projectId) }}
			>
				<div className={classes.header}>
					<ActivityDescriptionWithEndDate activity={props.activity} onCreateIssueClick={onCreateIssueClick} />
				</div>
				<div className={classes.separator} />
				<div className={classes.body}>
					<div className={classes.topBody}>
						<div className={classes.leftSection}>
							<section className={classes.professionContainer}>
								<ProfessionDisplayWithTradeIcon
									profession={props.activity.profession}
									projectId={projectId}
								/>
							</section>
						</div>
						<div className={classes.rightSection} onClick={goToWorkersOnSitePage}>
							<div
								className={`${classes.headerOnSite} ${
									props.activity.workersOnSite.length ? '' : classes.disabledOnSite
								}`}
							>
								<TrusstorIcon
									iconName={IconNames.users}
									color={props.activity.workersOnSite.length ? IconColor.Black : IconColor.Grey100}
								/>
								<div>
									{props.activity.workersOnSite.length} {translationService.get('onSite')}
								</div>
								{getOnSiteArrowIcon()}
							</div>
						</div>
					</div>
					<section className={classes.progressContainer}>
						<div className={classes.progressHeaders}>
							<div className={classes.titleIcon}>
								<TrusstorIcon iconName={IconNames.pin} />
								<div className={classes.title}>{getTitleText()}</div>
							</div>
							<div className={classes.title}>{getTodayProgressTitle()}</div>
						</div>
						<ProgressCards activity={props.activity} refetchDataCallback={props.refetchDataCallback} />
					</section>
				</div>
				<div className={classes.actionsFooter}>
					{userAccessLevelService.hasAccess('activitiesActions') && getActionButton()}
					<div
						onClick={() => {
							if (props.activity.issues?.length) {
								goToIssuesPage(history, props.activity.groupId);
								return undefined;
							}
							return onCreateIssueClick();
						}}
						className={`${classes.button} ${classes.issueButton}`}
					>
						<TrusstorIcon iconName={IconNames.issues} />
						{props.activity.issues?.length
							? props.activity.issues?.length
							: translationService.get('createIssue')}
					</div>
				</div>
			</div>
			<IssueUpsertPage
				show={showIssueModal}
				onClose={() => setShowIssueModal(false)}
				onIssueCreated={() => {
					props.refetchDataCallback?.();
				}}
				initialIssue={{
					profession: props.activity.profession,
					locations: issueLocations,
					linkedActivityGroupId: props.activity.groupId,
				}}
			/>
		</>
	);
};
