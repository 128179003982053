import React, { useEffect, useState } from 'react';
import { IAssignedActivityArea } from '@shared/interfaces/IAssignedActivityArea';
import { WidgetAreaSequenceItem } from '@shared/components/widget/WidgetAreaSequenceItem/WidgetAreaSequenceItem';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { IProfession } from '@shared/interfaces/IProfession';
import { selectProjectId } from '@store/slices/project.slice';
import { translationService, userAccessLevelService } from '@src/servicesInitializer';
import { AsiReviewDialog } from '@src/components/AsiReviewDialog/ASIReviewDialog';
import { ActionConfirmationDialog } from '@src/components/Dialogs/ActionConfirmationDialog/ActionConfirmationDialog';
import {
	useMainPageActivitiesAsiReviewStatusMutation,
	useMainPageActivitiesAsiStatusMutation,
} from '@src/hooks/mutations/activities.mutations';
import { IssueUpsertPage } from '@src/components/pages/IssuesPage/IssueUpsertPage/IssueUpsertPage';
import { successToast } from '@utils/toast.utils';
import { IIssue } from '@interfaces/IIssue';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { useQueryClient } from '@tanstack/react-query';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { IAreaSequenceItemWithActivityStatus } from '@shared/interfaces/IMainPageWidgetActivityData';

interface IWidgetAreaCardProps {
	description: string;
	profession: IProfession;
	areaSequenceItem: IAreaSequenceItem;
	area: IAssignedActivityArea;
	queryKeys: any[];
}

export const ActivityAreaCard = (props: IWidgetAreaCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	const [showReviewDialog, setShowReviewDialog] = useState(false);
	const [isReadyForReview, setIsReadyForReview] = useState<boolean>(
		props.areaSequenceItem.status === AreaSequenceItemStatus.readyForReview
	);
	const [isDone, setIsDone] = useState<boolean>(props.areaSequenceItem.status === AreaSequenceItemStatus.complete);
	const [showIssuesPage, setShowIssuesPage] = useState<boolean>(false);
	const { mutate: updateAsiStatus } = useMainPageActivitiesAsiStatusMutation(props.queryKeys);
	const { mutateAsync: updateAsiReviewStatus } = useMainPageActivitiesAsiReviewStatusMutation(props.queryKeys);

	useEffect(() => {
		setIsReadyForReview(props.areaSequenceItem.status === AreaSequenceItemStatus.readyForReview);
		setIsDone(props.areaSequenceItem.status === AreaSequenceItemStatus.complete);
	}, [props.areaSequenceItem.status]);

	const [isWarningDialogOpen, setIsWarningDialogOpen] = useState<boolean>(false);
	const userHasAccessToActivityActions: boolean = userAccessLevelService.hasAccess('workplanActivityActions');

	const handleLimitedUserChangeStatus = async (
		cell: IAreaSequenceItem,
		isReadyForReviewUpdate: boolean
	): Promise<IMergedAreaSequenceItem> =>
		updateAsiReviewStatus({
			asi: cell,
			isDone: false,
			newStatus: isReadyForReviewUpdate ? AreaSequenceItemStatus.readyForReview : AreaSequenceItemStatus.planned,
			updateToReadyForReview: isReadyForReviewUpdate,
		});

	const handleDone = async (cell: IAreaSequenceItem): Promise<void> =>
		updateAsiStatus({
			asi: cell,
			isDone: !isAsiComplete(cell.status),
		});

	const handleDoneWarningAction = async (): Promise<void> => {
		await handleDone(props.areaSequenceItem as IAreaSequenceItem);
		setIsWarningDialogOpen((prev) => !prev);
	};

	const onApproveSubmit = async () => {
		setShowReviewDialog(false);
		await handleDone(props.areaSequenceItem);
	};

	const onRejectSubmit = async () => {
		setShowReviewDialog(false);
		setShowIssuesPage(true);
	};

	const handleCellClick = async (cell: IAreaSequenceItemWithActivityStatus): Promise<void> => {
		if (!userHasAccessToActivityActions) {
			await handleLimitedUserChangeStatus(cell, !isReadyForReview);
			return;
		}
		if (userHasAccessToActivityActions && isReadyForReview) {
			setShowReviewDialog(true);
			return;
		}
		if (cell.groupStatus === ActivityGroupStatus.complete && isAsiComplete(cell.status)) {
			setIsWarningDialogOpen(true);
			return;
		}

		await handleDone(cell);
	};

	const defaultIssue: Partial<IIssue> = {
		projectId,
		locations: [
			{
				area: { areaId: props.area.areaId, areaNick: props.area.areaNick },
				floor: {
					floorId: props.area.floorId,
					floorNick: props.area.floorNick,
				},
			},
		],
		profession: props.profession,
		linkedAreaSequenceItemId: props.areaSequenceItem._id,
		linkedActivityGroupId: props.areaSequenceItem.activityGroupId,
	};

	const refetchActivities = () => {
		queryClient.refetchQueries([QueryKeys.mobileMainPageActivities, projectId]);
	};

	const handleIssueCreated = async () => {
		setShowReviewDialog(false);
		setShowIssuesPage(false);
		refetchActivities();
		const asiUpdated: IMergedAreaSequenceItem = await handleLimitedUserChangeStatus(props.areaSequenceItem, false);
		successToast(
			dispatch,
			translationService.get('asiReviewDialog_reviewRejected', {
				new_status: translationService.get(asiUpdated?.status || ''),
			})
		);
	};

	return (
		<>
			<IssueUpsertPage
				dialogTitle={translationService.get('asiReview_createIssueMobileTitle')}
				initialIssue={defaultIssue}
				onClose={() => setShowIssuesPage(false)}
				onIssueCreated={handleIssueCreated}
				disableSuccessToast
				show={showIssuesPage}
			/>
			{showReviewDialog && (
				<AsiReviewDialog
					asi={props.areaSequenceItem}
					projectId={projectId}
					area={props.area}
					sequenceItemDescription={props.description}
					profession={props.profession}
					show={showReviewDialog}
					onClose={() => setShowReviewDialog(false)}
					onAcceptSubmit={onApproveSubmit}
					onRejectSubmit={onRejectSubmit}
				/>
			)}
			<ActionConfirmationDialog
				title={translationService.get('areYouSure')}
				text={translationService.get('markDoneWarning')}
				mainButtonAction={handleDoneWarningAction}
				secondaryButtonAction={() => setIsWarningDialogOpen((prev) => !prev)}
				show={isWarningDialogOpen}
				onClose={() => setIsWarningDialogOpen((prev) => !prev)}
			/>
			<WidgetAreaSequenceItem
				isReadyForReview={isReadyForReview}
				isDoneCell={isDone}
				userHasAccessToActivityActions={userHasAccessToActivityActions}
				area={props.area}
				areaSequenceItem={props.areaSequenceItem as IAreaSequenceItem}
				onClick={handleCellClick}
				translationService={translationService}
			/>
		</>
	);
};
