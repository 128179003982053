import React, { useCallback } from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../servicesInitializer';
import whiteCloseIcon from '../../../assets/white_close.svg';
import classnames from 'classnames';

interface IEducationPopupProps {
	setShowEducation: (value: boolean) => void;
}

const EducationPopup = (props: IEducationPopupProps) => {
	const handleBlackScreenClicked = (event) => {
		event.stopPropagation();
		props.setShowEducation(false);
	};

	const isRtl: boolean = translationService.getIsRtl();
	return (
		<>
			<div className={classes.blackScreen} onClick={handleBlackScreenClicked} />
			<div className={classes.educationContainer}>
				<div className={classes.triangle} />
				<div className={classes.title}>{translationService.get('overseeYourPersonnelInRealTime')}</div>
				<div className={classes.descriptionText}>{translationService.get('attentionCustomizeViewExplain')}</div>
				<img
					src={whiteCloseIcon}
					className={classnames(classes.closeIcon, { [classes.rtl]: isRtl })}
					alt="X"
					onClick={() => props.setShowEducation(false)}
				/>
			</div>
		</>
	);
};

export { EducationPopup };
