import React, { useEffect } from 'react';
import { PushNotificationsEnableCard } from '../../PushNotificationsEnableCard/PushNotificationsEnableCard';
import { TrusstorDrawer } from '../../TrusstorDrawer/TrusstorDrawer';
import { openPushNotificationsDrawer_BI, pushNotificationDialogAction_BI } from '../../../utils/bi.utils';

interface IPushNotificationsRequestDrawerProps {
	isOpen: boolean;
	onClose: () => void;
}

export const PushNotificationsRequestDrawer = (props: IPushNotificationsRequestDrawerProps) => {
	useEffect(() => {
		openPushNotificationsDrawer_BI();
	}, []);

	const onButtonClick = (action: 'enable' | 'rejected') => {
		pushNotificationDialogAction_BI(action);
		props.onClose();
	};

	const onDrawerClose = () => {
		pushNotificationDialogAction_BI('dismissed');
		props.onClose();
	};

	return (
		<TrusstorDrawer isOpen={props.isOpen} onClose={onDrawerClose}>
			<PushNotificationsEnableCard onButtonClick={onButtonClick} isTextCentered showNotNowButton />
		</TrusstorDrawer>
	);
};
