import React from 'react';
import { IProfession } from '@shared/interfaces/IProfession';
import { groupBy } from 'lodash';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';
import { useSelector } from 'react-redux';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import classes from './styles.module.scss';
import { translationService } from '../../servicesInitializer';
import { useWorkingProfessionsQuery } from '../../hooks/queries/professions.queries.hooks';
import { DrawerDialog } from '../Dialogs/DrawerDialog/DrawerDialog';
import { selectProjectId, selectTimezone } from '../../store/slices/project.slice';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { getTradeGroupTranslation } from '../../utils/translations.utils';

interface IProfessionSelectorProps {
	onSelect: (profession: IProfession | null) => void;
	initialSelectedProfession: IProfession | null;
	closeDropdown: () => void;
	isOpen: boolean;
}

export const ProfessionSelector = (props: IProfessionSelectorProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [searchParam, setSearchParam] = React.useState<string>('');
	const professions = useWorkingProfessionsQuery(getTimezoneFormattedDate(tz, new Date()));
	const filteredProfessions = professions.filter((profession: IProfession) =>
		getProfessionDisplayText(profession, translationService).toLowerCase().includes(searchParam.toLowerCase())
	);

	const professionGrouped = groupBy(filteredProfessions, (profession: IProfession) =>
		getTradeGroupTranslation(profession)
	);

	const handleCloseDropdown = () => {
		setSearchParam('');
		props.closeDropdown();
	};

	const handleAddButton = (profession: IProfession) => {
		props.onSelect(profession);
		handleCloseDropdown();
	};

	const handleSetSearchParam = (value: string | React.ChangeEvent) => {
		setSearchParam(value as string);
	};

	return (
		<DrawerDialog
			isOpen={props.isOpen}
			onClose={handleCloseDropdown}
			title={translationService.get('addProfession')}
			customHeader={
				<TrusstorTextInput
					autoFocus={false}
					changeFunc={handleSetSearchParam}
					rootClassName={classes.searchInput}
					placeholder={translationService.get('searchForProfessions')}
					startIcon={<TrusstorIcon iconName={IconNames.search} />}
				/>
			}
		>
			<div className={classes.bottomSection}>
				{!!professions.length &&
					Object.entries(professionGrouped).map(([tradeGroup, professionsGrouped]) => (
						<div className={classes.profession} key={tradeGroup}>
							<div className={classes.tradeName}>{tradeGroup}</div>
							{professionsGrouped.map((profession: IProfession) => (
								<div
									className={classes.professionItem}
									key={profession._id}
									onClick={() => handleAddButton(profession)}
								>
									<div>
										<ProfessionDisplayWithTradeIcon profession={profession} projectId={projectId} />
									</div>
								</div>
							))}
						</div>
					))}
			</div>
		</DrawerDialog>
	);
};
