import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { translationService } from '../../../servicesInitializer';
import classes from '../styles.module.scss';
import { INotificationNavbarItemProps } from '../NotificationImageElement/NotificationImageElement';

export interface INavbarItemWithCustomIconProps {
	name: string;
	imageElement: React.FC<INotificationNavbarItemProps>;
	pathName: string;
}

export const NavbarItemWithCustomIcon = (props: INavbarItemWithCustomIconProps) => {
	const isCurrentPath: boolean = props.pathName.includes(useLocation().pathname);

	return (
		<Link to={props.pathName} className={`${classes.sideBarItem} ${isCurrentPath && classes.selectedLink}`}>
			<props.imageElement isCurrentPath={isCurrentPath} />
			<div className={`${classes.sideBarSubItem} ${isCurrentPath && classes.selected}`}>
				<div>{translationService.get(props.name)}</div>
			</div>
			{isCurrentPath && <div className={classes.selectedLine}></div>}
		</Link>
	);
};
