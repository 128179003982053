import { LoginRequestStatuses } from '@shared/interfaces/ILoginReducerState';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { isTrusstorMobile } from '@shared/utils/mobile.utils';
import { ILocalStorageUserData } from '@shared/interfaces/IUserService';
import { Environments } from '@shared/constants/environments';
import { initPostHog } from '@shared/utils/posthog.utils';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useIconQuery } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { initMonitoringService } from '@shared/utils/monitoring.util';
import { initFullStory } from '@shared/utils/fullstory.utils';
import { authService, requestService, streamService, userService, versionService } from '../index';
import { store } from '../store/store';
import { setLoginStateData } from '../store/slices/login.slice';
import { config } from '../config/config';
import { errorInterceptor } from './request.utils';
import { logout, setLoggedUserThunk } from '../store/thunks/login.thunks';

export const initRequestService = (): void => {
	requestService.addResponseErrorInterceptor(errorInterceptor(config));
};

export const initVersionService = (): void => {
	const currentVersion: string | null = versionService.getVersion();
	if (!currentVersion) {
		versionService.setVersion(process.env.REACT_APP_DASHBOARD_VERSION || '');
	}
};

export const initUserAuthenticationFromToken = async (token: string): Promise<void> => {
	try {
		store.dispatch(
			setLoginStateData({
				loginRequestStatus: {
					state: LoginRequestStatuses.LOADING,
				},
			})
		);

		const loggedUser: ILoggedUser = await authService.authorizeUserWithAccessToken(token);
		store.dispatch(setLoggedUserThunk(loggedUser) as any);
		store.dispatch(
			setLoginStateData({
				loginRequestStatus: {
					state: LoginRequestStatuses.SUCCESS,
				},
			})
		);
	} catch (e: any) {
		store.dispatch(
			setLoginStateData({
				loginRequestStatus: {
					state: LoginRequestStatuses.ERROR,
					statusCode: e.request.status,
					responseMessage: e.response?.data?.message || e.message,
				},
			})
		);
	}
};

export const handleLogoutAndRedirect = (): void => {
	store.dispatch(logout() as any);
	const desktopUrlQuery: string =
		process.env.REACT_APP_DEBUG === 'true' || isTrusstorMobile() ? '?loggedOut=true' : '';
	window.location.replace(`${config.desktopUrl}${desktopUrlQuery}`);
};

export const handleUserLoggedOut = (): void => {
	const lsUserData: ILocalStorageUserData | null = userService.getLSUserData();

	if (!lsUserData?.accessToken || !lsUserData?.refreshToken) {
		const tokenFromUrl: string | null = new URL(window.location.href).searchParams.get('token');
		if (tokenFromUrl) {
			initUserAuthenticationFromToken(tokenFromUrl);
			return;
		}

		handleLogoutAndRedirect();
	}
};

const initUserData = async (): Promise<void> => {
	try {
		await authService.refreshUserToken();
	} catch (e) {
		store.dispatch(logout() as any);
		window.location.reload();
	}
};

const initStreamService = (): void => {
	streamService.setOnOpenStreamInterceptor(async () => {
		const isTokenExpired: boolean = await authService.getIsTokenExpired();
		if (isTokenExpired) {
			await authService.refreshUserToken();
		}
	});
};

export const initImportantIcons = (): void => {
	const iconsNames: IconNames[] = [
		IconNames.wifiNoConnection,
		IconNames.refresh,
		IconNames.checkCircle,
		IconNames.warningTriangle,
		IconNames.star,
		IconNames.camera,
		IconNames.pin,
		IconNames.manager,
		IconNames.helmet,
		IconNames.plus,
	];

	iconsNames.forEach((iconName: IconNames) => {
		useIconQuery(requestService, iconName);
	});
};

export const initializeBootstrapUtilities = (): void => {
	initRequestService();
	handleUserLoggedOut();
	initUserData(); // important to be after initRequestService and handleUserLoggedOut
	initMonitoringService(
		config.sentryDsn,
		process.env.REACT_APP_STAGE as string,
		process.env.REACT_APP_DASHBOARD_VERSION as string,
		config.sentryOrganization,
		config.sentryProjectId
	);
	initStreamService();
	initFullStory(config.fullStoryOrganizationId);
	initPostHog(config.posthogAPIKey, process.env.REACT_APP_STAGE as Environments);
	initVersionService();
};
