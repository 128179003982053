import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { TrusstorCheckbox } from '@shared/components/TrusstorCheckbox/TrusstorCheckbox';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { sortByAreas } from '@shared/utils/sort.utils';
import { groupBy } from 'lodash';
import React, { useEffect } from 'react';
import { SORT_ORDER } from '@shared/constants/constants';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useAreasDataQuery } from '../../../hooks/queries/areas.queries.hooks';
import { translationService } from '../../../servicesInitializer';
import { TrusstorIcon } from '../../TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';
import { baseLocationToIssueLocation } from './locationListSelector.utils';

interface ILocationListSelectorProps {
	onSelect: (locations: IIssueLocation[]) => void;
	initialSelectedLocations: IIssueLocation[] | null;
}

export const LocationListSelector = (props: ILocationListSelectorProps) => {
	const [searchParam, setSearchParam] = React.useState<string>('');
	const [selectedLocations, setSelectedLocations] = React.useState<IIssueLocation[]>(
		props.initialSelectedLocations || []
	);

	const areas: IConfigArea[] = useAreasDataQuery();
	const filteredAreas = areas.filter(
		(area: IConfigArea) =>
			area.areaNick.toLowerCase().includes(searchParam.toLowerCase()) ||
			area.floorNick.toLowerCase().includes(searchParam.toLowerCase())
	);
	const groupedAreas = groupBy(
		sortByAreas(filteredAreas, SORT_ORDER.ASCENDING),
		(area: IBaseAreaBaseFloor) => area.floorNick
	);

	const selectLocation = (location: IBaseAreaBaseFloor, isSelected: boolean) => {
		if (isSelected && selectedLocations) {
			setSelectedLocations(
				selectedLocations.filter((selectedLocation) => selectedLocation.area.areaId !== location.areaId)
			);
			return;
		}

		setSelectedLocations([...selectedLocations, baseLocationToIssueLocation(location)]);
	};

	const selectLocationsOnFloor = (floorId: string, isSelected: boolean) => {
		if (isSelected && selectedLocations) {
			setSelectedLocations(
				selectedLocations.filter((selectedLocation) => selectedLocation.floor.floorId !== floorId)
			);
			return;
		}
		const locationsOnFloor: IBaseAreaBaseFloor[] = areas.filter(
			(area: IBaseAreaBaseFloor) => area.floorId === floorId
		);
		const issueLocationsOnFloor: IIssueLocation[] = locationsOnFloor.map(baseLocationToIssueLocation);
		setSelectedLocations([...selectedLocations, ...issueLocationsOnFloor]);
	};

	useEffect(() => {
		props.onSelect(selectedLocations);
	}, [selectedLocations]);

	const handleSetSearchParam = (value: string | React.ChangeEvent) => {
		setSearchParam(value as string);
	};

	return (
		<>
			<TrusstorTextInput
				autoFocus={false}
				changeFunc={handleSetSearchParam}
				rootClassName={classes.searchInput}
				placeholder={translationService.get('searchForLocations')}
				startIcon={<TrusstorIcon iconName={IconNames.search} />}
			/>
			<div className={classes.bottomSection}>
				{!!areas.length &&
					Object.entries(groupedAreas).map(([floorNick, areasGrouped]) => {
						const floorId: string = areasGrouped[0].floorId;
						const isFloorSelected = selectedLocations?.some(
							(selectedLocation) => selectedLocation.floor.floorId === floorId
						);
						return (
							<div key={floorNick}>
								<div className={classes.sectionName}>
									<TrusstorCheckbox
										checked={isFloorSelected}
										onChange={() => {
											selectLocationsOnFloor(floorId, isFloorSelected);
										}}
									/>
									{floorNick}
								</div>
								{areasGrouped.map((area: IBaseAreaBaseFloor) => {
									const isSelected = selectedLocations?.some(
										(selectedLocation) => selectedLocation.area.areaId === area.areaId
									);
									return (
										<div
											className={classes.areaItem}
											key={area.areaId}
											onClick={() => selectLocation(area, isSelected)}
										>
											<TrusstorCheckbox
												checked={!!isSelected}
												onChange={() => selectLocation(area, isSelected)}
											/>
											<div>{area.areaNick}</div>
										</div>
									);
								})}
							</div>
						);
					})}
			</div>
		</>
	);
};
