import React from 'react';
import { useLocationWidgetActivities } from '@src/hooks/queries/activities.queries.hooks';
import { useSelector } from 'react-redux';
import { ActivityCard } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCard';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { useQueryClient } from '@tanstack/react-query';
import { selectProjectId } from '@store/slices/project.slice';
import { getFloorResourcePageTitle } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { useCustomizedProfessions } from '@src/hooks/monitor.hooks';
import classes from './styles.module.scss';

interface IFloorActivitiesPageProps {
	floorId: string;
}

export const FloorActivitiesPage = (props: IFloorActivitiesPageProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const queryClient = useQueryClient();
	const professionIds: string[] | null = useCustomizedProfessions();
	const queryKeys: any[] = [QueryKeys.mobileLocationActivities, projectId, props.floorId, professionIds];
	const { activities, isLoading } = useLocationWidgetActivities(queryKeys, props.floorId, professionIds);

	const onIssueCreated = () => {
		queryClient.invalidateQueries(queryKeys);
	};

	return (
		<div className={classes.FloorActivitiesPage_container} data-testid="FloorActivitiesPage">
			<div className={classes.title}>
				{getFloorResourcePageTitle(MonitorResourcesEnum.ACTIVITIES, activities?.length, isLoading)}
			</div>
			<div className={classes.content}>
				{activities?.map((activity) => (
					<ActivityCard
						activity={activity}
						onIssueCreated={onIssueCreated}
						queryKeys={queryKeys}
						key={activity.groupId}
					/>
				))}
			</div>
		</div>
	);
};
