import { IMergedEquipmentView } from '@shared/interfaces/Equipment/IMergedEquipmentView';
import { IStaticEquipment } from '@shared/interfaces/Equipment/IStaticEquipment';
import { getRelativeHour } from '@shared/utils/dateUtils';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { translationService } from '../../../../index';
import { selectTimezone } from '../../../../store/slices/project.slice';
import classes from './styles.module.scss';
import locationIcon from '../../../../assets/locationIcon.svg';

interface IEquipmentCardProps {
	staticEquipment: IStaticEquipment;
	mergedEquipment: IMergedEquipmentView | undefined;
	index: number;
}

const EquipmentCard = (props: IEquipmentCardProps) => {
	const tz: string | undefined = useSelector(selectTimezone);

	const lastSeen = useMemo((): { date: string; floor: string } | undefined => {
		if (props.mergedEquipment) {
			return {
				floor: props.mergedEquipment.floorNick,
				date: props.mergedEquipment.lastSeenDate
					? getRelativeHour(props.mergedEquipment.lastSeenDate, tz!, translationService)
					: '',
			};
		}
		return undefined;
	}, [props.mergedEquipment]);

	return (
		<div className={classes.cardContainer}>
			<div className={classes.innerCardContainer}>
				<section className={classes.nameContainer}>
					<p className={classes.type}>{props.staticEquipment.name}</p>
					<p className={classes.serialNumber}>{props.staticEquipment.serialNumber}</p>
				</section>
				<section className={classes.lastSeenContainer}>
					{lastSeen ? (
						<>
							<div className={classes.location}>
								<img src={locationIcon} />
								<div className={classes.floorChip}>{lastSeen?.floor}</div>
							</div>
							<div className={classes.lastSeenDate}>{String(lastSeen?.date)} </div>
						</>
					) : (
						<div className={classes.notAvailable}>{translationService.get('notAvailable')}</div>
					)}
				</section>
			</div>
		</div>
	);
};

export { EquipmentCard };
