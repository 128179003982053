export interface IRecentSearchLocationItem {
	id: string;
	floorId?: string;
	areaId?: string;
}

interface IRecentSearchResults {
	[projectId: string]: IRecentSearchLocationItem[];
}

export class MonitorSearchRecentResultsLocalStorageService {
	static localStorageKey: string = 'recentSearchResults';

	static getRecentSearchResults(): IRecentSearchResults {
		const results: string | null = localStorage.getItem(this.localStorageKey);
		const parsedResults: IRecentSearchResults = results ? JSON.parse(results) : {};
		return parsedResults;
	}

	static getRecentSearchProjectResults(projectId: string): IRecentSearchLocationItem[] {
		const parsedResults: IRecentSearchResults = this.getRecentSearchResults();
		return parsedResults[projectId] || [];
	}

	static addRecentSearchResultItem(projectId: string, item: IRecentSearchLocationItem): void {
		const results: IRecentSearchResults = this.getRecentSearchResults();
		results[projectId] = [item, ...(results[projectId] || [])];
		localStorage.setItem(this.localStorageKey, JSON.stringify(results));
	}

	static clearRecentSearchResults(): void {
		localStorage.removeItem(this.localStorageKey);
	}

	static removeRecentSearchResultItem(projectId: string, id: string): void {
		const results: IRecentSearchResults = this.getRecentSearchResults();
		results[projectId] = results[projectId].filter((result) => result.id !== id);
		localStorage.setItem(this.localStorageKey, JSON.stringify(results));
	}
}
