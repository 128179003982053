import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@shared/components/Loader/Loader';
import { IActiveManager } from '@shared/interfaces/IActiveManager';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { selectUserPreference } from '../../../store/slices/userPreferences.slice';
import { translationService } from '../../../index';
import { WorkersPageSortOptions } from '../../../models/WorkerPageSortOptions';
import { WorkerPageViewTypes } from '../../../models/WorkerPageViewTypes';
import { FloorsWorkersDataList } from '../../FloorsWorkersDataList/FloorsWorkersDataList';
import { ProfessionWorkersDataList } from '../../ProfessionsWorkersDataList/ProfessionsWorkersDataList';
import classes from './styles.module.scss';
import { EmptyStateComponent } from '../../EmptyStateComponent/EmptyStateComponent';
import paintBrush from '../../../assets/Workers/paintBrush.svg';
import { workersPageAccess_BI } from '../../../utils/bi.utils';
import { isUserPreferencesDefined } from '../../../utils/filter.utils';
import { PageHeader } from '../../PageHeader';
import { ViewPreferences } from '../../ViewPreferences/ViewPreferences';
import { useActiveManagersQuery } from '../../../hooks/queries/managers.queries.hooks';
import { useWorkersDataByFloors, useWorkersDataByProfessions } from '../../../hooks/queries/workers.queries.hooks';

export const WorkersPage = ({ match }) => {
	useRenderMonitoring('WorkersPage');
	const [viewType, setViewType] = useState<WorkerPageViewTypes | undefined>(undefined);
	const [sortOption, setSortOption] = useState<WorkersPageSortOptions | undefined>(undefined);
	const [showEducation, setShowEducation] = useState<boolean>(false);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const activeManagers: IActiveManager[] = useActiveManagersQuery();
	const {
		professionsData,
		isLoading: isProfessionWorkersDataLoading,
		isError: isProfessionError,
	} = useWorkersDataByProfessions(
		sortOption,
		viewType === WorkerPageViewTypes.ALL_WORKERS ? null : userPreferences?.mobile?.filters?.professionIds
	);
	const {
		floorsData,
		isLoading: isFloorsWorkersDataLoading,
		isError: isFloorsError,
	} = useWorkersDataByFloors(
		sortOption,
		viewType === WorkerPageViewTypes.ALL_WORKERS ? null : userPreferences?.mobile?.filters?.floorIds
	);

	useEffect(() => {
		if (match.params?.mode === 'education') {
			setShowEducation(true);
		}
	}, [match.params]);

	const setViewTypeByFilters = (professionIds?: string[] | null, floorIds?: string[] | null) => {
		if ((professionIds && professionIds.length > 0) || (floorIds && floorIds.length > 0)) {
			setViewType(WorkerPageViewTypes.PERSONAL_VIEW);
		} else {
			setViewType(WorkerPageViewTypes.ALL_WORKERS);
		}
	};

	const setSortOptionByFilters = (professionIds?: string[] | null, floorIds?: string[] | null) => {
		if ((!professionIds || professionIds.length === 0) && floorIds && floorIds.length > 0) {
			setSortOption(WorkersPageSortOptions.BY_FLOORS);
		} else {
			setSortOption(WorkersPageSortOptions.BY_PROFESSIONS);
		}
	};

	useEffect(() => {
		workersPageAccess_BI();
	}, []);

	useEffect(() => {
		if (!isUserPreferencesDefined(userPreferences)) {
			setViewType(WorkerPageViewTypes.ALL_WORKERS);
			setSortOption(WorkersPageSortOptions.BY_PROFESSIONS);
			return;
		}

		const professionIds: string[] | null | undefined = userPreferences?.mobile?.filters.professionIds;
		const floorIds: string[] | null | undefined = userPreferences?.mobile?.filters.floorIds;
		setViewTypeByFilters(professionIds, floorIds);
		setSortOptionByFilters(professionIds, floorIds);
	}, [userPreferences]);

	if (isProfessionWorkersDataLoading || isFloorsWorkersDataLoading) return <Loader />;

	if (isProfessionError || isFloorsError) {
		throw Error('error');
	}

	const shouldDisplayEmptyState: boolean =
		sortOption === WorkersPageSortOptions.BY_PROFESSIONS
			? !!(professionsData && !professionsData.some((profession) => profession.workers?.length > 0))
			: !!(floorsData && !floorsData.some((floorData) => floorData.workers?.length > 0));

	const getPageContent = () => {
		if (shouldDisplayEmptyState) {
			return (
				<EmptyStateComponent
					icon={paintBrush}
					text={translationService.get('NoWorkersOnSite')}
					marginTop={'10px'}
				/>
			);
		}
		return sortOption === WorkersPageSortOptions.BY_PROFESSIONS ? (
			<ProfessionWorkersDataList professionsData={professionsData} viewType={viewType} />
		) : (
			<FloorsWorkersDataList activeManagers={activeManagers} floorsData={floorsData} viewType={viewType} />
		);
	};

	return (
		<div className={classes.workersPageContainer}>
			<PageHeader title={translationService.get('Personnel')} />
			<ViewPreferences
				setViewType={setViewType}
				viewType={viewType}
				setSortOption={setSortOption}
				sortOption={sortOption}
				showEducation={showEducation}
				setShowEducation={setShowEducation}
				professionData={professionsData}
			/>
			<div className={classes.workersDataContainer}>{getPageContent()}</div>
		</div>
	);
};
