import React, { useContext } from 'react';
import { TrusstorDrawer } from '@src/components/TrusstorDrawer/TrusstorDrawer';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { isUserPreferencesDefined } from '@utils/filter.utils';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { translationService } from '@src/index';
import { WorkerPageViewTypes } from '@src/models/WorkerPageViewTypes';
import { SelectOptions } from '@src/components/TrusstorDrawer/SelectOption/SelectOptions';
import { useHistory } from 'react-router';
import { SORT_ORDER } from '@shared/constants/constants';
import { MonitorMainPageContext } from '@src/components/pages/MonitorPage/MonitorMainPage/MonitorMainPage';
import { CustomizedOptionSection } from '@src/components/TrusstorDrawer/CustomizedOptionSection/CustomizedOptionSection';
import classes from './styles.module.scss';

interface IMonitorSettingsDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	setSortOption: (value: SORT_ORDER | undefined) => void;
	sortOption: SORT_ORDER | undefined;
}

export const MonitorSettingsDrawer = (props: IMonitorSettingsDrawerProps) => {
	const { setIsCustomizedViewType, isCustomizedSelected } = useContext(MonitorMainPageContext)!;
	const history = useHistory();
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const userPreferencesDefined: boolean = isUserPreferencesDefined(userPreferences);

	const handleViewChange = (value: string): void => {
		setIsCustomizedViewType(value === WorkerPageViewTypes.PERSONAL_VIEW);
	};

	const goToPreferencesPage = () => {
		history.push('/preferences');
	};

	const handleSortChange = (value: string): void => {
		props.setSortOption(value as SORT_ORDER);
	};

	const getUserPreferencesIdentifier = (): React.ReactNode => {
		if (!userPreferencesDefined) {
			return null;
		}
		return <CustomizedOptionSection userPreferences={userPreferences} />;
	};

	return (
		<div className={classes.monitorFilterDrawerContainer}>
			<TrusstorDrawer
				isOpen={props.isOpen}
				onClose={props.onClose}
				topSection={translationService.get('viewSettings')}
			>
				<div className={classes.container}>
					<SelectOptions
						title={translationService.get('view')}
						handleChange={handleViewChange}
						options={[
							{
								actionButton: {
									text: translationService.get(userPreferencesDefined ? 'edit' : 'create'),
									onClick: goToPreferencesPage,
								},
								isSelected: isCustomizedSelected,
								value: WorkerPageViewTypes.PERSONAL_VIEW,
								title: translationService.get('customized'),
								isDisabled: !userPreferencesDefined,
								children: getUserPreferencesIdentifier(),
							},
							{
								isSelected: !isCustomizedSelected,
								value: WorkerPageViewTypes.ALL_WORKERS,
								title: translationService.get('entireSite'),
							},
						]}
					/>
					<SelectOptions
						title={translationService.get('sortBy')}
						handleChange={handleSortChange}
						options={[
							{
								isSelected: props.sortOption === SORT_ORDER.ASCENDING,
								value: SORT_ORDER.ASCENDING,
								title: translationService.get('ascending'),
							},
							{
								isSelected: props.sortOption === SORT_ORDER.DESCENDING,
								value: SORT_ORDER.DESCENDING,
								title: translationService.get('descending'),
							},
						]}
					/>
				</div>
			</TrusstorDrawer>
		</div>
	);
};
