import React from 'react';
import { IMergedEquipmentView } from '@shared/interfaces/Equipment/IMergedEquipmentView';
import { Dictionary, groupBy } from 'lodash';
import { FloorEquipmentSection } from '@shared/components/FloorEquipmentSection/FloorEquipmentSection';
import { EquipmentTypes } from '@shared/constants/equipment.const';

interface IMergedEquipmentsCardProps {
	mergedEquipments: IMergedEquipmentView[];
}

export const MergedEquipmentsCards = (props: IMergedEquipmentsCardProps) => {
	const equipmentsByType: Dictionary<IMergedEquipmentView[]> = groupBy(props.mergedEquipments, (eq) => eq.type);
	return (
		<>
			{Object.entries(equipmentsByType).map(([type, equipments]) => (
				<FloorEquipmentSection type={type as EquipmentTypes} equipment={equipments} />
			))}
		</>
	);
};
