import { IUser } from '@shared/interfaces/IUser';
import { INotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IProfession } from '@shared/interfaces/IProfession';
import { IssueStatus } from '@shared/interfaces/IIssueShared';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { biService } from '../index';
import { BIEventNames } from '../interfaces/BI/BIEventNames.enum';
import { IIssue } from '../interfaces/IIssue';

interface IUserFloorsProfessionsPreferences extends IUser {
	floors: boolean;
	professions: boolean;
}

export const asiUpdatedStatus_BI = (asiUpdated: IMergedAreaSequenceItem) => {
	workplanActivityActions_BI({
		activityId: asiUpdated.activityId,
		groupStatus: asiUpdated.groupStatus,
		action: asiUpdated.status,
		areaId: asiUpdated.area.areaId,
		sequenceItemId: asiUpdated.sequenceItemId,
		description: asiUpdated.description,
	});
};

interface IWorkplanActivityActions_BI {
	activityId?: string;
	sequenceItemId?: string;
	areaId?: string;
	profession?: IProfession;
	description?: string;
	assignee?: string;
	groupStatus?: ActivityGroupStatus;
	action: AreaSequenceItemStatus;
}

export const workplanActivityActions_BI = ({
	activityId,
	profession,
	description,
	sequenceItemId,
	areaId,
	assignee,
	groupStatus,
	action,
}: IWorkplanActivityActions_BI) => {
	const eventData = {
		activityId,
		profession,
		sequenceItemId,
		areaId,
		description,
		assignee,
		groupStatus,
		action,
	};
	biService.logEvent(BIEventNames.workplanActivityActions, eventData);
};
export const issueComment_BI = (description: string, comment: string, author: string, type: string) => {
	const eventData = {
		description,
		comment,
		author,
		type,
	};
	biService.logEvent(BIEventNames.issueComment, eventData);
};

export const createIssue_BI = (issue: IIssue) => {
	biService.logEvent(BIEventNames.issueCreate, issue);
};
export const editIssue_BI = (issue: Partial<IIssue | IUpdateIssue>) => {
	biService.logEvent(BIEventNames.issueEdit, issue);
};
export const appBecameVisible_BI = () => {
	biService.logEvent(BIEventNames.appBecameVisible);
};
export const myInterestsDefinitions_BI = (userDetails: IUserFloorsProfessionsPreferences) => {
	biService.logEvent(BIEventNames.myInterestsDefinitions, userDetails);
};
export const welcomeScreenInterestsDefinitions_BI = (userDetails: IUserFloorsProfessionsPreferences) => {
	biService.logEvent(BIEventNames.welcomeScreenInterestsDefinitions, userDetails);
};
export const workersPageAccess_BI = () => {
	biService.logEvent(BIEventNames.workersMobileAccess);
};
export const preferencesPageAccess_BI = () => {
	biService.logEvent(BIEventNames.preferencesMobileAccess);
};
export const equipmentPageAccess_BI = () => {
	biService.logEvent(BIEventNames.equipmentMobileAccess);
};

export const activitiesPageAccess_BI = () => {
	biService.logEvent(BIEventNames.activitiesMobileAccess);
};

export const notificationsPageAccess_BI = () => {
	biService.logEvent(BIEventNames.notificationsMobileAccess);
};

export const notificationSettingsTabAccess_BI = () => {
	biService.logEvent(BIEventNames.notificationSettingsTabAccess);
};

export const viewSettingsOpen_BI = () => {
	biService.logEvent(BIEventNames.viewSettingsOpen);
};

export const viewSettingsUpdate_BI = (parameterChange: string, prevValue: string, newValue: string) => {
	biService.logEvent(BIEventNames.viewSettingsUpdate, { parameterChange, prevValue, newValue });
};

export const activitiesViewSettingsOpen_BI = () => {
	biService.logEvent(BIEventNames.activitiesViewSettingsOpen);
};

export const activitiesViewSettingsUpdate_BI = (parameterChange: string, prevValue: string, newValue: string) => {
	biService.logEvent(BIEventNames.activitiesViewSettingsUpdate, { parameterChange, prevValue, newValue });
};
export const notificationsSettingsPreferencesEngagement_BI = (
	notificationType: INotificationTypes,
	parameterChange
) => {
	biService.logEvent(BIEventNames.notificationsSettingsPreferencesEngagement, { notificationType, parameterChange });
};

export const issuesTabAccess_BI = () => {
	biService.logEvent(BIEventNames.issuesTabAccess);
};

export const issueStatusChange_BI = (issueUpdated: IIssue, newStatus: IssueStatus) => {
	const eventData = {
		issueId: issueUpdated._id,
		...issueUpdated,
		newStatus,
	};
	biService.logEvent(BIEventNames.issueStatusChange, eventData);
};

export const openPushNotificationsDrawer_BI = () => {
	biService.logEvent(BIEventNames.pushNotificationDialogShown);
};

export const pushNotificationDialogAction_BI = (action: 'enable' | 'rejected' | 'dismissed') => {
	biService.logEvent(BIEventNames.pushNotificationDialogAction, { action });
};

export const pushNotificationNativeDialogAction_BI = (action: 'enable' | 'rejected') => {
	biService.logEvent(BIEventNames.pushNotificationNativeDialogAction, { action });
};
