import React from 'react';
import { IToastMessage, IToastMessageTypes } from '@shared/interfaces/IToastMessages';
import { useDispatch } from 'react-redux';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { requestService, translationService } from '../../../servicesInitializer';
import { ActionConfirmationDialog } from '../ActionConfirmationDialog/ActionConfirmationDialog';
import { IMergedAreaSequenceItem } from '../../../interfaces/IMergedAreaSequenceItem';
import { setMessage } from '../../../store/slices/toastMessage.slice';
import { useAreaSequenceItemsByGroupIdQuery } from '../../../hooks/queries/sequenceItems.hooks';

interface IAreaSequenceMarkAllAsDoneDialogProps {
	show: boolean;
	onClose: () => void;
	activityGroupId: string;
	showNoWorkDetectedDisclaimerText?: boolean;
	disclaimerPoints?: string[];
	onAction?: () => void;
}

const AreaSequenceMarkAllAsDoneDialog = (props: IAreaSequenceMarkAllAsDoneDialogProps) => {
	const dispatch = useDispatch();
	const { areaSequenceItems, refetchAreaSequenceItems } = useAreaSequenceItemsByGroupIdQuery(props.activityGroupId);

	const markAllAsDoneAction = async () => {
		try {
			const allRelevantAreaSequenceItems: IMergedAreaSequenceItem[] = areaSequenceItems.filter(
				(areaSequence: IMergedAreaSequenceItem) => !isAsiComplete(areaSequence.status)
			);
			await Promise.all(
				allRelevantAreaSequenceItems.map((areaSequence: IMergedAreaSequenceItem) =>
					requestService.put(`/activities/sequenceItems/areaSequenceItem/done`, {
						body: {
							mergedAreaSequenceItem: areaSequence,
							isDone: !isAsiComplete(areaSequence.status),
						},
					})
				)
			);
			props.onAction?.();
			refetchAreaSequenceItems();
		} catch (err) {
			const message: IToastMessage = {
				text: translationService.get('tryAgain'),
				type: IToastMessageTypes.WARNING,
			};
			dispatch(setMessage({ message }));
		}
		props.onClose();
	};

	return (
		<ActionConfirmationDialog
			show={props.show}
			onClose={props.onClose}
			mainButtonAction={markAllAsDoneAction}
			secondaryButtonAction={props.onClose}
			title={translationService.get('markAllAsComplete')}
			text={translationService.get('isAllItemsCompleted')}
		/>
	);
};

export { AreaSequenceMarkAllAsDoneDialog };
