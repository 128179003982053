import { IUser } from '@shared/interfaces/IUser';
// eslint-disable-next-line import/no-cycle
import { initPostHogByUserData } from '@shared/utils/posthog.utils';
import { Environments } from '@shared/constants/environments';
import { initSentryUser } from '@shared/utils/monitoring.util';
import { initFullStoryByUserData } from '@shared/utils/fullstory.utils';
// eslint-disable-next-line import/no-cycle
import { biService } from '../servicesInitializer';

export const initBIByUserData = (userDetails: IUser): void => {
	biService.initUserIdentify({
		userId: userDetails._id,
		username: userDetails.username,
		email: userDetails.email,
		roleType: userDetails.permissions.roleType,
	});
};

export const initServicesDependentByUserData = (userDetails: IUser, projectId: string): void => {
	initBIByUserData(userDetails);
	initFullStoryByUserData(userDetails, projectId);
	initPostHogByUserData(userDetails, projectId, process.env.REACT_APP_STAGE as Environments);
	initSentryUser(userDetails);
};
