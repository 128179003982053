import React from 'react';
import {
	useProjectIssuesDeleteMutation,
	useProjectIssuesByLocationQuery,
	useUpdateIssueMutation,
	IssuesQueryKeysEnum,
	UseGetIssuesProps,
} from '@src/hooks/queries/issues.queries.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { IIssue } from '@interfaces/IIssue';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { IUser } from '@shared/interfaces/IUser';
import { DisplayIssues } from '@src/components/pages/MonitorPage/DisplayIssues/DisplayIssues';
import { getFloorResourcePageTitle } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { useCustomizedProfessions } from '@src/hooks/monitor.hooks';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import classes from './styles.module.scss';

interface IFloorIssuesPageProps {
	floorId: string;
}

const queryKey: IssuesQueryKeysEnum = IssuesQueryKeysEnum.getIssuesByFloor;

export const FloorMonitorIssuesPage = (props: IFloorIssuesPageProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const professionIds: string[] | null = useCustomizedProfessions();
	const getIssuesProps: UseGetIssuesProps = {
		queryKey,
		projectId,
		floorId: props.floorId,
		professionIds,
		notCompleted: true,
	};
	const { issues, isLoading } = useProjectIssuesByLocationQuery(getIssuesProps);

	const deleteIssueMutation = useProjectIssuesDeleteMutation(projectId);

	const deleteIssue = async (issueId: string) => {
		deleteIssueMutation.mutate(issueId);
	};

	const updateIssueMutation = useUpdateIssueMutation({
		getIssuesProps,
		user,
		dispatch,
	});

	const updateIssue = async (issueId: string, issue: Partial<IUpdateIssue>, updateBackend = true) => {
		const issueBeforeUpdate: IIssue = issues.find((issue) => issue._id === issueId)!;
		return updateIssueMutation.mutateAsync({
			issueBeforeUpdate,
			issueId,
			issueToUpdate: issue,
			updateBackend,
		});
	};

	return (
		<div className={classes.FloorIssuesPage_container} data-testid="FloorMonitorIssuesPage">
			<div className={classes.title}>
				{getFloorResourcePageTitle(MonitorResourcesEnum.ISSUES, issues?.length, isLoading)}
			</div>
			<DisplayIssues
				issues={issues}
				isIssuesLoading={isLoading}
				deleteIssue={deleteIssue}
				updateIssue={updateIssue}
				user={user}
			/>
		</div>
	);
};
