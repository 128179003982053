import React from 'react';
import classes from './styles.module.scss';

interface IManagerFloorCardProps {
	managerName: string;
	managerRole: string;
}

export const ManagerFloorCard = (props: IManagerFloorCardProps) => (
	<div className={classes.ManagerFloorCard_container} data-testid="ManagerFloorCard">
		<div>{props.managerName}</div>
		<div className={classes.managerRole}>{props.managerRole}</div>
	</div>
);
