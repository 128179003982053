import React, { useMemo } from 'react';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { useWorkersTagsQuery } from '@src/hooks/queries/workers.queries.hooks';
import { Dictionary, groupBy, uniq } from 'lodash';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { useManagersMergedTagsByLocationQuery } from '@src/hooks/queries/managers.queries.hooks';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { ManagersTagsCard } from '@src/components/pages/MonitorPage/ManagersTagsCard/ManagersTagsCard';
import { ProfessionTagsCard } from '@src/components/pages/MonitorPage/ProfessionTagsCard/ProfessionTagsCard';
import { getFloorResourcePageTitle } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { getSortedAreaIds } from '@shared/utils/sort.utils';
import { useAreasByFloorIdDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import classes from './styles.module.scss';

interface IFloorPersonnelPageProps {
	floorId: string;
}

export const FloorPersonnelPage = (props: IFloorPersonnelPageProps) => {
	const floorAreas: IConfigArea[] | undefined = useAreasByFloorIdDataQuery(props.floorId);
	const { workersTags, isWorkersLoading } = useWorkersTagsQuery(props.floorId);
	const { managers, isManagersLoading } = useManagersMergedTagsByLocationQuery(props.floorId);
	const managersByAreaId: Dictionary<IManagerMergedTag[]> = groupBy(managers, (tag) => tag.closestAnchor.areaId);
	const tagsByAreaId: Dictionary<IWorkerMergedTag[]> = groupBy(workersTags, (tag) => tag.closestAnchor.areaId);

	const areaIds: string[] = uniq([...Object.keys(managersByAreaId), ...Object.keys(tagsByAreaId)]);

	if (!floorAreas) {
		return null;
	}

	const sortedAreaIds: string[] = useMemo(() => getSortedAreaIds(floorAreas, areaIds), [floorAreas, areaIds]);

	const isLoading: boolean = isManagersLoading || isWorkersLoading;
	const resourceCount: number = workersTags.length + managers.length;
	return (
		<div className={classes.FloorPersonnelPage_container} data-testid="FloorPersonnelPage">
			<div className={classes.pageTitle}>
				{getFloorResourcePageTitle(MonitorResourcesEnum.PERSONNEL, resourceCount, isLoading)}
			</div>
			{sortedAreaIds.map((areaId) => {
				const areaNick: string = floorAreas.find((area) => area.areaId === areaId)?.areaNick ?? '';
				const areaWorkerTags: IWorkerMergedTag[] = tagsByAreaId[areaId] ?? [];
				const areaManagerTags: IManagerMergedTag[] = managersByAreaId[areaId] ?? [];
				const tagsByProfession: Dictionary<IWorkerMergedTag[]> = groupBy(
					areaWorkerTags,
					(tag) => tag.profession._id
				);
				return (
					<div key={areaId} className={classes.tagsContent}>
						<CollapsableContainer headerText={areaNick}>
							<div className={classes.content}>
								{Object.values(tagsByProfession).map((professionTags) => (
									<ProfessionTagsCard professionTags={professionTags} />
								))}
								{!!areaManagerTags.length && <ManagersTagsCard managersTags={areaManagerTags} />}
							</div>
						</CollapsableContainer>
					</div>
				);
			})}
		</div>
	);
};
