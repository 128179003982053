import { Route, Switch, useHistory } from 'react-router-dom';
import React, { createContext, useEffect, useState } from 'react';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import { MonitorFloorResourcePage } from '@src/components/pages/MonitorPage/MonitorFloorResourcePage/MonitorFloorResourcePage';
import { MonitorFloorPage } from '@src/components/pages/MonitorPage/MonitorFloorPage/MonitorFloorPage';
import { MonitorPage } from '@src/components/pages/MonitorPage/MonitorPage';
import { MonitorAreaPage } from '@src/components/pages/MonitorPage/MonitorAreaPage/MonitorAreaPage';
import { useAreasDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { isUserPreferencesDefined } from '@utils/filter.utils';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { SORT_ORDER } from '@shared/constants/constants';
import { MonitorSettingsDrawer } from '@src/components/pages/MonitorPage/MonitorSettingsDrawer/MonitorSettingsDrawer';
import { MonitorSearchDrawer } from '@src/components/pages/MonitorPage/MonitorSearch/MonitorSearchDrawer/MonitorSearchDrawer';
import { CustomizedViewLocalStorageService } from '../../../../services/customizedViewLocalStorage.service';

interface IMonitorMainPageContext {
	openSearch: () => void;
	isCustomizedSelected: boolean;
	setIsCustomizedViewType: (value: boolean) => void;
}

export const MonitorMainPageContext = createContext<IMonitorMainPageContext | undefined>(undefined);

export const MonitorMainPage = () => {
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const userPreferencesDefined: boolean = isUserPreferencesDefined(userPreferences);
	const isCustomizedViewDisabled: boolean = CustomizedViewLocalStorageService.getIsCustomizedViewDisabled();
	const [isCustomizedView, setIsCustomizedView] = useState<boolean>(
		!isCustomizedViewDisabled && userPreferencesDefined
	);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const [drawerSortOption, setDrawerSortOption] = useState<SORT_ORDER>(SORT_ORDER.ASCENDING);
	const history = useHistory();

	const setIsCustomizedViewType = (isCustomized: boolean) => {
		if (userPreferencesDefined) {
			CustomizedViewLocalStorageService.setIsCustomizedView(isCustomized);
		}
		setIsCustomizedView(isCustomized);
	};

	const setSortOption = (value: SORT_ORDER | undefined) => {
		setDrawerSortOption(value as SORT_ORDER);
	};

	useEffect(() => {
		const isCustomizedViewDisabledFromLocalStorage: boolean =
			CustomizedViewLocalStorageService.getIsCustomizedViewDisabled();
		if (isCustomizedViewDisabledFromLocalStorage) {
			setIsCustomizedViewType(false);
			return;
		}
		setIsCustomizedView(userPreferencesDefined);
	}, [userPreferencesDefined, userPreferences]);

	const closeSearch = () => {
		history.goBack();
	};

	const openSearch = () => {
		history.push(AppRoutes.monitorSearch);
	};

	const monitorPageComponent = () => (
		<MonitorPage setIsDrawerOpen={(value) => setIsDrawerOpen(value)} drawerSortOption={drawerSortOption} />
	);

	return (
		<MonitorMainPageContext.Provider
			value={{
				openSearch,
				setIsCustomizedViewType,
				isCustomizedSelected: isCustomizedView,
			}}
		>
			<div data-testid="MonitorMainPage">
				<MonitorSettingsDrawer
					isOpen={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					setSortOption={setSortOption}
					sortOption={drawerSortOption}
				/>
				<Switch>
					<Route path={AppRoutes.monitor} component={monitorPageComponent} exact />
					<Route
						path={AppRoutes.monitorSearch}
						component={() => <MonitorSearchDrawer closeSearch={closeSearch} />}
						exact
					/>
					<Route path={`${AppRoutes.monitorFloor}/:floorId/:resource`} component={MonitorFloorResourcePage} />
					<Route path={`${AppRoutes.monitorFloor}/:floorId`} component={MonitorFloorPage} />
					<Route path={`${AppRoutes.monitorArea}/:areaId`} component={MonitorAreaPage} />
					<Route path="*" component={monitorPageComponent} />
				</Switch>
			</div>
		</MonitorMainPageContext.Provider>
	);
};
