import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { IActiveManager } from '@shared/interfaces/IActiveManager';
import { IManager } from '@shared/interfaces/IManager';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { requestService } from '../../servicesInitializer';
import { selectProjectId } from '../../store/slices/project.slice';

export const useActiveManagersQuery = (): IActiveManager[] => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data: activeManagers } = useQuery<IActiveManager[]>(
		['activeManagers', projectId],
		() => requestService.get(`/location/merged/activeManagers?projectId=${projectId}`),
		{
			useErrorBoundary: false,
			initialData: [],
		}
	);
	return activeManagers.filter((manager) => !manager.disconnected);
};

export const useManagersQuery = (): IManager[] => {
	const projectId: string = useSelector(selectProjectId)!;

	const { data: managers } = useQuery<IManager[]>(
		['manager', projectId],
		() => requestService.get(`/projectConfig/managers/${projectId}`),
		{
			useErrorBoundary: false,
			initialData: [],
			cacheTime: Infinity,
		}
	);
	return managers;
};

export const useManagersMergedTagsByLocationQuery = (
	floorId?: string,
	areaId?: string
): { managers: IManagerMergedTag[]; isManagersLoading: boolean } => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data: managers, isInitialLoading: isManagersLoading } = useQuery<IManagerMergedTag[]>(
		['managerMergedTags', projectId],
		() =>
			requestService.get(`/location/merged/managersMergedTags/byLocation?projectId=${projectId}`, {
				params: {
					floorId,
					areaId,
				},
			}),
		{
			useErrorBoundary: false,
			initialData: [],
		}
	);
	return { managers, isManagersLoading };
};
