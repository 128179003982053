import { subscribeUserToPush } from '../apis/channelMessenger.api';
import { config } from '../config/config';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import { isPushNotificationsEnabledOnDevice } from './notifications.utils';

const handleRegisterServiceWorker = (username: string) => async (subscription: PushSubscription) => {
	await subscribeUserToPush(username, subscription);
};

export const registerServiceWorker = async (username: string) => {
	if (isPushNotificationsEnabledOnDevice() && Notification.permission === 'granted') {
		const pushVapidPublicKey: string = config.pushVapidPublicKey;
		serviceWorkerRegistration.register(pushVapidPublicKey, handleRegisterServiceWorker(username), config);
	}
};
