import { SORT_ORDER } from '@shared/constants/constants';
import { IConfigFloor } from '@shared/interfaces/IConfigFloor';
import { orderBy } from 'lodash';
import { IFloorWorkerData } from '../../interfaces/IFloorWorkerData';

const isNumeric = (value: string) => !Number.isNaN(Number(value));

const sortConfigFloors = (floors: IConfigFloor[]): IConfigFloor[] => {
	const numericFloorsIds = floors.filter((floor) => isNumeric(floor.floorId));
	const sortedNumericFloorsIds = orderBy(numericFloorsIds, (floor) => Number(floor.floorId), [SORT_ORDER.DESCENDING]);
	const nonNumericFloorsIds = floors.filter((floor) => !isNumeric(floor.floorId));
	const sortedNonNumericFloorsIds = orderBy(nonNumericFloorsIds, (floor) => floor.floorNick, [SORT_ORDER.ASCENDING]);
	return [...sortedNumericFloorsIds, ...sortedNonNumericFloorsIds];
};

export const addInactiveFloors = (
	workersDataFloors: IFloorWorkerData[],
	configFloors: IConfigFloor[]
): IFloorWorkerData[] => {
	const sortedConfigFloors: IConfigFloor[] = sortConfigFloors(configFloors);
	const workerDataFloors: IFloorWorkerData[] = sortedConfigFloors.map((floor) => {
		const floorData: IFloorWorkerData | undefined = workersDataFloors.find(
			(f) => f.floor.floorId === floor.floorId
		);
		if (floorData) {
			return floorData;
		}
		return {
			floor: {
				floorId: floor.floorId,
				floorNick: floor.floorNick,
			},
			professions: [],
			workers: [],
		};
	});
	return workerDataFloors;
};

export const filterFloorsByProfessionIdsPreferences = (
	workersDataFloors: IFloorWorkerData[],
	userPreferencesProfessionIds: string[],
	isPersonalView: boolean
): IFloorWorkerData[] => {
	if (!userPreferencesProfessionIds.length || !isPersonalView) {
		return workersDataFloors;
	}

	return workersDataFloors.map((floor) => {
		const filteredProfessions: IFloorWorkerData['professions'] = floor.professions.filter(({ profession }) =>
			userPreferencesProfessionIds.includes(profession._id)
		);
		return {
			...floor,
			professions: filteredProfessions,
		};
	});
};
