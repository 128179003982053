import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { Loader } from '@shared/components/Loader/Loader';
import { useSelector } from 'react-redux';
import { SORT_ORDER } from '@shared/constants/constants';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { sortFloors } from '@shared/utils/sort.utils';
import { IActivityByFloor, IActivityByFloorData } from '../../interfaces/IActivityByFloor';
import { IActivityRealtimeInfoByFloors } from '../../interfaces/IActivityRealtimeInfoByFloors';
import { useActivitiesRealtimeDataByFloorsQuery } from '../../hooks/queries/activities.queries.hooks';
import {
	addReportDataToActivitiesByFloors,
	filterActivityByFloorByUserPreferences,
	isActivityInStartedStatusFromToday,
} from '../../utils/activities.utils';
import { selectTimezone } from '../../store/slices/project.slice';
import { ActivitiesFloorCard } from '../pages/ActivitiesPage/ActivitiesFloorsCard/ActivitiesFloorCard';
import classes from './styles.module.scss';
import { ActivityByFloorActionsPopup } from '../ActivityByFloorActionsPopup/ActivityByFloorActionsPopup';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import { userAccessLevelService } from '../../index';

interface IMyWalkAroundActivitiesByFloorProps {
	activitiesReportData?: IWorkplanWidgetRealtimeActivityReport[];
	isPersonalView: boolean;
	refetchAllData: () => Promise<void>;
}

export const MyWalkAroundActivitiesByFloors = (props: IMyWalkAroundActivitiesByFloorProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const [activitiesByFloorToDisplay, setActivityByFloorToDisplay] = useState<IActivityByFloor | null>(null);
	const [selectedActivityForActions, setSelectedActivityForAction] = useState<IActivityByFloorData | null>(null);

	const {
		activitiesRealtimeByFloor,
		refetchFloors,
	}: {
		activitiesRealtimeByFloor: IActivityRealtimeInfoByFloors | undefined;
		refetchFloors: () => any;
	} = useActivitiesRealtimeDataByFloorsQuery([ActivityGroupStatus.overdue, ActivityGroupStatus.inProgress]);

	const filterActivitiesByProgressToday = (activitiesByFloor: IActivityByFloor): IActivityByFloor => {
		const activitiesByFloorFiltered: IActivityByFloor = {} as IActivityByFloor;
		Object.values(activitiesByFloor).forEach(({ floor, activitiesData }) => {
			const activitiesDataWithProgressToday: IActivityByFloorData[] = activitiesData.filter(
				(activityData) =>
					activityData.workHoursToday > 0 || isActivityInStartedStatusFromToday(activityData, tz)
			);

			if (activitiesDataWithProgressToday.length) {
				activitiesByFloorFiltered[floor.floorId] = {
					floor,
					activitiesData: activitiesDataWithProgressToday,
				};
			}
		});

		return activitiesByFloorFiltered;
	};

	useEffect(() => {
		if (!activitiesRealtimeByFloor) {
			return;
		}

		const activityByFloor: IActivityByFloor = addReportDataToActivitiesByFloors(
			activitiesRealtimeByFloor,
			props.activitiesReportData || []
		);

		const activitiesByFloorFiltered: IActivityByFloor = props.isPersonalView
			? filterActivityByFloorByUserPreferences(activityByFloor, userPreferences!)
			: activityByFloor;

		const activityByFloorResult: IActivityByFloor = filterActivitiesByProgressToday(activitiesByFloorFiltered);
		setActivityByFloorToDisplay(activityByFloorResult);
	}, [activitiesRealtimeByFloor, props.activitiesReportData, props.isPersonalView, userPreferences]);

	const refetchData = async () => {
		await Promise.all([props.refetchAllData(), refetchFloors()]);
	};

	const resetSelectedActivityForAction = useCallback(() => setSelectedActivityForAction(null), []);
	const changeSelectedActivityForAction = useCallback(
		(activity: IActivityByFloorData) => setSelectedActivityForAction(activity),
		[]
	);

	if (!activitiesByFloorToDisplay) {
		return <Loader />;
	}

	return (
		<>
			<div className={classes.activitiesCardsContainer}>
				{sortFloors<{ floor: IBaseFloor; activitiesData: IActivityByFloorData[] }>(
					Object.values(activitiesByFloorToDisplay),
					SORT_ORDER.DESCENDING,
					'floor.floorId'
				).map(({ floor, activitiesData }) => (
					<ActivitiesFloorCard
						floor={floor}
						activitiesData={activitiesData}
						key={floor.floorId}
						setSelectedActivityForAction={changeSelectedActivityForAction}
					/>
				))}
			</div>
			{selectedActivityForActions && userAccessLevelService.hasAccess('activitiesActions') && (
				<div>
					<ActivityByFloorActionsPopup
						activity={selectedActivityForActions}
						onClose={resetSelectedActivityForAction}
						onAction={refetchData}
					/>
				</div>
			)}
		</>
	);
};
