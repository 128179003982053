import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IMenuItem, TrusstorMenu } from '@shared/components/TrusstorMenu/TrusstorMenu';
import { useOutsideClick } from '@shared/hooks/custom.hooks';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { IIssue } from '@shared/interfaces/IIssue';
import { goToIssuesPage } from '../../utils/activities.utils';
import { translationService } from '../../servicesInitializer';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';
import { selectIsLimitedUser } from '../../store/slices/login.slice';

interface IActivityMenuProps {
	onCreateIssueClick: () => void;
	groupId?: string;
	issues?: IIssue[];
	menuIconColor?: IconColor;
	onViewIssuesClick?: () => void;
}

export const ActivityMenu = (props: IActivityMenuProps) => {
	const isLimitedUser: boolean = useSelector(selectIsLimitedUser);
	const [showMenu, setShowMenu] = React.useState<boolean>(false);
	const isRtl: boolean = translationService.getIsRtl();
	const history = useHistory();
	const outerRef = useRef(null);
	const ref = useRef(null);

	const doesIssuesExist: boolean = !!props.issues?.length;
	const activityMenuItems: IMenuItem[] = [
		...(!isLimitedUser
			? [
					{
						title: translationService.get('createIssue'),
						iconComponent: <TrusstorIcon iconName={IconNames.plus} size={IconSize.SMALL} />,
						onClick: () => {
							setShowMenu(false);
							props.onCreateIssueClick();
						},
					},
				]
			: []),
		...(doesIssuesExist
			? [
					{
						title: translationService.get('viewIssues'),
						iconComponent: <TrusstorIcon iconName={IconNames.issues} size={IconSize.SMALL} />,
						onClick: () => {
							setShowMenu(false);
							if (props.onViewIssuesClick) {
								props.onViewIssuesClick();
								return;
							}

							if (props.groupId) {
								goToIssuesPage(history, props.groupId);
							}
						},
					},
				]
			: []),
	];

	useOutsideClick(ref, setShowMenu, false, [outerRef]);

	if (!activityMenuItems.length) {
		return null;
	}
	return (
		<div className={classes.wrapper} ref={outerRef}>
			<div className={classes.image} onClick={() => setShowMenu((prev) => !prev)}>
				<TrusstorIcon iconName={IconNames.dotsHorizontal} color={props.menuIconColor} />
			</div>
			{showMenu && (
				<div className={classNames(classes.menuWrapper, { [classes.isRtl]: isRtl })} ref={ref}>
					<TrusstorMenu items={activityMenuItems} />
				</div>
			)}
		</div>
	);
};
