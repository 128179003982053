import { IProfession } from '@shared/interfaces/IProfession';
import * as React from 'react';
import { ArrowUpDown } from '@shared/components/ArrowUpDown/ArrowUpDown';
import { useState } from 'react';
import { IActiveManager } from '@shared/interfaces/IActiveManager';
import { FloorHeader } from '../WorkersPageFloorHeader/WorkersPageFloorHeader';
import WorkerPageProfessionRow from '../WorkerPageProfessionRow/WorkerPageProfessionRow';
import { WorkersIcon } from '../WorkersIcon/WorkersIcon';
import classes from './styles.module.scss';
import { getFloorTotalWorkers } from './floorWorkerData.utils';
import floorsIcon from '../../assets/floorsIcon.svg';
import { WorkerFloorDrawer } from '../WorkerFloorDrawer/WorkerFloorDrawer';
import { IWorkerMergedTag } from '../../interfaces/IWorkerMergedTag';
import { IFloorWorkerData } from '../../interfaces/IFloorWorkerData';
import { ManagerFloorCard } from '../ManagerFloorCard/ManagerFloorCard';
import { getTradeTranslation } from '../../utils/translations.utils';

export interface IFloorWorkerDataProps {
	floor: IFloorWorkerData;
	activeManagers: IActiveManager[];
}

export const FloorWorkerData = (props: IFloorWorkerDataProps) => {
	const floorManagers: IActiveManager[] = props.activeManagers.filter(
		(manager) => manager.currentFloor?.floorId === props.floor.floor.floorId
	);
	const totalWorkers: number = getFloorTotalWorkers(props.floor, floorManagers);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [selectedProfession, setSelectedProfession] = useState<IProfession>();
	const [currentFloorWorkers, setCurrentFloorWorkers] = useState<IFloorWorkerData['workers']>();
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const openDrawer = (profession: IProfession) => {
		const workersFromProfession: IFloorWorkerData['workers'] = props.floor.workers.filter(
			(worker) => (worker as IWorkerMergedTag).profession?._id === profession._id
		);
		setIsDrawerOpen(true);
		setCurrentFloorWorkers(workersFromProfession);
		setSelectedProfession(profession);
	};

	return (
		<div className={`${classes.container} ${totalWorkers === 0 ? classes.inactive : ''}`}>
			<div className={classes.headers} onClick={() => setIsExpanded((prevState) => !prevState)}>
				<div className={classes.floorsTextWrapper}>
					<img className={classes.floorIcon} src={floorsIcon} />
					<FloorHeader floor={props.floor.floor}></FloorHeader>
				</div>
				<div className={classes.rightSection}>
					<WorkersIcon workersAmount={totalWorkers} />
					{totalWorkers > 0 && <ArrowUpDown isOpen={isExpanded} />}
				</div>
			</div>
			{isExpanded && (
				<>
					{props.floor.professions.map(
						({ profession, workersCount }: { profession: IProfession; workersCount: number }) => (
							<WorkerPageProfessionRow
								onClick={() => openDrawer(profession)}
								key={profession._id}
								profession={profession}
								workersCount={workersCount}
							/>
						)
					)}
					{!!floorManagers.length &&
						floorManagers.map((manager) => (
							<ManagerFloorCard managerName={manager.name} managerRole={getTradeTranslation(manager)} />
						))}
				</>
			)}

			<WorkerFloorDrawer
				isOpen={isDrawerOpen}
				currentFloorData={props.floor.floor}
				onClose={() => setIsDrawerOpen(false)}
				profession={selectedProfession!}
				currentFloorWorkers={currentFloorWorkers}
			/>
		</div>
	);
};

export default FloorWorkerData;
