import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IProfession } from '@shared/interfaces/IProfession';
import { useQuery } from '@tanstack/react-query';
import { IProfessionWorkerData } from '@interfaces/IProfessionWorkerData';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import { WorkerPageViewTypes } from '../../models/WorkerPageViewTypes';
import ProfessionWorkerData from '../ProfessionWorkerData/ProfessionWorkerData';
import { addInactiveProfessions, filterFloorsByPreferences, sortProfessions } from './ProfessionsWorkersDataList.utils';
import { requestService } from '../../index';
import { IRootState } from '../../store/slices';
import { PersonalViewEmptyState } from '../pages/PersonalViewEmptyState/PersonalViewEmptyState';
import { isUserPreferencesDefined } from '../../utils/filter.utils';
import { ActiveManagersList } from '../ActiveManagersList/ActiveManagersList';

interface IProfessionWorkersDataListProps {
	viewType?: WorkerPageViewTypes;
	professionsData?: IProfessionWorkerData[];
}

export const ProfessionWorkersDataList = (props: IProfessionWorkersDataListProps) => {
	const projectId: string | undefined = useSelector(
		(state: IRootState) => state.projectReducer.workingProject?.projectId
	);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const { data: projectProfessions } = useQuery<IProfession[]>(
		['professionsData', projectId],
		() => requestService.get(`/projectConfig/professions/${projectId}`),
		{
			useErrorBoundary: true,
		}
	);

	const getEmptyPageComponent = () => {
		if (props.viewType === WorkerPageViewTypes.PERSONAL_VIEW) {
			return <PersonalViewEmptyState />;
		}
		return <Redirect to="/projectNotReady" />;
	};

	if (!props.professionsData || !projectProfessions) {
		// implement loader here
		return <div></div>;
	}

	// return empty page when no preferences are set and on personal view
	if (
		(props.viewType === WorkerPageViewTypes.PERSONAL_VIEW && !isUserPreferencesDefined(userPreferences)) ||
		projectProfessions.length === 0
	) {
		return getEmptyPageComponent();
	}

	let professionsDataList = [...props.professionsData];
	if (props.viewType === WorkerPageViewTypes.PERSONAL_VIEW) {
		const doesPreferencesProfessionIdsExists: boolean = !!(
			userPreferences!.mobile?.filters.professionIds && userPreferences!.mobile?.filters.professionIds.length > 0
		);

		if (doesPreferencesProfessionIdsExists) {
			const userPreferencesProfessionsIds: string[] | null = userPreferences!.mobile!.filters.professionIds;
			if (userPreferencesProfessionsIds) {
				professionsDataList = addInactiveProfessions(
					professionsDataList,
					projectProfessions,
					userPreferencesProfessionsIds
				);
			}
		}

		const doesPreferencesFloorIdsExists: boolean = !!(
			userPreferences!.mobile?.filters.floorIds && userPreferences!.mobile?.filters?.floorIds.length > 0
		);
		if (doesPreferencesFloorIdsExists) {
			const userPreferencesFloorIds: string[] | null = userPreferences!.mobile!.filters?.floorIds;
			if (userPreferencesFloorIds) {
				professionsDataList = filterFloorsByPreferences(professionsDataList, userPreferencesFloorIds);
			}
		}
	}

	const sortedProfessionsComponentData = sortProfessions(professionsDataList);
	return (
		<React.Fragment>
			{sortedProfessionsComponentData.map((profession) => (
				<ProfessionWorkerData profession={profession} key={profession.profession._id} />
			))}
			<ActiveManagersList viewType={props.viewType} />
		</React.Fragment>
	);
};
