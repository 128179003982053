import React from 'react';
import { useSelector } from 'react-redux';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { translationService } from '../../index';
import classes from './styles.module.scss';
import { selectLoggedUser } from '../../store/slices/login.slice';

function EmptyModePage() {
	useRenderMonitoring('EmptyModePage');
	const welcomeText: string = translationService.get('welcomeText');
	const userName: string | undefined = useSelector(selectLoggedUser)?.userDetails.username;

	const welcomeUserName: string = `${welcomeText} ${userName},`;
	const uncompletedProjectSetupText: string = translationService.get('uncompletedProjectSetup');
	return (
		<div className={classes.textContainer}>
			<p className={classes.welcomeText}>{welcomeUserName}</p>
			<p className={classes.pageText}>{uncompletedProjectSetupText}</p>
		</div>
	);
}

export default EmptyModePage;
