import { CustomSelect } from '@shared/components/Dropdowns/CustomSelect/CustomSelect';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../store/slices';
import { changeWorkingProject } from '../../../store/thunks/project.thunks';
import classes from './styles.module.scss';

export const ProjectSelector = () => {
	const dispatch = useDispatch();

	const loggedUserRole: RoleType | undefined = useSelector(
		(state: IRootState) => state.loginReducer.loggedUser?.userDetails.permissions.roleType
	);
	const userProjects = useSelector((state: IRootState) => state.projectReducer.userProjects);
	const workingProject = useSelector((state: IRootState) => state.projectReducer.workingProject);

	const ProjectItemComponent = ({ item, selectedItem, changeSelected }) => (
		<div
			key={item.projectId}
			className={classes.listItemContainer}
			onClick={() => (item.name !== selectedItem ? changeSelected(item) : null)}
		>
			<p className={classes.listItem}>{item.name}</p>
		</div>
	);

	const renderDropdown = () => {
		if (loggedUserRole === RoleType.TRUSSTOR_ADMIN && userProjects && userProjects.length > 1) {
			return (
				<CustomSelect
					currentValue={workingProject?.name}
					placeholder={workingProject?.name}
					list={userProjects}
					selectHandler={({ projectId }) => {
						dispatch(changeWorkingProject(projectId));
					}}
					menuItemComponent={ProjectItemComponent}
					minClosedHeight={38}
					rootStyleClass={classes.projectSelect}
					disabled={undefined}
					isBlackMenu={undefined}
					fieldToDisplay={undefined}
					idField={undefined}
					errorMessage={undefined}
					isError={undefined}
					errorEnabled={undefined}
					headerContainerMarginTop={'8px'}
				/>
			);
		}
		return <p className={classes.headerTitle}>{workingProject?.name}</p>;
	};

	return <div className={classes.container}>{renderDropdown()}</div>;
};
