import React, { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import {
	convertDateObjectToServerFormat,
	getProjectDateWithBrowserTZOffset,
	getTimezoneStartOfDate,
} from '@shared/utils/dateUtils';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { DatePickerHeader } from '@shared/components/DatePickerHeader/DatePickerHeader';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { dateFormats } from '@shared/constants/formats.constants';
import { TrusstorIcon } from '../../TrusstorIcon/TrusstorIcon';
import { selectTimezone } from '../../../store/slices/project.slice';
import { translationService } from '../../../servicesInitializer';
import { DrawerDialog } from '../../Dialogs/DrawerDialog/DrawerDialog';
import classes from './styles.module.scss';

interface ISelectSectionProps {
	iconName: IconNames;
	onClick: (date: Date) => void;
	selectedDate: Date | null;
	minDate?: Date;
	sectionTitle: string;
}

export const DateSelector = (props: ISelectSectionProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const [showCalendar, setShowCalendar] = React.useState<boolean>(false);

	const dateFormat: string = `${translationService.getDateFormat().replaceAll('.', '/')}`;

	const DateInput = forwardRef(({ value, onClick }: any, ref: any) => (
		<div className={classes.selectSection} onClick={onClick} ref={ref}>
			<TrusstorIcon iconName={props.iconName} size={IconSize.MEDIUM} color={IconColor.Grey500} />
			<div className={classes.text}>
				{!value
					? translationService.get('mobile_issue_dueDate')
					: moment.tz(value, dateFormats.MSDSY, tz).format(dateFormat)}
			</div>
		</div>
	));

	const changeSelectedDate = (date: Date) => {
		props.onClick(getTimezoneStartOfDate(tz, convertDateObjectToServerFormat(date)));
	};

	return (
		<div>
			<DateInput onClick={() => setShowCalendar(true)} value={props.selectedDate} />
			<DrawerDialog
				title={props.sectionTitle}
				isOpen={showCalendar}
				onClose={() => setShowCalendar(false)}
				maxHeightClass={classes.drawerHeight}
			>
				<div className={classes.datePickerContainer}>
					<DatePicker
						inline
						renderCustomHeader={({
							monthDate,
							decreaseMonth,
							increaseMonth,
							prevMonthButtonDisabled,
							nextMonthButtonDisabled,
						}) => (
							<DatePickerHeader
								monthDate={monthDate}
								decreaseMonth={decreaseMonth}
								increaseMonth={increaseMonth}
								prevMonthButtonDisabled={prevMonthButtonDisabled}
								nextMonthButtonDisabled={nextMonthButtonDisabled}
							/>
						)}
						formatWeekDay={(nameOfDay) => translationService.get(`datepicker_weekday_${nameOfDay}`)}
						selected={props.selectedDate ? getProjectDateWithBrowserTZOffset(tz, props.selectedDate) : null}
						onChange={(date: Date) => changeSelectedDate(date)}
						minDate={props.minDate ? getProjectDateWithBrowserTZOffset(tz, props.minDate) : null}
					/>
				</div>
			</DrawerDialog>
		</div>
	);
};
