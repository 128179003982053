import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { IActivityByProgress } from '../../../interfaces/IActivityByProgress';
import { ActivityPageSortOptions } from '../../../models/ActivityPageSortOptions';
import { IActivityRealtimeInfo } from '../../../interfaces/IActivityRealtimeInfo';
import { isUserPreferencesDefined } from '../../../utils/filter.utils';

export const filteredActivitiesByUserPreferencesProgressView = (
	activities: IActivityByProgress[] | IActivityRealtimeInfo[],
	userPreferences: IUserPreferences
) => {
	if (!isUserPreferencesDefined(userPreferences)) {
		return activities;
	}

	const { floorIds, professionIds } = userPreferences.mobile!.filters;

	const activitiesFilteredByProfessions: IActivityByProgress[] | IActivityRealtimeInfo[] = !professionIds
		? activities
		: activities.filter(
				(activity) => !!userPreferences.mobile?.filters?.professionIds?.includes(activity.profession._id)
		  );

	return !floorIds
		? activitiesFilteredByProfessions
		: activitiesFilteredByProfessions.filter((activity) =>
				activity.floors.some(
					(floor) => !!userPreferences.mobile?.filters?.floorIds?.includes(floor.floor.floorId)
				)
		  );
};

export const getDefaultValueForPersonalViewState = (
	userPreferences: IUserPreferences | null
): ActivityPageSortOptions => {
	if (!isUserPreferencesDefined(userPreferences)) {
		return ActivityPageSortOptions.ALL_ISSUES;
	}

	return ActivityPageSortOptions.PERSONAL_VIEW;
};

export const getProgressActivitiesAfterUserPreferencesFilter = (
	activities: IActivityByProgress[] | IActivityRealtimeInfo[],
	userPreference: IUserPreferences | null,
	personalViewState: ActivityPageSortOptions
) => {
	if (
		!userPreference ||
		!(userPreference.mobile?.filters?.professionIds || userPreference.mobile?.filters?.floorIds)
	) {
		return activities;
	}

	if (personalViewState === ActivityPageSortOptions.PERSONAL_VIEW) {
		return filteredActivitiesByUserPreferencesProgressView(activities, userPreference);
	}

	return activities;
};
