import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import classes from './styles.module.scss';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { translationService } from '../../servicesInitializer';
import { IRootState } from '../../store/slices';
import { PushPermissionLocalStorageService } from '../../services/pushPermissionLocalStorage.service';
import { pushNotificationNativeDialogAction_BI } from '../../utils/bi.utils';
import { registerServiceWorker } from '@utils/serviceWorkerRegistration.utils';

interface IPushNotificationsEnableCardProps {
	onButtonClick?: (action: 'enable' | 'rejected') => void;
	isTextCentered?: boolean;
	onPermissionSet?: () => void;
	showNotNowButton?: boolean;
}

export const PushNotificationsEnableCard = (props: IPushNotificationsEnableCardProps) => {
	const loggedUser: ILoggedUser | null = useSelector((state: IRootState) => state.loginReducer.loggedUser);

	const requestNotificationPermission = async (): Promise<boolean> => {
		if (Notification.permission === 'default') {
			const permission = await Notification.requestPermission();
			if (permission !== 'default') {
				PushPermissionLocalStorageService.setIsPushPermissionDrawerShown(
					loggedUser?.userDetails.username,
					true
				);
				pushNotificationNativeDialogAction_BI(permission === 'granted' ? 'enable' : 'rejected');
			}
			return permission === 'granted';
		}
		return Notification.permission === 'granted';
	};

	const requestPermissionAndRegisterSW = async () => {
		if (!loggedUser?.userDetails.username) {
			return;
		}
		const isGratnedPermission: boolean = await requestNotificationPermission();
		props.onPermissionSet?.();
		if (!isGratnedPermission) {
			return;
		}
		registerServiceWorker(loggedUser.userDetails.username);
	};

	return (
		<div
			className={classes.contentContainer}
			style={{ alignItems: props.isTextCentered ? 'center' : 'flex-start' }}
		>
			<div className={classes.header}>
				<TrusstorIcon iconName={IconNames.bell} />
				<p className={classes.pushNotificationText}>{translationService.get('turnOnNotifications')}</p>
			</div>
			<p className={classes.enablePushText} style={{ textAlign: props.isTextCentered ? 'center' : 'start' }}>
				{translationService.get('enablePushNotificationsMessage')}
			</p>
			<TrusstorButton
				className={classes.fullWidthButton}
				text={translationService.get('turnOn')}
				handleClick={async () => {
					await requestPermissionAndRegisterSW();
					props.onButtonClick?.('enable');
				}}
			/>

			{props.showNotNowButton && (
				<TrusstorButton
					className={classes.fullWidthButton}
					text={translationService.get('notNow')}
					buttonType={ITrusstorButtonType.GHOST}
					handleClick={() => {
						PushPermissionLocalStorageService.setIsPushPermissionDrawerShown(
							loggedUser?.userDetails.username,
							true
						);
						props.onButtonClick?.('rejected');
					}}
				/>
			)}
		</div>
	);
};
