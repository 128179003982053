import { IProfession } from '@shared/interfaces/IProfession';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { IProfessionWorkerData } from '@shared/interfaces/IProfessionWorkerData';
import { selectProjectId } from '../../store/slices/project.slice';
import { requestService } from '../../index';

export const useProfessionsQuery = (): IProfession[] => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: professions } = useQuery<IProfession[]>(
		['professions', projectId],
		() => requestService.get(`/projectConfig/professions/${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
			cacheTime: Infinity,
		}
	);
	return professions;
};

export const useWorkingProfessionsQuery = (timezoneDate: string): IProfession[] => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: professions } = useQuery<IProfession[]>(
		['professionsData', projectId],
		() => requestService.get(`/projectConfig/professions/${projectId}/working?startDate=${timezoneDate}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return professions;
};

export const useProjectWorkersQuery = (professionIds?: string[]): IProfessionWorkerData[] => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const professionIdsQueryParams: string = !professionIds?.length
		? ''
		: `&${professionIds.map((professionId) => `professionsIds[]=${professionId}`).join('&')}`;
	const { data: professionWorkersData } = useQuery<IProfessionWorkerData[]>(
		['workersData', projectId, professionIds],
		() =>
			requestService.get(
				`/location/mobile/workers/byProfessions/?projectId=${projectId}${professionIdsQueryParams}`
			),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return professionWorkersData;
};
