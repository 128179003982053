import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import workerIcon from '@shared/assets/icons/workersBlueIcon.svg';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import fullArrowRight from '@shared/assets/icons/fullArrowRight.svg';
import { ISingleActivityRealtimeInfoShared } from '@shared/interfaces/ISingleActivityRealtimeInfoShared';
import { IProfession } from '@shared/interfaces/IProfession';
import { uniq } from 'lodash';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { ActivitiesFloorCard } from '../ActivitiesPage/ActivitiesFloorsCard/ActivitiesFloorCard';
import classes from './styles.module.scss';
import { requestService, translationService } from '../../../index';
import { UnassignedActivityFloorCard } from '../ActivitiesPage/ActivitiesFloorsCard/UnassignedActivityFloorCard';
import { IUnassignedActivity } from '../../../interfaces/IUnassignedActivity';
import { IActivityRealtimeInfoByFloors } from '../../../interfaces/IActivityRealtimeInfoByFloors';

interface IActivitiesToShow {
	assignedActivities: IActivityRealtimeInfoByFloors | null;
	unAssignedActivities: IUnassignedActivity[] | null;
}

interface IWorkersOnSitePageProps {
	goBack?: () => void;
	professionId?: string;
}

const WorkersOnSite = (props: IWorkersOnSitePageProps) => {
	useRenderMonitoring('WorkersOnSite');
	const projectId: string = useSelector(selectProjectId)!;
	const useQueryParams = () => new URLSearchParams(useLocation().search);
	const query: URLSearchParams = useQueryParams();
	const headerEl = useRef<HTMLHeadingElement>(null);
	const history = useHistory();
	const [scrollContainerHeight, setScrollContainerHeight] = useState<string>('100%');

	const [activitiesToShow, setActivitiesToShow] = useState<IActivitiesToShow>({
		assignedActivities: null,
		unAssignedActivities: null,
	});

	const goBack = () => {
		props.goBack ? props.goBack() : history.goBack();
	};

	const fetchAssignedAndUnassignedActivities = async (professionId: string | null) => {
		if (!professionId) goBack();
		try {
			const professionActivitiesRealTimeInfoByFloor: IActivityRealtimeInfoByFloors = await requestService.get(
				`/activities/groups/onGoingAndCompleteTodayRealtimeActivitiesInfoByFloors?professionId=${professionId}`
			);
			const unAssignedActivities: IUnassignedActivity[] = await requestService.get(
				`/activities/unassigned/byProfessionId/${professionId}?includeVisibleTags=true`
			);
			setActivitiesToShow({
				assignedActivities: professionActivitiesRealTimeInfoByFloor,
				unAssignedActivities,
			});
		} catch (err) {
			// todo handle error with empty state
			console.error(`Failed to load Activities with professionId: ${professionId}`, err);
			throw err;
		}
	};

	useEffect(() => {
		const professionId: string | null = props.professionId || query.get('professionId');
		fetchAssignedAndUnassignedActivities(professionId);
	}, []);

	useEffect(() => {
		const headerHeight: number = headerEl.current?.clientHeight || 0;
		setScrollContainerHeight(`calc(100% - ${headerHeight + 75}px)`);
	}, [headerEl.current]);

	const getTotalWorkers = (): number => {
		const totalWorkers: string[] = [];
		if (activitiesToShow.assignedActivities) {
			Object.values(activitiesToShow.assignedActivities).forEach((floorActivities) => {
				floorActivities.activitiesData.forEach((activity) => {
					totalWorkers.push(...(activity.tags || []));
				});
			});
		}
		if (activitiesToShow.unAssignedActivities) {
			activitiesToShow.unAssignedActivities.forEach((unAssignedActivity) => {
				totalWorkers.push(...(unAssignedActivity.tags || []));
			});
		}
		return uniq(totalWorkers).length;
	};

	const assignedActivitiesValues: {
		floor: IBaseFloor;
		activitiesData: ISingleActivityRealtimeInfoShared<IProfession>[];
	}[] = Object.values(activitiesToShow.assignedActivities || {});

	const isRtl: boolean = translationService.getIsRtl();
	return (
		<section className={classes.workersOnSiteContainer}>
			<div className={classes.header} ref={headerEl}>
				<section className={classes.backContainer} onClick={() => goBack()}>
					<img src={fullArrowRight} alt="<" className={isRtl ? classes.rotateArrow : ''} />
					<div className={classes.backButton}>{translationService.get('back')}</div>
				</section>
				<section className={classes.professionContainer}>
					{activitiesToShow.assignedActivities && assignedActivitiesValues.length > 0 && (
						<div>
							<ProfessionDisplayWithTradeIcon
								profession={assignedActivitiesValues[0].activitiesData[0].profession}
								projectId={projectId}
							/>
						</div>
					)}
					<div className={classes.professionDescription}>
						<img src={workerIcon} alt={'worker icon'} />
						<div className={classes.totalWorkers}>{getTotalWorkers()}</div>
						<div className={classes.totalText}>{translationService.get('Total')}</div>
					</div>
				</section>
			</div>
			<div className={classes.scrollContainer} style={{ height: scrollContainerHeight }}>
				{activitiesToShow.assignedActivities &&
					assignedActivitiesValues.reverse().map((professionFloorActivities) => (
						<div
							className={classes.workersOnSiteCardWrapper}
							key={`assignedCard_${professionFloorActivities.floor.floorId}`}
						>
							<ActivitiesFloorCard
								floor={professionFloorActivities.floor}
								activitiesData={professionFloorActivities.activitiesData}
								displayPartialCard
								clearMargin
							/>
						</div>
					))}
				{activitiesToShow.unAssignedActivities &&
					activitiesToShow.unAssignedActivities.map((activity) => (
						<div className={classes.workersOnSiteCardWrapper}>
							<UnassignedActivityFloorCard unAssignedActivity={activity} clearMargin />
						</div>
					))}
			</div>
		</section>
	);
};
export { WorkersOnSite };
