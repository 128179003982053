import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import filledRadioButtonIcon from '../../../assets/filled_radio_button.svg';
import radioButtonIcon from '../../../assets/radio_button.svg';

interface ISelectButton {
	text: string;
	onClick: () => void;
}

export interface ISelectOption {
	title: string;
	value: any;
	children?: React.ReactNode;
	actionButton?: ISelectButton;
	isSelected?: boolean;
	isDisabled?: boolean;
}

interface ISelectOptionProps {
	handleChange: (value: string) => any;
	title: string;
	options: ISelectOption[];
}

export const SelectOptions = (props: ISelectOptionProps) => (
	<div className={classes.selectOptions}>
		<div className={classes.sectionTitle}>{props.title}</div>
		{props.options.map((option: ISelectOption) => (
			<div
				className={classes.optionWrapper}
				onClick={() => !option.isDisabled && props.handleChange(option.value)}
			>
				<div className={classes.option}>
					<img src={option.isSelected ? filledRadioButtonIcon : radioButtonIcon} alt="" />
					<div className={classes.optionSections}>
						<div className={classes.titleWrapper}>
							<div className={classnames(classes.title, { [classes.disabled]: option.isDisabled })}>
								{option.title}
							</div>
							{option.children}
						</div>
						<div className={classes.actionButton} onClick={option.actionButton?.onClick}>
							{option.actionButton?.text}
						</div>
					</div>
				</div>
			</div>
		))}
	</div>
);
