import React, { useEffect, useState } from 'react';
import { translationService } from '@src/servicesInitializer';
import { ActivityPageSortOptions } from '@src/models/ActivityPageSortOptions';
import { activitiesPageAccess_BI, activitiesViewSettingsUpdate_BI } from '@utils/bi.utils';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { Loader } from '@shared/components/Loader/Loader';
import { useMainPageActivities } from '@src/hooks/queries/activities.queries.hooks';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { getMainPageActivitiesAfterUserPreferencesFilter } from '@src/components/pages/ActivitiesPageV2/utils';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { getDefaultValueForPersonalViewState } from '@src/components/pages/ActivitiesPage/utils';
import { isUserPreferencesDefined } from '@utils/filter.utils';
import { EmptyStateComponent } from '@src/components/EmptyStateComponent/EmptyStateComponent';
import settingsIcon from '@shared/assets/icons/settings_icon.svg';
import { useFilteredMainPageActivities } from '@shared/hooks/useFilteredMainPageActivities.hook';
import { WidgetActivityFilters } from '@shared/interfaces/WidgetActivityFilters';
import { ActivitiesPageHeader } from '@src/components/pages/ActivitiesPageV2/ActivitiesPageHeader/ActivitiesPageHeader';
import { ActivityCard } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCard';
import { OpenActivitiesList } from '@src/components/pages/ActivitiesPageV2/OpenActivitiesList/OpenActivitiesList';
import { ProtectedRoute } from '@src/components/ProtectedRoute/ProtectedRoute';
import { MultiIssuesViewPage } from '@src/components/MultiIssuesView/MultiIssuesView';
import { Switch } from 'react-router-dom';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import classes from './styles.module.scss';

export const ActivitiesPageV2 = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const queryKeys: string[] = [QueryKeys.mobileMainPageActivities, projectId];
	const { mainPageActivities, isLoading } = useMainPageActivities(queryKeys, projectId);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const [personalViewSortOption, setPersonalViewSortOrderState] = useState<ActivityPageSortOptions | null>(null);
	const [filteredActivitiesByPreference, setFilteredActivitiesByPreference] = useState<IMainPageWidgetActivityData[]>(
		[]
	);
	const [selectedQuickFilter, setSelectedQuickFilter] = React.useState<WidgetActivityFilters>(
		WidgetActivityFilters.toDo
	);
	const {
		selectedActivities,
		overdueActivities,
		noProgressActivities,
		dueInSevenDaysActivities,
		futureActivities,
		forReviewActivities,
		completedActivities,
		toDoActivitiesCount,
		inProgressActivities,
		dueTodayActivities,
		todayProgressActivities,
	} = useFilteredMainPageActivities(filteredActivitiesByPreference, selectedQuickFilter, tz);

	useEffect(() => {
		const defaultValueForPersonalViewState: ActivityPageSortOptions =
			getDefaultValueForPersonalViewState(userPreferences);
		setPersonalViewSortOrderState(defaultValueForPersonalViewState);
	}, [userPreferences?.mobile?.filters?.floorIds, userPreferences?.mobile?.filters?.professionIds]);

	useEffect(() => {
		if (!mainPageActivities || !personalViewSortOption) {
			return;
		}

		const activitiesFilteredByPreference: IMainPageWidgetActivityData[] =
			getMainPageActivitiesAfterUserPreferencesFilter(
				mainPageActivities,
				userPreferences,
				personalViewSortOption
			);

		setFilteredActivitiesByPreference(activitiesFilteredByPreference);
	}, [mainPageActivities, personalViewSortOption, userPreferences]);

	useEffect(() => {
		activitiesPageAccess_BI();
	}, [projectId, tz]);

	const changePersonalViewState = (viewType: string) => {
		activitiesViewSettingsUpdate_BI('preferences', personalViewSortOption as string, viewType as string);
		setPersonalViewSortOrderState(viewType as ActivityPageSortOptions);
	};

	const isUserPreferenceEmptyState: boolean =
		personalViewSortOption === ActivityPageSortOptions.PERSONAL_VIEW && !isUserPreferencesDefined(userPreferences);

	if (isLoading || !personalViewSortOption) {
		return <Loader />;
	}

	if (isUserPreferenceEmptyState) {
		<EmptyStateComponent
			icon={settingsIcon}
			text={translationService.get('personalViewEmptyStateText')}
			style={{ maxWidth: '250px', marginTop: 10 }}
		/>;
	}

	return (
		<div className={classes.activitiesPage}>
			<ActivitiesPageHeader
				changePersonalViewState={changePersonalViewState}
				personalViewSortOption={personalViewSortOption}
				completedActivitiesCount={completedActivities?.length}
				forReviewActivitiesCount={forReviewActivities?.length}
				noProgressActivitiesCount={noProgressActivities?.length}
				todayProgressActivitiesCount={todayProgressActivities?.length}
				openActivitiesCount={toDoActivitiesCount}
				setWidgetActivityFilters={setSelectedQuickFilter}
				selectedQuickFilter={selectedQuickFilter}
				inProgressActivitiesCount={inProgressActivities?.length}
				toDoActivities={toDoActivitiesCount}
			/>
			<div className={classes.activitiesPageBody} data-testid={'widgetBody'}>
				{!filteredActivitiesByPreference?.length && (
					<EmptyStateComponent
						icon={settingsIcon}
						text={translationService.get('activitiesPage_personalViewEmptyStateText')}
					/>
				)}
				{selectedQuickFilter === WidgetActivityFilters.toDo ? (
					<OpenActivitiesList
						queryKeys={queryKeys}
						overdueActivities={overdueActivities!}
						dueInSevenDaysActivities={dueInSevenDaysActivities!}
						futureActivities={futureActivities!}
						dueTodayActivities={dueTodayActivities!}
					/>
				) : (
					selectedActivities?.map((activity) => (
						<ActivityCard key={activity.groupId} activity={activity} queryKeys={queryKeys} />
					))
				)}
			</div>
			<Switch>
				<ProtectedRoute
					exact
					hideNavbar
					hideHeader
					path="/activities/:groupId/issues"
					component={() => <MultiIssuesViewPage />}
				/>
			</Switch>
		</div>
	);
};
