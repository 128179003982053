import { IMergedEquipmentView } from '@shared/interfaces/Equipment/IMergedEquipmentView';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { IStaticEquipment } from '@shared/interfaces/Equipment/IStaticEquipment';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { selectProjectId } from '../../store/slices/project.slice';
import { requestService } from '../../index';

export const useMergedEquipmentQuery = (): {
	mergedEquipments: IMergedEquipmentView[];
	mergedEquipmentsLoading: boolean;
} => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const {
		data: mergedEquipments,
		isLoading,
		isFetching,
	} = useQuery<IMergedEquipmentView[]>(
		['mergedEquipmentsData', projectId],
		() => requestService.get(`/location/merged/equipment?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { mergedEquipments, mergedEquipmentsLoading: isLoading || isFetching };
};

export const useStaticEquipmentQuery = (): {
	staticEquipments: IStaticEquipment[];
	staticEquipmentsLoading: boolean;
} => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const {
		data: staticEquipments,
		isLoading,
		isFetching,
	} = useQuery<IStaticEquipment[]>(
		['staticEquipmentsData', projectId],
		() => requestService.get(`/siteNetIntegrator/static/equipment?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { staticEquipments, staticEquipmentsLoading: isLoading || isFetching };
};

export const useEquipmentInLocationQuery = (floorId?: string, areaId?: string) => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: mergedEquipments, isInitialLoading } = useQuery<IMergedEquipmentView[]>(
		[QueryKeys.mergedEquipmentInLocation, projectId, floorId, areaId],
		() =>
			requestService.get(`/location/merged/equipment/byLocation`, {
				params: {
					projectId,
					...(floorId && { floorId }),
					...(areaId && { areaId }),
				},
			}),
		{
			useErrorBoundary: true,
			refetchOnWindowFocus: false,
			initialData: [],
		}
	);
	return { mergedEquipments, mergedEquipmentsLoading: isInitialLoading };
};
