import React from 'react';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useHistory } from 'react-router-dom';
import { translationService } from '../../../../index';
import classes from './styles.module.scss';
import { ActivityPageSortOptions } from '../../../../models/ActivityPageSortOptions';
import { TrusstorDrawer } from '../../../TrusstorDrawer/TrusstorDrawer';
import { SelectOptions } from '../../../TrusstorDrawer/SelectOption/SelectOptions';
import { CustomizedOptionSection } from '../../../TrusstorDrawer/CustomizedOptionSection/CustomizedOptionSection';
import { AppRoutes } from '../../../../constants/appRoutes.enum';

interface IActivitiesPageHeaderProps {
	userPreferences: IUserPreferences | null;
	setPersonalViewSortOrderState: (viewType: string) => void;
	sortOptionState: ActivityPageSortOptions;
	handleClose: () => void;
}

export const ActivitiesPagePreferenceDrawer = (props: IActivitiesPageHeaderProps) => {
	const history = useHistory();
	const goToPreferencesPage = () => {
		history.push(AppRoutes.preferences);
	};

	return (
		<div className={classes.subTabWrapper}>
			<TrusstorDrawer
				topSection={translationService.get('filters')}
				isOpen={true}
				onClose={() => props.handleClose()}
			>
				<SelectOptions
					title={translationService.get('preference')}
					handleChange={props.setPersonalViewSortOrderState}
					options={[
						{
							actionButton: {
								text: translationService.get('edit'),
								onClick: goToPreferencesPage,
							},
							isSelected: props.sortOptionState === ActivityPageSortOptions.PERSONAL_VIEW,
							value: ActivityPageSortOptions.PERSONAL_VIEW,
							title: translationService.get('personalView'),
							children: <CustomizedOptionSection userPreferences={props.userPreferences} />,
						},
						{
							isSelected: props.sortOptionState === ActivityPageSortOptions.ALL_ISSUES,
							value: ActivityPageSortOptions.ALL_ISSUES,
							title: translationService.get('allActivities'),
						},
					]}
				/>
			</TrusstorDrawer>
		</div>
	);
};
