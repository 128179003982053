import React, { useCallback } from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../../servicesInitializer';
import helmetIcon from '@shared/assets/icons/helmet.svg';
import floorsIcon from '@shared/assets/icons/floors_black.svg';
import helpIcon from '../../../assets/helpCircle.svg';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { CustomizedOptionSection } from '../../TrusstorDrawer/CustomizedOptionSection/CustomizedOptionSection';
interface ICustomizeInfoProps {
	userPreferences: IUserPreferences | null;
	setShowEducation: (value: boolean) => void;
}

const CustomizeInfo = (props: ICustomizeInfoProps) => {
	const handleExplainingIconClick = (event) => {
		event.stopPropagation();
		props.setShowEducation(true);
	};

	return (
		<div className={classes.customizeViewInfo}>
			<section className={classes.infoSection}>
				<div className={classes.text}>{translationService.get('customized')}</div>
				<CustomizedOptionSection userPreferences={props.userPreferences} />
			</section>
			<section className={classes.explainIcon}>
				<img src={helpIcon} alt="" onClick={handleExplainingIconClick} />
			</section>
		</div>
	);
};

export { CustomizeInfo };
