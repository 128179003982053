import { NotificationTypes, RestrictedAreaType } from '@shared/interfaces/NotificationTypes.enum';
import { notificationsConfiguration, PreDefinedFrequencies } from '@shared/constants/notifications.constants';
import { INotificationCardSection } from '@shared/interfaces/NotificationSettings/INotificationCardSection';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import issueIcon from '@shared/assets/icons/Issues.svg';
import shieldIcon from '../assets/Notifications/shield.svg';
import reportIcon from '../assets/Notifications/report.svg';
import activityIcon from '../assets/Notifications/activity.svg';
import { projectAccessLevelService, userAccessLevelService } from '../servicesInitializer';

export const getRestrictedAreaCard = (type: RestrictedAreaType): INotificationCard => ({
	type,
	channels: notificationsConfiguration.RestrictedArea.channels,
});

export const getMobileNotificationsCards = (): INotificationCardSection[] => [
	...(projectAccessLevelService.hasAccess('notifications-safety') &&
	userAccessLevelService.hasAccess('safetyNotifications')
		? [
				{
					name: 'safetyAlerts',
					icon: shieldIcon,
					cards: [
						{
							type: NotificationTypes.DistressButton,
							description: 'notificationDistressButtonDescription',
							channels: notificationsConfiguration.DistressButton.channels,
						},
						{
							type: NotificationTypes.RestrictedArea,
							channels: notificationsConfiguration.RestrictedArea.channels,
						},
					],
				},
		  ]
		: []),
	...(projectAccessLevelService.hasAccess('notifications-reports')
		? [
				{
					name: 'reports',
					icon: reportIcon,
					cards: [
						{
							type: NotificationTypes.DailyReport,
							description: 'notificationDailyReportDescription',
							channels: notificationsConfiguration.DailyReport.channels,
						},
						{
							type: NotificationTypes.TagHealth,
							description: 'notificationTagHealthDescription',
							channels: notificationsConfiguration.TagHealth.channels,
							frequency: [PreDefinedFrequencies.DAILY, PreDefinedFrequencies.WEEKLY],
						},
					],
				},
		  ]
		: []),
	...(projectAccessLevelService.hasAccess('notifications-activities')
		? [
				{
					name: 'activities',
					icon: activityIcon,
					cards: [
						{
							type: NotificationTypes.PlanningCreated,
							description: 'notificationPlanningCreatedDescription',
							channels: notificationsConfiguration.PlanningCreated.channels,
						},
						{
							type: NotificationTypes.AreaSequenceItemStatusUpdate,
							description: 'settings_notification_AreaSequenceItemStatusUpdate_description',
							channels: notificationsConfiguration.AreaSequenceItemStatusUpdate.channels,
						},
					],
				},
		  ]
		: []),
	...(projectAccessLevelService.hasAccess('notifications-issues')
		? [
				{
					name: 'issues',
					icon: issueIcon,
					cards: [
						{
							type: NotificationTypes.CreatedIssues,
							description: 'notificationCreatedIssuesDescription',
							channels: notificationsConfiguration.CreatedIssues.channels,
							frequency: [PreDefinedFrequencies.DAILY, PreDefinedFrequencies.WEEKLY],
						},
						{
							type: NotificationTypes.IssueCommentCreated,
							description: 'notificationIssueCommentedAddedDescription',
							channels: notificationsConfiguration.IssueCommentCreated.channels,
						},
						{
							type: NotificationTypes.AssignedIssue,
							description: 'notificationIssueAssignedDescription',
							channels: notificationsConfiguration.AssignedIssue.channels,
						},
						{
							type: NotificationTypes.IssueStatusUpdated,
							description: 'notificationIssueStatusUpdatedDescription',
							channels: notificationsConfiguration.IssueStatusUpdated.channels,
						},
					],
				},
		  ]
		: []),
];

export const isPushNotificationsEnabledOnDevice = (): boolean =>
	'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
