import React from 'react';
import { useSelector } from 'react-redux';
import { INotification } from '@shared/interfaces/INotification';
import NotificationThickIcon from '../../../assets/Navbar/NotificationThickIcon.svg';
import NotificationThinIcon from '../../../assets/Navbar/NotificationThinIcon.svg';
import { AppRoutes } from '../../../constants/appRoutes.enum';
import classes from '../styles.module.scss';
import { selectNotifications } from '../../../store/slices/notifications.slice';

export interface INotificationNavbarItemProps {
	isCurrentPath: boolean;
}

export const NotificationImageElement = (props: INotificationNavbarItemProps) => {
	const notifications: INotification[] | undefined = useSelector(selectNotifications);
	const notViewedNotifications: INotification[] = !notifications
		? []
		: notifications.filter((notification: INotification) => !notification.isViewed);

	return (
		<div className={classes.notificationIconContainer}>
			{notViewedNotifications.length > 0 && (
				<div className={classes.dot}>
					<span className={classes.dotText}>{notViewedNotifications.length}</span>
				</div>
			)}
			<img
				src={props.isCurrentPath ? NotificationThickIcon : NotificationThinIcon}
				alt={`Icon for page: ${AppRoutes.notifications}`}
				className={`${classes.navbarIcon} ${props.isCurrentPath && classes.selected}`}
			/>
		</div>
	);
};
