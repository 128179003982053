import { IProject } from '@shared/interfaces/IProject';
import { setSentryProjectId } from '@shared/utils/monitoring.util';
import { setWorkingProjectSuccess } from '../slices/project.slice';
import { biService, projectService, requestService } from '../../index';

export const setWorkingProject = (projectId: string) => async (dispatch, getState) => {
	const project: IProject | undefined = getState().projectReducer.userProjects?.find(
		(p) => p.projectId === projectId
	);
	biService.setGlobalData({
		projectId: project?.projectId || 'no defined',
		tz: project?.tz || 'no defined',
	});
	if (!project) {
		return;
	}
	projectService.setChosenProject(project);
	dispatch(setWorkingProjectSuccess({ workingProject: project }));
	setSentryProjectId(project.projectId);
};

export const fetchAndSetWorkingProject = (projectId: string) => async (dispatch) => {
	dispatch(setWorkingProject(projectId));
	const updatedProject: IProject = await requestService.get(
		`/projectConfig/project/byProjectId?projectId=${projectId}`
	);
	biService.setGlobalData({
		projectId: updatedProject.projectId,
		tz: updatedProject.tz,
	});
	projectService.setChosenProject(updatedProject);
	dispatch(setWorkingProjectSuccess({ workingProject: updatedProject }));
};

export const changeWorkingProject = (projectId: string) => (dispatch) => {
	dispatch(setWorkingProject(projectId));
};
