import { Route, Router, Switch } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';
import 'moment/locale/he';
import 'moment/locale/ja';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { ILoginRequestStatus, LoginRequestStatuses } from '@shared/interfaces/ILoginReducerState';
import { useTabVisibility } from '@shared/hooks/useTabVisibility.hook';
import { Environments } from '@shared/constants/environments';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { initPostHogByUserData } from '@shared/utils/posthog.utils';
import { QueryClient } from '@tanstack/react-query';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import * as Sentry from '@sentry/react';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { MonitoringRoute } from '@shared/utils/monitoring.util';
import { customHistory } from '@shared/utils/history.utils';
import { ActivitiesPageV2 } from '@src/components/pages/ActivitiesPageV2/ActivitiesPageV2';
import { MonitorMainPage } from '@src/components/pages/MonitorPage/MonitorMainPage/MonitorMainPage';
import { projectAccessLevelService, translationService, userAccessLevelService } from './index';
import { IRootState } from './store/slices';
import { selectProject, selectProjectId } from './store/slices/project.slice';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { WorkersPage } from './components/pages/WorkersPage/WorkersPage';
import { EquipmentPage } from './components/pages/EquipmentPage/EquipmentPage';
import EmptyModePage from './components/EmptyModePage/EmptyModePage';
import { PreferencesPage } from './components/pages/PreferencesPage/PreferencesPage';
import { InitialRedirect } from './components/InitialRedirect/InitialRedirect';
import { AppRoutes } from './constants/appRoutes.enum';
import { ActivitiesPage } from './components/pages/ActivitiesPage/ActivitiesPage';
import { appBecameVisible_BI } from './utils/bi.utils';
import { AssignTag } from './components/pages/AssignTag/AssignTag';
import { TrusstorToastMessages } from './components/TrusstorToastMessages/TrusstorToastMessages';
import { WorkersOnSite } from './components/pages/WorkersOnSitePage/WorkersOnSite';
import { ProfilePage } from './components/pages/ProfilePage/ProfilePage';
import { NotificationsPage } from './components/pages/NotificationsPage/NotificationsPage';
import { getNotificationsFromStream, stopGettingNotifications } from './store/thunks/notifications.thunks';
import { resetNotifications } from './store/slices/notifications.slice';
import { NotificationsSettingsPage } from './components/pages/NotificationsSettingsPage/NotificationsSettingsPage';
import { SplashScreen } from './components/SplashScreen/SplashScreen';
import { SetupPage } from './components/pages/SetupPage/SetupPage';
import { IssuesPage } from './components/pages/IssuesPage/IssuesPage';
import { setIsOffline } from './store/slices/offlineBanner.slice';
import { initImportantIcons } from './utils/bootstrap.utils';
import { errorToast } from './utils/toast.utils';
import { ErrorPage } from './components/pages/ErrorPage/ErrorPage';
import { PushNotificationsRequestDrawer } from './components/Drawers/PushNotificationsRequestDrawer/PushNotificationsRequestDrawer';
import { PushPermissionLocalStorageService } from './services/pushPermissionLocalStorage.service';
import { isPushNotificationsEnabledOnDevice } from './utils/notifications.utils';
import { registerServiceWorker } from './utils/serviceWorkerRegistration.utils';

const queryClient = new QueryClient();
const persister = createSyncStoragePersister({
	storage: window.localStorage,
});

const getThemeStyles = (): any => ({
	direction: document.body.dir,
	typography: {
		button: {
			textTransform: 'none',
		},
	},
});

function App() {
	const dispatch = useDispatch();
	document.body.dir = translationService.getDirection();
	const [areTranslationsFetched, setAreTranslationsFetched] = useState<boolean>(false);
	const [theme, setTheme] = useState(createMuiTheme(getThemeStyles()));
	const loggedUser: ILoggedUser | null = useSelector((state: IRootState) => state.loginReducer.loggedUser);
	const loginRequestStatus: ILoginRequestStatus = useSelector(
		(state: IRootState) => state.loginReducer.loginRequestStatus
	);
	const loggedUserPreferences: IUserPreferences | undefined = loggedUser?.userPreferences;
	const workingProject = useSelector(selectProject);
	const workingProjectId: string | undefined = useSelector(selectProjectId);
	useTabVisibility(appBecameVisible_BI);
	const shouldShowSplashScreen: boolean =
		process.env.REACT_APP_STAGE !== Environments.dev && process.env.REACT_APP_STAGE !== Environments.compose;
	const isPushNotificationsFeatureFlagEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.usePushNotifications
	);
	const isMonitorPageEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useMobileMonitor);
	const isActivitiesV2FeatureFlagEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.useActivitiesTabV2Mobile
	);
	const [showSplashScreen, setShowSplashScreen] = useState(shouldShowSplashScreen);
	const [isNotificationPermissionNotGranted, setIsNotificationPermissionNotGranted] = useState<boolean>(
		isPushNotificationsEnabledOnDevice() && Notification.permission === 'default'
	);
	initImportantIcons();

	useEffect(() => {
		const handleOnline = () => {
			dispatch(setIsOffline(false));
		};

		const handleOffline = () => {
			dispatch(setIsOffline(true));
		};

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);
		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, []);

	const updateUserLayout = () => {
		const language: string = loggedUserPreferences?.language || workingProject!.language;
		translationService.changeChosenLanguage(language);
		moment.locale(translationService.getDateLocale());
		document.body.dir = translationService.getDirection();
		setTheme(createMuiTheme(getThemeStyles()));
	};

	useEffect(() => {
		if (loggedUserPreferences) {
			updateUserLayout();
		}
	}, [loggedUserPreferences]);

	useEffect(() => {
		if (loggedUser && workingProjectId) {
			dispatch(getNotificationsFromStream(workingProjectId, loggedUser.userDetails.username));
			updateUserLayout();
			initPostHogByUserData(
				loggedUser.userDetails,
				workingProjectId,
				process.env.REACT_APP_STAGE as Environments
			);
		}
		return () => {
			dispatch(stopGettingNotifications());
		};
	}, [loggedUser?.userDetails?.username, workingProjectId, setTheme]);

	const registerServiceWorkerIfPermissionGranted = () => {
		if (
			isPushNotificationsEnabledOnDevice() &&
			Notification.permission === 'granted' &&
			loggedUser?.userDetails.username
		) {
			registerServiceWorker(loggedUser.userDetails.username);
		}
	};

	useEffect(() => {
		setShowSplashScreen(shouldShowSplashScreen);
		const doSomethingAfterDuration = () => {
			setShowSplashScreen(false);
		};

		const twoSecondsInMilliseconds: number = 2000;

		const timer = setTimeout(doSomethingAfterDuration, twoSecondsInMilliseconds);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		const initTranslations = async () => {
			const { error } = await translationService.init();
			setAreTranslationsFetched(true);
			if (error) {
				errorToast(dispatch, 'Error fetching translations, please try again later');
			}
		};
		if (loggedUser?.userDetails.username) {
			setAreTranslationsFetched(false);
			initTranslations();
		}
	}, [loggedUser?.userDetails?.username]);

	useEffect(() => {
		if (loggedUser?.userDetails.username && isPushNotificationsFeatureFlagEnabled) {
			registerServiceWorkerIfPermissionGranted();
		}
	}, [loggedUser?.userDetails?.username, isPushNotificationsFeatureFlagEnabled]);

	if (showSplashScreen || !areTranslationsFetched) {
		return <SplashScreen />;
	}

	if (
		loginRequestStatus.state === LoginRequestStatuses.LOADING ||
		loginRequestStatus.state === LoginRequestStatuses.ERROR
	) {
		return <div />;
	}
	const isDrawerShownForUser: boolean = PushPermissionLocalStorageService.getIsPushPermissionDrawerShown(
		loggedUser?.userDetails.username
	);
	const isPushPermissionDrawerShown: boolean =
		!isDrawerShownForUser && isNotificationPermissionNotGranted && isPushNotificationsFeatureFlagEnabled;
	return (
		<PersistQueryClientProvider
			onSuccess={() => queryClient.resumePausedMutations().then(() => queryClient.invalidateQueries())}
			persistOptions={{ persister }}
			client={queryClient}
		>
			<ThemeProvider theme={theme}>
				<div className="App">
					<PushNotificationsRequestDrawer
						isOpen={isPushPermissionDrawerShown}
						onClose={() => setIsNotificationPermissionNotGranted(false)}
					/>
					<Sentry.ErrorBoundary fallback={<ErrorPage />}>
						<Router history={customHistory}>
							<Switch>
								<MonitoringRoute exact path={'/'} component={InitialRedirect} />
								{isMonitorPageEnabled && (
									<ProtectedRoute path={AppRoutes.monitor} component={MonitorMainPage} />
								)}
								{projectAccessLevelService.hasAccess('personnelPage') && (
									<ProtectedRoute exact path={AppRoutes.workers} component={WorkersPage} />
								)}
								{projectAccessLevelService.hasAccess('personnelPage') && (
									<ProtectedRoute exact path={AppRoutes.personnel} component={WorkersPage} />
								)}
								<ProtectedRoute exact path={AppRoutes.myProfile} component={ProfilePage} />
								{projectAccessLevelService.hasAccess('activitiesPage') && (
									<ProtectedRoute
										exact={false}
										path={AppRoutes.activities}
										component={isActivitiesV2FeatureFlagEnabled ? ActivitiesPageV2 : ActivitiesPage}
										disablePageScroll
									/>
								)}
								{projectAccessLevelService.hasAccess('equipmentPage') && (
									<ProtectedRoute exact path={AppRoutes.equipment} component={EquipmentPage} />
								)}
								<ProtectedRoute
									exact
									path={AppRoutes.preferences}
									component={PreferencesPage}
									hideNavbar
								/>
								<ProtectedRoute exact path={AppRoutes.setup} component={SetupPage} hideNavbar />
								<ProtectedRoute exact path={AppRoutes.projectNotReady} component={EmptyModePage} />
								{userAccessLevelService.hasAccess('assignTag') &&
									projectAccessLevelService.hasAccess('assignTag') && (
										<ProtectedRoute
											exact
											path={AppRoutes.assignTag}
											component={AssignTag}
											hideNavbar
										/>
									)}
								{projectAccessLevelService.hasAccess('personnelPage') && (
									<ProtectedRoute exact path={AppRoutes.workersOnSite} component={WorkersOnSite} />
								)}
								<ProtectedRoute exact path={AppRoutes.notifications} component={NotificationsPage} />
								{projectAccessLevelService.hasAccess('issuesPage') && (
									<ProtectedRoute path={`${AppRoutes.issues}/:issueId?`} component={IssuesPage} />
								)}
								<ProtectedRoute
									exact
									path={AppRoutes.notificationsSettings}
									component={NotificationsSettingsPage}
								/>
								<Route path="*" render={() => <InitialRedirect />} />
							</Switch>
						</Router>
						<TrusstorToastMessages />
					</Sentry.ErrorBoundary>
				</div>
			</ThemeProvider>
		</PersistQueryClientProvider>
	);
}

export default App;
