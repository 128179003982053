import React from 'react';
import moment from 'moment/moment';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { DateFlagDisplay } from '@shared/components/DateFlagDisplay/DateFlagDisplay';
import { getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import { ActivityMenu } from '@src/components/ActivityMenu/ActivityMenu';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import classes from './styles.module.scss';

interface IWidgetActivityCardDetailsHeaderProps {
	activity: IMainPageWidgetActivityData;
	closeAction?: () => void;
	onCreateIssueClick: () => void;
	onViewIssuesClick?: () => void;
}

export const ActivityCardDetailsHeader = (props: IWidgetActivityCardDetailsHeaderProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;

	const isDueDatePassed: boolean = moment.tz(props.activity.originalEndDate, tz).isBefore(getTimezoneStartOfDate(tz));
	return (
		<div className={classes.headerContainer}>
			<div className={classes.header} data-testid="WidgetActivityCardDetailsHeader">
				<div className={classes.description} data-testid={'description'}>
					{props.activity.description}
				</div>
				<div className={classes.headerEndSection}>
					<DateFlagDisplay
						date={props.activity.originalEndDate}
						isOverdue={isDueDatePassed}
						tz={tz}
						showRedBackground
					/>
					<div onClick={(e) => e.stopPropagation()}>
						<ActivityMenu
							onViewIssuesClick={props.onViewIssuesClick}
							onCreateIssueClick={props.onCreateIssueClick}
							groupId={props.activity.groupId}
							issues={props.activity.issues}
						/>
					</div>
				</div>
			</div>
			<div>
				<ProfessionDisplayWithTradeIcon profession={props.activity.profession} projectId={projectId} />
			</div>
		</div>
	);
};
