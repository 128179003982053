import { IToastMessage, IToastMessageTypes } from '@shared/interfaces/IToastMessages';
import { setMessage } from '../store/slices/toastMessage.slice';
import { store } from '../store/store';

export const errorToast = (dispatch: typeof store.dispatch, text: string) => {
	const message: IToastMessage = {
		text,
		type: IToastMessageTypes.ERROR,
	};
	dispatch(setMessage({ message }));
};

export const warningToast = (dispatch: typeof store.dispatch, text: string) => {
	const message: IToastMessage = {
		text,
		type: IToastMessageTypes.WARNING,
	};
	dispatch(setMessage({ message }));
};

export const successToast = (dispatch: typeof store.dispatch, text: string) => {
	const message: IToastMessage = {
		text,
		type: IToastMessageTypes.SUCCESS,
	};
	dispatch(setMessage({ message }));
};
