import React from 'react';
import { IssueStatus } from '@shared/interfaces/IIssueShared';
import { getIssueStatusMainText } from '@shared/utils/issues.utils';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { FilterCard } from '@shared/components/Filters/FilterCard/FilterCard';
import { translationService } from '../../../../../servicesInitializer';
import classes from '../styles.module.scss';
import {
	addStatus,
	clearStatuses,
	removeStatus,
	selectSelectedStatuses,
} from '../../../../../store/slices/filters.slice';

interface IStatusFilterSectionProps {
	statusesCountObj?: { [issueStatusId: string]: number };
}

export const StatusFilterSection = (props: IStatusFilterSectionProps) => {
	const dispatch = useDispatch();
	const selectedStatuses: IssueStatus[] = useSelector(selectSelectedStatuses);
	const onClear = () => {
		dispatch(clearStatuses());
	};

	const addSelectedStatus = (status: IssueStatus) => {
		dispatch(addStatus({ status }));
	};

	const removeSelectedStatus = (status: IssueStatus) => {
		dispatch(removeStatus({ status }));
	};

	return (
		<CollapsableContainer
			onClear={selectedStatuses.length ? onClear : undefined}
			headerText={translationService.get('status')}
		>
			<div className={classes}>
				{Object.values(IssueStatus).map((status: IssueStatus) => {
					const isSelected: boolean = selectedStatuses.includes(status);
					return (
						<FilterCard
							key={status}
							handleClick={
								isSelected ? () => removeSelectedStatus(status) : () => addSelectedStatus(status)
							}
							text={getIssueStatusMainText(status, translationService)}
							isSelected={isSelected}
							count={props.statusesCountObj?.[status]}
						/>
					);
				})}
			</div>
		</CollapsableContainer>
	);
};
