import React from 'react';
import { IAssignedActivityArea } from '@shared/interfaces/IAssignedActivityArea';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { SORT_ORDER } from '@shared/constants/constants';
import { Dictionary, groupBy } from 'lodash';
import { ActivityAreaCard } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardSequenceAreas/ActivityAreaCard/ActivityAreaCard';
import classes from './styles.module.scss';
import { IMainPageWidgetActivityData } from '../../../../../interfaces/IMainPageWidgetActivityData';
import { sortByAreas } from '../../../../../../../shared/utils/sort.utils';

interface IWeeklyWidgetCardSequenceAreasProps {
	activity: IMainPageWidgetActivityData;
	queryKeys: any[];
}

export const ActivityCardSequenceAreas = (props: IWeeklyWidgetCardSequenceAreasProps) => {
	const sortedAreaSequenceItems: IAreaSequenceItem[] = sortByAreas(
		props.activity.areaSequenceItems,
		SORT_ORDER.ASCENDING,
		'area'
	);

	const groupedAreaSequenceItemsByFloorNick: Dictionary<IAreaSequenceItem[]> = groupBy(
		sortedAreaSequenceItems,
		(areaSequenceItem) => areaSequenceItem.area.floorId
	);

	return (
		<div className={classes.sequenceProgressCardsContainer}>
			{Object.values(groupedAreaSequenceItemsByFloorNick).map((asis: IAreaSequenceItem[]) => {
				const floorNick: string = asis[0].area.floorNick;
				const completedCount: number = asis.filter(
					(asi: IAreaSequenceItem) => asi?.status === AreaSequenceItemStatus.complete
				).length;
				return (
					<div className={classes.floorAsisWrapper}>
						<div className={classes.floorTitleSection}>
							<div className={classes.floorNick}>{floorNick}</div>
							<div className={classes.count}>{`${completedCount}/${asis.length}`}</div>
						</div>
						<div className={classes.floorAsis}>
							{asis.map((areaSequenceItem: IAreaSequenceItem) => {
								const area: IAssignedActivityArea | undefined = props.activity.areas.find(
									(areaToCheck: IAssignedActivityArea) =>
										areaToCheck.areaSequenceItemId === areaSequenceItem._id
								);
								if (!area) return null;
								return (
									<div className={classes.widgetCard}>
										<ActivityAreaCard
											queryKeys={props.queryKeys}
											description={props.activity.description}
											profession={props.activity.profession}
											key={area.areaId}
											areaSequenceItem={areaSequenceItem}
											area={area}
										/>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};
