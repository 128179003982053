import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { translationService } from '@src/index';

export const getActivitiesSectionsFromAreaSequenceItems = (
	areaSequenceItemsWithGroupDescription: IAreaSequenceItem[]
): {
	[title: string]: IAreaSequenceItem[];
} => {
	const ongoingAreaSequenceItems: IAreaSequenceItem[] = areaSequenceItemsWithGroupDescription.filter(
		(asi: IAreaSequenceItem) =>
			asi.status !== AreaSequenceItemStatus.unplanned && asi.status !== AreaSequenceItemStatus.complete
	);
	const completedAreaSequenceItems: IAreaSequenceItem[] = areaSequenceItemsWithGroupDescription.filter(
		(asi: IAreaSequenceItem) => asi.status === AreaSequenceItemStatus.complete
	);
	const unplannedAreaSequenceItems: IAreaSequenceItem[] = areaSequenceItemsWithGroupDescription.filter(
		(asi: IAreaSequenceItem) => asi.status === AreaSequenceItemStatus.unplanned
	);

	return {
		[translationService.get('ongoing')]: ongoingAreaSequenceItems,
		[translationService.get('completed')]: completedAreaSequenceItems,
		[translationService.get('notStarted')]: unplannedAreaSequenceItems,
	};
};
