import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import filledRadioButtonIcon from '../../assets/filled_radio_button.svg';
import radioButtonIcon from '../../assets/radio_button.svg';

interface IFloorCardClickableProps {
	handleClick: () => void;
	isSelected: boolean;
	text: string;
	counterText?: string;
}

const FloorTypeCardClickable = (props: IFloorCardClickableProps) => {
	return (
		<div
			className={classnames(classes.floorCard, { [classes.isSelected]: props.isSelected })}
			onClick={props.handleClick}
		>
			<img src={props.isSelected ? filledRadioButtonIcon : radioButtonIcon} alt="" />
			<div className={classes.floorCardText}>{props.text}</div>
			{props.counterText && <div className={classes.counterText}>{props.counterText}</div>}
		</div>
	);
};

export { FloorTypeCardClickable };
