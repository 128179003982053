import { ISliceToastMessage, IToastMessageTypes } from '@shared/interfaces/IToastMessages';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { clearMessage } from '../../store/slices/toastMessage.slice';
import classes from './styles.module.scss';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { IconNames } from '../../../../shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '../../../../shared/components/TrusstorIconShared/TrusstorIconShared';

const ToastMessage = (props: { toast: ISliceToastMessage }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		const timeout: NodeJS.Timeout = setTimeout(() => {
			dispatch(clearMessage({ id: props.toast.id }));
		}, 3000);
		return () => {
			clearTimeout(timeout);
		};
	}, [props.toast.id]);

	const getIconName = (type: IToastMessageTypes): IconNames =>
		type === IToastMessageTypes.SUCCESS ? IconNames.checkCircle : IconNames.warningTriangle;

	return (
		<div key={props.toast.id} className={classes.toast}>
			<TrusstorIcon
				iconName={getIconName(props.toast.type)}
				size={IconSize.MEDIUM}
				color={IconColor.White}
				className={classes.toastIcon}
			/>
			{props.toast.text}
		</div>
	);
};

export { ToastMessage };
