import React from 'react';
import { IProfession } from '@shared/interfaces/IProfession';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { groupBy, Dictionary } from 'lodash';
import { IMergedTag } from '@interfaces/IMergedTag';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { DrawerDialog } from '@src/components/Dialogs/DrawerDialog/DrawerDialog';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import { IWorkerMergedTag } from '../../interfaces/IWorkerMergedTag';
import classes from './styles.module.scss';

interface IWorkerFloorDrawerProps {
	onClose: () => void;
	isOpen: boolean;
	profession?: IProfession;
	currentFloorData?: IBaseFloor;
	currentFloorWorkers?: IMergedTag[];
}

export const WorkerFloorDrawer = (props: IWorkerFloorDrawerProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tagsGroupedByArea: Dictionary<IMergedTag[]> = groupBy(
		props.currentFloorWorkers,
		(worker) => worker.closestAnchor.areaId
	);

	if (!props.profession || !props.currentFloorWorkers || !props.currentFloorData) {
		return null;
	}

	return (
		<>
			<DrawerDialog
				title={
					<ProfessionDisplayWithTradeIcon
						isNarrow
						contractorTextClassname={classes.contractorHeader}
						profession={props.profession}
						projectId={projectId}
					/>
				}
				onClose={props.onClose}
				isOpen={props.isOpen}
			>
				<div className={classes.content}>
					<div className={classes.floorNick}>{props.currentFloorData.floorNick}</div>
					<div className={classes.contentBody}>
						{props.currentFloorWorkers &&
							Object.values(tagsGroupedByArea)?.map((workers: IMergedTag[]) => (
								<div className={classes.areaSection}>
									<CollapsableContainer
										headerText={`${workers[0].closestAnchor.areaNick} (${workers.length})`}
										headerTextClassName={classes.areaNick}
									>
										<div className={classes.cardsContainer}>
											{workers.map((worker: IMergedTag) => (
												<div className={classes.card}>
													<div className={classes.name}>
														{(worker as IWorkerMergedTag).name}
													</div>
													<div className={classes.tagNick}>
														{(worker as IWorkerMergedTag).tagNick}
													</div>
												</div>
											))}
										</div>
									</CollapsableContainer>
								</div>
							))}
					</div>
				</div>
			</DrawerDialog>
		</>
	);
};
