import React, { useState } from 'react';
import { DashboardTab } from './DashboardTab/DashboardTab';
import classes from './styles.module.scss';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';

interface IDashboardTabsSectionProps {
	dashboardItems: IDashboardTabItem[];
	onTabClick: (selected: MonitorResourcesEnum) => void;
}

const DashboardTabsSection = (props: IDashboardTabsSectionProps) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

	const handleTabClick = (index: number) => {
		props.onTabClick(props.dashboardItems[index].resource);
		if (index !== selectedTabIndex) {
			setSelectedTabIndex(index);
		}
	};

	return (
		<div className={classes.dashboardTabsSectionContainer}>
			{props.dashboardItems.map((item, index) => {
				return (
					<DashboardTab
						key={index}
						isSelected={selectedTabIndex === index}
						selectTab={() => handleTabClick(index)}
						{...item}
					/>
				);
			})}
		</div>
	);
};

export { DashboardTabsSection };
