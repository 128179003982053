import React from 'react';
import { IActivityByProgress } from '../../interfaces/IActivityByProgress';
import classes from './styles.module.scss';
import { ProgressViewActivityCard } from '../ProgressViewProfessionCard/ProgressViewActivityCard';

interface IProgressViewCardsWithTitleProps {
	title: string;
	activities: IActivityByProgress[];
	refetchDataCallback: () => Promise<void>;
	titleIconSrc?: string;
	isMyWalkAroundTab?: boolean;
}

export const ProgressViewCardsWithTitle = (props: IProgressViewCardsWithTitleProps) => {
	if (!props.activities.length) {
		return null;
	}

	return (
		<div className={classes.container}>
			<div className={classes.title}>
				{props.titleIconSrc && <img style={{ marginRight: '4px' }} alt="" src={props.titleIconSrc} />}
				<span>
					{props.title} ({props.activities.length})
				</span>
			</div>
			{props.activities.map((activity) => (
				<ProgressViewActivityCard
					activity={activity}
					refetchDataCallback={props.refetchDataCallback}
					key={activity.groupId}
					isMyWalkAroundTab={props.isMyWalkAroundTab}
				/>
			))}
		</div>
	);
};
