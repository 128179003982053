import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from '../styles.module.scss';
import { translationService } from '../../../index';
import { INavbarItemProps } from '../../../interfaces/INavbarItemProps';

function NavbarItem(props: INavbarItemProps) {
	const monitorPath: string = 'monitor';
	const currentPath: string = useLocation().pathname;
	const isMonitorPath: boolean = props.pathName.includes(monitorPath);
	const isCurrentPath: boolean = isMonitorPath
		? currentPath.includes(monitorPath)
		: props.pathName.includes(currentPath);

	return (
		<Link to={props.pathName} className={`${classes.sideBarItem} ${isCurrentPath && classes.selectedLink}`}>
			<img
				src={props.icon}
				alt={`Icon for page: ${props.pathName}`}
				className={`${classes.navbarIcon} ${isCurrentPath && classes.selected}`}
			/>
			<div className={`${classes.sideBarSubItem} ${isCurrentPath && classes.selected} ${props.className ?? ''}`}>
				<div>{translationService.get(props.name)}</div>
			</div>
			{isCurrentPath && <div className={classes.selectedLine}></div>}
		</Link>
	);
}

export default NavbarItem;
