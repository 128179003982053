import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { ISequence } from '@shared/interfaces/ISequence';
import { updateAreaSequenceItemStatus } from '@src/apis/areaSequenceItems.api';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { workplanActivityActions_BI } from '@utils/bi.utils';
import { IMergedAreaSequenceItem } from '../../interfaces/IMergedAreaSequenceItem';
import { requestService } from '../../index';
import { IProfession } from '@shared/interfaces/IProfession';

export const useProjectAreaSequenceItemsQuery = (
	projectId: string,
	sequenceId?: string
): {
	areaSequenceItems: IMergedAreaSequenceItem[];
	isInitialLoading: boolean;
	refetchAreaSequenceItems: () => void;
} => {
	if (!sequenceId) return { areaSequenceItems: [], isInitialLoading: false, refetchAreaSequenceItems: () => {} };

	const {
		data: areaSequenceItems,
		isInitialLoading,
		refetch: refetchAreaSequenceItems,
	} = useQuery<IMergedAreaSequenceItem[]>(
		['projectAreaSequenceItems', projectId, sequenceId],
		() =>
			requestService.get(
				`/activities/sequenceItems/areaSequenceItem/sequenceId/${sequenceId}?projectId=${projectId}`
			),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { areaSequenceItems, isInitialLoading, refetchAreaSequenceItems };
};

export const useAreaSequenceItemsByGroupIdQuery = (
	activityGroupId: string
): {
	areaSequenceItems: IMergedAreaSequenceItem[];
	isInitialLoading: boolean;
	refetchAreaSequenceItems: () => void;
} => {
	const {
		data: areaSequenceItems,
		isInitialLoading,
		refetch: refetchAreaSequenceItems,
	} = useQuery<IMergedAreaSequenceItem[]>(
		['projectAreaSequenceItems', activityGroupId],
		() =>
			requestService.get(
				`/activities/sequenceItems/areaSequenceItem/byActivityGroupId?activityGroupId=${activityGroupId}`
			),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { areaSequenceItems, isInitialLoading, refetchAreaSequenceItems };
};

export const useAreaSequenceItemsByAreaIdQuery = (
	areaId: string
): {
	areaSequenceItems: IAreaSequenceItem[];
	isLoading: boolean;
	refetch: () => {};
} => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const {
		data: areaSequenceItems,
		isLoading,
		refetch,
	} = useQuery<IAreaSequenceItem[]>(
		[QueryKeys.areaSequenceItemsByAreaId, projectId, areaId],
		() =>
			requestService.get(`/activities/sequenceItems/areaSequenceItem/byAreaId/${areaId}`, {
				params: {
					projectId,
				},
			}),
		{
			initialData: [],
			useErrorBoundary: true,
		}
	);
	return { areaSequenceItems, isLoading, refetch };
};

export const getAsiStatusUpdateQueryKeys = (areaId: string): string[] => {
	const projectId: string = useSelector(selectProjectId)!;
	const queryKey: string[] = [QueryKeys.areaSequenceItemsByAreaId, projectId, areaId];
	return queryKey;
};

export const useAsiStatusUpdateMutation = (areaId: string) => {
	const queryKey = getAsiStatusUpdateQueryKeys(areaId);
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({
			areaSequenceItemId,
			updatedStatus,
			sequenceItemId,
			areaId,
			activityId,
			profession,
			description,
		}: {
			areaSequenceItemId: string;
			updatedStatus: AreaSequenceItemStatus;
			sequenceItemId: string;
			areaId: string;
			activityId: string;
			profession: IProfession;
			description: string;
		}) => {
			await updateAreaSequenceItemStatus(areaSequenceItemId, updatedStatus);
			workplanActivityActions_BI({
				action: updatedStatus,
				sequenceItemId,
				areaId,
				activityId,
				profession,
				description,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(queryKey);
		},
	});
};

export const useProjectSequences = (): {
	sequences: ISequence[];
	isFetched: boolean;
} => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: sequences, isFetched } = useQuery<ISequence[]>(
		[QueryKeys.projectSequences, projectId],
		() =>
			requestService.get(`/activities/sequences`, {
				params: {
					projectId,
				},
			}),
		{
			initialData: [],
			useErrorBoundary: true,
		}
	);
	return { sequences, isFetched };
};

export const useProjectSequenceItems = (): {
	sequenceItems: ISequenceItem[];
	isFetched: boolean;
} => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data: sequenceItems, isFetched } = useQuery<ISequenceItem[]>(
		[QueryKeys.projectSequenceItemsByProject, projectId],
		() =>
			requestService.get(`/activities/sequenceItems/allProjectSequenceItems`, {
				params: {
					projectId,
				},
			}),
		{
			initialData: [],
		}
	);

	return { sequenceItems, isFetched };
};
