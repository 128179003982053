import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestService } from '@src/servicesInitializer';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { IMergedAreaSequenceItem } from '@interfaces/IMergedAreaSequenceItem';
import { asiUpdatedStatus_BI } from '@utils/bi.utils';

interface IAsiStatusUpdateMutation {
	asi: IAreaSequenceItem;
	isDone: boolean;
	newStatus?: AreaSequenceItemStatus;
	updateToReadyForReview?: boolean;
}

const getOptimisticAsis = (activities: IMainPageWidgetActivityData[] | undefined, newData: IAsiStatusUpdateMutation) =>
	activities?.map((activity) => {
		if (activity.groupId != newData.asi.activityGroupId) {
			return activity;
		}

		return {
			...activity,
			areaSequenceItems: activity.areaSequenceItems.map((areaSequenceItemToMap) => {
				if (areaSequenceItemToMap._id !== newData.asi._id) {
					return areaSequenceItemToMap;
				}
				const finalStatus: AreaSequenceItemStatus =
					newData.newStatus ||
					(newData.isDone ? AreaSequenceItemStatus.complete : AreaSequenceItemStatus.delayed);
				return {
					...areaSequenceItemToMap,
					status: finalStatus,
				};
			}),
		};
	});

export const useMainPageActivitiesAsiStatusMutation = (queryKeys: string[]) => {
	const queryClient = useQueryClient();

	const updateStatus = async ({ asi, isDone }: IAsiStatusUpdateMutation) =>
		requestService.put(`/activities/sequenceItems/areaSequenceItem/done`, {
			body: { mergedAreaSequenceItem: { ...asi }, isDone },
		});

	return useMutation({
		mutationFn: updateStatus,
		onMutate: async (newData: IAsiStatusUpdateMutation) => {
			await queryClient.cancelQueries(queryKeys);
			const previousActivitiesData = queryClient.getQueryData(queryKeys);
			queryClient.setQueryData(queryKeys, (activities: IMainPageWidgetActivityData[] | undefined) =>
				getOptimisticAsis(activities, newData)
			);

			const rollbackData: IAsiStatusUpdateMutation = {
				asi: newData.asi,
				isDone: !newData.isDone,
			};
			return { previousActivitiesData, rollbackData };
		},
		onError: (error, newData, context) => {
			const rollbackData: IMainPageWidgetActivityData[] | undefined = getOptimisticAsis(
				context?.previousActivitiesData as IMainPageWidgetActivityData[],
				context?.rollbackData as IAsiStatusUpdateMutation
			);
			queryClient.setQueryData(queryKeys, rollbackData);
		},
		onSuccess: (asiUpdated: IMergedAreaSequenceItem) => {
			asiUpdatedStatus_BI(asiUpdated);
		},
		onSettled: () => {
			queryClient.invalidateQueries(queryKeys);
		},
	});
};

export const useMainPageActivitiesAsiReviewStatusMutation = (queryKeys: any[]) => {
	const queryClient = useQueryClient();

	const updateAreaSequenceItemReadyForReviewStatus = async ({
		asi,
		updateToReadyForReview,
	}: IAsiStatusUpdateMutation): Promise<IMergedAreaSequenceItem> =>
		requestService.put(`/activities/sequenceItems/areaSequenceItem/${asi._id}/readyForReview`, {
			body: { isReadyForReview: updateToReadyForReview },
		});

	return useMutation({
		mutationFn: updateAreaSequenceItemReadyForReviewStatus,
		onMutate: async (newData: IAsiStatusUpdateMutation) => {
			await queryClient.cancelQueries(queryKeys);
			const previousActivitiesData = queryClient.getQueryData(queryKeys);
			queryClient.setQueryData(queryKeys, (activities: IMainPageWidgetActivityData[] | undefined) =>
				getOptimisticAsis(activities, newData)
			);

			const rollbackData: IAsiStatusUpdateMutation = {
				asi: newData.asi,
				isDone: false,
				newStatus: newData.asi.status,
			};
			return { previousActivitiesData, rollbackData };
		},
		onError: (error, newData, context) => {
			const rollbackData: IMainPageWidgetActivityData[] | undefined = getOptimisticAsis(
				context?.previousActivitiesData as IMainPageWidgetActivityData[],
				context?.rollbackData as IAsiStatusUpdateMutation
			);
			queryClient.setQueryData(queryKeys, rollbackData);
		},
		onSuccess: (asiUpdated) => {
			asiUpdatedStatus_BI(asiUpdated);
		},
		onSettled: () => {
			queryClient.invalidateQueries(queryKeys);
		},
	});
};
