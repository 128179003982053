import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializer';
import { IResourceCountData } from '@src/hooks/queries/resourceCountByLocation.queries.hooks';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IFloor } from '@shared/interfaces/IFloor';
import { AreaTypeEnum } from '@shared/interfaces/AreaType.enum';

export const getResourceIcon = (resource: MonitorResourcesEnum): IconNames => {
	switch (resource) {
		case MonitorResourcesEnum.PERSONNEL:
			return IconNames.users;
		case MonitorResourcesEnum.EQUIPMENT:
			return IconNames.wrench;
		case MonitorResourcesEnum.ACTIVITIES:
			return IconNames.workplan;
		case MonitorResourcesEnum.ISSUES:
			return IconNames.issues;
		default:
			return IconNames.helmet;
	}
};

export const getResourceLink = (floorId: string, resource: MonitorResourcesEnum): string =>
	`${floorId}/${resource.toLowerCase()}`;

export const getMonitorResourceTranslation = (resource: MonitorResourcesEnum | string): string => {
	const text: string = `mobileMonitor_resource_${resource.toLowerCase()}`;
	return translationService.get(text);
};

export const getFloorResourcePageTitle = (
	resource: MonitorResourcesEnum,
	count: number | undefined,
	isLoading: boolean
): string => `${getMonitorResourceTranslation(resource)} ${isLoading ? '' : `(${count})`}`;

export const getFloorCountByResourceFromResourcesCount = (
	resourcesCount: Record<MonitorResourcesEnum, IResourceCountData>,
	floorId: string
): Record<string, number> => {
	const countData: Record<string, number> = {};
	Object.keys(resourcesCount).forEach((resource) => {
		const resourceData: IResourceCountData = resourcesCount[resource];
		if (!resourceData.isLoaded) {
			return;
		}
		if (!resourceData.countByLocation.floors[floorId]) {
			return;
		}
		countData[resource] = resourceData.countByLocation.floors[floorId].floorTotal;
	});
	return countData;
};

export const getAreaCountByResourceFromResourcesCount = (
	resourcesCount: Record<MonitorResourcesEnum, IResourceCountData>,
	floorId: string,
	areaId: string
): Record<string, number> => {
	const countData: Record<string, number> = {};
	Object.keys(resourcesCount).forEach((resource) => {
		const resourceData: IResourceCountData = resourcesCount[resource];
		if (!resourceData.isLoaded) {
			return;
		}
		if (!resourceData.countByLocation.floors[floorId]?.areas[areaId]) {
			return;
		}
		countData[resource] = resourceData.countByLocation.floors[floorId].areas[areaId];
	});
	return countData;
};

export const getSpecialAreaIconName = (areaType?: AreaTypeEnum, isHoist?: boolean) => {
	if (isHoist) return IconNames.elevator;
	switch (areaType) {
		case AreaTypeEnum.APARTMENT:
			return IconNames.apartment;
		case AreaTypeEnum.STRUCTURE:
			return IconNames.structure;
		default:
			return IconNames.pin;
	}
};
