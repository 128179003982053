import React, { useEffect } from 'react';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import settingsIcon from '@shared/assets/icons/settings_icon.svg';
import { IProfessionWithActiveTags } from '../../../../interfaces/IProfessionWithActiveTags';
import { ActivityPageViewTypes } from '../../../../models/ActivityPageViewTypes';
import { ActivityPageSortOptions } from '../../../../models/ActivityPageSortOptions';
import { EmptyStateComponent } from '../../../EmptyStateComponent/EmptyStateComponent';
import { translationService } from '../../../../index';
import { MyWalkAroundActivitiesByFloors } from '../../../MyWalkAroundActivitiesByFloors/MyWalkAroundActivitiesByFloors';
import { MyWalkAroundActivitiesByProgress } from './MyWalkAroundActivitiesByProgress/MyWalkAroundActivitiesByProgress';
import { IMyWorkAroundData } from '../../../../interfaces/IMyWorkAroundData';
import classes from './styles.module.scss';

interface IMyWalkAroundProps {
	myWalkAroundData: IMyWorkAroundData | undefined;
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[] | undefined;
	activitiesLocationData: IProfessionWithActiveTags[] | undefined;
	refetchAllData: () => Promise<void>;
	shouldNotShowProgressFloorsViewOption: (value: boolean) => void;
	personalViewSortOption: ActivityPageSortOptions;
	progressFloorsViewState: ActivityPageViewTypes;
	isUserPreferenceEmptyState: boolean;
}

const MyWalkAround = (props: IMyWalkAroundProps) => {
	const isPersonalView = props.personalViewSortOption === ActivityPageSortOptions.PERSONAL_VIEW;

	useEffect(() => {
		props.refetchAllData();
	}, []);

	const getComponentToRender = () =>
		props.progressFloorsViewState === ActivityPageViewTypes.BY_PROGRESS ? (
			<MyWalkAroundActivitiesByProgress
				myWalkAroundData={props.myWalkAroundData}
				activitiesReportData={props.activitiesReportData}
				activitiesLocationData={props.activitiesLocationData}
				refetchAllData={props.refetchAllData}
				personalViewState={props.personalViewSortOption}
			/>
		) : (
			<MyWalkAroundActivitiesByFloors
				isPersonalView={isPersonalView}
				activitiesReportData={props.activitiesReportData}
				refetchAllData={props.refetchAllData}
			/>
		);

	const noProgressActivityEmptyState: boolean = !!(
		props.myWalkAroundData &&
		[...props.myWalkAroundData?.progressedToday, ...props.myWalkAroundData?.startedToday]?.length === 0
	);

	props.shouldNotShowProgressFloorsViewOption(noProgressActivityEmptyState || props.isUserPreferenceEmptyState);
	return (
		<div className={classes.myWalkAroundContainer}>
			{props.isUserPreferenceEmptyState ? (
				<EmptyStateComponent
					icon={settingsIcon}
					text={translationService.get(
						props.isUserPreferenceEmptyState
							? 'personalViewEmptyStateText'
							: 'noProgressActivitiesEmptyStateText'
					)}
					style={{ maxWidth: '250px', marginTop: 10 }}
				/>
			) : (
				getComponentToRender()
			)}
		</div>
	);
};

export { MyWalkAround };
