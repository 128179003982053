import React from 'react';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import classes from './styles.module.scss';

interface IProfessionTagsCardProps {
	professionTags: IWorkerMergedTag[];
}

export const ProfessionTagsCard = (props: IProfessionTagsCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;

	return (
		<div className={classes.ProfessionTagsCard_container} data-testid="ProfessionTagsCard">
			<div className={classes.title}>
				<ProfessionDisplayWithTradeIcon profession={props.professionTags[0].profession} projectId={projectId} />
				<div className={classes.length}>{props.professionTags.length}</div>
			</div>
			<div className={classes.content}>
				{props.professionTags.map((tag, index) => (
					<div key={tag._id} className={classnames(classes.tagRow)}>
						<div className={classes.name}>{tag.name}</div>
						<div className={classes.tagNick}>{tag.tagNick}</div>
					</div>
				))}
			</div>
		</div>
	);
};
