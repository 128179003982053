import { createSlice } from '@reduxjs/toolkit';
import { INotification } from '@shared/interfaces/INotification';
import { orderBy, uniqBy } from 'lodash';
import type { IRootState } from './index';

interface INotificationsReducersState {
	notifications: INotification[] | undefined;
}

const initialState: INotificationsReducersState = {
	notifications: undefined,
};
const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		updateNotifications: (
			state: INotificationsReducersState,
			action: { payload: { notifications: INotification[] } }
		) => {
			const combinedNotifications: INotification[] = [
				...action.payload.notifications,
				...(state.notifications || []),
			];
			const uniqueCombinedNotifications: INotification[] = uniqBy(combinedNotifications, '_id');
			const visibledNotifications: INotification[] = uniqueCombinedNotifications.filter(
				(notification) => notification.isVisible
			);
			const sortedVisibleNotifications: INotification[] = orderBy(visibledNotifications, 'createdAt', ['desc']);
			state.notifications = sortedVisibleNotifications;
		},
		markNotificationAsViewed: (state: INotificationsReducersState) => {
			state.notifications = state.notifications?.map((notification) => ({
				...notification,
				isViewed: true,
			}));
		},
		resetNotifications: (state: INotificationsReducersState) => {
			state.notifications = undefined;
		},
	},
});

export const selectNotifications = (state: IRootState): INotification[] | undefined =>
	state.notificationsReducer.notifications;

export const { markNotificationAsViewed, updateNotifications, resetNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;
