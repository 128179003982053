export enum IToastMessageTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	INFO = 'info',
}

export interface IToastMessage {
	text: string;
	type: IToastMessageTypes;
}

export interface ISliceToastMessage extends IToastMessage {
	id: string;
}

export interface IToastMessageReducerState {
	messages: ISliceToastMessage[];
}
