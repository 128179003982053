import React, { useState } from 'react';
import { IProfession } from '@shared/interfaces/IProfession';
import rightArrowIcon from '@shared/assets/icons/rightArrow.svg';
import { TranslationService } from '@shared/services/translation.service';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import classes from './styles.module.scss';
import { selectProjectId } from '../../../../store/slices/project.slice';

interface IIssuesCollapseProps {
	profession: IProfession | undefined;
	translationService: TranslationService;
	children?: React.ReactNode;
	count?: number;
	initialOpenState?: boolean;
}

const IssuesCollapse = (props: IIssuesCollapseProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const language: string = props.translationService.getChosenLanguage();
	const [isOpen, setIsOpen] = useState<boolean>(!!props.initialOpenState);

	const handleCollapseToggle = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<section className={classes.issueCollapseContainer}>
			<div
				className={classes.headerContainer}
				onClick={handleCollapseToggle}
				style={{ pointerEvents: props.count && props.count > 0 ? 'auto' : 'none' }}
			>
				{props.profession ? (
					<div>
						<ProfessionDisplayWithTradeIcon
							contractorTextClassname={classes.headerContractorText}
							profession={props.profession}
							projectId={projectId}
						/>
					</div>
				) : (
					<p className={classes.headerText}>{props.translationService.get('general')}</p>
				)}
				<div className={classes.infoSection}>
					{props.count && <p>{props.count}</p>}
					<img
						src={rightArrowIcon}
						alt=""
						className={classnames(classes.arrow, { [classes.open]: isOpen })}
					/>
				</div>
			</div>
			{isOpen && <div className={classes.issuesItemsContainer}>{props.children}</div>}
		</section>
	);
};

export { IssuesCollapse };
