import React, { useEffect, useMemo, useState } from 'react';
import { Loader } from '@shared/components/Loader/Loader';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { ISequence } from '@shared/interfaces/ISequence';
import { SingleDropdown } from '@src/components/Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { uniq } from 'lodash';
import { MonitorAreaActivitiesSection } from './MonitorAreaActivitiesSection/MonitorAreaActivitiesSection';
import { getActivitiesSectionsFromAreaSequenceItems } from './monitorAreaActivities.utils';
import classes from './styles.module.scss';
import { useProjectSequenceItems, useProjectSequences } from '@src/hooks/queries/sequenceItems.hooks';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';

interface IMonitorAreaActivitiesProps {
	areaId: string;
	areaSequenceItems: IAreaSequenceItem[];
}

export const MonitorAreaActivities = (props: IMonitorAreaActivitiesProps) => {
	const [selectedSequenceId, setSelectedSequenceId] = useState<string | undefined>();
	const { sequences, isFetched: areSequencesFetched } = useProjectSequences();
	const { sequenceItems, isFetched: areSequenceItemsFetched } = useProjectSequenceItems();

	const uniqSequenceIdsFromAsis: string[] = useMemo(
		() => uniq(props.areaSequenceItems.map((asi) => asi.sequenceId)),
		[props.areaSequenceItems]
	);

	const sequencesForAsis: ISequence[] = useMemo(
		() => sequences.filter((sequenceItem) => uniqSequenceIdsFromAsis.includes(sequenceItem._id)),
		[sequences.length, uniqSequenceIdsFromAsis]
	);

	useEffect(() => {
		if (sequencesForAsis.length && !selectedSequenceId) {
			setSelectedSequenceId(sequencesForAsis[0]._id);
		}
	}, [sequencesForAsis]);

	const areaSequenceItemsWithDescriptionFilteredBySelectedSequence: IAreaSequenceItem[] = useMemo(() => {
		return props.areaSequenceItems.filter((asi) => asi.sequenceId === selectedSequenceId);
	}, [props.areaSequenceItems, selectedSequenceId]);

	const activitiesSections: {
		[title: string]: IAreaSequenceItem[];
	} = useMemo(() => {
		return getActivitiesSectionsFromAreaSequenceItems(areaSequenceItemsWithDescriptionFilteredBySelectedSequence);
	}, [areaSequenceItemsWithDescriptionFilteredBySelectedSequence]);

	if (!areSequencesFetched || !areSequenceItemsFetched) {
		return <Loader />;
	}

	if (!sequencesForAsis.length) {
		return <DashboardTabEmptyState resource={EmptyStateResourcesEnum.activities} />;
	}

	return (
		<div className={classes.monitorAreaActivitiesContainer}>
			{sequencesForAsis.length > 1 ? (
				<SingleDropdown
					options={sequencesForAsis}
					getInputDisplay={(option: ISequence) => option.name}
					value={sequencesForAsis.find((sequence) => sequence._id === selectedSequenceId)}
					onChange={(option: ISequence | null) => option && setSelectedSequenceId(option._id)}
					startIcon={<TrusstorIcon iconName={IconNames.pin} />}
					getDisplayOption={(option: ISequence) => <div>{option.name}</div>}
					hideClearTextButton
					shouldNotSelectInputTextOnFocus
				/>
			) : (
				<p className={classes.sequenceTitle}>{sequencesForAsis[0].name}</p>
			)}
			<div className={classes.activitiesSectionsContainer}>
				{Object.entries(activitiesSections).map(([title, asis]) => {
					if (!asis.length) {
						return null;
					}
					return (
						<MonitorAreaActivitiesSection
							key={title}
							title={title}
							areaSequenceItems={asis}
							sequenceItems={sequenceItems}
						/>
					);
				})}
			</div>
		</div>
	);
};
