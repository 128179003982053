import React, { useEffect } from 'react';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IUser } from '@shared/interfaces/IUser';
import { useSelector } from 'react-redux';
import { filterUsersByPermittedProfessions } from '@shared/utils/users.utils';
import classes from './styles.module.scss';
import { translationService } from '../../servicesInitializer';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { DrawerDialog } from '../Dialogs/DrawerDialog/DrawerDialog';
import { useUsersQuery } from '../../hooks/queries/users.queries.hook';
import { selectProjectId } from '../../store/slices/project.slice';

interface IUserSelectorProps {
	onSelect: (manager: IUser | null) => void;
	initialSelectedUser?: IUser | null;
	closeDropdown: () => void;
	isOpen: boolean;
	permittedProfessionsIds?: string[];
}

export const UserSelector = (props: IUserSelectorProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const [searchParam, setSearchParam] = React.useState<string>('');
	const [usersOptions, setUsersOptions] = React.useState<IUser[]>([]);
	const { users }: { users: IUser[] } = useUsersQuery(projectId);
	const filteredUsers: IUser[] = usersOptions.filter((user: IUser) =>
		user.name.toLowerCase().includes(searchParam.toLowerCase())
	);

	useEffect(() => {
		const visibleUsers: IUser[] = props.permittedProfessionsIds?.length
			? filterUsersByPermittedProfessions(users, props.permittedProfessionsIds)
			: users;
		setUsersOptions(visibleUsers);
	}, [users, props.permittedProfessionsIds]);

	const handleCloseDropdown = () => {
		setSearchParam('');
		props.closeDropdown();
	};

	const handleAddButton = (user: IUser) => {
		props.onSelect(user);
		handleCloseDropdown();
	};

	const handleSetSearchParam = (value: string | React.ChangeEvent) => {
		setSearchParam(value as string);
	};

	return (
		<DrawerDialog
			isOpen={props.isOpen}
			onClose={handleCloseDropdown}
			title={translationService.get('addAssignee')}
			customHeader={
				<TrusstorTextInput
					autoFocus={false}
					changeFunc={handleSetSearchParam}
					rootClassName={classes.searchInput}
					placeholder={translationService.get('searchForAssignees')}
					startIcon={<TrusstorIcon iconName={IconNames.search} />}
				/>
			}
		>
			<div className={classes.bottomSection}>
				{!!filteredUsers.length &&
					filteredUsers.map((user: IUser) => (
						<div className={classes.managerItem} key={user.username} onClick={() => handleAddButton(user)}>
							{user.name}
						</div>
					))}
			</div>
		</DrawerDialog>
	);
};
