import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import appReducer, { IRootState } from './slices';

export const RESET_ALL_REDUCERS_ACTION_TYPE: string = 'RESET_ALL_REDUCERS';

const rootReducer = (state, action) => {
	if (action.type === RESET_ALL_REDUCERS_ACTION_TYPE) {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};

const store: EnhancedStore = configureStore({
	reducer: rootReducer,
});

export { store };
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, IRootState, unknown, Action<string>>;
