import PersonnelEmptyStateIcon from '@shared/assets/icons/EmptyStateIcons/personnelEmptyState.svg';
import React from 'react';
import { Dictionary, groupBy } from 'lodash';
import { ProfessionTagsCard } from '@src/components/pages/MonitorPage/ProfessionTagsCard/ProfessionTagsCard';
import { ManagersTagsCard } from '@src/components/pages/MonitorPage/ManagersTagsCard/ManagersTagsCard';
import { IPersonnelAreaData } from '@src/hooks/monitor.hooks';
import { MergedEquipmentsCards } from '@src/components/MergedEquipmentsCards/MergedEquipmentsCards';
import { translationService } from '@src/servicesInitializer';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import classes from './styles.module.scss';

interface IAreaPersonnelPageProps {
	areaId: string;
	data: IPersonnelAreaData;
}

export const AreaPersonnelPage = (props: IAreaPersonnelPageProps) => {
	const isLoading: boolean = props.data.isLoading;
	const isEmptyState: boolean = !isLoading && !props.data.personnelDataCount && !props.data.mergedEquipments.length;
	const workersByProfessions: Dictionary<IWorkerMergedTag[]> = groupBy(
		props.data.workersTags,
		(tag) => tag.profession._id
	);

	return (
		<div className={classes.AreaPersonnelPage_container} data-testid="AreaPersonnelPage">
			{isEmptyState ? (
				<DashboardTabEmptyState resource={EmptyStateResourcesEnum.personnel} />
			) : (
				<>
					{Object.values(workersByProfessions).map((workers) => (
						<ProfessionTagsCard professionTags={workers} />
					))}
					{!!props.data.managerTags?.length && <ManagersTagsCard managersTags={props.data.managerTags} />}
					{!!props.data.mergedEquipments?.length && (
						<MergedEquipmentsCards mergedEquipments={props.data.mergedEquipments} />
					)}
				</>
			)}
		</div>
	);
};
