import Lottie from 'react-lottie';
import React from 'react';
import classes from './styles.module.scss';
import SplashScreenAnimation from './splashScreen.json';

export const SplashScreen = () => (
	<div className={classes.splashWrapper}>
		<div className={classes.animationItem}>
			<Lottie
				options={{
					animationData: SplashScreenAnimation,
				}}
			/>
		</div>
	</div>
);
