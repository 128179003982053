import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IFloor } from '@shared/interfaces/IFloor';
import { IProfession } from '@shared/interfaces/IProfession';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { ProfessionsSelectionSection } from '../../ProfessionsSelectionSection/ProfessionsSelectionSection';
import { FloorsSelectionSection } from '../../FloorsSelectionSection/FloorsSelectionSection';
import { selectLoggedUser } from '../../../store/slices/login.slice';
import { updateUserPreferences } from '../../../store/thunks/userPreferences.thunks';
import { SetupCard } from './SetupCard';
import { translationService } from '../../../index';
import classes from './styles.module.scss';
import { selectTimezone } from '../../../store/slices/project.slice';
import { useWorkingProfessionsQuery } from '../../../hooks/queries/professions.queries.hooks';
import { InnerPageHeader } from '../../InnerPageHeader/InnerPageHeader';
import { BottomActionButton } from '../../BottomActionButton/BottomActionButton';
import { AppRoutes } from '../../../constants/appRoutes.enum';

export enum SetupCardType {
	ENTIRE_SITE = 'ENTIRE_SITE',
	CUSTOMIZED = 'CUSTOMIZED',
}

const SetupPage = () => {
	const [selectedCard, setSelectedCard] = useState<SetupCardType | null>(null);
	const [setupStage, setSetupStage] = useState<number>(0);
	const [selectedProfessions, setSelectedProfessions] = useState<IProfession[]>([]);
	const [updatedFloors, setUpdatedFloors] = useState<IFloor[]>([]);

	const tz: string = useSelector(selectTimezone)!;
	const timezoneDate: string = getTimezoneFormattedDate(tz, new Date());
	const username: string = useSelector(selectLoggedUser)!.userDetails.username;

	const workingProfessions: IProfession[] = useWorkingProfessionsQuery(timezoneDate);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleCardClick = (cardType: SetupCardType): void => {
		if (cardType === selectedCard) {
			setSelectedCard(null);
			return;
		}
		setSelectedCard(cardType);
	};

	const updateUserPreferencesByFloorsAndProfessions = (
		currentFloors: IFloor[],
		currentProfessions: IProfession[]
	) => {
		const userPreferencesCreated: Partial<IUserPreferences> = {
			username,
			mobile: {
				filters: {
					floorIds: currentFloors.length === 0 ? null : currentFloors.map((floor) => floor.floorId),
					professionIds:
						currentProfessions.length === 0 ? null : currentProfessions.map((profession) => profession._id),
				},
			},
		};
		dispatch(updateUserPreferences(userPreferencesCreated, username));
	};

	const handleEntireSiteClick = () => {
		updateUserPreferencesByFloorsAndProfessions([], []);
		history.push(AppRoutes.workersWithNoMode);
	};

	const increaseSetupStage = (): void => {
		setSetupStage((prevStage: number) => prevStage + 1);
	};

	const handleButtonClick = () => {
		if (setupStage === 0) {
			if (selectedCard === SetupCardType.ENTIRE_SITE) return handleEntireSiteClick();
			increaseSetupStage();
		}
		if (setupStage === 1) {
			increaseSetupStage();
		}
		if (setupStage === 2) {
			updateUserPreferencesByFloorsAndProfessions(updatedFloors, selectedProfessions);
			history.push(AppRoutes.workerWithEducation);
		}
		return null;
	};

	const handleBackClick = (): void => {
		setSetupStage((prevStage: number) => prevStage - 1);
	};

	const isActionButtonDisabled = (stage: number): boolean => {
		if (stage === 0) return !selectedCard;
		if (stage === 1) return !selectedProfessions.length;
		if (stage === 2) return !updatedFloors.length;
		return true;
	};

	const shouldShowHeader: boolean = setupStage !== 0;

	return (
		<section className={classnames(classes.fullContainer, { [classes.makePlace]: shouldShowHeader })}>
			{shouldShowHeader && (
				<InnerPageHeader title={translationService.get('customizeView')} handleBackClick={handleBackClick} />
			)}
			{setupStage === 0 && (
				<div className={classes.setupPageContainer}>
					<div className={classes.setupText}>{translationService.get('setupPageText')}</div>
					<div className={classes.selectViewText}>{translationService.get('selectViewText')}</div>
					<SetupCard
						onCardClick={() => handleCardClick(SetupCardType.ENTIRE_SITE)}
						title={translationService.get('entireSite')}
						text={translationService.get('entireSiteText')}
						recommendedRoles={['projectManager', 'siteEngineer']}
						isSelected={selectedCard === SetupCardType.ENTIRE_SITE}
					/>
					<SetupCard
						onCardClick={() => handleCardClick(SetupCardType.CUSTOMIZED)}
						title={translationService.get('customized')}
						text={translationService.get('customizedText')}
						recommendedRoles={['fieldManager']}
						isSelected={selectedCard === SetupCardType.CUSTOMIZED}
					/>
				</div>
			)}
			{setupStage === 1 && (
				<>
					<div className={classes.innerSection}>
						<div className={classes.title}>{translationService.get('selectProfessionsOversee')}</div>
					</div>
					<ProfessionsSelectionSection
						setSelectedProfessions={setSelectedProfessions}
						allProfessions={workingProfessions}
					/>
				</>
			)}
			{setupStage === 2 && (
				<>
					<div className={classes.floorSelectionTitle}>{translationService.get('chooseFloorMonitoring')}</div>
					<FloorsSelectionSection setSelectedFloors={setUpdatedFloors} selectedFloors={updatedFloors} />
				</>
			)}
			<BottomActionButton
				isDisabled={isActionButtonDisabled(setupStage)}
				text={translationService.get(setupStage === 2 ? 'done' : 'next')}
				handleClick={handleButtonClick}
			/>
		</section>
	);
};

export { SetupPage };
