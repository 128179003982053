import React from 'react';
import { IconColor, IconSize, TrusstorIconShared } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { translationService } from '@src/servicesInitializer';
import classes from './styles.module.scss';

interface IActivityCardReviewChipProps {
	asis: IAreaSequenceItem[];
}

export const ActivityCardReviewChip = (props: IActivityCardReviewChipProps) => {
	const isAtLeastOneAsiInReview: boolean = props.asis.some(
		(asi) => asi.status === AreaSequenceItemStatus.readyForReview
	);

	if (!isAtLeastOneAsiInReview) return null;

	return (
		<div className={classes.reviewChipContainer}>
			<TrusstorIconShared
				iconName={IconNames.workplan}
				color={IconColor.FeedbackHighDark}
				size={IconSize.SMALL}
			/>
			{translationService.get('mobile_activitiesPage_reviewChip')}
		</div>
	);
};
