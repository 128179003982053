import React from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import classnames from 'classnames';
import classes from './styles.module.scss';
import closeIcon from '../../assets/close.svg';
import { translationService } from '../../servicesInitializer';

interface ITrusstorDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	topSection?: React.ReactNode | string;
	children?: React.ReactNode;
}

const TrusstorDrawer = (props: ITrusstorDrawerProps) => {
	const isTitleString: boolean = typeof props.topSection === 'string';
	const isRtl: boolean = translationService.getIsRtl();
	const bodyClassname = classnames(classes.bodySection, {
		[classes.noTopSection]: !props.topSection,
	});

	return (
		<SwipeableDrawer
			open={props.isOpen}
			classes={{ paper: classes.dialogPaper }}
			anchor={'bottom'}
			onOpen={() => {}}
			onClose={props.onClose}
			disableSwipeToOpen={true}
		>
			<div className={classes.drawerContainer}>
				{props.topSection && (
					<div
						className={classnames(classes.topSection, {
							[classes.isTitleString]: isTitleString,
						})}
					>
						{isTitleString ? <div className={classes.title}>{props.topSection}</div> : props.topSection}
						<img
							src={closeIcon}
							alt="X"
							className={classnames(classes.closeIcon, {
								[classes.rtl]: isRtl,
							})}
							onClick={props.onClose}
						/>
					</div>
				)}
				<div className={bodyClassname}>{props.children}</div>
			</div>
		</SwipeableDrawer>
	);
};

export { TrusstorDrawer };
