import { UserService } from '@shared/services/user.service';
import { UserAccessLevelService } from '@shared/services/userAccessLevelService';
import { ProjectService } from '@shared/services/project.service';
import { VersionService } from '@shared/services/version.service';
import { RequestService } from '@shared/services/request.service';
import { StreamService } from '@shared/services/stream.service';
import { IProject } from '@shared/interfaces/IProject';
import { TranslationService } from '@shared/services/translation.service';
import { BIService } from '@shared/services/bi.service';
import { StorageService } from '@shared/services/storage.service';
import { ITranslations } from '@shared/interfaces/ITranslations';
import { ProjectAccessLevelService } from '@shared/services/projectAccessLevel.service';
// eslint-disable-next-line import/no-cycle
import { AuthService } from './services/auth.service';
import { UserFeaturesNamesType, userFeaturesProhibitedList } from './featuresProhibitedList/userFeaturesProhibitedList';
import { store } from './store/store';
import { config } from './config/config';
import { BIEventNames } from './interfaces/BI/BIEventNames.enum';
import {
	ProjectPackagesFeaturesNamesType,
	projectPackagesFeaturesProhibitedList,
} from './featuresProhibitedList/projectPackagesFeaturesProhibitedList';

const userService: UserService = new UserService();
const projectService: ProjectService = new ProjectService();
const userAccessLevelService: UserAccessLevelService<UserFeaturesNamesType> =
	new UserAccessLevelService<UserFeaturesNamesType>(userFeaturesProhibitedList, userService);
const projectAccessLevelService: ProjectAccessLevelService<ProjectPackagesFeaturesNamesType> =
	new ProjectAccessLevelService<ProjectPackagesFeaturesNamesType>(
		projectPackagesFeaturesProhibitedList,
		projectService
	);
const versionService: VersionService = new VersionService();
const requestService: RequestService = new RequestService(
	config.apiGateway,
	userService,
	projectService,
	versionService,
	store
);
const authService: AuthService = new AuthService(requestService, userService);
const streamService: StreamService = new StreamService(config, userService, store);
const storageService: StorageService = new StorageService(requestService);
const chosenProject: IProject | undefined = projectService.getChosenProject();
const localTranslationsJson: ITranslations = require('@shared/translations/translations.json');

const translationService: TranslationService = new TranslationService(
	chosenProject?.language || config.defaultLanguage,
	async () => storageService.getTranslationsCsv(),
	localTranslationsJson
);

const biService: BIService<BIEventNames> = new BIService<BIEventNames>(config, versionService);

export {
	userAccessLevelService,
	projectAccessLevelService,
	streamService,
	userService,
	requestService,
	authService,
	versionService,
	storageService,
	translationService,
	projectService,
	biService,
};
