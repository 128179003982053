import React, { useContext, useEffect, useState } from 'react';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { AreaSequenceItemStatusChip } from '@src/components/AreaSequenceItemStatusChip/AreaSequenceItemStatusChip';
import { translationService, userAccessLevelService } from '@src/index';
import { TrusstorDrawer } from '@src/components/TrusstorDrawer/TrusstorDrawer';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { AsiReviewDialog } from '@src/components/AsiReviewDialog/ASIReviewDialog';
import { getAsiStatusUpdateQueryKeys, useAsiStatusUpdateMutation } from '@src/hooks/queries/sequenceItems.hooks';
import { IssueUpsertPage } from '@src/components/pages/IssuesPage/IssueUpsertPage/IssueUpsertPage';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { baseLocationToIssueLocation } from '@src/components/IssueLocationSelector/LocationListSelector/locationListSelector.utils';
import { IIssuesAreaData, useMonitorAreaIssuesData } from '@src/hooks/monitor.hooks';
import { updateAreaSequenceItemReadyForReviewStatus } from '@src/apis/areaSequenceItems.api';
import { useQueryClient } from '@tanstack/react-query';
import { MonitorAreaPageContext } from '../../../MonitorAreaPage';
import { getStatusesToUpdateTo } from './monitorAreaActivityCard.utils';
import classes from './styles.module.scss';

interface IMonitorAreaActivityCardProps {
	areaSequenceItem: IAreaSequenceItem;
	sequenceItem: ISequenceItem;
}

export const MonitorAreaActivityCard = (props: IMonitorAreaActivityCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const userHasAccessToActivityActions: boolean = userAccessLevelService.hasAccess('workplanActivityActions');
	const [asiStatus, setAsiStatus] = useState<AreaSequenceItemStatus>(props.areaSequenceItem.status);
	const [showStatusUpdateDrawer, setShowStatusUpdateDrawer] = useState<boolean>(false);
	const [showReviewDialog, setShowReviewDialog] = useState<boolean>(false);
	const [showCreateIssueModal, setShowCreateIssueModal] = useState<boolean>(false);
	const asiUpdateMutation = useAsiStatusUpdateMutation(props.areaSequenceItem.area.areaId);
	const queryClient = useQueryClient();
	const { area } = useContext(MonitorAreaPageContext)!;
	const asiUpdateQuery: string[] = getAsiStatusUpdateQueryKeys(area!.areaId);
	const areaIssueLocation: IIssueLocation | undefined = area && baseLocationToIssueLocation(area);
	const issuesData: IIssuesAreaData = useMonitorAreaIssuesData(props.areaSequenceItem.area.areaId);

	useEffect(() => {
		if (props.areaSequenceItem.status) {
			setAsiStatus(props.areaSequenceItem.status);
		}
	}, [props.areaSequenceItem.status]);

	const updateAsiStatus = async (status: AreaSequenceItemStatus) => {
		const prevStatus: AreaSequenceItemStatus = status;
		setAsiStatus(status);
		try {
			await asiUpdateMutation.mutateAsync({
				areaSequenceItemId: props.areaSequenceItem._id!,
				updatedStatus: status,
				areaId: props.areaSequenceItem.area.areaId!,
				activityId: props.areaSequenceItem.activityId!,
				profession: props.sequenceItem.profession,
				sequenceItemId: props.sequenceItem._id!,
				description: props.sequenceItem.description,
			});
		} catch (err) {
			setAsiStatus(prevStatus);
		}
	};

	const onUpdateStatusClick = async (status: AreaSequenceItemStatus) => {
		setShowStatusUpdateDrawer(false);
		await updateAsiStatus(status);
	};

	const onChipClick = () => {
		if (asiStatus === AreaSequenceItemStatus.readyForReview && userHasAccessToActivityActions) {
			setShowReviewDialog(true);
			return;
		}
		setShowStatusUpdateDrawer(true);
	};

	const onApproveSubmit = async () => {
		await updateAsiStatus(AreaSequenceItemStatus.complete);
	};

	const onRejectSubmit = () => {
		setShowReviewDialog(false);
		setShowCreateIssueModal(true);
	};

	const handleCloseDrawer = () => {
		setShowStatusUpdateDrawer(false);
	};

	const closeCreateIssueModal = () => {
		setShowCreateIssueModal(false);
	};

	const onRejectForReviewIssueCreated = async () => {
		setShowCreateIssueModal(false);
		await updateAreaSequenceItemReadyForReviewStatus(props.areaSequenceItem._id!, false);
		issuesData.issueCreatedQueryInvalidation();
		queryClient.invalidateQueries(asiUpdateQuery);
	};

	const statusesToUpdateTo: AreaSequenceItemStatus[] = getStatusesToUpdateTo(
		asiStatus,
		userHasAccessToActivityActions
	);

	return (
		<>
			<div className={classes.monitorAreaActivityCardContainer}>
				<p className={classes.activityDescription}>{props.sequenceItem.description}</p>
				<div className={classes.professionAndStatus}>
					<ProfessionDisplayWithTradeIcon
						profession={props.sequenceItem.profession}
						projectId={projectId}
						hideContractor
					/>
					<AreaSequenceItemStatusChip
						status={asiStatus}
						onClick={onChipClick}
						isDropdown={!!statusesToUpdateTo.length}
					/>
				</div>
			</div>
			<TrusstorDrawer
				isOpen={showStatusUpdateDrawer && statusesToUpdateTo.length > 0}
				onClose={handleCloseDrawer}
				topSection={
					<div>
						<p className={classes.changeStatusText}>{translationService.get('changeStatus')}</p>
					</div>
				}
			>
				<div className={classes.statusUpdatesList}>
					{statusesToUpdateTo.map((status: AreaSequenceItemStatus) => (
						<div
							className={classes.statusUpdateItem}
							key={status}
							onClick={() => onUpdateStatusClick(status)}
						>
							<div className={classes.radioItem} />
							<AreaSequenceItemStatusChip status={status} />
						</div>
					))}
				</div>
			</TrusstorDrawer>
			{showReviewDialog && (
				<AsiReviewDialog
					asi={props.areaSequenceItem}
					projectId={projectId}
					area={props.areaSequenceItem.area}
					sequenceItemDescription={props.sequenceItem.description}
					profession={props.sequenceItem.profession}
					show={showReviewDialog}
					onClose={() => setShowReviewDialog(false)}
					onAcceptSubmit={onApproveSubmit}
					onRejectSubmit={onRejectSubmit}
				/>
			)}
			<IssueUpsertPage
				show={showCreateIssueModal}
				onClose={closeCreateIssueModal}
				initialIssue={{
					locations: areaIssueLocation ? [areaIssueLocation] : undefined,
					linkedActivityGroupId: props.areaSequenceItem.activityGroupId,
					profession: props.sequenceItem.profession,
				}}
				onIssueCreated={onRejectForReviewIssueCreated}
			/>
		</>
	);
};
