import { IIssue } from '@interfaces/IIssue';
import { IUser } from '@shared/interfaces/IUser';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { IProfession } from '@shared/interfaces/IProfession';

export const getOptimisticUpdatedIssue = (
	issueBeforeUpdate: IIssue,
	issueToUpdate: Partial<IUpdateIssue>,
	user: IUser
): IIssue => {
	const statusUpdatedData: Partial<IIssue> = {
		statusUpdateDate: new Date(),
		statusUpdateUser: {
			name: user.name,
			username: user.username,
		},
	};
	const isStatusUpdated: boolean = !!(issueToUpdate.status && issueToUpdate.status !== issueBeforeUpdate.status);
	const professionToUpdate: IProfession | undefined = (() => {
		if (issueToUpdate.profession === null) {
			return undefined;
		}
		if (issueToUpdate.profession) {
			return issueToUpdate.profession;
		}
		return issueBeforeUpdate.profession;
	})();

	const locationToUpdate: IIssueLocation[] | undefined = (() => {
		if (issueToUpdate.locations === null) {
			return undefined;
		}
		if (issueToUpdate.locations) {
			return issueToUpdate.locations;
		}
		return issueBeforeUpdate.locations;
	})();

	const optimisticUpdatedIssue: IIssue = {
		...issueBeforeUpdate,
		...issueToUpdate,
		profession: professionToUpdate,
		locations: locationToUpdate,
		...(isStatusUpdated && statusUpdatedData),
		isInitial: false,
	};

	return optimisticUpdatedIssue;
};
