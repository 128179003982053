import * as React from 'react';
import { useEffect, useId, useState } from 'react';
import { CriticalSection } from '@shared/components/Issue/CriticalSection/CriticalSection';
import { IIssueAssignee, IIssueLocation, IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { IProfession } from '@shared/interfaces/IProfession';
import { ICreateIssue, IIssue, IUpdateIssue } from '@shared/interfaces/IIssue';
import { useDispatch, useSelector } from 'react-redux';
import { ImageUploadInput } from '@shared/components/Inputs/ImageUploadInput/ImageUploadInput';
import { IUser } from '@shared/interfaces/IUser';
import { addImagesToIssue } from '@shared/utils/issues.utils';
import { isValidFileUploaded } from '@shared/utils/validation.utils';
import { useQueryClient } from '@tanstack/react-query';
import { ITrusstorButtonType, TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classnames from 'classnames';
import { omit } from 'lodash';
import { Drawer } from '@material-ui/core';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { IssuesQueryKeysEnum } from '@src/hooks/queries/issues.queries.hooks';
import { IssuePrivateSection } from '@shared/components/Issue/IssuePrivateSection/IssuePrivateSection';
import { requestService, translationService } from '../../../../servicesInitializer';
import classes from './styles.module.scss';
import { ProfessionSelector } from '../../../ProfessionSelector/ProfessionSelector';
import { SelectSection } from './SelectSection/SelectSection';
import { selectProjectId } from '../../../../store/slices/project.slice';
import { UserSelector } from '../../../UserSelector/UserSelector';
import { createIssue_BI, editIssue_BI } from '../../../../utils/bi.utils';
import { useCreateIssueMutation } from '../../../../apis/issues.apis';
import { selectIsLimitedUser, selectLoggedUserDetails } from '../../../../store/slices/login.slice';
import { errorToast } from '../../../../utils/toast.utils';
import { selectIsOffline } from '../../../../store/slices/offlineBanner.slice';
import { IToastMessage, IToastMessageTypes } from '../../../../../../shared/interfaces/IToastMessages';
import { setMessage } from '../../../../store/slices/toastMessage.slice';
import { IconNames } from '../../../../../../shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '../../../TrusstorIcon/TrusstorIcon';
import { IssueLocationSelector } from '../../../IssueLocationSelector/IssueLocationSelector';
import { DateSelector } from '../../../Inputs/DateSelector/DateSelector';
import { useUsersQuery } from '../../../../hooks/queries/users.queries.hook';

interface IIssuesModalProps {
	onClose: () => void;
	addIssueToState?: (issue: IIssue) => void;
	onIssueCreated?: (issue: IIssue) => void;
	initialIssue?: Partial<IIssue>;
	handleIssueEdit?: (issueId: string, newIssue: Partial<IUpdateIssue>) => Promise<IIssue>;
	dialogTitle?: string;
	disableSuccessToast?: boolean;
	show: boolean;
}

export const IssueUpsertPage = (props: IIssuesModalProps) => {
	const isLimitedUser: boolean = useSelector(selectIsLimitedUser);
	const loggedUser: IUser = useSelector(selectLoggedUserDetails)!;
	const id = useId();
	const [description, setDescription] = useState<string>('');
	const [priority, setPriority] = useState<IssuePriority>(IssuePriority.REGULAR);
	const [professionDialogOpen, setProfessionDialogOpen] = useState<boolean>(false);
	const [profession, setProfession] = useState<IProfession | null>(null);
	const [locations, setLocations] = useState<IIssueLocation[] | null>(null);
	const [locationDialogOpen, setLocationDialogOpen] = useState<boolean>(false);
	const [assigneeDialogOpen, setAssigneeDialogOpen] = useState<boolean>(false);
	const [assignee, setAssignee] = useState<IIssueAssignee | null>(null);
	const [dueDate, setDueDate] = useState<Date | null>(null);
	const [isPrivate, setIsPrivate] = useState<boolean>(false);
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [showCreateLoader, setShowLoader] = useState<boolean>(false);
	const isPendingRef = React.useRef<boolean>(false);
	const floorTextRef: any = React.useRef(null);
	const projectId: string = useSelector(selectProjectId)!;
	const currentUser: IUser = useSelector(selectLoggedUserDetails)!;
	const dispatch = useDispatch();
	const createIssueMutation = useCreateIssueMutation(projectId, loggedUser.username);
	const queryClient = useQueryClient();
	const { users }: { users: IUser[] } = useUsersQuery(projectId);
	const isOffline: boolean = useSelector(selectIsOffline);
	const isEditMode: boolean = !!props.handleIssueEdit && !!props.initialIssue?._id;
	const dialogTitle: string = (() => {
		if (props.dialogTitle) {
			return props.dialogTitle;
		}
		return isEditMode ? translationService.get('editIssue') : translationService.get('newIssue');
	})();

	const isRTL: boolean = translationService.getIsRtl();

	useEffect(() => {
		const selectedUser: IUser | undefined = users.find((user) => user.username === assignee?.username);
		const isSelectedAssigneePermitted: boolean =
			!selectedUser?.permissions.permittedProfessionsIds?.length ||
			selectedUser?.permissions.permittedProfessionsIds?.some((permittedId) => permittedId === profession?._id);
		if (profession && !isSelectedAssigneePermitted) {
			setAssignee(null);
		}
	}, [users, profession, assignee]);

	useEffect(() => {
		if (isPrivate) {
			setAssignee({ username: loggedUser.username, name: loggedUser.name });
		}
	}, [isPrivate]);

	useEffect(() => {
		if (!props.initialIssue && props.show) {
			setAssignee({ username: loggedUser.username, name: loggedUser.name });
		} else {
			assignee || setAssignee(props.initialIssue?.assignee || null);
		}
		description || setDescription(props.initialIssue?.description || '');
		priority || setPriority(props.initialIssue?.priority || IssuePriority.REGULAR);
		profession || setProfession(props.initialIssue?.profession || null);
		locations || setLocations(props.initialIssue?.locations || null);
		dueDate || setDueDate(props.initialIssue?.dueDate || null);
		isPrivate || setIsPrivate(props.initialIssue?.isPrivate || false);
	}, [props.initialIssue, props.show]);

	const handlePriority = () => {
		setPriority((prevState) =>
			prevState === IssuePriority.CRITICAL ? IssuePriority.REGULAR : IssuePriority.CRITICAL
		);
	};

	const handleProfessionSelection = (professionSelected: IProfession | null) => {
		setProfession(professionSelected);
		setProfessionDialogOpen(false);
	};

	const selectLocation = (locationsSelected: IIssueLocation[]) => {
		setLocations(locationsSelected);
	};

	const handleAssigneeSelection = (assigneeSelected: IUser | null) => {
		setAssignee(
			assigneeSelected
				? {
						username: assigneeSelected?.username,
						name: assigneeSelected?.name,
					}
				: null
		);
		setAssigneeDialogOpen(false);
	};

	const clearState = () => {
		setSelectedImages([]);
		setPriority(IssuePriority.REGULAR);
		setDescription('');
		setProfession(null);
		setLocations(null);
		setAssignee(null);
		setDueDate(null);
		setIsPrivate(false);
	};

	const handleClose = () => {
		clearState();
		props.onClose();
	};

	const handleSetIsPrivate = (value: boolean) => {
		setIsPrivate(value);
	};

	const handleSuccessToastMessage = (): void => {
		if (props.disableSuccessToast) return;
		if (isOffline) {
			const offlineText: string = isEditMode
				? translationService.get('editedIssueToastOffline')
				: translationService.get('createdIssueToastOffline');
			const message: IToastMessage = {
				text: offlineText,
				type: IToastMessageTypes.SUCCESS,
			};
			dispatch(setMessage({ message }));
			return;
		}
		const onlineText: string = isEditMode
			? translationService.get('editedIssueToastOnline')
			: translationService.get('createdIssueToastOnline');
		const message: IToastMessage = {
			text: onlineText,
			type: IToastMessageTypes.SUCCESS,
		};
		dispatch(setMessage({ message }));
	};

	const createIssue = async () => {
		if (isPendingRef.current) return;
		isPendingRef.current = true;
		setShowLoader(true);
		const issue: ICreateIssue = {
			description,
			status: IssueStatus.PLANNED,
			priority,
			...(assignee && { assignee: { username: assignee.username } }),
			projectId,
			...(profession && { profession }),
			...(dueDate && { dueDate }),
			...(locations?.length && { locations }),
			...(props.initialIssue?.linkedActivityGroupId && {
				linkedActivityGroupId: props.initialIssue.linkedActivityGroupId,
			}),
			...(props.initialIssue?.linkedAreaSequenceItemId && {
				linkedAreaSequenceItemId: props.initialIssue.linkedAreaSequenceItemId,
			}),
			isPrivate,
		};

		try {
			createIssueMutation.mutateAsync(issue).then(async (issueCreated: IIssue) => {
				await addImagesToIssue(issueCreated._id, currentUser, selectedImages, requestService);
				queryClient.invalidateQueries([IssuesQueryKeysEnum.getIssuesByProject, projectId, loggedUser.username]);
				props.onIssueCreated?.(issueCreated);
			});
			const issueWithoutAssignee: Omit<ICreateIssue, 'assignee'> = omit(issue, ['assignee']);
			const createdIssue: IIssue = {
				_id: `temp-${id}`,
				...issueWithoutAssignee,
				...(issue.assignee && {
					assignee: {
						username: issue.assignee.username,
						name: users.find((user) => user.username === assignee?.username)?.name || assignee?.name || '',
					},
				}),
				createDate: new Date(),
			};
			props.addIssueToState?.(createdIssue);
			handleSuccessToastMessage();
			createIssue_BI(createdIssue);
		} catch (e) {
			console.log(e);
		}
		setShowLoader(false);
		isPendingRef.current = false;
		handleClose();
	};

	const editIssue = async () => {
		const issueId = props.initialIssue?._id;
		if (!issueId) return;
		if (isPendingRef.current) return;
		isPendingRef.current = true;
		setShowLoader(true);
		const issueUpdateData: Partial<IUpdateIssue> = {
			description,
			priority,
			...((assignee || props.initialIssue?.assignee) && { assignee }),
			...((profession || props.initialIssue?.profession) && { profession }),
			...((locations || props.initialIssue?.locations) && { locations: locations ?? undefined }),
			...((dueDate || props.initialIssue?.dueDate) && { dueDate: dueDate || undefined }),
		};
		try {
			await props.handleIssueEdit?.(issueId, issueUpdateData);
			editIssue_BI(issueUpdateData);
			handleSuccessToastMessage();
		} catch (e) {
			console.log(e);
		}
		setShowLoader(false);
		isPendingRef.current = false;
		handleClose();
	};

	const saveImages = async (images: FileList) => {
		const sizeLimit: number = 1e7; // 10MB
		if (Array.from(images).some((image) => image.size > sizeLimit)) {
			errorToast(dispatch, translationService.get('imageAboveTheSizeLimitError'));
			return;
		}
		if (Array.from(images).some((image) => !isValidFileUploaded(image))) {
			errorToast(dispatch, translationService.get('imageInvalidFormatError'));
			return;
		}
		setSelectedImages((prev) => [...prev, ...images]);
	};

	const removeImage = (index: number) => {
		setSelectedImages((prev) => prev.filter((_, i) => i !== index));
	};

	return (
		<Drawer anchor={'bottom'} open={props.show} onClose={() => {}}>
			<div style={{ minHeight: '100vh' }}>
				<div className={classes.header}>
					<TrusstorIcon
						className={classnames(classes.backArrow, { [classes.isRtl]: isRTL })}
						color={IconColor.White}
						iconName={isRTL ? IconNames.arrowRight : IconNames.arrowLeft}
						onClick={handleClose}
					/>
					<div className={classes.title}>{dialogTitle}</div>
				</div>
				<div className={classes.content}>
					<CriticalSection
						isLimitedUser={isLimitedUser}
						issuePriority={priority}
						styles={{ fontSize: '16px', fontWeight: 600 }}
						translationService={translationService}
						onClick={handlePriority}
						isMobile
					/>
					<div className={classes.titleSection}>
						<input
							disabled={isLimitedUser}
							autoFocus
							className={classes.titleInput}
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							placeholder={translationService.get('issueNewTitlePlaceholder')}
						/>
					</div>
					<div className={classes.selectWrapper}>
						<SelectSection
							onClick={() => setProfessionDialogOpen(true)}
							iconName={IconNames.helmet}
							text={translationService.get('addProfession')}
							selectedComponent={
								profession && (
									<div>
										<ProfessionDisplayWithTradeIcon profession={profession} projectId={projectId} />
									</div>
								)
							}
						/>
						<SelectSection
							selectedComponent={
								locations?.length ? (
									<div className={classes.locationsSelected} ref={floorTextRef}>
										<div className={classes.floorText}>
											{locations.length} {translationService.get('locations')}
										</div>
									</div>
								) : null
							}
							onClick={() => setLocationDialogOpen(true)}
							iconName={IconNames.pin}
							text={translationService.get('addLocation')}
						/>
						<SelectSection
							disabled={isPrivate}
							iconName={IconNames.user}
							text={translationService.get('addAssignee')}
							selectedComponent={
								assignee && (
									<div>
										<div className={classes.assigneeName}>{assignee.name}</div>
									</div>
								)
							}
							onClick={() => setAssigneeDialogOpen(true)}
						/>
						<DateSelector
							iconName={IconNames.flag}
							onClick={(date: Date) => setDueDate(date)}
							selectedDate={dueDate ? new Date(dueDate) : null}
							minDate={new Date()}
							sectionTitle={translationService.get('issues_mobile_selectDueDate')}
						/>
						{!isEditMode && (
							<ImageUploadInput
								isMobile
								removeImage={removeImage}
								saveImages={saveImages}
								selectedImages={selectedImages}
							/>
						)}
						{!props.initialIssue && (
							<IssuePrivateSection
								textMode={'complete'}
								checkboxController={{ isPrivate, setIsPrivate: handleSetIsPrivate }}
							/>
						)}
					</div>
				</div>
				<ProfessionSelector
					isOpen={professionDialogOpen}
					initialSelectedProfession={profession}
					onSelect={handleProfessionSelection}
					closeDropdown={() => setProfessionDialogOpen(false)}
				/>
				<IssueLocationSelector
					isOpen={locationDialogOpen}
					onSave={selectLocation}
					initialSelectedLocations={locations}
					closeDropdown={() => setLocationDialogOpen(false)}
				/>
				<UserSelector
					isOpen={assigneeDialogOpen}
					initialSelectedUser={users.find((user) => user.username === assignee?.username)}
					onSelect={handleAssigneeSelection}
					closeDropdown={() => setAssigneeDialogOpen(false)}
					permittedProfessionsIds={profession ? [profession._id] : []}
				/>
				<div className={classes.buttonContainer}>
					<TrusstorButton
						className={classnames(classes.createButton, { [classes.isRtl]: isRTL })}
						text={isEditMode ? translationService.get('edit') : translationService.get('create')}
						disabled={!description}
						handleClick={isEditMode ? editIssue : createIssue}
						buttonType={ITrusstorButtonType.PRIMARY_COLORED}
						showLoader={showCreateLoader}
						loaderSize={20}
					/>
				</div>
			</div>
		</Drawer>
	);
};
