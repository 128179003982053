import { SORT_ORDER } from '@shared/constants/constants';
import { IFloorWithWorkersCount } from '@shared/interfaces/IFloorWithWorkersCount';
import { orderBy } from 'lodash';

const isNumeric = (value: string) => !Number.isNaN(Number(value));

export const sortFloorsInsideProfession = (floors: IFloorWithWorkersCount[]): IFloorWithWorkersCount[] => {
	const numericFloorsIds: IFloorWithWorkersCount[] = floors.filter((floor) => isNumeric(floor.floor.floorId));
	const sortedNumericFloorsIds: IFloorWithWorkersCount[] = orderBy(
		numericFloorsIds,
		(floor) => Number(floor.floor.floorId),
		[SORT_ORDER.DESCENDING]
	);
	const nonNumericFloorsIds: IFloorWithWorkersCount[] = floors.filter((floor) => !isNumeric(floor.floor.floorId));
	const sortedNonNumericFloorsIds: IFloorWithWorkersCount[] = orderBy(
		nonNumericFloorsIds,
		(floor) => floor.floor.floorNick,
		[SORT_ORDER.ASCENDING]
	);
	return [...sortedNumericFloorsIds, ...sortedNonNumericFloorsIds];
};
