import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IProfession } from '@shared/interfaces/IProfession';
import { useSelector } from 'react-redux';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { getProfessionBackgroundColor } from '@shared/utils/professions.utils';
import { COLORS } from '@shared/constants/colors.constants';
import classes from './styles.module.scss';
import { requestService, translationService } from '../../index';
import { selectProjectId, selectTimezone } from '../../store/slices/project.slice';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import { ProfessionCardClickable } from '../ProfessionCardClickable/ProfessionCardClickable';
import { getTradeTranslation } from '../../utils/translations.utils';

interface ProfessionsSelectionSectionParams {
	setSelectedProfessions: (value: IProfession[]) => void;
	allProfessions?: IProfession[];
}

export const ProfessionsSelectionSection = (props: ProfessionsSelectionSectionParams) => {
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [selectedProfessions, setSelectedProfessions] = useState<IProfession[]>([]);
	const timezoneDate: string = getTimezoneFormattedDate(tz, new Date(new Date()));
	const history = useHistory();

	const { data: workingProfessions } = useQuery<IProfession[]>(
		['professionsData', projectId],
		() => requestService.get(`/projectConfig/professions/${projectId}/working?startDate${timezoneDate}}`),
		{
			useErrorBoundary: true,
		}
	);

	const professions: IProfession[] = props.allProfessions || (workingProfessions as IProfession[]);

	if (professions && professions.length === 0) {
		history.push('/projectNotReady');
	}

	useEffect(() => {
		props.setSelectedProfessions(selectedProfessions);
	}, [selectedProfessions]);

	useEffect(() => {
		if (userPreferences && professions && professions.length > 0) {
			const userSelectedProfessions: IProfession[] = professions.filter((profession) =>
				userPreferences.mobile?.filters?.professionIds?.includes(profession._id)
			);
			setSelectedProfessions(userSelectedProfessions);
		}
	}, [professions]);

	const addProfession = (profession: IProfession) => {
		setSelectedProfessions((prevState) => [...prevState, profession]);
	};

	const removeProfession = (removedProfession: IProfession) => {
		setSelectedProfessions((prevState) => [
			...prevState.filter((profession) => removedProfession._id !== profession._id),
		]);
	};

	const isAllSelected: boolean = professions?.length === selectedProfessions?.length;

	const handleSelectAll = () => {
		if (isAllSelected) {
			setSelectedProfessions([]);
			return;
		}
		setSelectedProfessions(professions);
	};

	return (
		<div className={classes.section}>
			<div>
				{professions && (
					<div className={classes.cardWrapper}>
						<ProfessionCardClickable
							color={COLORS.primaryColor}
							handleClick={handleSelectAll}
							secondaryText={translationService.get('allProfessions')}
							isSelected={isAllSelected}
							totalProfessionsLength={professions.length}
						/>
					</div>
				)}
				{professions &&
					orderBy(
						professions,
						[(entitiesProperties) => getTradeTranslation(entitiesProperties).toLowerCase()],
						['asc']
					).map((profession) => {
						const isSelected: boolean = !!selectedProfessions.find(
							(selectedEntity) => profession._id === selectedEntity._id
						);
						return (
							<div className={classes.cardWrapper} key={profession._id}>
								<ProfessionCardClickable
									color={getProfessionBackgroundColor(profession, projectId)}
									handleClick={
										isSelected
											? () => removeProfession(profession)
											: () => addProfession(profession)
									}
									mainText={getTradeTranslation(profession)}
									secondaryText={profession.contractor || ''}
									thirdText={profession.specialty || ''}
									isSelected={isSelected}
								/>
							</div>
						);
					})}
				<div style={{ height: '75px' }} />
			</div>
		</div>
	);
};
