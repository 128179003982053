import React, { useEffect, useState } from 'react';
import { IConfigFloor } from '@shared/interfaces/IConfigFloor';
import { IProfession } from '@shared/interfaces/IProfession';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { Redirect } from 'react-router-dom';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import { selectUserPreference, selectUserPreferenceRequestStatus } from '../../store/slices/userPreferences.slice';
import { projectAccessLevelService, requestService, userAccessLevelService } from '../../index';
import { IRootState } from '../../store/slices';
import { AppRoutes } from '../../constants/appRoutes.enum';

export const InitialRedirect = () => {
	const [floors, setFloors] = useState<IConfigFloor[] | null>(null);
	const [professions, setProfessions] = useState<IProfession[] | null>(null);
	const [didFetchRequests, setDidFetchRequests] = useState<boolean>(false);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const isUserPreferenceLoading: boolean =
		useSelector(selectUserPreferenceRequestStatus) === HTTPRequestStatuses.pending;
	const loggedUser: ILoggedUser | null = useSelector((state: IRootState) => state.loginReducer.loggedUser);
	const isMonitorPageEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useMobileMonitor);

	const fetchProjectFloors = async (projectId: string) => {
		const projectFloors: IConfigFloor[] | null = await requestService.get(
			`/projectConfig/floor/?projectId=${projectId}`
		);

		setFloors(projectFloors || []);
	};

	const fetchProjectProfessions = async (projectId: string) => {
		const projectProfessions: IProfession[] | null = await requestService.get(
			`/projectConfig/professions/${projectId}/working`
		);

		setProfessions(projectProfessions || []);
	};

	useEffect(() => {
		if (loggedUser && !didFetchRequests) {
			const projectId: string = loggedUser.userProjects[0].projectId;
			fetchProjectFloors(projectId);
			fetchProjectProfessions(projectId);
			setDidFetchRequests(true);
		}
	}, [loggedUser?.userDetails.username, didFetchRequests]);

	if (!professions || !floors || isUserPreferenceLoading) {
		return <div />;
	}

	if (!professions.length || !floors.length) {
		return <Redirect to={AppRoutes.projectNotReady} />;
	}

	if (!userPreferences?.mobile && userAccessLevelService.hasAccess('initialWelcomePageAccess')) {
		return <Redirect to={AppRoutes.setup} />;
	}

	if (isMonitorPageEnabled) {
		return <Redirect to={AppRoutes.monitor} />;
	}

	if (projectAccessLevelService.hasAccess('personnelPage')) {
		return <Redirect to={AppRoutes.personnel} />;
	}

	if (projectAccessLevelService.hasAccess('activitiesPage')) {
		return <Redirect to={AppRoutes.activities} />;
	}

	if (projectAccessLevelService.hasAccess('issuesPage')) {
		return <Redirect to={AppRoutes.issues} />;
	}

	if (projectAccessLevelService.hasAccess('equipmentPage')) {
		return <Redirect to={AppRoutes.equipment} />;
	}

	return <Redirect to={AppRoutes.notifications} />;
};
