import { Loader } from '@shared/components/Loader/Loader';
import { SORT_ORDER } from '@shared/constants/constants';
import { IFloor } from '@shared/interfaces/IFloor';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { sortFloors } from '@shared/utils/sort.utils';
import { useFloorDataQuery } from '../../../hooks/queries/floors.queries.hooks';
import { FloorPlanPointSelector } from './FloorPlanPointsSelector/FloorPlanPointsSelector';
import classes from './styles.module.scss';
import { translationService } from '../../../servicesInitializer';

interface ISelectedPointsPerFloor {
	[floorId: string]: IIssueLocation[];
}

interface ILocationFloorPlanSelectorProps {
	onSelect: (locations: IIssueLocation[]) => void;
	initialSelectedLocations: IIssueLocation[] | null;
}

export const LocationFloorPlanSelector = (props: ILocationFloorPlanSelectorProps) => {
	const floors: IFloor[] = useFloorDataQuery();
	const getInitialSelectedPointsPerFloor = () => {
		if (!props.initialSelectedLocations || !Object.keys(props.initialSelectedLocations).length) {
			return undefined;
		}
		return groupBy(props.initialSelectedLocations, (location: IIssueLocation) => location.floor.floorId);
	};
	const [floorsSelectedPoints, setFloorsSelectedPoints] = useState<ISelectedPointsPerFloor | undefined>(
		getInitialSelectedPointsPerFloor()
	);
	const [selectedFloor, setSelectedFloor] = useState<IFloor | undefined>(undefined);

	const onFloorClick = (floor: IFloor) => {
		setSelectedFloor(floor);
	};

	const onFloorPointSelect = (selectedPoints: IIssueLocation[]) => {
		setFloorsSelectedPoints((prevFloorsSelectedPoints) => ({
			...prevFloorsSelectedPoints,
			[selectedFloor!.floorId]: selectedPoints,
		}));
	};

	useEffect(() => {
		if (!floorsSelectedPoints) return;
		const selectedLocations: IIssueLocation[] = Object.values(floorsSelectedPoints).reduce(
			(acc: IIssueLocation[], curr: IIssueLocation[]) => [...acc, ...curr]
		);
		props.onSelect(selectedLocations);
	}, [floorsSelectedPoints]);

	if (!floors) return <Loader />;
	const sortedFloors: IFloor[] = sortFloors(floors, SORT_ORDER.ASCENDING);

	if (selectedFloor) {
		return (
			<FloorPlanPointSelector
				floor={selectedFloor}
				onClose={() => setSelectedFloor(undefined)}
				onSelect={onFloorPointSelect}
				selectedLocations={floorsSelectedPoints && floorsSelectedPoints[selectedFloor.floorId]}
			/>
		);
	}

	return (
		<div className={classes.floorPlanContainer}>
			{sortedFloors.map((floor: IFloor) => (
				<div className={classes.floorItem} onClick={() => onFloorClick(floor)}>
					<p className={classes.floorNickText}>{floor.floorNick}</p>
					{floorsSelectedPoints &&
						floorsSelectedPoints[floor.floorId] &&
						floorsSelectedPoints[floor.floorId].length > 0 && (
							<div className={classes.selectedFloorMark}>
								{floorsSelectedPoints[floor.floorId].length}
								{floorsSelectedPoints[floor.floorId].length > 1
									? ` ${translationService.get('areas')}`
									: ` ${translationService.get('area')}`}
							</div>
						)}
				</div>
			))}
		</div>
	);
};
