import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IssueItem } from '@shared/components/Issue/IssueItem';
import {
	filterIssues,
	filterIssuesByProfessionAndFloors,
	getIssueStatusMainText,
	getSortedKeysOfIssuesColumns,
	sortIssuesByDueDateAndPriority,
} from '@shared/utils/issues.utils';
import { groupBy } from 'lodash';
import classnames from 'classnames';
import { IToastMessage, IToastMessageTypes } from '@shared/interfaces/IToastMessages';
import { IUser } from '@shared/interfaces/IUser';
import { Switch, useHistory, useParams } from 'react-router-dom';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonDeprecated,
} from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { getOptimisticUpdatedIssue } from '@utils/issuesUpdate.utils';
import { IssueStatus } from '@shared/interfaces/IIssueShared';
import { isIOSDevice } from '@utils/device.utils';
import { isPushNotificationsEnabledOnDevice } from '@utils/notifications.utils';
import { IssuesPageViewModes } from '@shared/constants/issues.contants';
import { selectProjectId, selectTimezone } from '../../../store/slices/project.slice';
import { IIssue } from '../../../interfaces/IIssue';
import { useIssuesSortingSettingsQuery, useProjectIssuesQuery } from '../../../hooks/queries/issues.queries.hooks';
import classes from './styles.module.scss';
import { requestService, storageService, translationService } from '../../../servicesInitializer';
import { IssuesCollapse } from './IssuesCollapse/IssuesCollapse';
import { issueComment_BI, issuesTabAccess_BI, issueStatusChange_BI } from '../../../utils/bi.utils';
import { setMessage } from '../../../store/slices/toastMessage.slice';
import { selectIsLimitedUser, selectLoggedUserDetails } from '../../../store/slices/login.slice';
import { IssueUpsertPage } from './IssueUpsertPage/IssueUpsertPage';
import { errorToast, successToast } from '../../../utils/toast.utils';
import { useProfessionsQuery } from '../../../hooks/queries/professions.queries.hooks';
import { clearOfflineText, setOfflineText } from '../../../store/slices/offlineBanner.slice';
import { useAreasDataQuery } from '../../../hooks/queries/areas.queries.hooks';
import { useManagersQuery } from '../../../hooks/queries/managers.queries.hooks';
import { TrusstorIcon } from '../../TrusstorIcon/TrusstorIcon';
import { IconNames } from '../../../../../shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '../../../../../shared/components/TrusstorIconShared/TrusstorIconShared';
import { ProtectedRoute } from '../../ProtectedRoute/ProtectedRoute';
import {
	selectSelectedAssignees,
	selectSelectedFloors,
	selectSelectedPriorities,
	selectSelectedProfessions,
	selectSelectedStatuses,
} from '../../../store/slices/filters.slice';
import { IssuesFilterPage } from './IssuesFilterPage/IssuesFilterPage';

const IssuesPage = () => {
	useRenderMonitoring('IssuesPage');
	const history = useHistory();
	const { issueId: issueIdFromParams }: { issueId: string } = useParams();
	const dispatch = useDispatch();
	const projectId = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const issues: IIssue[] | undefined = useProjectIssuesQuery(projectId, user.username);
	const { sortingSettings } = useIssuesSortingSettingsQuery(projectId);
	const [filteredIssues, setFilteredIssues] = useState<IIssue[]>([]);
	const [totalIssues, setTotalIssues] = React.useState<IIssue[]>(issues || []);
	const [groupedIssuesByProfession, setGroupedIssuesByProfession] = React.useState<Record<string, IIssue[]>>({});
	const [issueToEdit, setIssueToEdit] = useState<IIssue | undefined>();
	const [showCreateIssueModal, setShowCreateIssueModal] = useState<boolean>(false);
	const [showEditIssueModal, setShowEditIssueModal] = useState<boolean>(false);
	const [sortedIssuesProfessions, setSortedIssuesProfessions] = useState<string[]>([]);
	const selectedFloors = useSelector(selectSelectedFloors);
	const selectedProfessions = useSelector(selectSelectedProfessions);
	const selectedAssignees = useSelector(selectSelectedAssignees);
	const selectedStatuses = useSelector(selectSelectedStatuses);
	const selectedPriorities = useSelector(selectSelectedPriorities);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);

	useProfessionsQuery();
	useAreasDataQuery();
	useManagersQuery();

	useEffect(() => {
		const filteredIssuesForLimitedUser: IIssue[] = userPreferences
			? filterIssuesByProfessionAndFloors(
					totalIssues,
					userPreferences!.mobile?.filters.professionIds || [],
					userPreferences.mobile?.filters.floorIds || []
				)
			: totalIssues;
		const issuesFiltered: IIssue[] = filterIssues({
			issues: filteredIssuesForLimitedUser,
			selectedProfessions,
			selectedUsers: selectedAssignees,
			selectedFloors,
			selectedPriorities,
			selectedStatuses,
		});
		setFilteredIssues(issuesFiltered);
	}, [selectedProfessions, selectedFloors, selectedAssignees, selectedStatuses, selectedPriorities, totalIssues]);

	useEffect(() => {
		dispatch(
			setOfflineText({
				text: translationService.get('issuesPageOfflineBanner'),
				subText: translationService.get('issuesPageOfflineBannerSubText'),
			})
		);

		return () => {
			dispatch(clearOfflineText());
		};
	}, [projectId]);

	useEffect(() => {
		issuesTabAccess_BI();
	}, []);

	useEffect(() => {
		if (!issues) {
			return;
		}

		setTotalIssues(issues);
	}, [issues]);

	useEffect(() => {
		const totalIssuesWithoutInitialIssue: IIssue[] = totalIssues.filter((issue) => !issue.isInitial);
		const issuesToDisplay: IIssue[] = !totalIssuesWithoutInitialIssue.length
			? filteredIssues
			: filteredIssues.filter((issue) => !issue.isInitial);
		const issuesGroupedByProfession: Record<string, IIssue[]> = groupBy(
			issuesToDisplay,
			(issue) => issue.profession?._id
		);

		setGroupedIssuesByProfession(issuesGroupedByProfession);
	}, [totalIssues, filteredIssues]);

	useEffect(() => {
		if (issueToEdit) {
			setShowEditIssueModal(true);
		}
	}, [issueToEdit]);

	useEffect(() => {
		const issuesProfessionsSorted: string[] = getSortedKeysOfIssuesColumns(
			totalIssues,
			IssuesPageViewModes.PROFESSIONS,
			sortingSettings
		);
		setSortedIssuesProfessions(issuesProfessionsSorted);
	}, [totalIssues, sortingSettings]);

	const onCreateIssueClick = () => {
		setShowCreateIssueModal(true);
	};

	const onFilterClick = () => {
		history.push('/issues/filters');
	};

	const updateIssue = async (
		issueId: string,
		issueToUpdate: Partial<IUpdateIssue>,
		updateBackend: boolean = true
	): Promise<IIssue> => {
		const issueBeforeUpdate: IIssue = totalIssues.find((issue) => issue._id === issueId)!;
		const optimisticUpdatedIssue: IIssue = getOptimisticUpdatedIssue(issueBeforeUpdate, issueToUpdate, user);

		setTotalIssues((prevIssues) =>
			prevIssues.map((issue) => {
				if (issue._id === issueId) {
					return {
						...issue,
						...optimisticUpdatedIssue,
					};
				}
				return issue;
			})
		);

		if (!updateBackend) {
			return optimisticUpdatedIssue;
		}

		try {
			const updatedIssue: IIssue = await requestService.put(`/issues/${issueId}`, {
				body: issueToUpdate,
			});
			if (issueToUpdate.status && issueBeforeUpdate?.status !== issueToUpdate.status) {
				successToast(
					dispatch,
					translationService.get('issueStatusUpdatedSnackbarMessage', {
						new_status: getIssueStatusMainText(issueToUpdate.status!, translationService),
					})
				);
			}
			return updatedIssue;
		} catch (e) {
			console.error(e);
			errorToast(dispatch, translationService.get('failedToUpdateGeneric'));
			setTotalIssues((prevIssues) =>
				prevIssues.map((issue) => {
					if (issue._id === issueId) {
						return {
							...issue,
							...issueBeforeUpdate,
						};
					}
					return issue;
				})
			);
			return issueBeforeUpdate;
		}
	};

	const deleteIssue = async (issueId: string) => {
		const issuesToDisplayBeforeDelete: IIssue[] = [...totalIssues];
		try {
			setTotalIssues((prevIssues) => prevIssues.filter((issue) => issue._id !== issueId));
			await requestService.delete(`/issues/${issueId}`);
			successToast(dispatch, translationService.get('issueDeletedSuccessfully'));
		} catch (e) {
			setTotalIssues(issuesToDisplayBeforeDelete);
			console.error(e);
			errorToast(dispatch, translationService.get('failedToUpdateGeneric'));
		}
	};

	const handleSizeLimitImageError = () => {
		const message: IToastMessage = {
			text: translationService.get('imageAboveTheSizeLimitError'),
			type: IToastMessageTypes.ERROR,
		};
		dispatch(setMessage({ message }));
	};

	const closeCreateModal = () => {
		setIssueToEdit(undefined);
		setShowCreateIssueModal(false);
	};

	const closeEditIssueModal = () => {
		setIssueToEdit(undefined);
		setShowEditIssueModal(false);
	};

	const addIssueToState = (issue: IIssue) => {
		setTotalIssues((prevIssues) => [...prevIssues, issue]);
	};

	const shouldAddMarginBottomInset: boolean = isIOSDevice() && isPushNotificationsEnabledOnDevice();
	const isRtl: boolean = translationService.getIsRtl();
	const isOnlyInitialIssue: boolean = !!totalIssues[0]?.isInitial && totalIssues.length === 1;
	const isLimitedUser: boolean = useSelector(selectIsLimitedUser);
	return (
		<section className={classes.issuesPageContainer}>
			<section className={classes.header}>
				<div>{translationService.get('issues')}</div>
				<TrusstorIconButtonDeprecated
					onClick={onFilterClick}
					buttonSize={ITrusstorIconButtonSize.LARGE}
					iconElement={<TrusstorIcon iconName={IconNames.filter} />}
				/>
			</section>
			{sortedIssuesProfessions.length > 0 &&
				sortedIssuesProfessions.map((profession, index) => {
					const columnIssues: IIssue[] | undefined = groupedIssuesByProfession[profession];
					if (!columnIssues) {
						return null;
					}
					const sortedIssues: IIssue[] = sortIssuesByDueDateAndPriority(columnIssues);
					return (
						<IssuesCollapse
							initialOpenState={
								isOnlyInitialIssue || columnIssues.some((issue) => issue._id === issueIdFromParams)
							}
							key={index}
							profession={sortedIssues[0]?.profession}
							translationService={translationService}
							count={sortedIssues?.length}
						>
							{sortedIssues?.map((issue) => (
								<IssueItem
									issueComment_BI={issueComment_BI}
									deleteIssue={(issueId) => deleteIssue(issueId)}
									key={issue._id}
									issue={issue}
									tz={tz}
									translationService={translationService}
									updateIssue={updateIssue}
									issueStatusChangeBiEvent={(status: IssueStatus, issueUpdated: IIssue) =>
										issueStatusChange_BI(issueUpdated, status)
									}
									isMobile={true}
									storageService={storageService}
									requestService={requestService}
									user={user}
									imageSizeLimitCallback={handleSizeLimitImageError}
									setIssueToEdit={setIssueToEdit}
									hideProfession={true}
									projectId={projectId}
									usePathForDialog
								/>
							))}
						</IssuesCollapse>
					);
				})}
			{!isLimitedUser && (
				<TrusstorIcon
					onClick={() => onCreateIssueClick()}
					iconName={IconNames.plus}
					size={IconSize.EXTRA_LARGE}
					color={IconColor.Black}
					className={classnames({
						[classes.addIssueButton]: true,
						[classes.rtl]: isRtl,
						[classes.marginBottom]: shouldAddMarginBottomInset,
					})}
				/>
			)}
			<IssueUpsertPage show={showCreateIssueModal} addIssueToState={addIssueToState} onClose={closeCreateModal} />
			<IssueUpsertPage
				show={showEditIssueModal}
				addIssueToState={addIssueToState}
				onClose={closeEditIssueModal}
				initialIssue={issueToEdit}
				handleIssueEdit={updateIssue}
			/>
			<Switch>
				<ProtectedRoute exact hideNavbar hideHeader path="/issues/filters" component={IssuesFilterPage} />
			</Switch>
		</section>
	);
};

export { IssuesPage };
