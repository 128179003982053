import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProjectId } from '../../store/slices/project.slice';
import { storageService } from '../../index';
import { ProjectSelector } from '../Dropdowns/ProjectSelector/ProjectSelector';
import classes from './styles.module.scss';
import TrusstorLogo from '../../assets/images/trusstorLogo.svg';
import { OfflineBanner } from '../OfflineBanner/OfflineBanner';

export const Header = () => {
	const [projectLogo, setProjectLogo] = useState<string>('');
	const projectId: string | undefined = useSelector(selectProjectId);

	const fetchProjectLogo = async () => {
		const logoData: string = await storageService.getProjectLogo(projectId || '', true);
		if (logoData) {
			setProjectLogo(logoData);
		}
	};

	useEffect(() => {
		fetchProjectLogo();
	}, [projectId]);

	return (
		<>
			<div className={classes.header}>
				<div
					className={classes.logosContainer}
					style={{ justifyContent: projectLogo ? 'space-between' : 'flex-end' }}
				>
					{projectLogo && <img src={projectLogo} alt="" />}
					<img className={classes.trusstorLogo} src={TrusstorLogo} alt="Trusstor Logo" />
				</div>
				<div className={classes.projectSelectorContainer}>
					<ProjectSelector />
				</div>
			</div>
			<OfflineBanner />
		</>
	);
};
