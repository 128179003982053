import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { IProject } from '@shared/interfaces/IProject';
import { useResizeObserver } from '@shared/hooks/useResizeObserver';
import { MonitoringRoute } from '@shared/utils/monitoring.util';
import classnames from 'classnames';
import { translationService, userService } from '../../index';
import classes from './styles.module.scss';
import { selectLoggedUser, selectLoggedUserProjects } from '../../store/slices/login.slice';
import Navbar from '../Navbar/Navbar';
import { Header } from '../Header/Header';
import { handleLogoutAndRedirect } from '../../utils/bootstrap.utils';
import { selectProjectId } from '../../store/slices/project.slice';
import { navBarHeight } from '../../constants/styles.constants';
import { isPushNotificationsEnabledOnDevice } from '../../utils/notifications.utils';
import { isIOSDevice } from '../../utils/device.utils';

interface IProtectedRouteProps {
	exact?: boolean;
	path: string;
	component: any;
	hideNavbar?: boolean;
	hideHeader?: boolean;
	disablePageScroll?: boolean;
}

export const ProtectedRoute = (props: IProtectedRouteProps) => {
	const { component: Component } = props;
	const loggedUser: ILoggedUser | null = useSelector(selectLoggedUser);
	const workingProjectId: string | undefined = useSelector(selectProjectId);
	const userProjects: IProject[] | undefined = useSelector(selectLoggedUserProjects);
	const accessToken: string | null = userService.getLoggedUserAccessToken();
	const isRtl: boolean = translationService.getIsRtl();
	const { observeElement: observeHeader, contentBoxSize: headerBoxSize } = useResizeObserver();

	const headerRef = useCallback(
		(node) => {
			if (node !== null) {
				observeHeader(node);
			}
		},
		[observeHeader]
	);

	const redirectToLogin = () => {
		handleLogoutAndRedirect();
	};

	const isAppRequiredDataLoaded: boolean = !!(loggedUser && userProjects?.length && workingProjectId);

	const shouldAddSafeAreaInset: boolean = isIOSDevice() && isPushNotificationsEnabledOnDevice();

	const containerStyle = {
		height: `calc(100dvh - ${navBarHeight} - ${
			headerBoxSize?.blockSize || 0
		}px - env(safe-area-inset-bottom) - env(safe-area-inset-top) - ${shouldAddSafeAreaInset ? '15px' : '0px'})`,
	};

	return (
		<MonitoringRoute
			exact={props.exact}
			path={props.path}
			render={(renderProps) => {
				if (isAppRequiredDataLoaded) {
					return (
						<>
							<div ref={headerRef}>{!props.hideHeader && <Header />}</div>
							<div
								className={classnames({
									[classes.pageContainer]: true,
									[classes.rtl]: isRtl,
									[classes.disableScroll]: props.disablePageScroll,
								})}
								style={containerStyle}
								id="appContainer"
							>
								<Component {...renderProps} />
							</div>
							{!props.hideNavbar && <Navbar />}
						</>
					);
				}

				if (accessToken) {
					return <div />;
				}

				redirectToLogin();
				return undefined;
			}}
		/>
	);
};
