import { IMergedAreaSequenceItem } from '../interfaces/IMergedAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { requestService } from '../servicesInitializer';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';

export const updateAreaSequenceItemReadyForReviewStatus = async (
	areaSequenceItemId: string,
	updateToReadyForReview: boolean
): Promise<IMergedAreaSequenceItem> =>
	requestService.put(`/activities/sequenceItems/areaSequenceItem/${areaSequenceItemId}/readyForReview`, {
		body: { isReadyForReview: updateToReadyForReview },
	});

export const updateAreaSequenceItemStatus = async (
	areaSequenceItemId: string,
	updatedStatus: AreaSequenceItemStatus
): Promise<void> => {
	return await requestService.put(`/activities/sequenceItems/areaSequenceItem/status/bulk`, {
		body: { ids: [areaSequenceItemId], status: updatedStatus },
	});
};

export const updateAreaSequenceItemDoneStatus = async (
	areaSequenceItem: IAreaSequenceItem,
	isDone: boolean
): Promise<void> => {
	await requestService.put(`/activities/sequenceItems/areaSequenceItem/done`, {
		body: { mergedAreaSequenceItem: areaSequenceItem, isDone },
	});
};
