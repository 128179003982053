import React, { useState } from 'react';
import { TrusstorSwitcher } from '@shared/components/TrusstorSwitcher/TrusstorSwitcher';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { areIssuesLocationsEqual } from '@shared/utils/issues.utils';
import { translationService } from '../../servicesInitializer';
import { DrawerDialog } from '../Dialogs/DrawerDialog/DrawerDialog';
import { LocationFloorPlanSelector } from './LocationFloorPlanSelector.tsx/LocationFloorPlanSelector';
import { LocationListSelector } from './LocationListSelector/LocationListSelector';
import classes from './styles.module.scss';

interface IIssueLocationSelectorProps {
	isOpen: boolean;
	onSave: (locations: IIssueLocation[]) => void;
	initialSelectedLocations: IIssueLocation[] | null;
	closeDropdown: () => void;
}

enum LocationView {
	LIST = 'list',
	FLOORPLAN = 'floorPlan',
}

export const IssueLocationSelector = (props: IIssueLocationSelectorProps) => {
	const [locationView, setLocationView] = useState<LocationView>(LocationView.FLOORPLAN);
	const [selectedLocations, setSelectedLocations] = useState<IIssueLocation[] | null>(props.initialSelectedLocations);

	const toggleLocationView = () => {
		setLocationView(locationView === LocationView.FLOORPLAN ? LocationView.LIST : LocationView.FLOORPLAN);
	};

	const handleCloseDropdown = () => {
		setSelectedLocations(props.initialSelectedLocations);
		props.closeDropdown();
	};

	const onLocationUpdate = (locations: IIssueLocation[]) => {
		setSelectedLocations(locations);
	};

	const onSave = () => {
		props.onSave(selectedLocations!);
		props.closeDropdown();
	};

	const isSaveDisabled = () => {
		if (!selectedLocations) {
			return true;
		}
		if (!props.initialSelectedLocations) {
			return selectedLocations.length === 0;
		}
		return areIssuesLocationsEqual(props.initialSelectedLocations, selectedLocations);
	};

	return (
		<DrawerDialog
			isOpen={props.isOpen}
			onClose={handleCloseDropdown}
			title={translationService.get('addLocation')}
			primaryButton={{
				text: translationService.get('save'),
				disabled: isSaveDisabled(),
				onClick: () => {
					onSave();
				},
			}}
			bodyNotScrollable
		>
			<div className={classes.switcherContainer}>
				<TrusstorSwitcher
					options={[LocationView.FLOORPLAN, LocationView.LIST]}
					handleChange={toggleLocationView}
					initialState={locationView}
				/>
			</div>
			{locationView === LocationView.FLOORPLAN ? (
				<LocationFloorPlanSelector onSelect={onLocationUpdate} initialSelectedLocations={selectedLocations} />
			) : (
				<LocationListSelector onSelect={onLocationUpdate} initialSelectedLocations={selectedLocations} />
			)}
		</DrawerDialog>
	);
};
