import * as React from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../servicesInitializer';

export interface IWorkersIconProps {
	workersAmount: number;
}

export const WorkersIcon = (props: IWorkersIconProps) => (
	<div className={classes.content}>
		<p className={props.workersAmount > 0 ? classes.amountText : classes.empty}>
			{props.workersAmount > 0 ? props.workersAmount : translationService.get('empty')}
		</p>
	</div>
);
