import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IFloor } from '@shared/interfaces/IFloor';
import { useFloorDataQuery } from '@src/hooks/queries/floors.queries.hooks';
import { translationService } from '@src/index';
import { useAreasByFloorIdDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { baseLocationToIssueLocation } from '@src/components/IssueLocationSelector/LocationListSelector/locationListSelector.utils';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import { IMonitorResourcesData, useMonitorResources } from '@src/hooks/monitor.hooks';
import { getAreaCountByResourceFromResourcesCount } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { LocationCountSummaryCard } from '@src/components/pages/MonitorPage/LocationsCountSummaryCardsSection/LocationCountSummaryCard/LocationCountSummaryCard';
import { sortByAreas } from '@shared/utils/sort.utils';
import { ResourceCountCardsSection } from '../ResourceCountCardsSection/ResourceCountCardsSection';
import { LocationMonitorHeader } from '../LocationMonitorHeader/LocationMonitorHeader';
import classes from './styles.module.scss';

export const MonitorFloorPage = () => {
	const floors: IFloor[] = useFloorDataQuery();
	const { floorId }: { floorId: string } = useParams();
	const floor: IFloor | undefined = floors.find((f: IFloor) => f.floorId === floorId);
	const [addedIssuesCount, setAddIssuesCount] = useState<number>(0);
	const floorAreas: IConfigArea[] | undefined = useAreasByFloorIdDataQuery(floorId);
	const sortedFloorAreas: IConfigArea[] | undefined = floorAreas ? sortByAreas(floorAreas) : undefined;
	const history = useHistory();

	const issueLocations: IIssueLocation[] | undefined = floorAreas?.map(baseLocationToIssueLocation);
	const monitorResourcesData: IMonitorResourcesData = useMonitorResources([floorId]);

	const onIssueCreated = () => {
		setAddIssuesCount((prevCount) => prevCount + 1);
	};

	if (floors.length && !floor) {
		history.push(AppRoutes.monitor);
	}

	if (!floor || !sortedFloorAreas) {
		return <div></div>;
	}

	return (
		<div className={classes.monitorFloorPageContainer}>
			<LocationMonitorHeader
				text={floor.floorNick}
				issueLocations={issueLocations}
				onIssueCreated={onIssueCreated}
				breadCrumbsItems={[
					{ title: translationService.get('mobileMonitor_monitor'), link: AppRoutes.monitor },
					{ title: floor.floorNick, link: AppRoutes.monitorFloor, isSelected: true },
				]}
			/>
			<div className={classes.contentContainer}>
				<div className={classes.resourcesCountCards}>
					<ResourceCountCardsSection floorId={floorId} addedIssuesCount={addedIssuesCount} />
				</div>
				<div className={classes.locationsTitle}>{translationService.get('mobileMonitor_floorLocations')} </div>
				{sortedFloorAreas.map((area: IConfigArea) => {
					const areaCountByResources: Record<string, number> = getAreaCountByResourceFromResourcesCount(
						monitorResourcesData.resourcesCount,
						floorId,
						area.areaId
					);
					return (
						<LocationCountSummaryCard
							floor={floor}
							area={area}
							countData={areaCountByResources}
							onClick={() => {
								history.push(`${AppRoutes.monitorArea}/${area.areaId}`);
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};
