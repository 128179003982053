import React from 'react';
import { useEquipmentInLocationQuery } from '@src/hooks/queries/equipment.queries.hooks';
import { Loader } from '@shared/components/Loader/Loader';
import { MergedEquipmentsCards } from '@src/components/MergedEquipmentsCards/MergedEquipmentsCards';
import { getFloorResourcePageTitle } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import classes from './style.module.scss';

interface IFloorEquipmentsProps {
	floorId: string;
}

export const FloorEquipments = (props: IFloorEquipmentsProps) => {
	const { mergedEquipments, mergedEquipmentsLoading } = useEquipmentInLocationQuery(props.floorId);
	return (
		<div className={classes.container}>
			<div className={classes.title}>
				{getFloorResourcePageTitle(
					MonitorResourcesEnum.EQUIPMENT,
					mergedEquipments?.length,
					mergedEquipmentsLoading
				)}
			</div>
			{mergedEquipmentsLoading ? <Loader /> : <MergedEquipmentsCards mergedEquipments={mergedEquipments} />}
		</div>
	);
};
