import React from 'react';
import { Drawer } from '@material-ui/core';
import { MultiIssuesViewPage } from '@src/components/MultiIssuesView/MultiIssuesView';
import classes from './styles.module.scss';

interface IIssueViewDrawerProps {
	show: boolean;
	groupId: string;
	onClose: () => void;
}

export const IssueViewDrawer = (props: IIssueViewDrawerProps) => (
	<Drawer anchor={'bottom'} open={props.show} onClose={() => {}}>
		<div className={classes.container}>
			<MultiIssuesViewPage groupId={props.groupId} close={props.onClose} />
		</div>
	</Drawer>
);
