import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import settingsIcon from '@shared/assets/icons/settings_icon.svg';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { Loader } from '@shared/components/Loader/Loader';
import { Switch } from 'react-router-dom';
import { IHeaderTabItem } from '@shared/interfaces/IHeaderTabItem';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { ActivitiesPages } from '../../../constants/activitiesPages.enum';
import { MyIssues } from './MyIssues/MyIssues';
import { MyWalkAround } from './MyWalkAround/MyWalkAround';
import { CompletedActivities } from './CompletedActivities/CompletedActivities';
import { translationService } from '../../../index';
import { selectProjectId, selectTimezone } from '../../../store/slices/project.slice';
import { IActivityDataFromBackend, IAllActivitiesRelevantData } from './ActivityPage.models';
import { EmptyStateComponent } from '../../EmptyStateComponent/EmptyStateComponent';
import { fetchAllActivitiesData, filterActivitiesAndGroupByTabs } from '../../../utils/activities.utils';
import { ActivityPageSortOptions } from '../../../models/ActivityPageSortOptions';
import { ActivityPageViewTypes } from '../../../models/ActivityPageViewTypes';
import { selectUserPreference } from '../../../store/slices/userPreferences.slice';
import { IActivityRealtimeInfo } from '../../../interfaces/IActivityRealtimeInfo';
import { getDefaultValueForPersonalViewState } from './utils';
import classes from './styles.module.scss';
import { ProtectedRoute } from '../../ProtectedRoute/ProtectedRoute';
import { isUserPreferencesDefined } from '../../../utils/filter.utils';
import {
	activitiesPageAccess_BI,
	activitiesViewSettingsOpen_BI,
	activitiesViewSettingsUpdate_BI,
} from '../../../utils/bi.utils';
import { PageHeader } from '../../PageHeader';
import customizedFilterOn from '../../../assets/customizedFilterOn.svg';
import customizedFilterOff from '../../../assets/customizedFilterOff.svg';
import { SubTabHeader } from './SubTabWrapper/SubTabHeader';
import { HeaderTabsSection } from '../../HeaderTabsSection/HeaderTabsSection';
import { MultiIssuesViewPage } from '../../MultiIssuesView/MultiIssuesView';

const ActivitiesPage = () => {
	useRenderMonitoring('ActivitiesPage');
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);

	const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState<ActivitiesPages>(ActivitiesPages.issues);
	const [allActivitiesRelevantData, setAllActivitiesRelevantData] = useState<IAllActivitiesRelevantData | null>(null);
	const [activitiesNotFiltered, setActivitiesNotFiltered] = useState<IActivityRealtimeInfo[] | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [progressFloorsViewState, setProgressFloorsViewState] = useState<ActivityPageViewTypes>(
		ActivityPageViewTypes.BY_PROGRESS
	);
	const [shouldNotShowProgressFloorsViewOption, setShouldNotShowProgressFloorsViewOption] = useState<boolean>(true);
	const [personalViewSortOption, setPersonalViewSortOrderState] = useState<ActivityPageSortOptions | null>(null);

	useEffect(() => {
		const defaultValueForPersonalViewState: ActivityPageSortOptions =
			getDefaultValueForPersonalViewState(userPreferences);
		setPersonalViewSortOrderState(defaultValueForPersonalViewState);
	}, [userPreferences?.mobile?.filters?.floorIds, userPreferences?.mobile?.filters?.professionIds]);

	useEffect(() => {
		if (!allActivitiesRelevantData || !activitiesNotFiltered || !personalViewSortOption) {
			return;
		}

		const allActivitiesDataFiltered: IAllActivitiesRelevantData = filterActivitiesAndGroupByTabs(
			{
				activitiesData: activitiesNotFiltered,
				activitiesReportData: allActivitiesRelevantData.activitiesReportData,
				activitiesLocationData: allActivitiesRelevantData.activitiesLocationData,
			},
			personalViewSortOption,
			tz,
			userPreferences
		);
		setAllActivitiesRelevantData(allActivitiesDataFiltered);
		setIsLoading(false);
	}, [personalViewSortOption]);

	const fetchAllData = useCallback(async () => {
		const allActivitiesData: IActivityDataFromBackend = await fetchAllActivitiesData(projectId, tz);

		setActivitiesNotFiltered(allActivitiesData.activitiesData);

		if (!personalViewSortOption) {
			setIsLoading(false);
			return;
		}

		const allActivitiesDataFiltered: IAllActivitiesRelevantData = filterActivitiesAndGroupByTabs(
			allActivitiesData,
			personalViewSortOption,
			tz,
			userPreferences
		);
		setAllActivitiesRelevantData(allActivitiesDataFiltered);
		setIsLoading(false);
	}, [
		projectId,
		tz,
		personalViewSortOption,
		userPreferences?.mobile?.filters?.floorIds,
		userPreferences?.mobile?.filters?.professionIds,
	]);

	useEffect(() => {
		fetchAllData();
		activitiesPageAccess_BI();
	}, [projectId, tz]);

	const changeProgressViewState = (viewType: string) => {
		activitiesViewSettingsUpdate_BI('view', progressFloorsViewState, viewType as string);
		setProgressFloorsViewState(viewType as ActivityPageViewTypes);
	};

	const changePersonalViewState = (viewType: string) => {
		activitiesViewSettingsUpdate_BI('preferences', personalViewSortOption as string, viewType as string);
		setPersonalViewSortOrderState(viewType as ActivityPageSortOptions);
	};

	const changeShouldNotShowProgressFloorsViewOption = (value: boolean) => {
		setShouldNotShowProgressFloorsViewOption(value);
	};

	const handleActivitiesViewSettingsClicked = useCallback(() => {
		activitiesViewSettingsOpen_BI();
		setIsFiltersDrawerOpen(true);
	}, []);

	const isUserPreferenceEmptyState: boolean =
		personalViewSortOption === ActivityPageSortOptions.PERSONAL_VIEW && !isUserPreferencesDefined(userPreferences);

	const getActivityPage = (): React.ReactNode => {
		if (currentPage === ActivitiesPages.walkAround)
			return (
				<MyWalkAround
					myWalkAroundData={allActivitiesRelevantData?.myWalkAroundData}
					activitiesLocationData={allActivitiesRelevantData?.activitiesLocationData}
					activitiesReportData={allActivitiesRelevantData?.activitiesReportData}
					refetchAllData={fetchAllData}
					shouldNotShowProgressFloorsViewOption={changeShouldNotShowProgressFloorsViewOption}
					progressFloorsViewState={progressFloorsViewState}
					isUserPreferenceEmptyState={isUserPreferenceEmptyState}
					personalViewSortOption={personalViewSortOption!}
				/>
			);
		if (currentPage === ActivitiesPages.completed)
			return (
				<CompletedActivities
					relevantActivities={allActivitiesRelevantData?.completedActivitiesData}
					activitiesLocationData={allActivitiesRelevantData?.activitiesLocationData}
					activitiesReportData={allActivitiesRelevantData?.activitiesReportData}
					refetchAllData={fetchAllData}
					shouldNotShowProgressFloorsViewOption={changeShouldNotShowProgressFloorsViewOption}
					progressFloorsViewState={progressFloorsViewState}
					isUserPreferenceEmptyState={isUserPreferenceEmptyState}
					personalViewSortOption={personalViewSortOption!}
				/>
			);
		return (
			<MyIssues
				relevantActivities={allActivitiesRelevantData?.myIssuesData}
				activitiesLocationData={allActivitiesRelevantData?.activitiesLocationData}
				activitiesReportData={allActivitiesRelevantData?.activitiesReportData}
				refetchAllData={fetchAllData}
				shouldNotShowProgressFloorsViewOption={changeShouldNotShowProgressFloorsViewOption}
				isUserPreferenceEmptyState={isUserPreferenceEmptyState}
				personalViewSortOption={personalViewSortOption!}
			/>
		);
	};

	if (isLoading || !personalViewSortOption) {
		return <Loader />;
	}

	if (!activitiesNotFiltered?.length) {
		return (
			<EmptyStateComponent
				icon={settingsIcon}
				text={translationService.get('mainActivitiesEmptyState')}
				style={{ maxWidth: '227px' }}
			/>
		);
	}

	const headerTabs: IHeaderTabItem[] = [
		{
			label: translationService.get(ActivitiesPages.issues),
			type: ActivitiesPages.issues,
			items: [
				{
					icon: IconNames.warningTriangle,
					value:
						allActivitiesRelevantData?.myIssuesData && !isUserPreferenceEmptyState
							? allActivitiesRelevantData?.myIssuesData.length
							: 0,
				},
			],
		},
		{
			label: translationService.get(ActivitiesPages.walkAround),
			type: ActivitiesPages.walkAround,
			items: [
				{
					icon: IconNames.route,
					value:
						allActivitiesRelevantData?.myWalkAroundData && !isUserPreferenceEmptyState
							? [
									...allActivitiesRelevantData.myWalkAroundData.startedToday,
									...allActivitiesRelevantData.myWalkAroundData.progressedToday,
							  ].length
							: 0,
				},
			],
		},
		{
			label: translationService.get(ActivitiesPages.completed),
			type: ActivitiesPages.completed,
			items: [
				{
					icon: IconNames.checkCircle,
					value:
						!isUserPreferenceEmptyState && allActivitiesRelevantData?.completedActivitiesData
							? allActivitiesRelevantData?.completedActivitiesData.length
							: 0,
				},
			],
		},
	];

	return (
		<div className={classes.activitiesContainer}>
			<PageHeader title={translationService.get('activities')}>
				<div onClick={handleActivitiesViewSettingsClicked}>
					<img
						src={
							personalViewSortOption === ActivityPageSortOptions.PERSONAL_VIEW
								? customizedFilterOn
								: customizedFilterOff
						}
					/>
				</div>
			</PageHeader>
			<HeaderTabsSection
				headerItems={headerTabs}
				onTabClick={(tabType: string) => {
					setCurrentPage(tabType as ActivitiesPages);
				}}
			/>
			{isFiltersDrawerOpen && (
				<SubTabHeader
					userPreferences={userPreferences}
					handleClose={() => setIsFiltersDrawerOpen(false)}
					sortOptionState={personalViewSortOption}
					progressFloorsViewState={progressFloorsViewState}
					setProgressViewState={changeProgressViewState}
					setPersonalViewSortOrderState={changePersonalViewState}
					shouldNotShowProgressFloorsView={shouldNotShowProgressFloorsViewOption}
				/>
			)}
			{getActivityPage()}
			{!allActivitiesRelevantData && <Loader />}
			<Switch>
				<ProtectedRoute
					exact
					hideNavbar
					hideHeader
					path="/activities/:groupId/issues"
					component={() => <MultiIssuesViewPage />}
				/>
			</Switch>
		</div>
	);
};

export { ActivitiesPage };
