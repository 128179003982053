import React, { useState } from 'react';
import dangerousActivityIcon from '@shared/assets/icons/dangerousActivityIcon.svg';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import closeIcon from '../../assets/x_close_black.svg';
import classes from './styles.module.scss';
import { IActivityByFloorData } from '../../interfaces/IActivityByFloor';
import { translationService } from '../../index';
import { ActivityMarkAsCompleteActionDialog } from '../Dialogs/ActivityMarkAsCompleteActionDialog/ActivityMarkAsCompleteActionDialog';
import { workplanActivityActions_BI } from '../../utils/bi.utils';
import { getTradeTranslation } from '../../utils/translations.utils';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';

interface IActivityByFloorActionsPopup {
	activity: IActivityByFloorData;
	onClose: () => void;
	onAction: () => Promise<void>;
}

export const ActivityByFloorActionsPopup = (props: IActivityByFloorActionsPopup) => {
	const [showCompleteDialog, setShowCompleteDialog] = useState<boolean>(false);

	const shouldShowDisclaimerTextForComplete = (): boolean =>
		props.activity.floors.some((floor) => floor.totalWorkHours <= 0);

	const dialogsDisclaimerPoints: string[] = [translationService.get('actionAppliesToAllFloors')];

	const sendActionBiEvent = (action: AreaSequenceItemStatus) => {
		workplanActivityActions_BI({
			groupStatus: props.activity.groupStatus,
			assignee: props.activity.assignee,
			description: props.activity.description,
			action,
		});
	};

	const onCompleteAction = () => {
		props.onAction();
		props.onClose();
	};

	return (
		<>
			<ActivityMarkAsCompleteActionDialog
				sendActionBiEventOnSubmit={() => sendActionBiEvent(AreaSequenceItemStatus.complete)}
				activityGroupId={props.activity.groupId}
				show={showCompleteDialog}
				onClose={() => setShowCompleteDialog(false)}
				showNoWorkDetectedDisclaimerText={shouldShowDisclaimerTextForComplete()}
				disclaimerPoints={dialogsDisclaimerPoints}
				onAction={onCompleteAction}
			/>
			<div className={classes.activityActionsContainer}>
				<img
					onClick={props.onClose}
					className={translationService.getIsRtl() ? classes.closeIconRTL : classes.closeIcon}
					src={closeIcon}
					alt={'xClose'}
				/>
				<div className={classes.professionContainer}>
					{props.activity.dangerousActivity && (
						<img src={dangerousActivityIcon} style={{ width: 12 }} alt={'dangerousActivity'} />
					)}
					<div className={classes.professionDescription}>{props.activity.description}</div>
					<div className={classes.professionText}>{getTradeTranslation(props.activity.profession)}</div>
				</div>
				<div className={classes.actionsContainer}>
					{props.activity.groupStatus !== ActivityGroupStatus.complete && (
						<div className={classes.lineDivider} />
					)}
					{props.activity.groupStatus !== ActivityGroupStatus.complete && (
						<div onClick={() => setShowCompleteDialog(true)} className={classes.actionText}>
							{translationService.get('markActivityAsCompleted')}
						</div>
					)}
				</div>
			</div>
		</>
	);
};
