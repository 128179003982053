import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getMonitorResourceTranslation } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { projectAccessLevelService } from '@src/index';

export const getMonitorDashboardTabs = ({
	personnelCount,
	equipmentCount,
	activitiesCount,
	issuesCount,
}: {
	personnelCount?: number;
	equipmentCount?: number;
	activitiesCount?: number;
	issuesCount?: number;
}): IDashboardTabItem[] => {
	const personnelTab: IDashboardTabItem = {
		label: getMonitorResourceTranslation('Resources'),
		resource: MonitorResourcesEnum.PERSONNEL,
		items: [
			{
				icon: IconNames.users,
				value: personnelCount,
			},
			{
				icon: IconNames.wrench,
				value: equipmentCount,
			},
		],
	};

	const activitiesTab: IDashboardTabItem = {
		label: getMonitorResourceTranslation(MonitorResourcesEnum.ACTIVITIES),
		resource: MonitorResourcesEnum.ACTIVITIES,
		items: [
			{
				icon: IconNames.workplan,
				value: activitiesCount,
			},
		],
	};

	const issuesTab: IDashboardTabItem = {
		label: getMonitorResourceTranslation(MonitorResourcesEnum.ISSUES),
		resource: MonitorResourcesEnum.ISSUES,
		items: [
			{
				icon: IconNames.issues,
				value: issuesCount,
			},
		],
	};

	return [
		...(projectAccessLevelService.hasAccess('personnelPage') ? [personnelTab] : []),
		...(projectAccessLevelService.hasAccess('activitiesPage') ? [activitiesTab] : []),
		...(projectAccessLevelService.hasAccess('issuesPage') ? [issuesTab] : []),
	];
};
