import React from 'react';
import { Switch } from '@material-ui/core';
import { INotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { translationService } from '../../../../index';
import classes from './styles.module.scss';

interface INotificationChannelSwitchProps {
	channelName: NotificationChannelTypes;
	handleSettingsChange: (
		value: boolean,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;
	card: INotificationCard;
	userSettings: IUserNotificationSettings | undefined;
}

const NotificationChannelSwitch = (props: INotificationChannelSwitchProps) => (
	<div className={classes.switchContainer}>
		<div>{translationService.get(props.channelName)}</div>
		<Switch
			color="primary"
			onChange={(event, checked) => props.handleSettingsChange(checked, props.card.type, props.channelName)}
			checked={
				!!(props.userSettings && props.userSettings?.settings[props.card.type]?.channels[props.channelName])
			}
		/>
	</div>
);

export { NotificationChannelSwitch };
