import React from 'react';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useHistory } from 'react-router-dom';
import { ActivityPageViewTypes } from '../../../../models/ActivityPageViewTypes';
import { translationService } from '../../../../index';
import classes from './styles.module.scss';
import { ActivityPageSortOptions } from '../../../../models/ActivityPageSortOptions';
import { TrusstorDrawer } from '../../../TrusstorDrawer/TrusstorDrawer';
import { SelectOptions } from '../../../TrusstorDrawer/SelectOption/SelectOptions';
import { CustomizedOptionSection } from '../../../TrusstorDrawer/CustomizedOptionSection/CustomizedOptionSection';
import { AppRoutes } from '../../../../constants/appRoutes.enum';

interface ISubTabHeaderProps {
	userPreferences: IUserPreferences | null;
	setPersonalViewSortOrderState: (viewType: string) => void;
	sortOptionState: ActivityPageSortOptions;
	setProgressViewState: (viewType: string) => void;
	progressFloorsViewState: ActivityPageViewTypes;
	shouldNotShowProgressFloorsView?: boolean;
	handleClose: () => void;
}

export const SubTabHeader = (props: ISubTabHeaderProps) => {
	const history = useHistory();
	const goToPreferencesPage = () => {
		history.push(AppRoutes.preferences);
	};

	return (
		<div className={classes.subTabWrapper}>
			<TrusstorDrawer
				topSection={translationService.get('filters')}
				isOpen={true}
				onClose={() => props.handleClose()}
			>
				<SelectOptions
					title={translationService.get('preference')}
					handleChange={props.setPersonalViewSortOrderState}
					options={[
						{
							actionButton: {
								text: translationService.get('edit'),
								onClick: goToPreferencesPage,
							},
							isSelected: props.sortOptionState === ActivityPageSortOptions.PERSONAL_VIEW,
							value: ActivityPageSortOptions.PERSONAL_VIEW,
							title: translationService.get('personalView'),
							children: <CustomizedOptionSection userPreferences={props.userPreferences} />,
						},
						{
							isSelected: props.sortOptionState === ActivityPageSortOptions.ALL_ISSUES,
							value: ActivityPageSortOptions.ALL_ISSUES,
							title: translationService.get('allActivities'),
						},
					]}
				/>

				{!props.shouldNotShowProgressFloorsView && (
					<SelectOptions
						title={translationService.get('viewBy')}
						handleChange={props.setProgressViewState}
						options={[
							{
								isSelected: props.progressFloorsViewState === ActivityPageViewTypes.BY_PROGRESS,
								value: ActivityPageViewTypes.BY_PROGRESS,
								title: translationService.get('byProgress'),
							},
							{
								isSelected: props.progressFloorsViewState === ActivityPageViewTypes.BY_FLOORS,
								value: ActivityPageViewTypes.BY_FLOORS,
								title: translationService.get('viewByFloors'),
							},
						]}
					/>
				)}
			</TrusstorDrawer>
		</div>
	);
};
