import { chunk, compact } from 'lodash';

export function splitArrayInMiddle<T>(array: T[]): [T[], T[]] {
	if (array.length === 0) {
		return [[], []];
	}
	const chunkSize = Math.ceil(array.length / 2);
	const result = chunk(array, chunkSize);
	return result.length === 2 ? (result as [T[], T[]]) : [result[0], []];
}

export function compactMap<T, U>(array: T[], callback: (value: T) => U | undefined): U[] {
	const mappedArray: (U | undefined)[] = array.map(callback);
	return compact(mappedArray);
}
