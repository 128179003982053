import React from 'react';
import { useParams } from 'react-router';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { LocationMonitorHeader } from '@src/components/pages/MonitorPage/LocationMonitorHeader/LocationMonitorHeader';
import { translationService } from '@src/servicesInitializer';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import { IFloor } from '@shared/interfaces/IFloor';
import { useFloorDataQuery } from '@src/hooks/queries/floors.queries.hooks';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { baseLocationToIssueLocation } from '@src/components/IssueLocationSelector/LocationListSelector/locationListSelector.utils';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useAreasByFloorIdDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { getMonitorResourceTranslation } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { FloorEquipments } from '@src/components/pages/MonitorPage/MonitorFloorResourcePage/FloorEquipments/FloorEquipments';
import { FloorMonitorIssuesPage } from '@src/components/pages/MonitorPage/MonitorFloorResourcePage/FloorMonitorIssuesPage/FloorMonitorIssuesPage';
import { useIssueCreatedQueryInvalidation } from '@src/hooks/queries/issues.queries.hooks';
import { FloorPersonnelPage } from '@src/components/pages/MonitorPage/MonitorFloorResourcePage/FloorPersonnelPage/FloorPersonnelPage';
import { FloorActivitiesPage } from '@src/components/pages/MonitorPage/MonitorFloorResourcePage/FloorActivitiesPage/FloorActivitiesPage';
import classes from './styles.module.scss';

const validFloorResources: string[] = Object.values(MonitorResourcesEnum).map((resource: string) =>
	resource.toLowerCase()
);

export const MonitorFloorResourcePage = () => {
	const floors: IFloor[] = useFloorDataQuery();
	const { floorId }: { floorId: string } = useParams();
	const floor: IFloor | undefined = floors.find((fl: IFloor) => fl.floorId === floorId);
	const areas: IConfigArea[] | undefined = useAreasByFloorIdDataQuery(floorId);
	const { resource }: { resource: string } = useParams();
	const isWrongResource: boolean = !validFloorResources.includes(resource);

	const issueCreatedQueryInvalidation = useIssueCreatedQueryInvalidation();
	if (!floor || !areas || isWrongResource) {
		return null;
	}

	const issueLocations: IIssueLocation[] = areas.map(baseLocationToIssueLocation);

	const getFloorResourceContentComponent = () => {
		switch (resource) {
			case MonitorResourcesEnum.PERSONNEL.toLowerCase():
				return <FloorPersonnelPage floorId={floorId} />;
			case MonitorResourcesEnum.EQUIPMENT.toLowerCase():
				return <FloorEquipments floorId={floorId} />;
			case MonitorResourcesEnum.ACTIVITIES.toLowerCase():
				return <FloorActivitiesPage floorId={floorId} />;
			case MonitorResourcesEnum.ISSUES.toLowerCase():
				return <FloorMonitorIssuesPage floorId={floorId} />;
			default:
				return null;
		}
	};

	const onIssueCreated = () => {
		issueCreatedQueryInvalidation();
	};

	return (
		<div className={classes.MonitorFloorResourcePage_container} data-testid="MonitorFloorResourcePage">
			<LocationMonitorHeader
				text={floor.floorNick}
				issueLocations={issueLocations}
				onIssueCreated={onIssueCreated}
				breadCrumbsItems={[
					{ title: translationService.get('mobileMonitor_monitor'), link: AppRoutes.monitor },
					{ title: floor.floorNick, link: `${AppRoutes.monitorFloor}/${floorId}` },
					{
						title: getMonitorResourceTranslation(resource),
						link: resource,
						isSelected: true,
					},
				]}
			/>
			<div className={classes.contentContainer}>{getFloorResourceContentComponent()}</div>
		</div>
	);
};
