import React from 'react';
import leftArrow from '@shared/assets/icons/leftArrow.svg';
import rightArrow from '@shared/assets/icons/rightArrow.svg';
import { translationService } from '../../servicesInitializer';

export const RightLeftArrow = () => {
	const arrowIconSrc = translationService.getIsRtl() ? leftArrow : rightArrow;
	return (
		<div data-testid="RightLeftArrow">
			<img alt="arrow" src={arrowIconSrc} />
		</div>
	);
};
