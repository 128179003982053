import React from 'react';
import { Header } from '../../Header/Header';
import classes from './styles.module.scss';
import { SomethingWentWrongPage } from '@shared/components/SomethingWentWrongPage/SomethingWentWrongPage';
import { useHistory } from 'react-router-dom';

const ErrorPage = () => {
	const history = useHistory();
	const forceRefresh = () => {
		history.push('/');
		window.location.reload();
	};
	return (
		<>
			<Header />
			<div className={classes.errorPageContainer}>
				<SomethingWentWrongPage
					onRefresh={() => {
						forceRefresh();
					}}
				/>
			</div>
		</>
	);
};

export { ErrorPage };
