import React, { useEffect, useState } from 'react';
import { IAssignedActivityArea } from '@shared/interfaces/IAssignedActivityArea';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetAreaSequenceItem } from '@shared/components/widget/WidgetAreaSequenceItem/WidgetAreaSequenceItem';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { IProfession } from '@shared/interfaces/IProfession';
import { IIssue } from '@shared/interfaces/IIssue';
import { IAreaSequenceItemWithActivityStatus } from '@shared/interfaces/IMainPageWidgetActivityData';
import { successToast, warningToast } from '../../../../utils/toast.utils';
import { requestService, translationService, userAccessLevelService } from '../../../../index';
import { workplanActivityActions_BI } from '../../../../utils/bi.utils';
import { IMergedAreaSequenceItem } from '../../../../interfaces/IMergedAreaSequenceItem';
import { selectProjectId } from '../../../../store/slices/project.slice';
import { AsiReviewDialog } from '../../../AsiReviewDialog/ASIReviewDialog';
import { IssueUpsertPage } from '../../../pages/IssuesPage/IssueUpsertPage/IssueUpsertPage';
import { updateAreaSequenceItemReadyForReviewStatus } from '../../../../apis/areaSequenceItems.api';

interface ISequenceProgressCardProps {
	areaSequenceItem: IMergedAreaSequenceItem;
	area: IAssignedActivityArea;
	refetchAreaSequenceItems: () => void;
	description: string;
	profession: IProfession;
	refetchDataCallback: () => Promise<void>;
}

const SequenceProgressCard = (props: ISequenceProgressCardProps) => {
	const dispatch = useDispatch();
	const projectId: string = useSelector(selectProjectId)!;
	const [showReviewDialog, setShowReviewDialog] = useState(false);
	const [showIssuesPage, setShowIssuesPage] = useState(false);
	const [isReadyForReview, setIsReadyForReview] = useState<boolean>(
		props.areaSequenceItem.status === AreaSequenceItemStatus.readyForReview
	);
	const [isDone, setIsDone] = useState<boolean>(props.areaSequenceItem.status === AreaSequenceItemStatus.complete);
	const userHasAccessToActivityActions: boolean = userAccessLevelService.hasAccess('activitiesActions');

	useEffect(() => {
		setIsReadyForReview(props.areaSequenceItem.status === AreaSequenceItemStatus.readyForReview);
		setIsDone(props.areaSequenceItem.status === AreaSequenceItemStatus.complete);
	}, [props.areaSequenceItem.status]);

	const handleLimitedUserChangeStatus = async (cell: IAreaSequenceItem) => {
		try {
			if (!cell) return undefined;
			setIsReadyForReview((prev) => !prev);
			const asiUpdatedFromAPI: IMergedAreaSequenceItem = await updateAreaSequenceItemReadyForReviewStatus(
				cell._id!,
				!isReadyForReview
			);
			props.refetchAreaSequenceItems();
			if (asiUpdatedFromAPI.status === AreaSequenceItemStatus.readyForReview) {
				successToast(dispatch, translationService.get('markReadyForReviewSuccess'));
			}
			return asiUpdatedFromAPI;
		} catch (e) {
			warningToast(dispatch, translationService.get('markReadyForReviewFailed'));
		}
		return undefined;
	};

	const handleDone = async (cell: IAreaSequenceItemWithActivityStatus): Promise<void> => {
		try {
			if (!cell) return;
			setIsDone((prev) => !prev);
			await requestService.put(`/activities/sequenceItems/areaSequenceItem/done`, {
				body: { mergedAreaSequenceItem: { ...cell }, isDone: !isDone },
			});
			props.refetchAreaSequenceItems();
			workplanActivityActions_BI({
				activityId: cell.activityId,
				groupStatus: cell.groupStatus,
				action: isAsiComplete(cell.status) ? AreaSequenceItemStatus.complete : AreaSequenceItemStatus.unplanned,
			});
		} catch (err) {
			warningToast(dispatch, translationService.get('tryAgain'));
		}
	};

	const handleCellClick = async (cell: IAreaSequenceItemWithActivityStatus): Promise<void> => {
		if (!userHasAccessToActivityActions) {
			const asiUpdatedFromAPI: IMergedAreaSequenceItem | undefined = await handleLimitedUserChangeStatus(cell);
			if (asiUpdatedFromAPI?.status !== AreaSequenceItemStatus.readyForReview) {
				successToast(dispatch, translationService.get('undoASISubmissionForReview'));
			}
			return undefined;
		}
		if (userHasAccessToActivityActions && cell.status === AreaSequenceItemStatus.readyForReview) {
			return setShowReviewDialog(true);
		}
		return handleDone(cell);
	};

	const onApproveSubmit = async () => {
		setShowReviewDialog(false);
		await handleDone(props.areaSequenceItem);
		successToast(dispatch, translationService.get('asiReviewDialog_reviewApproved'));
	};

	const onRejectSubmit = async () => {
		setShowIssuesPage(true);
	};

	const defaultIssue: Partial<IIssue> = {
		projectId,
		locations: [
			{
				area: { areaId: props.area.areaId, areaNick: props.area.areaNick },
				floor: {
					floorId: props.area.floorId,
					floorNick: props.area.floorNick,
				},
			},
		],
		profession: props.profession,
		linkedAreaSequenceItemId: props.areaSequenceItem._id,
		linkedActivityGroupId: props.areaSequenceItem.activityGroupId,
	};

	const handleIssueCreated = async () => {
		setShowReviewDialog(false);
		setShowIssuesPage(false);
		props.refetchDataCallback();
		const asiUpdated = await handleLimitedUserChangeStatus(props.areaSequenceItem);
		successToast(
			dispatch,
			translationService.get('asiReviewDialog_reviewRejected', {
				new_status: translationService.get(asiUpdated?.status || ''),
			})
		);
	};

	return (
		<>
			{showReviewDialog && (
				<AsiReviewDialog
					asi={props.areaSequenceItem}
					projectId={projectId}
					area={props.area}
					sequenceItemDescription={props.description}
					profession={props.profession}
					show={true}
					onClose={() => setShowReviewDialog(false)}
					onAcceptSubmit={onApproveSubmit}
					onRejectSubmit={onRejectSubmit}
				/>
			)}
			<IssueUpsertPage
				dialogTitle={translationService.get('asiReview_createIssueMobileTitle')}
				initialIssue={defaultIssue}
				onClose={() => setShowIssuesPage(false)}
				onIssueCreated={handleIssueCreated}
				disableSuccessToast
				show={showIssuesPage}
			/>
			<WidgetAreaSequenceItem
				isDoneCell={isDone}
				isReadyForReview={isReadyForReview}
				userHasAccessToActivityActions={userHasAccessToActivityActions}
				area={props.area}
				areaSequenceItem={props.areaSequenceItem as IAreaSequenceItem}
				onClick={handleCellClick}
				translationService={translationService}
			/>
		</>
	);
};

export { SequenceProgressCard };
