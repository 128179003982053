import React from 'react';
import { translationService } from '@src/servicesInitializer';
import { IFloor } from '@shared/interfaces/IFloor';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { MonitorSearchCardItem } from '@src/components/pages/MonitorPage/MonitorSearch/MonitorSearchCardItem/MonitorSearchCardItem';
import { IRecentSearchLocationItem } from '@src/services/monitorSearchRecentResultsLocalStorage.service';
import { removeItemFromSearchResults } from '@src/components/pages/MonitorPage/MonitorSearch/monitorSearch.utils';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';

interface IFloorOrArea {
	floor?: IFloor;
	area?: IConfigArea;
}

interface IRecentSearchItemsProps {
	relevantFloors: IFloor[];
	projectAreas: IConfigArea[];
	recentSearchItems: IRecentSearchLocationItem[];
	setRecentSearchItems: React.Dispatch<React.SetStateAction<IRecentSearchLocationItem[] | undefined>>;
	onCardClick: ({ area, floor }: IFloorOrArea) => void;
}

export const RecentSearchItems = (props: IRecentSearchItemsProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const getFloorFromSearchItem = (item: IRecentSearchLocationItem) =>
		props.relevantFloors.find((floor) => floor.floorId === item.floorId);

	const getAreaFromSearchItem = (item: IRecentSearchLocationItem) =>
		props.projectAreas.find((area) => area.areaId === item.areaId);

	const onCardRemoveClick = (id: string, e) => {
		e.stopPropagation();
		removeItemFromSearchResults(projectId, props.setRecentSearchItems, id);
	};

	return (
		<div className={classes.RecentSearchItems_container} data-testid="RecentSearchItems">
			<div className={classes.recentTitle}>{translationService.get('monitorSearch_recentTitle')}</div>
			{props.recentSearchItems.map((searchItem) => {
				const floor: IFloor | undefined = getFloorFromSearchItem(searchItem);
				const area: IConfigArea | undefined = getAreaFromSearchItem(searchItem);
				if (area) {
					return (
						<MonitorSearchCardItem
							area={area}
							onClick={() => props.onCardClick({ area })}
							removeClick={(e) => onCardRemoveClick(searchItem.id, e)}
						/>
					);
				}
				return (
					<MonitorSearchCardItem
						floor={floor}
						onClick={() => props.onCardClick({ floor })}
						removeClick={(e) => onCardRemoveClick(searchItem.id, e)}
					/>
				);
			})}
		</div>
	);
};
