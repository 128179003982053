import React, { useMemo } from 'react';
import { countBy } from 'lodash';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { FilterCard } from '@shared/components/Filters/FilterCard/FilterCard';
import { translationService } from '../../../../../servicesInitializer';
import { addFloor, clearFloors, removeFloor, selectSelectedFloors } from '../../../../../store/slices/filters.slice';

interface ILocationFilterSectionProps {
	floors: IBaseFloor[];
}

export const FloorFilterSection = (props: ILocationFilterSectionProps) => {
	const dispatch = useDispatch();
	const selectedFloors: IBaseFloor[] = useSelector(selectSelectedFloors);
	const onClear = () => {
		dispatch(clearFloors());
	};

	const addSelectedFloor = (location: IBaseFloor) => {
		dispatch(addFloor({ floor: location }));
	};

	const removeSelectedFloor = (location: IBaseFloor) => {
		dispatch(removeFloor({ floor: location }));
	};

	if (!props.floors.length) {
		return <></>;
	}

	const floorsCountObj: {
		[floorId: string]: number;
	} = useMemo(() => countBy(props.floors, (floor) => floor.floorId), [props.floors]);

	return (
		<CollapsableContainer
			onClear={selectedFloors.length ? onClear : undefined}
			headerText={translationService.get('location')}
		>
			{props.floors.map((floor: IBaseFloor) => {
				const isSelected: boolean = selectedFloors.some(
					(selectedFloor) => selectedFloor.floorId === floor.floorId
				);
				return (
					<FilterCard
						key={floor.floorId}
						handleClick={isSelected ? () => removeSelectedFloor(floor) : () => addSelectedFloor(floor)}
						text={floor.floorNick}
						isSelected={isSelected}
						count={floorsCountObj[floor.floorId]}
					/>
				);
			})}
		</CollapsableContainer>
	);
};
