import { IProfession } from '@shared/interfaces/IProfession';
import * as React from 'react';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import classes from './styles.module.scss';
import { RightLeftArrow } from '../RightLeftArrow/RightLeftArrow';

export interface IWorkerPageProfessionRowProps {
	profession: IProfession;
	workersCount: number;
	onClick?: () => void;
}

export const WorkerPageProfessionRow = (props: IWorkerPageProfessionRowProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	return (
		<div className={classes.row} onClick={props.onClick}>
			<div>
				<ProfessionDisplayWithTradeIcon
					contractorTextClassname={classes.professionHeader}
					profession={props.profession}
					projectId={projectId}
				/>
			</div>
			<div className={classes.rightSection}>
				{props.workersCount}
				<RightLeftArrow />
			</div>
		</div>
	);
};

export default WorkerPageProfessionRow;
