import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize, TrusstorIconShared } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { COLORS } from '@shared/constants/colors.constants';
import { translationService } from '@src/index';

export enum AdditionalAreaSequenceItemStatus {
	notReadyForReview = 'notReadyForReview',
}

interface IAreaSequenceItemStatusChipProps {
	status: AreaSequenceItemStatus | AdditionalAreaSequenceItemStatus;
	isDropdown?: boolean;
	onClick?: () => void;
}

interface IStatusProperties {
	backgroundColor: string;
	iconName?: IconNames | 'circle';
	iconColor?: IconColor;
	text: string;
	textColor: COLORS;
}

export const AreaSequenceItemStatusChip = (props: IAreaSequenceItemStatusChipProps) => {
	const statusesProperties: Record<AreaSequenceItemStatus, IStatusProperties> = {
		unplanned: {
			backgroundColor: COLORS.transparent,
			text: props.isDropdown ? translationService.get('status') : translationService.get('unplanned'),
			textColor: COLORS.darkBlue,
			iconName: props.isDropdown ? undefined : 'circle',
			iconColor: IconColor.White,
		},
		planned: {
			backgroundColor: COLORS.grey100,
			iconName: IconNames.upcomingCircleFilled,
			iconColor: IconColor.Grey300,
			text: translationService.get('planned'),
			textColor: COLORS.darkBlue,
		},
		inProgress: {
			backgroundColor: COLORS.yellow100,
			iconName: IconNames.progress,
			iconColor: IconColor.Yellow500,
			text: translationService.get('inProgress'),
			textColor: COLORS.grey700,
		},
		delayed: {
			backgroundColor: COLORS.grey100,
			iconColor: IconColor.Grey600,
			iconName: IconNames.warningTriangle,
			text: translationService.get('delayed'),
			textColor: COLORS.darkBlue,
		},
		complete: {
			backgroundColor: COLORS.green400,
			iconName: IconNames.checkCircle,
			iconColor: IconColor.White,
			text: translationService.get('complete'),
			textColor: COLORS.white,
		},
		overdue: {
			backgroundColor: COLORS.red400,
			iconName: IconNames.flag,
			iconColor: IconColor.White,
			text: translationService.get('overdue'),
			textColor: COLORS.white,
		},
		delayedOverdue: {
			backgroundColor: COLORS.red400,
			iconName: IconNames.flag,
			iconColor: IconColor.White,
			text: translationService.get('overdue'),
			textColor: COLORS.white,
		},
		readyForReview: {
			backgroundColor: COLORS.feedbackHighDefault,
			iconName: IconNames.workplan,
			iconColor: IconColor.White,
			text: translationService.get('readyForReview'),
			textColor: COLORS.white,
		},
	};
	const statusProperties: IStatusProperties = statusesProperties[props.status];

	if (!statusProperties) {
		return null;
	}

	return (
		<div
			className={classnames(classes.chipContainer, {
				[classes.border]: props.status === AreaSequenceItemStatus.unplanned,
				[classes.noDropdown]: !props.isDropdown,
			})}
			style={{ backgroundColor: statusProperties.backgroundColor }}
			onClick={props.onClick}
		>
			<div className={classes.iconTextContainer}>
				{statusProperties.iconName &&
					(statusProperties.iconName === 'circle' ? (
						<div className={classes.circle} />
					) : (
						<TrusstorIconShared
							iconName={statusProperties.iconName}
							color={statusProperties.iconColor}
							size={IconSize.SMALL}
						/>
					))}
				<p
					className={classnames(classes.text, { [classes.noIconText]: !statusProperties.iconName })}
					style={{ color: statusProperties.textColor }}
				>
					{statusProperties.text}
				</p>
			</div>

			{props.isDropdown && <TrusstorIconShared iconName={IconNames.chevronDown} />}
		</div>
	);
};
