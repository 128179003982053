import React, { createContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { baseLocationToIssueLocation } from '@src/components/IssueLocationSelector/LocationListSelector/locationListSelector.utils';
import { translationService } from '@src/index';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import { DashboardTabsSection } from '@src/components/DashboardTabsSection/DashboardTabsSection';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { AreaPersonnelPage } from '@src/components/pages/MonitorPage/MonitorAreaPage/AreaPersonnelPage/AreaPersonnelPage';
import {
	IIssuesAreaData,
	IPersonnelAreaData,
	useMonitorAreaActivitiesData,
	useMonitorAreaIssuesData,
	useMonitorAreaPersonnelData,
} from '@src/hooks/monitor.hooks';
import { AreaIssuesResourcePage } from '@src/components/pages/MonitorPage/MonitorAreaPage/AreaIssuesResourcePage/AreaIssuesResourcePage';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { getPermittedMonitorResources } from '@utils/permissions.utils';
import { useAreaByAreaIdDataQuery, useAreasDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { getMonitorDashboardTabs } from './monitorAreaPage.utils';
import classes from './styles.module.scss';
import { LocationMonitorHeader } from '../LocationMonitorHeader/LocationMonitorHeader';
import { MonitorAreaActivities } from './MonitorAreaActivities/MonitorAreaActivities';

interface IMonitorAreaPageContext {
	area: IConfigArea | undefined;
}

export const MonitorAreaPageContext = createContext<IMonitorAreaPageContext | undefined>(undefined);

export const MonitorAreaPage = () => {
	const history = useHistory();
	const projectAreas: IConfigArea[] = useAreasDataQuery();
	const { areaId }: { areaId: string } = useParams();
	const areaFromProject: IConfigArea | undefined = projectAreas.find((area: IConfigArea) => area.areaId === areaId);
	const { area: areaFromQuery } = useAreaByAreaIdDataQuery(areaId);
	const area: IConfigArea | undefined = areaFromQuery || areaFromProject;
	const isMultiAreaFloor: boolean = projectAreas.filter((a: IConfigArea) => a.floorId === area?.floorId)?.length > 1;

	const permittedResources: MonitorResourcesEnum[] = getPermittedMonitorResources();
	const [selectedTabResource, setSelectedTabResource] = useState<MonitorResourcesEnum>(permittedResources[0]);
	const personnelData: IPersonnelAreaData = useMonitorAreaPersonnelData(areaId);
	const issuesData: IIssuesAreaData = useMonitorAreaIssuesData(areaId);
	const { areaSequenceItems, isLoading, ongoingAreaSequenceItems } = useMonitorAreaActivitiesData(areaId);

	if (projectAreas.length && !area) {
		history.push(AppRoutes.monitor);
	}

	const getResourceContent = () => {
		if (!area) {
			return null;
		}
		switch (selectedTabResource) {
			case MonitorResourcesEnum.ISSUES:
				return (
					<AreaIssuesResourcePage
						areaId={areaId}
						issues={issuesData.issues}
						isLoading={issuesData.isIssuesInitialLoading}
						getIssuesProps={issuesData.getIssuesProps}
					/>
				);
			case MonitorResourcesEnum.ACTIVITIES:
				return <MonitorAreaActivities areaId={areaId} areaSequenceItems={areaSequenceItems} />;
			case MonitorResourcesEnum.PERSONNEL:
				return <AreaPersonnelPage areaId={areaId} data={personnelData} />;
			default:
				return null;
		}
	};

	const issueLocation: IIssueLocation | undefined = area && baseLocationToIssueLocation(area);
	return (
		<MonitorAreaPageContext.Provider
			value={{
				area,
			}}
		>
			<div className={classes.monitorAreaPageContainer}>
				<LocationMonitorHeader
					text={area?.areaNick}
					onIssueCreated={() => {
						issuesData.issueCreatedQueryInvalidation();
					}}
					issueLocations={issueLocation ? [issueLocation] : []}
					breadCrumbsItems={[
						{ title: translationService.get('mobileMonitor_monitor'), link: AppRoutes.monitor },
						...(!isMultiAreaFloor
							? []
							: [{ title: area?.floorNick, link: `${AppRoutes.monitorFloor}/${area?.floorId}` }]),
						{ title: area?.areaNick, link: AppRoutes.monitorArea, isSelected: true },
					]}
				/>
				<div className={classes.contentContainer}>
					<DashboardTabsSection
						dashboardItems={getMonitorDashboardTabs({
							personnelCount: personnelData.isLoading ? undefined : personnelData.personnelDataCount,
							equipmentCount: personnelData.isLoading ? undefined : personnelData.mergedEquipments.length,
							issuesCount: issuesData.isIssuesInitialLoading ? undefined : issuesData.issues.length,
							activitiesCount: isLoading ? undefined : ongoingAreaSequenceItems.length,
						})}
						onTabClick={(selected: MonitorResourcesEnum) => setSelectedTabResource(selected)}
					/>
					<div className={classes.content}>{getResourceContent()}</div>
				</div>
			</div>
		</MonitorAreaPageContext.Provider>
	);
};
