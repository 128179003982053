import decode from 'jwt-decode';
import { RequestService } from '@shared/services/request.service';
import { UserService } from '@shared/services/user.service';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { HEADERS } from '@shared/constants/constants';
import { store } from '../store/store';
// eslint-disable-next-line import/no-cycle
import { setLoggedUserThunk } from '../store/thunks/login.thunks';

class AuthService {
	private refreshTokenPromise: Promise<ILoggedUser> | null;

	constructor(private requestService: RequestService, private userService: UserService) {
		this.refreshTokenPromise = null;
	}

	public refreshUserToken = async (): Promise<boolean> => {
		const refreshToken: string | null = this.userService.getLoggedUserRefreshToken();
		if (!refreshToken) {
			return false;
		}

		const loggedUser: ILoggedUser = await this.fetchRefreshToken(refreshToken);
		store.dispatch(setLoggedUserThunk(loggedUser) as any);
		return true;
	};

	public async getIsTokenExpired() {
		try {
			const accessToken: string | null = this.userService.getLoggedUserAccessToken();

			if (!accessToken) {
				return true;
			}

			const decodedAccessToken: any = decode(accessToken);
			const expireDateInSecondsSinceEpoch: number = decodedAccessToken.exp;
			return new Date().getTime() > expireDateInSecondsSinceEpoch * 1000;
		} catch (e) {
			return true;
		}
	}

	public async authorizeUserWithAccessToken(accessToken: string): Promise<ILoggedUser> {
		const user: ILoggedUser = await this.requestService.get(`/auth/authorization/authData?token=${accessToken}`, {
			disableAuth: true,
			headers: { [HEADERS.authorization]: `Bearer ${accessToken}` },
		});

		return user;
	}

	private async fetchRefreshToken(refreshToken: string): Promise<ILoggedUser> {
		if (this.refreshTokenPromise) {
			return this.refreshTokenPromise;
		}

		this.refreshTokenPromise = this.requestService.post('/auth/authentication/refresh', {
			disableAuth: true,
			body: {
				refreshToken,
			},
		});
		const loggedUser: ILoggedUser = await this.refreshTokenPromise;
		this.refreshTokenPromise = null;
		return loggedUser;
	}
}

export { AuthService };
