import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import {
	UseGetIssuesProps,
	useProjectIssuesDeleteMutation,
	useUpdateIssueMutation,
} from '@src/hooks/queries/issues.queries.hooks';
import { IIssue } from '@interfaces/IIssue';
import { getMonitorResourceTranslation } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { DisplayIssues } from '@src/components/pages/MonitorPage/DisplayIssues/DisplayIssues';
import {
	DashboardTabEmptyState,
	EmptyStateResourcesEnum,
} from '@shared/components/DashboardTabEmptyState/DashboardTabEmptyState';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import classes from './styles.module.scss';

interface IAreaIssuesResourcePageProps {
	areaId: string;
	getIssuesProps: UseGetIssuesProps;
	issues: IIssue[];
	isLoading: boolean;
}

export const AreaIssuesResourcePage = (props: IAreaIssuesResourcePageProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const user: IUser = useSelector(selectLoggedUserDetails)!;

	const deleteIssueMutation = useProjectIssuesDeleteMutation(projectId);

	const deleteIssue = async (issueId: string) => {
		deleteIssueMutation.mutate(issueId);
	};

	const updateIssueMutation = useUpdateIssueMutation({
		getIssuesProps: props.getIssuesProps,
		user,
		dispatch,
	});

	const updateIssue = async (issueId: string, issue: Partial<IUpdateIssue>, updateBackend = true) => {
		const issueBeforeUpdate: IIssue = props.issues.find((issue) => issue._id === issueId)!;
		return updateIssueMutation.mutateAsync({
			issueBeforeUpdate,
			issueId,
			issueToUpdate: issue,
			updateBackend,
		});
	};

	const isEmptyState: boolean = !props.issues.length && !props.isLoading;
	return (
		<div className={classes.AreaIssuesResourcePage_container} data-testid="AreaIssuesResourcePage">
			{isEmptyState ? (
				<DashboardTabEmptyState resource={EmptyStateResourcesEnum.issues} />
			) : (
				<>
					<div
						className={classes.title}
					>{`${getMonitorResourceTranslation(MonitorResourcesEnum.ISSUES)} (${props.issues.length || 0})`}</div>
					<DisplayIssues
						issues={props.issues}
						isIssuesLoading={props.isLoading}
						deleteIssue={deleteIssue}
						updateIssue={updateIssue}
						user={user}
					/>
				</>
			)}
		</div>
	);
};
