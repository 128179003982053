import { combineReducers } from 'redux';
import loginReducer from './login.slice';
import projectReducer from './project.slice';
import userPreferencesReducer from './userPreferences.slice';
import toastMessagesReducer from './toastMessage.slice';
import offlineBannerReducer from './offlineBanner.slice';
import notificationsReducer from './notifications.slice';
import filterReducer from './filters.slice';

const rootReducer = combineReducers({
	userPreferencesReducer,
	projectReducer,
	loginReducer,
	toastMessagesReducer,
	notificationsReducer,
	offlineBannerReducer,
	filterReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
export default rootReducer;
