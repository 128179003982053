import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { IStaticTag } from '../../interfaces/IStaticTag';
import { selectProjectId } from '../../store/slices/project.slice';
import { requestService } from '../../index';

export const useProjectStaticTagsQuery = (): IStaticTag[] => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: staticTags } = useQuery<IStaticTag[]>(
		['staticTags', projectId],
		() => requestService.get(`/siteNetIntegrator/static/tags/${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return staticTags;
};
