import { IHeaderTabItem } from '@shared/interfaces/IHeaderTabItem';
import React from 'react';
import { TrusstorIcon } from '../../TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';

interface IHeaderTabProps extends IHeaderTabItem {
	isSelected: boolean;
	selectTab: () => void;
}

export const HeaderTab = (props: IHeaderTabProps) => {
	return (
		<div
			className={`${classes.tabContainer} ${props.isSelected ? classes.selectedItem : ''}`}
			onClick={props.selectTab}
		>
			<div className={classes.itemsContainer}>
				{props.items.map((item, index) => {
					return (
						<div key={index}>
							<div className={classes.item}>
								<div
									className={`${classes.iconValueContainer} ${
										props.isSelected ? classes.selectedItemText : ''
									}`}
								>
									<TrusstorIcon iconName={item.icon} />
									<p className={classes.valueText}>{item.value}</p>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<p className={classes.tabLabel}>{props.label}</p>
		</div>
	);
};
