import React, { useState } from 'react';
import { Dictionary, keyBy } from 'lodash';
import classes from './styles.module.scss';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { ISequenceItem } from '@interfaces/ISequenceItem';
import { MonitorAreaActivityCard } from './MonitorAreaActivityCard/MonitorAreaActivityCard';

interface IMonitorAreaActivitiesSectionProps {
	title: string;
	areaSequenceItems: IAreaSequenceItem[];
	sequenceItems: ISequenceItem[];
}

export const MonitorAreaActivitiesSection = (props: IMonitorAreaActivitiesSectionProps) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);
	const sequenceItemsBySequenceItemIds: Dictionary<ISequenceItem> = keyBy(
		props.sequenceItems,
		(sequenceItem: ISequenceItem) => sequenceItem._id
	);
	return (
		<div className={classes.monitorAreaActivitiesSectionContainer}>
			<div
				className={classes.expandSection}
				onClick={() => setIsExpanded((prevIsExpanded: boolean) => !prevIsExpanded)}
			>
				<TrusstorIcon iconName={isExpanded ? IconNames.chevronUp : IconNames.chevronDown} />
				<p className={classes.titleText}>
					{props.title} ({props.areaSequenceItems.length})
				</p>
			</div>
			{isExpanded && (
				<div className={classes.activitiesContainer}>
					{props.areaSequenceItems.map((asi, index) => {
						if (!sequenceItemsBySequenceItemIds[asi.sequenceItemId]) {
							return null;
						}
						return (
							<div className={classes.activityContainer}>
								<MonitorAreaActivityCard
									key={asi._id}
									areaSequenceItem={asi}
									sequenceItem={sequenceItemsBySequenceItemIds[asi.sequenceItemId]}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
