import { IActivityRealtimeFloorInfo } from '@shared/interfaces/IActivityRealtimeInfo';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import moment from 'moment-timezone';
import { getTimezoneFormattedDate } from '@shared/utils/dateUtils';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import { IActivityRealtimeInfo } from 'src/interfaces/IActivityRealtimeInfo';
import {
	IActivityDataFromBackend,
	IAllActivitiesRelevantData,
} from 'src/components/pages/ActivitiesPage/ActivityPage.models';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { requestService } from '../index';
import { IProfessionWithActiveTags } from '../interfaces/IProfessionWithActiveTags';
import { IActivityByProgress, IActivityRealTimeFloorInfoMobile } from '../interfaces/IActivityByProgress';
import { IActivityRealtimeInfoByFloors } from '../interfaces/IActivityRealtimeInfoByFloors';
import { IActivityByFloor, IActivityByFloorData } from '../interfaces/IActivityByFloor';
import { IMyWorkAroundData } from '../interfaces/IMyWorkAroundData';
import { getProgressActivitiesAfterUserPreferencesFilter } from '../components/pages/ActivitiesPage/utils';
import { ActivityPageSortOptions } from '../models/ActivityPageSortOptions';
import { ISingleActivityRealtimeInfo } from '../interfaces/ISingleActivityRealtimeInfo';

export const isActivityInStartedStatusFromToday = (
	activityData: IActivityByFloorData | IActivityRealtimeInfo,
	tz: string
) =>
	moment.tz(activityData.currentStartDate, tz).isSame(moment.tz(tz), 'day') &&
	(activityData.groupStatus === ActivityGroupStatus.inProgress ||
		activityData.groupStatus === ActivityGroupStatus.overdue);

export const getMyIssuesData = (
	activitiesData: IActivityRealtimeInfo[],
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[],
	tz: string
): IActivityRealtimeInfo[] =>
	activitiesData.filter((activity) => {
		if (
			activity.groupStatus === ActivityGroupStatus.delayed ||
			activity.groupStatus === ActivityGroupStatus.overdue
		)
			return true;
		const reportRelevantDataTodayHours: IWorkplanWidgetRealtimeActivityReport | undefined =
			activitiesReportData.find((report) => report.groupId === activity.groupId);
		return (
			activity.groupStatus === ActivityGroupStatus.inProgress &&
			moment.tz(activity.currentStartDate, tz!).isBefore(moment.tz(tz!), 'days') &&
			reportRelevantDataTodayHours &&
			reportRelevantDataTodayHours.todayHours === 0
		);
	});

export const getMyWalkAroundData = (
	activitiesData: IActivityRealtimeInfo[],
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[],
	tz: string
): IMyWorkAroundData => {
	const startedToday: IActivityRealtimeInfo[] = activitiesData.filter((activity) =>
		isActivityInStartedStatusFromToday(activity, tz)
	);
	const progressedToday: IActivityRealtimeInfo[] = activitiesData.filter((activity) => {
		if (
			activity.groupStatus === ActivityGroupStatus.complete ||
			activity.groupStatus === ActivityGroupStatus.delayed
		) {
			return false;
		}
		if (isActivityInStartedStatusFromToday(activity, tz)) return false;

		const reportRelevantData: IWorkplanWidgetRealtimeActivityReport | undefined = activitiesReportData.find(
			(report) => report.groupId === activity.groupId
		);
		return reportRelevantData && reportRelevantData.todayHours > 0;
	});

	return { startedToday, progressedToday };
};

export const getCompletedActivitiesData = (activitiesData: IActivityRealtimeInfo[]) =>
	activitiesData.filter((activity) => activity.groupStatus === ActivityGroupStatus.complete);

export const filterActivitiesAndGroupByTabs = (
	allActivitiesData: IActivityDataFromBackend,
	personalViewSortOption: ActivityPageSortOptions,
	tz: string,
	userPreferences: IUserPreferences | null
): IAllActivitiesRelevantData => {
	const activitiesAfterPreferenceFilter: IActivityRealtimeInfo[] = getProgressActivitiesAfterUserPreferencesFilter(
		allActivitiesData.activitiesData,
		userPreferences,
		personalViewSortOption
	);

	const myIssuesData: IActivityRealtimeInfo[] = getMyIssuesData(
		activitiesAfterPreferenceFilter,
		allActivitiesData.activitiesReportData,
		tz
	);
	const myWalkAroundData: IMyWorkAroundData = getMyWalkAroundData(
		activitiesAfterPreferenceFilter,
		allActivitiesData.activitiesReportData,
		tz
	);
	const completedActivitiesData: IActivityRealtimeInfo[] = getCompletedActivitiesData(
		activitiesAfterPreferenceFilter
	);

	return {
		myIssuesData,
		myWalkAroundData,
		completedActivitiesData,
		activitiesReportData: allActivitiesData.activitiesReportData,
		activitiesLocationData: allActivitiesData.activitiesLocationData,
	};
};

export const fetchAllActivitiesData = async (projectId: string, tz: string): Promise<IActivityDataFromBackend> => {
	const serverFormatDate: string = getTimezoneFormattedDate(tz!, new Date());

	const [activitiesData, activitiesLocationData, activitiesReportData]: [
		activitiesData: IActivityRealtimeInfo[],
		activitiesLocationData: IProfessionWithActiveTags[],
		activitiesReportData: IWorkplanWidgetRealtimeActivityReport[]
	] = await Promise.all([
		requestService.get(`/activities/groups/onGoingAndCompleteTodayRealtimeActivitiesInfo`),
		requestService.get(`/location/merged/activeProfessions?projectId=${projectId}`),
		requestService.get(`/reports/workplanWidgetActivitiesReport?date=${serverFormatDate}&projectId=${projectId}`),
	]);

	return {
		activitiesData,
		activitiesReportData,
		activitiesLocationData,
	};
};

export const addLocationAndReportDataToActivitiesByProgress = (
	relevantActivities: IActivityRealtimeInfo[],
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[],
	activitiesLocationData: IProfessionWithActiveTags[]
): IActivityByProgress[] =>
	relevantActivities.map((activity) => {
		const reportRelevantData: IWorkplanWidgetRealtimeActivityReport | undefined = activitiesReportData.find(
			(report) => report.groupId === activity.groupId
		);
		const locationRelevantData: IProfessionWithActiveTags | undefined = activitiesLocationData.find(
			(location) => location._id === activity.profession._id
		);
		const floorParsed: IActivityRealTimeFloorInfoMobile[] = activity.floors.map(
			(floor: IActivityRealtimeFloorInfo) => {
				const floorWorkHours: number =
					reportRelevantData?.activitiesHours.find(
						(activityHours) => activityHours.activityId === floor.activityId
					)?.workHours || 0;
				return {
					...floor,
					todayHours: floorWorkHours,
				};
			}
		);
		return {
			...activity,
			floors: floorParsed,
			workersOnSite: locationRelevantData?.activeTagsIds || [],
		};
	});

const getReportActivityHoursToday = (
	activityRealtimeInfo: ISingleActivityRealtimeInfo,
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[]
): number => {
	const reportRelevantData = activitiesReportData.find((report) => report.groupId === activityRealtimeInfo.groupId);
	const activityHoursToday: number =
		reportRelevantData?.activitiesHours.find(
			(activityHours) => activityHours.activityId === activityRealtimeInfo.activityId
		)?.workHours || 0;

	return activityHoursToday;
};

export const addReportDataToActivitiesByFloors = (
	activitiesRealtimeByFloor: IActivityRealtimeInfoByFloors,
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[]
): IActivityByFloor => {
	const activityByFloor: IActivityByFloor = {} as IActivityByFloor;
	Object.values(activitiesRealtimeByFloor).forEach(
		({ floor, activitiesData }: { floor: IBaseFloor; activitiesData: ISingleActivityRealtimeInfo[] }) => {
			const activitiesDataParsed: IActivityByFloorData[] = activitiesData.map((activityRealtimeInfo) => {
				const activityHoursToday: number = getReportActivityHoursToday(
					activityRealtimeInfo,
					activitiesReportData
				);

				return {
					...activityRealtimeInfo,
					workHoursToday: activityHoursToday,
				};
			});

			activityByFloor[floor.floorId] = {
				floor,
				activitiesData: activitiesDataParsed,
			};
		}
	);

	return activityByFloor;
};

export const filterActivityByFloorByUserPreferences = (
	activityByFloor: IActivityByFloor,
	userPreferences: IUserPreferences
): IActivityByFloor => {
	const activitiesByFloorFiltered: IActivityByFloor = {} as IActivityByFloor;

	Object.values(activityByFloor).forEach(({ floor, activitiesData }) => {
		const activitiesDataFiltered: IActivityByFloorData[] = activitiesData.filter(
			(activityData) =>
				!userPreferences.mobile?.filters?.professionIds ||
				userPreferences.mobile?.filters?.professionIds.includes(activityData.profession._id)
		);

		const isFloorIncludedInUserPreferences: boolean =
			!userPreferences.mobile?.filters?.floorIds ||
			userPreferences.mobile?.filters?.floorIds.includes(floor.floorId);

		if (activitiesDataFiltered.length && isFloorIncludedInUserPreferences) {
			activitiesByFloorFiltered[floor.floorId] = {
				floor,
				activitiesData: activitiesDataFiltered,
			};
		}
	});

	return activitiesByFloorFiltered;
};

export const goToActivitiesPage = (history) => {
	history.push(`/activities`);
};

export const goToIssuesPage = (history, groupId: string) => {
	history.push(`/activities/${groupId}/issues`);
};
