import React, { useState } from 'react';
import { debounce } from 'lodash';
import searchIcon from '../../../assets/SearchBar/searchIcon.svg';
import closeIcon from '../../../assets/SearchBar/closeIcon.svg';

import classes from './styles.module.scss';

interface ITextInputFieldProps {
	placeholder: string;
	handleChangeInput: (value: string) => void;
	onClick?: (value: boolean) => void;
	shouldHideTitle?: boolean;
}

const SearchBar = (props: ITextInputFieldProps) => {
	const [inputValue, setInputValue] = useState<string>('');

	const debouncedPropsHandleChangeInput = debounce(props.handleChangeInput, 500);

	const onClick = () => {
		if (props.onClick) {
			if (!props.shouldHideTitle) {
				props.onClick(true);
			}
		}
	};

	const handleInputChange = (e) => {
		debouncedPropsHandleChangeInput(e.target.value);
		setInputValue(e.target.value);
	};

	const handleClearClick = () => {
		props.handleChangeInput('');
		setInputValue('');
		props.onClick && props.onClick(false);
	};

	return (
		<div className={classes.searchContainer} onClick={onClick}>
			{props.shouldHideTitle && (
				<div className={classes.leftSection}>
					<img src={closeIcon} className={classes.clearIcon} onClick={handleClearClick} />
					<input
						placeholder={props.shouldHideTitle ? props.placeholder : ''}
						type="text"
						autoFocus
						className={classes.textInput}
						onChange={handleInputChange}
						value={inputValue}
					/>
				</div>
			)}

			<img className={classes.searchIcon} src={searchIcon} alt="search" />
		</div>
	);
};

export { SearchBar };
