import React from 'react';
import dotIcon from '@shared/assets/icons/dot.svg';
import { translationService } from '../../../index';
import classes from './styles.module.scss';

interface IActivityDialogDisclaimerProps {
	disclaimerPoints: string[];
}

export const ActivityDialogDisclaimer = (props: IActivityDialogDisclaimerProps) => {
	const isOnePoint: boolean = props.disclaimerPoints.length === 1;

	return (
		<p className={classes.secondaryTextDialog}>
			<span className={classes.secondaryTextDialogAttention}>{translationService.get('payAttention')}</span>
			{isOnePoint
				? props.disclaimerPoints[0]
				: props.disclaimerPoints.map((text: string) => (
						<div className={classes.disclaimerPoint}>
							<img alt="" src={dotIcon} /> {text}
						</div>
				  ))}
		</p>
	);
};
