import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISliceToastMessage, IToastMessage, IToastMessageReducerState } from '@shared/interfaces/IToastMessages';
import type { IRootState } from './index';

const initialState: IToastMessageReducerState = {
	messages: [] as ISliceToastMessage[],
};

const toastMessagesSlice = createSlice({
	name: 'toastMessages',
	initialState,
	reducers: {
		setMessage: (state: IToastMessageReducerState, action: PayloadAction<{ message: IToastMessage | null }>) => {
			const randomId: string = Math.random().toString(36).substring(7);
			if (action.payload.message) {
				state.messages = [...state.messages, { id: randomId, ...action.payload.message }];
			}
		},
		clearLastMessage: (state: IToastMessageReducerState) => {
			state.messages = state.messages.slice(1);
		},
		clearAllMessages: (state: IToastMessageReducerState) => {
			state.messages = [];
		},
		clearMessage: (state: IToastMessageReducerState, action: PayloadAction<{ id: string }>) => {
			state.messages = state.messages.filter((message) => message.id !== action.payload.id);
		},
	},
});

export const selectToastMessages = (state: IRootState) => state.toastMessagesReducer.messages;
export const { setMessage, clearAllMessages, clearMessage } = toastMessagesSlice.actions;
export default toastMessagesSlice.reducer;
