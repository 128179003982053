import React from 'react';
import { IFloor } from '@shared/interfaces/IFloor';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/servicesInitializer';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { getShortShortFloorNick } from '@utils/floors.utils';
import { getSpecialAreaIconName } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import classnames from 'classnames';
import classes from './styles.module.scss';

interface IMonitorSearchCardItemProps {
	floor?: IFloor;
	area?: IConfigArea;
	onClick: () => void;
	removeClick?: (e) => void;
}

export const MonitorSearchCardItem = (props: IMonitorSearchCardItemProps) => {
	const shortShortFloorNick: string | undefined = props.floor
		? getShortShortFloorNick(props.floor?.shortFloorNick)
		: undefined;
	const shouldShowIcon: boolean =
		!!(props.floor?.isSpecialFloor || props.floor?.isHoist) || shortShortFloorNick === '' || !shortShortFloorNick;
	const isRtl: boolean = translationService.getIsRtl();
	const arrowIconName: IconNames = (() => {
		if (props.removeClick) {
			return IconNames.close;
		}
		if (isRtl) {
			return IconNames.chevronLeft;
		}
		return IconNames.chevronRight;
	})();

	const getAreaNick = (area: IConfigArea): string => {
		if (!area) return '';
		return `${area.areaNick.includes(area.shortFloorNick) ? area.areaNick : `${area.shortFloorNick}-${area.areaNick}`}`;
	};

	return (
		<div className={classes.MonitorSearchCardItem_container} onClick={props.onClick}>
			<div className={classes.floorDetails}>
				{props.area ? (
					<>
						<div className={classes.floorNumberContainer}>
							<TrusstorIcon iconName={getSpecialAreaIconName(props.area.areaType, props.area.isHoist)} />
						</div>
						<p className={classes.floorNick}>{getAreaNick(props.area)}</p>
					</>
				) : (
					<>
						{shouldShowIcon ? (
							<div className={classnames(classes.floorNumberContainer, classes.isIcon)}>
								<TrusstorIcon iconName={IconNames.pin} />
							</div>
						) : (
							<div className={classes.floorNumberContainer}>
								{!!props.floor?.shortFloorNick && getShortShortFloorNick(props.floor?.shortFloorNick)}
							</div>
						)}
						<p className={classes.floorNick}>{props.floor?.floorNick}</p>
					</>
				)}
			</div>
			<div className={classes.countDataAndArrow}>
				<TrusstorIcon iconName={arrowIconName} onClick={props.removeClick} />
			</div>
		</div>
	);
};
