import React, { useMemo } from 'react';
import { IProfession } from '@shared/interfaces/IProfession';
import { IProfessionDisplaySettings } from '@shared/interfaces/IProfessionDisplaySettings';
import { getShouldShowProfessionDisplayProperties } from '@shared/utils/professions.utils';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { countBy } from 'lodash';
import { ProfessionFilterCard } from '@shared/components/Filters/ProfessionFilterCard/ProfessionFilterCard';
import { translationService } from '../../../../../servicesInitializer';
import { selectProjectId } from '../../../../../store/slices/project.slice';
import {
	addProfession,
	clearProfessions,
	removeProfession,
	selectSelectedProfessions,
} from '../../../../../store/slices/filters.slice';
import { getTradeTranslation } from '../../../../../utils/translations.utils';

interface IProfessionFilterSectionProps {
	professions: IProfession[];
	professionCountObj: { [professionId: string]: number };
}

export const ProfessionFilterSection = (props: IProfessionFilterSectionProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const dispatch = useDispatch();
	const selectedProfessions: IProfession[] = useSelector(selectSelectedProfessions);
	const onClear = () => {
		dispatch(clearProfessions());
	};

	const addSelectedProfession = (profession: IProfession) => {
		dispatch(addProfession({ profession }));
	};

	const removeSelectedProfession = (profession: IProfession) => {
		dispatch(removeProfession({ profession }));
	};

	if (!props.professions.length) {
		return <></>;
	}

	return (
		<CollapsableContainer
			onClear={selectedProfessions.length ? onClear : undefined}
			headerText={translationService.get('professions')}
		>
			{props.professions.map((profession: IProfession) => {
				const professionDisplayProperties: Record<keyof IProfessionDisplaySettings, boolean> =
					getShouldShowProfessionDisplayProperties(profession, projectId);
				const isSelected: boolean = selectedProfessions.some(
					(selectedEntity) => profession._id === selectedEntity._id
				);
				const tradeText: string = professionDisplayProperties.trade ? getTradeTranslation(profession) : '';
				return (
					<ProfessionFilterCard
						key={profession._id}
						isSelected={isSelected}
						handleClick={() =>
							isSelected ? removeSelectedProfession(profession) : addSelectedProfession(profession)
						}
						count={props.professionCountObj?.[profession._id] || 0}
						profession={profession}
						projectId={projectId}
					/>
				);
			})}
		</CollapsableContainer>
	);
};
