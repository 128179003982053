import React from 'react';
import classnames from 'classnames';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/servicesInitializer';
import { ActivityCardReviewChip } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardReviewChip/ActivityCardReviewChip';
import classes from './styles.module.scss';

interface IWidgetActivityCardDetailsProps {
	activity: IMainPageWidgetActivityData;
	isDialog?: boolean;
	showCreateIssuePage: () => void;
	showViewIssuesPage: () => void;
}

export const ActivityCardDetails = (props: IWidgetActivityCardDetailsProps) => {
	const completedAreaSequenceItemCount: number = props.activity.areaSequenceItems.filter((areaSequenceItem) =>
		isAsiComplete(areaSequenceItem.status)
	).length;

	return (
		<div className={classes.body}>
			<div className={classes.bodyHeader}>
				<div>
					<ActivityCardReviewChip asis={props.activity.areaSequenceItems} />
				</div>
				<div className={classes.bottomRight}>
					{!!props.activity.issues?.length && (
						<div
							className={classnames(classes.infoContainer)}
							onClick={(e) => {
								e.stopPropagation();
								props.showViewIssuesPage();
							}}
							data-testid={'issuesButton'}
						>
							<TrusstorIcon
								iconName={IconNames.issues}
								size={IconSize.MEDIUM}
								color={IconColor.Grey600}
							/>
							<div data-testid={'issuesCount'}>{props.activity.issues?.length}</div>
						</div>
					)}
					{!props.activity.issues?.length && (
						<div
							className={classnames(classes.infoContainer)}
							onClick={(e) => {
								e.stopPropagation();
								props.showCreateIssuePage();
							}}
							data-testid={'issuesButton'}
						>
							<TrusstorIcon
								iconName={IconNames.issues}
								size={IconSize.MEDIUM}
								color={IconColor.Grey600}
							/>
							<div data-testid={'issuesCount'}>
								{translationService.get('mobile_activities_addIssue')}
							</div>
						</div>
					)}

					{!!props.activity.areaSequenceItems.length && (
						<div className={classnames(classes.infoContainer)}>
							<TrusstorIcon
								iconName={IconNames.checkCircle}
								size={IconSize.MEDIUM}
								color={IconColor.FeedbackPositiveDark}
							/>
							<div data-testid={'completedCount'}>
								{`${completedAreaSequenceItemCount}/${props.activity.areaSequenceItems.length}`}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
