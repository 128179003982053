import { useSelector } from 'react-redux';
import React from 'react';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { selectProjectId } from '../../../../store/slices/project.slice';
import { getRestrictedAreaCard } from '../../../../utils/notifications.utils';
import { useRestrictedAreaTriggersQuery } from '../../../../hooks/queries/notifications.queries.hooks';
import classes from './styles.module.scss';
import { NotificationCard } from '../NotificationCard/NotificationCard';
import { RestrictedAreaTitleSection } from './RestrictedAreaTitleSection';
import { IRestrictedAreaTrigger } from '../../../../models/IRestrictedAreaTrigger';

export const RestrictedAreaSection = () => {
	const projectId: string = useSelector(selectProjectId)!;
	const { restrictedAreaTriggers, refetchRestrictedAreaTriggers } = useRestrictedAreaTriggersQuery(projectId);

	return (
		<>
			{restrictedAreaTriggers &&
				restrictedAreaTriggers.map((trigger: IRestrictedAreaTrigger, index: number) => {
					const restrictedAreaCard: INotificationCard = getRestrictedAreaCard(
						`RestrictedArea_${trigger._id}`
					);
					return (
						<>
							{index !== 0 && <div className={classes.divider} />}
							<NotificationCard
								key={trigger._id}
								dontShowDescription={true}
								titleElement={
									<RestrictedAreaTitleSection
										trigger={trigger}
										updateTriggersList={refetchRestrictedAreaTriggers}
									/>
								}
								card={restrictedAreaCard}
							/>
						</>
					);
				})}
		</>
	);
};
