import { useQuery } from '@tanstack/react-query';
import { NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { IRestrictedAreaTrigger } from '../../models/IRestrictedAreaTrigger';
import { requestService } from '../../servicesInitializer';

export const useRestrictedAreaTriggersQuery = (
	projectId: string
): {
	restrictedAreaTriggers: IRestrictedAreaTrigger[] | undefined;
	refetchRestrictedAreaTriggers: () => any;
} => {
	const { data: restrictedAreaTriggers, refetch: refetchRestrictedAreaTriggers } = useQuery<
		IRestrictedAreaTrigger[] | undefined
	>(
		['restrictedAreaTriggers', projectId],
		() =>
			requestService.get(
				`/notification/customizedTriggers/${NotificationTypes.RestrictedArea}?projectId=${projectId}`
			),
		{
			useErrorBoundary: false,
		}
	);
	return { restrictedAreaTriggers, refetchRestrictedAreaTriggers };
};
