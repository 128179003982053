import React from 'react';
import classes from './styles.module.scss';

interface IWorkersEmptyState {
	icon: string;
	text: string;
	marginTop?: string;
	style?: { [key: string]: any };
	children?: React.ReactNode;
}

const EmptyStateComponent = (props: IWorkersEmptyState) => (
	<div style={{ marginTop: props.marginTop ?? '128px', ...props.style }} className={classes.emptyPageContainer}>
		<img src={props.icon} alt={props.icon} />
		<p>{props.text}</p>
		{props.children && props.children}
	</div>
);

export { EmptyStateComponent };
