import { SwipeableDrawer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
	ITrusstorButtonSize,
	ITrusstorButtonType,
	TrusstorButton,
} from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { ITrusstorIconButtonStyle } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIconButtonV2 } from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';
import classes from './styles.module.scss';
import { translationService } from '../../../servicesInitializer';

interface IDialogButton {
	text: string;
	onClick: () => void;
	disabled?: boolean;
	loader?: boolean;
}

interface IDrawerDialogProps {
	isOpen: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	primaryButton?: IDialogButton;
	title: React.ReactNode | string;
	customHeader?: React.ReactNode;
	bodyNotScrollable?: boolean;
	maxHeightClass?: string;
}

export const DrawerDialog = (props: IDrawerDialogProps) => {
	const [bodyHeight, setBodyHeight] = React.useState<string>('100%');
	const [topSectionElement, setTopSectionElement] = useState<HTMLDivElement | null>(null);
	const [customHeaderElement, setCustomHeaderElement] = useState<HTMLDivElement | null>(null);
	const isRtl: boolean = translationService.getIsRtl();
	const isTitleString: boolean = typeof props.title === 'string';

	const bodySectionClassname: string = classnames(classes.bodySection, {
		[classes.notScrollable]: props.bodyNotScrollable,
	});
	useEffect(() => {
		const headerHeight: number = topSectionElement?.clientHeight || 0;
		const customHeaderHeight: number = customHeaderElement?.clientHeight || 0;
		const pixelsToRemoveFromBody: number = headerHeight + customHeaderHeight;
		setBodyHeight(`calc(100dvh - ${pixelsToRemoveFromBody}px - 85px)`);
	}, [topSectionElement, customHeaderElement]);

	return (
		<SwipeableDrawer
			classes={{
				paper: classes.dialogPaper,
				root: classes.dialogRoot,
				paperAnchorBottom: classnames(classes.dialogPaperAnchor, props.maxHeightClass),
			}}
			open={props.isOpen}
			anchor={'bottom'}
			onOpen={() => {}}
			onClose={props.onClose}
		>
			<div className={classes.drawerContainer}>
				<div className={classnames(classes.topSection)} ref={setTopSectionElement}>
					<TrusstorIconButtonV2
						rootClassName={classnames(classes.closeButton, { [classes.rtl]: isRtl })}
						onClick={props.onClose}
						style={ITrusstorIconButtonStyle.GHOST}
						iconName={IconNames.close}
					/>

					{isTitleString ? <div className={classes.title}>{props.title}</div> : props.title}

					{(props.primaryButton && (
						<TrusstorButton
							className={classnames(classes.primaryButton, { [classes.rtl]: isRtl })}
							text={props.primaryButton.text}
							disabled={props.primaryButton.disabled}
							handleClick={props.primaryButton.onClick}
							buttonType={ITrusstorButtonType.PRIMARY_COLORED}
							showLoader={props.primaryButton.loader}
							buttonSize={ITrusstorButtonSize.SMALL}
							loaderSize={20}
						/>
					)) || <div></div>}
				</div>
				<div ref={setCustomHeaderElement} className={classes.customHeader}>
					{props.customHeader}
				</div>
				<div className={bodySectionClassname} style={{ maxHeight: bodyHeight }}>
					{props.children}
				</div>
			</div>
		</SwipeableDrawer>
	);
};
