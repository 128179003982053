import { IUser } from '@shared/interfaces/IUser';
import { useQuery } from '@tanstack/react-query';
import { requestService } from '../../servicesInitializer';

export const useUsersQuery = (projectId: string) => {
	const { data: users, refetch: refetchUsers } = useQuery<IUser[]>(
		['users', projectId],
		() => requestService.get(`/entities/user/all/${projectId}?withoutAdmin=true`),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { users, refetchUsers };
};
