import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIconButtonDeprecated } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IIssue } from '@shared/interfaces/IIssue';
import {
	IUseIssuesFilterPropertiesData,
	useIssuesFilterPropertiesData,
} from '@shared/hooks/useIssuesFilterPropertiesData.hook';
import { useSelector } from 'react-redux';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { IUser } from '@shared/interfaces/IUser';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { filterIssuesByProfessionAndFloors } from '@shared/utils/issues.utils';
import { FullPage } from '../../../FullPage/FullPage';
import classes from './styles.module.scss';
import { TrusstorIcon } from '../../../TrusstorIcon/TrusstorIcon';
import { translationService } from '../../../../servicesInitializer';
import { PriorityFilterSection } from './FilterSections/PriorityFilterSection';
import { FloorFilterSection } from './FilterSections/FloorFilterSection';
import { ProfessionFilterSection } from './FilterSections/ProfessionFilterSection';
import { StatusFilterSection } from './FilterSections/StatusFilterSection';
import { AssigneeFilterSection } from './FilterSections/AssigneeFilterSection';
import { useProjectIssuesQuery } from '../../../../hooks/queries/issues.queries.hooks';
import { selectProjectId } from '../../../../store/slices/project.slice';

export const IssuesFilterPage = React.memo(() => {
	const history = useHistory();
	const projectId = useSelector(selectProjectId)!;
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const issues: IIssue[] | undefined = useProjectIssuesQuery(projectId, user.username);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const handleClose = () => {
		history.goBack();
	};

	const getDisplayedIssues = (issuesProp: IIssue[]): IIssue[] => {
		const issuesWithoutInitial: IIssue[] = issuesProp.filter((issue) => !issue.isInitial) || [];
		const displayedIssues: IIssue[] = issuesWithoutInitial.length > 0 ? issuesWithoutInitial : issuesProp;
		return userPreferences
			? filterIssuesByProfessionAndFloors(
					displayedIssues,
					userPreferences!.mobile?.filters.professionIds || [],
					userPreferences.mobile?.filters.floorIds || []
				)
			: displayedIssues;
	};

	const {
		assigneesCountObj,
		prioritiesCountObj,
		statusesCountObj,
		activeProfessions,
		professionCountObj,
		activeAssignees,
		activeFloors,
	}: IUseIssuesFilterPropertiesData = useIssuesFilterPropertiesData(getDisplayedIssues(issues || []));

	return (
		<FullPage rootClassName={classes.noOverflow}>
			<div className={classes.header}>
				<TrusstorIconButtonDeprecated
					rootClassName={classnames(classes.back, { [classes.isRtl]: translationService.getIsRtl() })}
					onClick={handleClose}
					iconElement={<TrusstorIcon iconName={IconNames.close} />}
				/>
				<div className={classes.title}>{translationService.get('filters')}</div>
			</div>
			<div className={classes.content}>
				<PriorityFilterSection prioritiesCountObj={prioritiesCountObj} />
				<StatusFilterSection statusesCountObj={statusesCountObj} />
				<ProfessionFilterSection professions={activeProfessions} professionCountObj={professionCountObj} />
				<AssigneeFilterSection assignees={activeAssignees} assigneesCountObj={assigneesCountObj} />
				<FloorFilterSection floors={activeFloors} />
			</div>
		</FullPage>
	);
});
