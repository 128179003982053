import React, { useEffect, useState } from 'react';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import classes from './styles.module.scss';
import { IActivityByProgress } from '../../../../../interfaces/IActivityByProgress';
import { addLocationAndReportDataToActivitiesByProgress } from '../../../../../utils/activities.utils';
import { ProgressViewActivityCard } from '../../../../ProgressViewProfessionCard/ProgressViewActivityCard';
import { selectUserPreference } from '../../../../../store/slices/userPreferences.slice';
import { IActivityRealtimeInfo } from '../../../../../interfaces/IActivityRealtimeInfo';
import { IProfessionWithActiveTags } from '../../../../../interfaces/IProfessionWithActiveTags';
import { ActivityPageSortOptions } from '../../../../../models/ActivityPageSortOptions';

interface ICompletedActivitiesByProgressProps {
	relevantActivities: IActivityRealtimeInfo[] | undefined;
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[] | undefined;
	activitiesLocationData: IProfessionWithActiveTags[] | undefined;
	refetchAllData: () => Promise<void>;
	personalViewState: ActivityPageSortOptions;
}

export const CompletedActivitiesByProgress = (props: ICompletedActivitiesByProgressProps) => {
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const [completedActivities, setCompletedActivities] = useState<IActivityByProgress[]>([]);

	const getCompletedActivitiesData = (): IActivityByProgress[] | null => {
		if (!props.relevantActivities || !props.activitiesReportData || !props.activitiesLocationData) return null;

		return addLocationAndReportDataToActivitiesByProgress(
			props.relevantActivities,
			props.activitiesReportData,
			props.activitiesLocationData
		);
	};

	useEffect(() => {
		const completedActivitiesByProgress: IActivityByProgress[] | null = getCompletedActivitiesData();
		if (!completedActivitiesByProgress) {
			return;
		}

		setCompletedActivities(completedActivitiesByProgress);
	}, [
		props.relevantActivities,
		props.activitiesLocationData,
		props.activitiesReportData,
		props.personalViewState,
		userPreferences,
	]);

	return (
		<div className={classes.activitiesContainer}>
			{completedActivities.map((activity) => (
				<ProgressViewActivityCard
					activity={activity}
					refetchDataCallback={props.refetchAllData}
					key={activity.groupId}
				/>
			))}
		</div>
	);
};
