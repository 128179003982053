import React, { useEffect } from 'react';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import settingsIcon from '@shared/assets/icons/settings_icon.svg';
import { IProfessionWithActiveTags } from '../../../../interfaces/IProfessionWithActiveTags';
import { IActivityRealtimeInfo } from '../../../../interfaces/IActivityRealtimeInfo';
import { ActivityPageViewTypes } from '../../../../models/ActivityPageViewTypes';
import { ActivityPageSortOptions } from '../../../../models/ActivityPageSortOptions';
import { CompletedActivitiesByFloor } from '../../../CompletedActivitiesByFloor/CompletedActivitiesByFloor';
import { EmptyStateComponent } from '../../../EmptyStateComponent/EmptyStateComponent';
import { translationService } from '../../../../index';
import { CompletedActivitiesByProgress } from './CompletedActivitiesByProgress/CompletedActivitiesByProgress';
import classes from './styles.module.scss';

interface ICompletedActivitiesProps {
	relevantActivities: IActivityRealtimeInfo[] | undefined;
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[] | undefined;
	activitiesLocationData: IProfessionWithActiveTags[] | undefined;
	refetchAllData: () => Promise<void>;
	shouldNotShowProgressFloorsViewOption: (value: boolean) => void;
	personalViewSortOption: ActivityPageSortOptions;
	progressFloorsViewState: ActivityPageViewTypes;
	isUserPreferenceEmptyState: boolean;
}

const CompletedActivities = (props: ICompletedActivitiesProps) => {
	useEffect(() => {
		props.refetchAllData();
	}, []);

	const getComponentToRender = () =>
		props.progressFloorsViewState === ActivityPageViewTypes.BY_PROGRESS ? (
			<CompletedActivitiesByProgress
				activitiesLocationData={props.activitiesLocationData}
				activitiesReportData={props.activitiesReportData}
				relevantActivities={props.relevantActivities}
				refetchAllData={props.refetchAllData}
				personalViewState={props.personalViewSortOption}
			/>
		) : (
			<CompletedActivitiesByFloor
				activitiesReportData={props.activitiesReportData}
				isPersonalView={props.personalViewSortOption === ActivityPageSortOptions.PERSONAL_VIEW}
				refetchAllData={props.refetchAllData}
			/>
		);

	const noCompletedActivityEmptyState: boolean = props.relevantActivities?.length === 0;

	props.shouldNotShowProgressFloorsViewOption(noCompletedActivityEmptyState || props.isUserPreferenceEmptyState);
	return (
		<div className={classes.completedActivitiesContainer}>
			{props.isUserPreferenceEmptyState || noCompletedActivityEmptyState ? (
				<EmptyStateComponent
					icon={settingsIcon}
					text={translationService.get(
						props.isUserPreferenceEmptyState
							? 'personalViewEmptyStateText'
							: 'completedActivitiesEmptyState'
					)}
					style={{ maxWidth: '250px', marginTop: 10 }}
				/>
			) : (
				getComponentToRender()
			)}
		</div>
	);
};

export { CompletedActivities };
