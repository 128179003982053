import React from 'react';
import classname from 'classnames';
import classes from './styles.module.scss';

interface IFullPageProps {
	children?: React.ReactNode;
	rootClassName?: string;
}

export const FullPage = (props: IFullPageProps) => (
	<div className={classname(classes.fullPage, props.rootClassName)}>{props.children}</div>
);
