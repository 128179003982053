import React from 'react';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { translationService } from '@src/servicesInitializer';
import { ExpandedSection } from '@shared/components/ExpandedSection/ExpandedSection';
import { ActivityCard } from '../ActivityCard/ActivityCard';

interface IWidgetOpenActivitiesListProps {
	overdueActivities: IMainPageWidgetActivityData[];
	dueInSevenDaysActivities: IMainPageWidgetActivityData[];
	futureActivities: IMainPageWidgetActivityData[];
	dueTodayActivities: IMainPageWidgetActivityData[];
	queryKeys: any[];
}

export const OpenActivitiesList = (props: IWidgetOpenActivitiesListProps) => (
	<>
		{!!props.overdueActivities?.length && (
			<ExpandedSection
				text={`${translationService.get('activities_widget_expanded_overdue')} (${
					props.overdueActivities.length
				})`}
			>
				{props.overdueActivities.map((activity) => (
					<ActivityCard key={activity.groupId} activity={activity} queryKeys={props.queryKeys} />
				))}
			</ExpandedSection>
		)}
		{!!props.dueTodayActivities?.length && (
			<ExpandedSection
				text={`${translationService.get('activities_widget_expanded_dueToday')} (${
					props.dueTodayActivities.length
				})`}
			>
				{props.dueTodayActivities.map((activity) => (
					<ActivityCard key={activity.groupId} activity={activity} queryKeys={props.queryKeys} />
				))}
			</ExpandedSection>
		)}
		{!!props.dueInSevenDaysActivities?.length && (
			<ExpandedSection
				text={`${translationService.get('activities_widget_expanded_dueIn7days')} (${
					props.dueInSevenDaysActivities.length
				})`}
			>
				{props.dueInSevenDaysActivities.map((activity) => (
					<ActivityCard key={activity.groupId} activity={activity} queryKeys={props.queryKeys} />
				))}
			</ExpandedSection>
		)}
		{!!props.futureActivities?.length && (
			<ExpandedSection
				text={`${translationService.get('activities_widget_expanded_after7days')} (${
					props.futureActivities.length
				})`}
			>
				{props.futureActivities.map((activity) => (
					<ActivityCard key={activity.groupId} activity={activity} queryKeys={props.queryKeys} />
				))}
			</ExpandedSection>
		)}
	</>
);
