import { ILanguageAndDisplayName, Languages } from '@shared/constants/languages';
import React from 'react';
import { requestService, translationService } from '../../../servicesInitializer';
import { TrusstorDrawer } from '../../TrusstorDrawer/TrusstorDrawer';
import classes from './styles.module.scss';
import { useSelector } from 'react-redux';
import { selectLoggedUserDetails } from '../../../store/slices/login.slice';
import { IUser } from '@shared/interfaces/IUser';

interface ILanguagesDrawerProps {
	onClose: () => void;
	isOpen: boolean;
}

export const updateUserLanguage = async (username: string, language: Languages) => {
	await requestService.put(`/entities/userPreferences/${username}`, {
		body: {
			language,
		},
	});
	window.location.reload();
};

export const LanguagesDrawer = (props: ILanguagesDrawerProps) => {
	const languagesAndDisplayNames: ILanguageAndDisplayName[] = translationService.getLanguagesAndDisplayNames();
	const loggedUser: IUser = useSelector(selectLoggedUserDetails)!;
	const currentLanguage: string = translationService.getChosenLanguage();

	const onLanguageClick = (language: Languages): void => {
		const isCurrentLanguage: boolean = currentLanguage === language;
		if (isCurrentLanguage) {
			return;
		}
		updateUserLanguage(loggedUser.username, language);
	};

	return (
		<TrusstorDrawer
			topSection={
				<div>
					<p className={classes.selectLanguageText}>{translationService.get('selectLanguage')}</p>
				</div>
			}
			onClose={props.onClose}
			isOpen={props.isOpen}
		>
			<div className={classes.content}>
				{languagesAndDisplayNames.map((lang) => {
					const isCurrentLanguage: boolean = currentLanguage === lang.language;
					return (
						<div
							className={`${classes.languageCard} ${isCurrentLanguage ? classes.currentLanguage : ''}`}
							onClick={() => onLanguageClick(lang.language)}
						>
							<div className={classes.languageText}>{lang.displayName}</div>
						</div>
					);
				})}
			</div>
		</TrusstorDrawer>
	);
};
