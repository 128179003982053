import preferencesIcon from '../../../assets/preferencesIcon.svg';
import signOutIcon from '../../../assets/ProfilePage/signOutIcon.svg';
import sideMenuTag from '../../../assets/ProfilePage/submenuTag.svg';
import bellMenuTag from '../../../assets/ProfilePage/bell.svg';
import equipmentThickIcon from '../../../assets/Navbar/EquipmentThickIcon.svg';
import globeIcon from '../../../assets/ProfilePage/globe.svg';
import { AppRoutes } from '../../../constants/appRoutes.enum';
import { projectAccessLevelService, translationService, userAccessLevelService } from '../../../servicesInitializer';

export interface IUserProfileActions {
	linkName: string;
	icon: string;
	secondText?: string;
	linkPath?: AppRoutes;
	onClick?: () => void;
	withArrow?: boolean;
}

export const getUserProfilePageActions = (onLanguageClick: () => void): IUserProfileActions[] => {
	const currentLanguageDisplayName: string = translationService.getChosenLanguageDisplayName();
	return [
		{
			linkName: 'preferences',
			linkPath: AppRoutes.preferences,
			icon: preferencesIcon,
			withArrow: true,
		},
		{
			linkName: 'alerts',
			linkPath: AppRoutes.notificationsSettings,
			icon: bellMenuTag,
			withArrow: true,
		},
		...(projectAccessLevelService.hasAccess('assignTag') && userAccessLevelService.hasAccess('assignTag')
			? [
					{
						linkName: 'assignTag',
						linkPath: AppRoutes.assignTag,
						icon: sideMenuTag,
						withArrow: true,
					},
			  ]
			: []),
		...(projectAccessLevelService.hasAccess('equipmentPage')
			? [
					{
						linkName: 'equipment',
						linkPath: AppRoutes.equipment,
						icon: equipmentThickIcon,
						withArrow: true,
					},
			  ]
			: []),
		{
			linkName: 'language',
			onClick: () => onLanguageClick(),
			icon: globeIcon,
			secondText: currentLanguageDisplayName,
			withArrow: true,
		},
		{
			linkName: 'logout',
			linkPath: AppRoutes.logout,
			icon: signOutIcon,
		},
	];
};
