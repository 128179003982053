import React from 'react';
import classnames from 'classnames';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/index';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { splitArrayInMiddle } from '@shared/utils/array.utils';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { getResourceIcon, getSpecialAreaIconName } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { IFloor } from '@shared/interfaces/IFloor';
import { useAreasByFloorIdDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { getPermittedMonitorResources } from '@utils/permissions.utils';
import { getShortShortFloorNick } from '@utils/floors.utils';
import classes from './styles.module.scss';

interface ILocationCountSummaryCardProps {
	area?: IConfigArea;
	floor: IFloor;
	onClick: () => void;
	countData?: Record<MonitorResourcesEnum, number>;
}

export const LocationCountSummaryCard = (props: ILocationCountSummaryCardProps) => {
	const isHoist: boolean = !!props.floor?.isHoist;
	const isSpecialFloor: boolean = !!props.floor?.isSpecialFloor;
	const areas: IConfigArea[] | undefined = props.floor ? useAreasByFloorIdDataQuery(props.floor.floorId) : undefined;
	const isAreaOrSpeacialFloor: boolean = !!props.area || isSpecialFloor || isHoist;
	const shortShortFloorNick: string = getShortShortFloorNick(props.floor!.shortFloorNick);
	const shouldShowIcon: boolean = isAreaOrSpeacialFloor || shortShortFloorNick === '';
	const resources: MonitorResourcesEnum[] = getPermittedMonitorResources();
	const isRtl: boolean = translationService.getIsRtl();
	const arrowIconName: IconNames = isRtl ? IconNames.chevronLeft : IconNames.chevronRight;
	const validResources: MonitorResourcesEnum[] = resources.filter((resource) => !!props.countData?.[resource]);
	const areThere4OrMoreCountDataElements: boolean = validResources.length >= 4;
	const [firstValidElementsSplit, secondValidElementsSplit]: [MonitorResourcesEnum[], MonitorResourcesEnum[]] =
		splitArrayInMiddle(validResources);

	const getResourceElement = (resource: MonitorResourcesEnum) => {
		const resourceCount = props.countData![resource];
		return (
			<div className={classes.resourceCountAndIcon}>
				<p className={classes.countText}>{resourceCount}</p>
				<TrusstorIcon iconName={getResourceIcon(resource)} />
			</div>
		);
	};

	const getAreaNick = () => {
		if (props.area) {
			return props.area.areaNick;
		}
		if (!isSpecialFloor) {
			return props.floor.floorNick;
		}
		const areThereMultipleAreas: boolean = !!areas?.length && areas?.length > 1;
		if (areThereMultipleAreas) {
			return props.floor.floorNick;
		}
		return areas?.[0]?.areaNick;
	};

	return (
		<div className={classes.locationCountSummaryCardContainer} onClick={props.onClick}>
			<div className={classes.floorDetails}>
				{shouldShowIcon ? (
					<div className={classnames(classes.floorNumberContainer, classes.isIcon)}>
						<TrusstorIcon
							iconName={getSpecialAreaIconName(props.area?.areaType || areas?.[0]?.areaType, isHoist)}
						/>
					</div>
				) : (
					<div className={classes.floorNumberContainer}>
						{getShortShortFloorNick(props.floor!.shortFloorNick)}
					</div>
				)}
				<p className={classes.floorNick}>{getAreaNick()}</p>
			</div>
			<div className={classes.countDataAndArrow}>
				<div
					className={classnames(classes.countDataContainer, {
						[classes.fourElements]: areThere4OrMoreCountDataElements,
					})}
				>
					{firstValidElementsSplit.length > 0 && (
						<div className={classes.elementsPartitionContainer}>
							{firstValidElementsSplit.map(getResourceElement)}
						</div>
					)}
					{secondValidElementsSplit.length > 0 && (
						<div className={classes.elementsPartitionContainer}>
							{secondValidElementsSplit.map(getResourceElement)}
						</div>
					)}
				</div>
				<TrusstorIcon iconName={arrowIconName} />
			</div>
		</div>
	);
};
