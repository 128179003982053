import React from 'react';
import dangerousActivityIcon from '@shared/assets/icons/dangerousActivityIcon.svg';
import workerIcon from '@shared/assets/icons/workersBlueIcon.svg';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { threeDotsVertical } from '@shared/assets/icons';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { ISingleActivityRealtimeInfoShared } from '@shared/interfaces/ISingleActivityRealtimeInfoShared';
import { IProfession } from '@shared/interfaces/IProfession';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { FloorHeader } from '../../../WorkersPageFloorHeader/WorkersPageFloorHeader';
import classes from './styles.module.scss';
import { IActivityByFloorData } from '../../../../interfaces/IActivityByFloor';
import { translationService, userAccessLevelService } from '../../../../index';

interface IByFloorsCardProp {
	activitiesData: IActivityByFloorData[] | ISingleActivityRealtimeInfoShared<IProfession>[];
	floor: IBaseFloor;
	displayPartialCard?: boolean;
	setSelectedActivityForAction?: (activity: IActivityByFloorData) => void;
	clearMargin?: boolean;
}

export const ActivitiesFloorCard = (props: IByFloorsCardProp) => {
	const projectId: string = useSelector(selectProjectId)!;
	return (
		<div className={classes.cardWrapper} style={{ margin: props.clearMargin ? 0 : '8px 10px' }}>
			<FloorHeader floor={props.floor}></FloorHeader>
			{props.activitiesData.map((activity, index) => (
				<div key={activity.groupId} className={classes.activityInnerCardWrapper}>
					{!props.displayPartialCard && (
						<div className={classes.professionHeaderContainer}>
							<ProfessionDisplayWithTradeIcon
								contractorTextClassname={classes.contractorHeader}
								isNarrow
								profession={activity.profession}
								projectId={projectId}
							/>
						</div>
					)}
					<div className={classes.activityInfoWrapper}>
						<div className={classes.activityInfo}>
							{activity.dangerousActivity && (
								<img src={dangerousActivityIcon} style={{ width: 15 }} alt={'dangerousActivity'} />
							)}
							<div className={classes.activityDescription}>{activity.description}</div>
						</div>
						<div className={classes.activityDetails}>
							{activity.tags.length > 0 && activity.groupStatus !== ActivityGroupStatus.complete && (
								<div className={classes.iconWithNumber}>
									<img src={workerIcon} alt={'worker icon'} />
									<div>{activity.tags.length}</div>
								</div>
							)}
							{!props.displayPartialCard && (
								<div className={classes.workHours}>
									<div>{activity.workHoursToday}</div>
									<div className={classes.h}>{translationService.get('hoursAbbreviated')}</div>
								</div>
							)}
							{!props.displayPartialCard &&
								activity.groupStatus !== ActivityGroupStatus.complete &&
								userAccessLevelService.hasAccess('activitiesActions') && (
									<div className={classes.threeDotsWrapper}>
										<img
											onClick={() =>
												props.setSelectedActivityForAction &&
												props.setSelectedActivityForAction(activity)
											}
											src={threeDotsVertical}
											alt={'three dots vertical'}
										/>
									</div>
								)}
						</div>
					</div>
					{index < props.activitiesData.length - 1 && <div className={classes.lineDivider}></div>}
				</div>
			))}
		</div>
	);
};
