import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { IResourceCountByLocation } from '@shared/interfaces/IResourceCountByLocation';
import { requestService } from '@src/index';
import { useQuery } from '@tanstack/react-query';

export interface IResourceCountData {
	isLoaded: boolean;
	countByLocation: IResourceCountByLocation;
}

export const getResourceUrl = (resource: MonitorResourcesEnum): string => {
	switch (resource) {
		case MonitorResourcesEnum.PERSONNEL:
			return `/location/merged/tags/byLocation/count`;
		case MonitorResourcesEnum.EQUIPMENT:
			return `/location/merged/equipment/byLocation/count`;
		case MonitorResourcesEnum.ACTIVITIES:
			return `/activities/groups/widgetActivities/byLocation/count`;
		case MonitorResourcesEnum.ISSUES:
			return `/issues/byLocation/count`;
	}
};

export const useResourceCountByLocationQuery = (
	resource: MonitorResourcesEnum,
	projectId: string,
	floorIds?: string[],
	professionIds?: string[] | null
): IResourceCountData => {
	const queryKey: string = `${QueryKeys.countResourcesByLocation}-${resource}`;
	const url: string = getResourceUrl(resource);
	const { data: countByLocation, isFetched } = useQuery<IResourceCountByLocation>(
		[queryKey, projectId, floorIds, professionIds],
		() =>
			requestService.get(url, {
				params: {
					projectId,
					floorIds,
					professionIds,
				},
			}),
		{
			initialData: {
				totalCount: 0,
				floors: {},
			},
		}
	);

	return {
		isLoaded: isFetched,
		countByLocation,
	};
};
