import React from 'react';
import classes from './styles.module.scss';

interface IPageHeaderProps {
	children?: React.ReactNode;
	title: string;
	shouldHideBottomLine?: boolean;
	shouldHideTitle?: boolean;
}

export const PageHeader = (props: IPageHeaderProps) => (
	<>
		<div className={classes.pageHeader}>
			{!props.shouldHideTitle && <div className={classes.title}>{props.title}</div>}
			{props.children && (props.shouldHideTitle ? props.children : <div>{props.children}</div>)}
		</div>
		{!props.shouldHideBottomLine && <hr className={classes.hr} />}
	</>
);
