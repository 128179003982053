import React from 'react';
import { IActiveManager } from '@shared/interfaces/IActiveManager';
import { groupBy } from 'lodash';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { translationService } from '../../servicesInitializer';
import { useActiveManagersQuery } from '../../hooks/queries/managers.queries.hooks';
import classes from './styles.module.scss';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import { WorkerPageViewTypes } from '../../models/WorkerPageViewTypes';

const ActiveManagersList = ({ viewType }) => {
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const activeManagers: IActiveManager[] = useActiveManagersQuery();
	const userPreferencesFloorIds: string[] | null = userPreferences?.mobile?.filters?.floorIds || null;
	const filteredActiveManagers: IActiveManager[] = activeManagers.filter(
		(manager) =>
			manager.currentFloor &&
			(viewType === WorkerPageViewTypes.PERSONAL_VIEW
				? userPreferencesFloorIds?.includes(manager.currentFloor.floorId)
				: true)
	);

	const getManagersByFloors = (): [string, IActiveManager[]][] => {
		if (!activeManagers || activeManagers.length === 0) return [];
		return Object.entries(groupBy(filteredActiveManagers, (manager) => manager.currentFloor?.floorNick));
	};

	const managersPerFloor: [string, IActiveManager[]][] = getManagersByFloors();

	const managerLength: number = filteredActiveManagers.length;
	return (
		<div className={classes.managersContainer}>
			<div className={classes.title}>
				<div className={classes.titleText}>{translationService.get('managers')}</div>
				<div className={classes.managerLength}>{managerLength}</div>
			</div>
			{managersPerFloor.map(([floorNick, managers]) => (
				<div className={classes.floorContainer}>
					<div className={classes.floorTitle}>{floorNick}</div>
					<div className={classes.floorManagers}>
						{managers.map((manager) => (
							<div className={classes.managerName}>{manager.name}</div>
						))}
					</div>
				</div>
			))}
		</div>
	);
};

export { ActiveManagersList };
