import { useQuery } from '@tanstack/react-query';
import { IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { requestService } from '../../servicesInitializer';

export const useUserSettingsQuery = (
	username: string,
	projectId: string
): {
	userSettings: IUserNotificationSettings | undefined;
	refetchUserSettings: () => any;
} => {
	const { data: userSettings, refetch: refetchUserSettings } = useQuery<IUserNotificationSettings>(
		['userSettings', projectId, username],
		() => requestService.get(`/notification/settings/user?projectId=${projectId}&username=${username}`),
		{
			useErrorBoundary: false,
		}
	);
	return { userSettings, refetchUserSettings };
};
