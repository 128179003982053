import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { IProfessionWorkerData } from '@interfaces/IProfessionWorkerData';
import { IWorkerMergedTag } from '@interfaces/IWorkerMergedTag';
import { useCustomizedProfessions } from '@src/hooks/monitor.hooks';
import { requestService } from '../../servicesInitializer';
import { selectProjectId } from '../../store/slices/project.slice';
import { IFloorWorkerData } from '../../interfaces/IFloorWorkerData';
import { WorkersPageSortOptions } from '../../models/WorkerPageSortOptions';

export const useWorkersDataByProfessions = (
	sortOption: WorkersPageSortOptions | undefined,
	professionIds?: string[] | null
) => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const professionIdsQueryParams: string = !professionIds?.length
		? ''
		: `&${professionIds.map((professionId) => `professionsIds[]=${professionId}`).join('&')}`;
	const byProfessionsUrl: string = `/location/mobile/workers/byProfessions/?projectId=${projectId}${professionIdsQueryParams}`;
	const {
		data: professionsData,
		isLoading,
		isError,
	} = useQuery<IProfessionWorkerData[]>(
		['workersProfessionsData', projectId, professionIds],
		() => requestService.get(byProfessionsUrl),
		{
			enabled: sortOption === WorkersPageSortOptions.BY_PROFESSIONS,
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { professionsData, isLoading, isError };
};

export const useWorkersDataByFloors = (sortOption: WorkersPageSortOptions | undefined, floorIds?: string[] | null) => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const floorIdsQueryParams: string = !floorIds?.length
		? ''
		: `&${floorIds.map((floorId) => `floorsIds[]=${floorId}`).join('&')}`;
	const byFloorsUrl: string = `/location/mobile/workers/byFloors/?projectId=${projectId}${floorIdsQueryParams}`;
	const {
		data: floorsData,
		isLoading,
		isError,
	} = useQuery<IFloorWorkerData[]>(['workersFloorData', projectId, floorIds], () => requestService.get(byFloorsUrl), {
		enabled: sortOption === WorkersPageSortOptions.BY_FLOORS,
		useErrorBoundary: true,
		initialData: [],
	});
	return { floorsData, isLoading, isError };
};

export const useWorkersTagsQuery = (
	floorId?: string,
	areaId?: string
): { workersTags: IWorkerMergedTag[]; isWorkersLoading: boolean } => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const professionIds: string[] | null = useCustomizedProfessions();
	const { data: workersTags, isInitialLoading: isWorkersLoading } = useQuery<IWorkerMergedTag[]>(
		['workersTags', projectId, professionIds, floorId, areaId],
		() =>
			requestService.get(`/location/merged/workers?projectId=${projectId}`, {
				params: {
					...(professionIds?.length && { professionsIds: professionIds }),
					...(floorId && { floorId }),
					...(areaId && { areaId }),
				},
			}),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { workersTags, isWorkersLoading };
};
