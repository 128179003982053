import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IFloor } from '@shared/interfaces/IFloor';
import {
	IRecentSearchLocationItem,
	MonitorSearchRecentResultsLocalStorageService,
} from '@src/services/monitorSearchRecentResultsLocalStorage.service';
import { uniqueId } from 'lodash';
import React from 'react';

export const getFilteredAreasBySearchInput = (areas: IConfigArea[], searchInput: string): IConfigArea[] =>
	areas.filter(
		(area) =>
			area.areaNick.toLowerCase().includes(searchInput.toLowerCase()) || area.shortFloorNick.includes(searchInput)
	);

export const getFilteredFloorsBySearchInput = (floors: IFloor[], searchInput: string): IFloor[] =>
	floors.filter((floor) => floor.floorNick.toLowerCase().includes(searchInput.toLowerCase()));

export const removeSearchItemFromState = (
	setRecentSearchItems: React.Dispatch<React.SetStateAction<IRecentSearchLocationItem[] | undefined>>,
	id: string
) => {
	setRecentSearchItems((prev) => prev?.filter((item) => item.id !== id) || []);
};

export const removeItemFromSearchResults = (
	projectId: string,
	setRecentSearchItems: React.Dispatch<React.SetStateAction<IRecentSearchLocationItem[] | undefined>>,
	id: string
) => {
	removeSearchItemFromState(setRecentSearchItems, id);
	MonitorSearchRecentResultsLocalStorageService.removeRecentSearchResultItem(projectId, id);
};

export const addItemToRecentSearchResults = (
	projectId: string,
	{ floor, area }: { floor?: IFloor; area?: IConfigArea },
	recentSearchItems: IRecentSearchLocationItem[] | undefined,
	setRecentSearchItems: React.Dispatch<React.SetStateAction<IRecentSearchLocationItem[] | undefined>>
) => {
	const itemToRemove: IRecentSearchLocationItem | undefined = recentSearchItems?.find((item) => {
		if (floor) return item.floorId === floor.floorId;
		if (area) return item.areaId === area.areaId;
		return true;
	});

	const newItem: IRecentSearchLocationItem = {
		id: uniqueId(),
		floorId: floor?.floorId,
		areaId: area?.areaId,
	};

	if (itemToRemove) {
		removeItemFromSearchResults(projectId, setRecentSearchItems, itemToRemove.id);
	}

	setRecentSearchItems((prev) => (!prev ? [newItem] : [newItem, ...prev]));
	MonitorSearchRecentResultsLocalStorageService.addRecentSearchResultItem(projectId, newItem);
};
