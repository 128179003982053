import React from 'react';
import { IssuePriority } from '@shared/interfaces/IIssueShared';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { IssuePriorityFilterCard } from '@shared/components/Filters/IssuePriorityFilterCard/IssuePriorityFilterCard';
import { translationService } from '../../../../../servicesInitializer';
import {
	addPriority,
	clearPriorities,
	removePriority,
	selectSelectedPriorities,
} from '../../../../../store/slices/filters.slice';

interface IPriorityFilterSectionProps {
	prioritiesCountObj?: { [issuePriorityId: string]: number };
}

export const PriorityFilterSection = (props: IPriorityFilterSectionProps) => {
	const dispatch = useDispatch();
	const selectedPriorities: IssuePriority[] = useSelector(selectSelectedPriorities);
	const onClear = () => {
		dispatch(clearPriorities());
	};

	const addSelectedPriority = (priority: IssuePriority) => {
		dispatch(addPriority({ priority }));
	};

	const removeSelectedPriority = (priority: IssuePriority) => {
		dispatch(removePriority({ priority }));
	};

	return (
		<CollapsableContainer
			headerText={translationService.get('priority')}
			onClear={selectedPriorities.length ? onClear : undefined}
		>
			{Object.values(IssuePriority).map((priority: IssuePriority) => {
				const isSelected = selectedPriorities.includes(priority);
				return (
					<IssuePriorityFilterCard
						key={priority}
						isSelected={isSelected}
						handleClick={() =>
							isSelected ? removeSelectedPriority(priority) : addSelectedPriority(priority)
						}
						priority={priority}
						count={props.prioritiesCountObj?.[priority]}
					/>
				);
			})}
		</CollapsableContainer>
	);
};
