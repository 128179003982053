import React from 'react';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { INotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { preDefinedFrequenciesMapping } from '@shared/constants/notifications.constants';
import { IToastMessage, IToastMessageTypes } from '@shared/interfaces/IToastMessages';
import { useDispatch, useSelector } from 'react-redux';
import { requestService, translationService } from '../../../../servicesInitializer';
import { NotificationFrequencyDropdown } from './NotificationFrequencyDropdown';
import { NotificationChannelSwitch } from './NotificationChannelSwitch';
import classes from './styles.module.scss';
import { useUserSettingsQuery } from '../../../../hooks/queries/notificationEngine.queries.hooks';
import { notificationsSettingsPreferencesEngagement_BI } from '../../../../utils/bi.utils';
import { setMessage } from '../../../../store/slices/toastMessage.slice';
import { selectProjectId } from '../../../../store/slices/project.slice';
import { selectLoggedUser } from '../../../../store/slices/login.slice';

interface INotificationCardProps {
	dontShowDescription?: boolean;
	card: INotificationCard;
	titleElement?: React.ReactElement;
}

export const NotificationCard = (props: INotificationCardProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const username: string | undefined = useSelector(selectLoggedUser)?.userDetails.username;
	const loggedUser = useSelector(selectLoggedUser)!;
	const dispatch = useDispatch();

	const { userSettings, refetchUserSettings } = useUserSettingsQuery(loggedUser.userDetails.username, projectId);

	const handleSettingsChange = async (
		value: string | boolean,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	): Promise<void> => {
		try {
			if (!frequency && !channelName) return;
			await requestService.post(
				`/notification/settings/user?username=${username}&projectId=${projectId}&notificationType=${notificationType}`,
				{
					body: {
						setting: {
							...(frequency && { frequency: preDefinedFrequenciesMapping[value as string] }),
							...(channelName && { channels: { [channelName]: value } }),
						},
					},
				}
			);
			const parameterChanged: string | NotificationChannelTypes = frequency ? 'frequency' : channelName!;
			notificationsSettingsPreferencesEngagement_BI(notificationType, parameterChanged);
		} catch (error) {
			const message: IToastMessage = {
				text: translationService.get('failedToChangeSettings'),
				type: IToastMessageTypes.WARNING,
			};
			dispatch(setMessage({ message }));
		}
		refetchUserSettings();
	};

	return (
		<div key={`notificationCard_${props.card.type}`}>
			<div className={classes.singleCardSection}>
				<div className={classes.textSection}>
					<div>{translationService.get(props.card.type)}</div>
					{props.card.description && !props.dontShowDescription && (
						<div className={classes.description}>{translationService.get(props.card.description)}</div>
					)}
					{props.titleElement && props.titleElement}
				</div>
				{props.card.frequency && (
					<NotificationFrequencyDropdown
						card={props.card}
						handleSettingsChange={handleSettingsChange}
						userSettings={userSettings}
					/>
				)}
				<div className={classes.actions}>
					{props.card.channels.map((channelName) => (
						<NotificationChannelSwitch
							channelName={channelName}
							card={props.card}
							handleSettingsChange={handleSettingsChange}
							userSettings={userSettings}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
