import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';
import { useQuery } from '@tanstack/react-query';
import { compact, flatMap } from 'lodash';
import { useSelector } from 'react-redux';
import { IActivityByProgress, IActivityRealTimeFloorInfoMobile } from 'src/interfaces/IActivityByProgress';
import { IActivityGroup } from '@shared/interfaces/IActivityGroup';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { IMainPageWidgetFloor } from '@shared/interfaces/IMainPageWidgetFloor';
import { requestService } from '../../index';
import { IActivityRealtimeInfoByFloors } from '../../interfaces/IActivityRealtimeInfoByFloors';
import { selectProjectId } from '../../store/slices/project.slice';
import { useAreasDataQuery } from './areas.queries.hooks';

export const useActivitiesRealtimeDataByFloorsQuery = (
	groupStatusesToFilterBy: ActivityGroupStatus[]
): {
	activitiesRealtimeByFloor: IActivityRealtimeInfoByFloors | undefined;
	activitiesRealtimeByFloorLoading: boolean;
	refetchFloors: () => any;
} => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const groupStatuses: string = groupStatusesToFilterBy.join(',');
	const {
		data: activitiesRealtimeByFloor,
		isLoading,
		refetch,
	} = useQuery<IActivityRealtimeInfoByFloors>(['activitiesRealtimeByFloor', projectId], () =>
		requestService.get(
			`/activities/groups/onGoingAndCompleteTodayRealtimeActivitiesInfoByFloors?groupStatus=${groupStatuses}`
		)
	);

	const refetchFloors = () => refetch();
	return { activitiesRealtimeByFloor, activitiesRealtimeByFloorLoading: isLoading, refetchFloors };
};

export const useActivityLocationsQuery = (
	activity: IActivityByProgress
): {
	activityLocations: IBaseAreaBaseFloor[];
} => {
	const areas: IBaseAreaBaseFloor[] = useAreasDataQuery();
	const activityFloors = activity.floors;
	const activityAreas: IBaseAreaBaseFloor[] = compact(
		flatMap(activityFloors, (activityFloor: IActivityRealTimeFloorInfoMobile) => {
			if (activityFloor.areas && activityFloor.areas.length > 0) {
				return activityFloor.areas;
			}
			const floorAreas: IBaseAreaBaseFloor[] = areas.filter(
				(area) => area.floorId === activityFloor.floor.floorId
			);
			return floorAreas;
		})
	);
	return { activityLocations: activityAreas };
};

export const useMainPageActivityLocationsQuery = (
	activity: IMainPageWidgetActivityData
): {
	activityLocations: IBaseAreaBaseFloor[];
} => {
	const areas: IBaseAreaBaseFloor[] = useAreasDataQuery();
	const activityFloorsWithNoAreas: IMainPageWidgetFloor[] = activity.floors.filter(
		(activityFloor) => !activity.areas.some((area) => activityFloor.floorId === area.floorId)
	);
	const activityAreasFromFloors = compact(
		flatMap(activityFloorsWithNoAreas, (activityFloor: IMainPageWidgetFloor) =>
			areas.filter((area) => area.floorId === activityFloor.floorId)
		)
	);
	const allAreas = [...activityAreasFromFloors, ...activity.areas];
	return { activityLocations: allAreas };
};

export const useActivityGroupByIdQuery = (groupId: string): IActivityGroup | undefined => {
	const { data: activityGroup } = useQuery<IActivityGroup>(
		['getActivityGroupById', groupId],
		() => requestService.get(`/activities/groups/${groupId}`),
		{
			useErrorBoundary: true,
		}
	);
	return activityGroup;
};

export const useMainPageActivities = (
	queryKeys: string[],
	projectId: string
): {
	mainPageActivities: IMainPageWidgetActivityData[] | undefined;
	isLoading: boolean;
} => {
	const { data: mainPageActivities, isLoading } = useQuery<IMainPageWidgetActivityData[]>(
		queryKeys,
		() => requestService.get(`/activities/groups/mainPageWidgetActivities?projectId=${projectId}`),
		{
			useErrorBoundary: true,
		}
	);
	return { mainPageActivities, isLoading };
};

export const useLocationWidgetActivities = (
	queryKeys: any[],
	floorId?: string,
	professionIds?: string[] | null
): {
	activities: IMainPageWidgetActivityData[] | undefined;
	isLoading: boolean;
} => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data: activities, isLoading } = useQuery<IMainPageWidgetActivityData[]>(
		queryKeys,
		() =>
			requestService.get(`/activities/groups/mainPageWidgetActivities?projectId=${projectId}`, {
				params: {
					floorId,
					professionIds,
				},
			}),
		{
			useErrorBoundary: true,
		}
	);
	return { activities, isLoading };
};
