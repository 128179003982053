import React from 'react';
import { IIssueAssignee } from '@shared/interfaces/IIssueShared';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsableContainer } from '@src/components/CollapsableContainer/CollapsableContainer';
import { FilterCard } from '@shared/components/Filters/FilterCard/FilterCard';
import { translationService } from '../../../../../servicesInitializer';
import {
	addAssignee,
	clearAssignees,
	removeAssignee,
	selectSelectedAssignees,
} from '../../../../../store/slices/filters.slice';

interface IAssigneeFilterSectionProps {
	assignees: IIssueAssignee[];
	assigneesCountObj?: { [username: string]: number };
}

export const AssigneeFilterSection = (props: IAssigneeFilterSectionProps) => {
	const dispatch = useDispatch();
	const selectedAssignees: IIssueAssignee[] = useSelector(selectSelectedAssignees);
	const onClear = () => {
		dispatch(clearAssignees());
	};

	const addSelectedAssignee = (assignee: IIssueAssignee) => {
		dispatch(addAssignee({ assignee }));
	};

	const removeSelectedAssignee = (assignee: IIssueAssignee) => {
		dispatch(removeAssignee({ assignee }));
	};

	if (!props.assignees.length) {
		return <></>;
	}

	return (
		<CollapsableContainer
			onClear={selectedAssignees.length ? onClear : undefined}
			headerText={translationService.get('assignees')}
		>
			{props.assignees.map((assignee: IIssueAssignee) => {
				const isSelected: boolean = selectedAssignees.some(
					(selectedEntity) => assignee.username === selectedEntity.username
				);
				return (
					<FilterCard
						// isMobile
						key={assignee.username}
						// color={COLORS.grey100}
						handleClick={
							isSelected ? () => removeSelectedAssignee(assignee) : () => addSelectedAssignee(assignee)
						}
						text={assignee.name}
						isSelected={isSelected}
						count={props.assigneesCountObj?.[assignee.username]}
					/>
				);
			})}
		</CollapsableContainer>
	);
};
