import * as React from 'react';
import classnames from 'classnames';
import rightArrow from '@shared/assets/icons/rightArrow.svg';
import leftArrow from '@shared/assets/icons/leftArrow.svg';
import classes from './styles.module.scss';
import { translationService } from '../../servicesInitializer';

export interface IWorkerPageFloorRowProps {
	floorNick: string;
	workersCount: number;
	onClick?: () => void;
}

export const WorkerPageFloorRow = (props: IWorkerPageFloorRowProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	return (
		<div onClick={props.onClick} className={classnames(classes.floorRow, classes.borderBottom)}>
			<span className={classes.floorNick}>{props.floorNick}</span>
			<div className={classes.rightSection}>
				<span className={classes.workersCount}>{props.workersCount}</span>
				<img src={isRtl ? leftArrow : rightArrow} />
			</div>
		</div>
	);
};
