import React from 'react';
import workerIcon from '@shared/assets/icons/workersBlueIcon.svg';
import { IUnassignedActivity } from '../../../../interfaces/IUnassignedActivity';
import classes from './styles.module.scss';
import { FloorHeader } from '../../../WorkersPageFloorHeader/WorkersPageFloorHeader';
import { translationService } from '../../../../index';

interface IUnassignedActivityFloorCardProps {
	unAssignedActivity: IUnassignedActivity;
	clearMargin?: boolean;
}

const UnassignedActivityFloorCard = (props: IUnassignedActivityFloorCardProps) => {
	const isNum: boolean = /^\d+$/.test(props.unAssignedActivity.floor.floorNick);
	return (
		<div className={classes.cardWrapper} style={{ margin: props.clearMargin ? 0 : '8px 10px' }}>
			<FloorHeader floor={props.unAssignedActivity.floor} forceFloorHeader={isNum}></FloorHeader>
			<div className={classes.activityInnerCardWrapper}>
				<div className={classes.activityInfoWrapper}>
					<div className={classes.activityInfo}>
						<div className={classes.activityDescription}>{translationService.get('unplannedActivity')}</div>
					</div>
					<div className={classes.activityDetails}>
						<div className={classes.iconWithNumber}>
							<img src={workerIcon} alt={'worker icon'} />
							<div>{props.unAssignedActivity.tags.length}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { UnassignedActivityFloorCard };
