import React from 'react';
import { useSelector } from 'react-redux';
import { ISliceToastMessage } from '@shared/interfaces/IToastMessages';
import { ToastMessage } from './ToastMessage';
import { selectToastMessages } from '../../store/slices/toastMessage.slice';
import classes from './styles.module.scss';

const TrusstorToastMessages = () => {
	const toastMessages: ISliceToastMessage[] = useSelector(selectToastMessages);

	return (
		<div className={classes.toastContainer}>
			{toastMessages.map((toast) => (
				<ToastMessage toast={toast} key={toast.id} />
			))}
		</div>
	);
};
export { TrusstorToastMessages };
