import React, { useState } from 'react';
import { IHeaderTabItem } from '@shared/interfaces/IHeaderTabItem';
import classes from './styles.module.scss';
import { HeaderTab } from './HeaderTab/HeaderTab';

interface IHeaderTabsSectionProps {
	headerItems: IHeaderTabItem[];
	onTabClick: (selected: string) => void;
}

const HeaderTabsSection = (props: IHeaderTabsSectionProps) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

	const handleTabClick = (index: number) => {
		setSelectedTabIndex(index);
		props.onTabClick(props.headerItems[index].type);
	};

	return (
		<div className={classes.headerTabsSectionContainer}>
			{props.headerItems.map((item, index) => {
				return (
					<HeaderTab
						key={index}
						isSelected={selectedTabIndex === index}
						selectTab={() => handleTabClick(index)}
						{...item}
					/>
				);
			})}
		</div>
	);
};

export { HeaderTabsSection };
