import React, { useEffect, useState } from 'react';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import greenCheckIcon from '@shared/assets/icons/greenCheck.svg';
import settingsIcon from '@shared/assets/icons/settings_icon.svg';
import { IProfessionWithActiveTags } from '../../../../interfaces/IProfessionWithActiveTags';
import { addLocationAndReportDataToActivitiesByProgress } from '../../../../utils/activities.utils';
import { IActivityByProgress } from '../../../../interfaces/IActivityByProgress';
import { IActivityRealtimeInfo } from '../../../../interfaces/IActivityRealtimeInfo';
import { ProgressViewCardsWithTitle } from '../../../ProgressViewCardsWithTitle/ProgressViewCardsWithTitle';
import { translationService } from '../../../../index';
import { ActivityPageSortOptions } from '../../../../models/ActivityPageSortOptions';
import classes from '../styles.module.scss';
import { EmptyStateComponent } from '../../../EmptyStateComponent/EmptyStateComponent';
import redFlag from '../../../../assets/Activities/red_flag.svg';

interface IMyIssuesProps {
	relevantActivities: IActivityRealtimeInfo[] | undefined;
	activitiesReportData: IWorkplanWidgetRealtimeActivityReport[] | undefined;
	activitiesLocationData: IProfessionWithActiveTags[] | undefined;
	refetchAllData: () => Promise<void>;
	shouldNotShowProgressFloorsViewOption: (value: boolean) => void;
	personalViewSortOption: ActivityPageSortOptions;
	isUserPreferenceEmptyState: boolean;
}

const MyIssues = (props: IMyIssuesProps) => {
	const [delayedActivities, setDelayedActivities] = useState<IActivityByProgress[]>([]);
	const [overdueActivities, setOverdueActivities] = useState<IActivityByProgress[]>([]);
	const [noProgressTodayActivities, setNoProgressTodayActivities] = useState<IActivityByProgress[]>([]);

	const getMyIssuesActivitiesData = (): IActivityByProgress[] | null => {
		if (!props.relevantActivities || !props.activitiesReportData || !props.activitiesLocationData) return null;

		return addLocationAndReportDataToActivitiesByProgress(
			props.relevantActivities,
			props.activitiesReportData,
			props.activitiesLocationData
		);
	};

	useEffect(() => {
		const activitiesByProgress: IActivityByProgress[] | null = getMyIssuesActivitiesData();
		if (!activitiesByProgress) {
			return;
		}

		const delayedActivitiesList: IActivityByProgress[] = activitiesByProgress.filter(
			(activity) => activity.groupStatus === ActivityGroupStatus.delayed
		);
		const overdueActivitiesList: IActivityByProgress[] = activitiesByProgress.filter(
			(activity) => activity.groupStatus === ActivityGroupStatus.overdue
		);
		const noProgressTodayActivitiesList: IActivityByProgress[] = activitiesByProgress.filter(
			(activity) => activity.groupStatus === ActivityGroupStatus.inProgress
		);
		setDelayedActivities(delayedActivitiesList);
		setOverdueActivities(overdueActivitiesList);
		setNoProgressTodayActivities(noProgressTodayActivitiesList);
	}, [props.relevantActivities, props.activitiesLocationData, props.activitiesReportData]);

	useEffect(() => {
		props.refetchAllData();
	}, []);

	if (!props.personalViewSortOption) {
		return null;
	}

	props.shouldNotShowProgressFloorsViewOption(true);
	const getTabBody = (): JSX.Element => {
		if ([...delayedActivities, ...overdueActivities, ...noProgressTodayActivities].length === 0) {
			return (
				<EmptyStateComponent
					icon={greenCheckIcon}
					text={translationService.get('myIssuesEmptyState')}
					style={{ marginTop: 10 }}
				/>
			);
		}

		return (
			<div className={classes.activitiesContainer}>
				<ProgressViewCardsWithTitle
					title={translationService.get('zeroPercentProgress')}
					refetchDataCallback={props.refetchAllData}
					activities={delayedActivities}
				/>
				<ProgressViewCardsWithTitle
					title={translationService.get('overdue')}
					refetchDataCallback={props.refetchAllData}
					activities={overdueActivities}
					titleIconSrc={redFlag}
				/>
				<ProgressViewCardsWithTitle
					title={translationService.get('withoutProgressToday')}
					refetchDataCallback={props.refetchAllData}
					activities={noProgressTodayActivities}
				/>
			</div>
		);
	};

	return (
		<div>
			{props.isUserPreferenceEmptyState ? (
				<EmptyStateComponent
					icon={settingsIcon}
					text={translationService.get('personalViewEmptyStateText')}
					style={{ maxWidth: '250px', marginTop: 10 }}
				/>
			) : (
				getTabBody()
			)}
		</div>
	);
};

export { MyIssues };
