export enum BIEventNames {
	'welcomeScreenInterestsDefinitions' = ' Mobile - Welcome screen_my interests definitions',
	'myInterestsDefinitions' = 'Mobile - My interests definitions',
	'workersMobileAccess' = 'Workers_Mobile - Access',
	'equipmentMobileAccess' = 'Equipment_Mobile - Access',
	'preferencesMobileAccess' = 'Preferences_Mobile - Access',
	'appBecameVisible' = 'Mobile Browser event - the application became visible',
	'activitiesMobileAccess' = 'Activities_Mobile - Access',
	'notificationsMobileAccess' = 'Mobile_Notification_center_tab_access',
	'notificationSettingsTabAccess' = 'Mobile_Notification_settings_tab_access',
	'notificationsSettingsPreferencesEngagement' = 'Mobile_Notification_settings_preferences_engagement',
	'viewSettingsOpen' = 'Mobile_workers_view_settings_open',
	'viewSettingsUpdate' = 'Mobile_workers_view_settings_update',
	'activitiesViewSettingsOpen' = 'Mobile_activities_view_settings_open',
	'activitiesViewSettingsUpdate' = 'Mobile_activities_view_settings_update',
	'issueStatusChange' = 'Mobile_issue_status_change',
	'issuesTabAccess' = 'Mobile_issues_tab_access',
	'issueCreate' = 'Mobile_issues_create_issue',
	'issueComment' = 'Mobile_issue_comment_creation',
	'workplanActivityActions' = 'Mobile_workplan_matrix_interaction',
	'issueEdit' = 'Mobile_issue_edit_issue',
	'pushNotificationDialogShown' = 'Mobile_Push_Notification_Dialog_Shown',
	'pushNotificationDialogAction' = 'Mobile_Push_Notification_Dialog_Action',
	'pushNotificationNativeDialogAction' = 'Mobile_Push_Notification_Native_Dialog_Action',
}
