import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { arrowUp as arrow } from '@shared/assets/tradesBox';
import { IUser } from '@shared/interfaces/IUser';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import classes from './styles.module.scss';
import userIcon from '../../../assets/Navbar/PreferencesThinIcon.svg';
import { translationService, userAccessLevelService } from '../../../index';
import { AppRoutes } from '../../../constants/appRoutes.enum';
import { logout } from '../../../store/thunks/login.thunks';
import { selectLoggedUserDetails } from '../../../store/slices/login.slice';
import { config } from '../../../config/config';
import { getUserProfilePageActions, IUserProfileActions } from './profilePage.utils';
import { LanguagesDrawer } from '../../Drawers/LanguageDrawer/LanguageDrawer';

const ProfilePage = () => {
	useRenderMonitoring('ProfilePage');
	const userDetails: IUser = useSelector(selectLoggedUserDetails)!;
	const history = useHistory();
	const dispatch = useDispatch();
	const [showLanguageSelector, setShowLanguageSelector] = useState<boolean>(false);

	const handleActionClick = (userProfileAction: IUserProfileActions): void => {
		if (userProfileAction.linkPath === AppRoutes.logout) {
			dispatch(logout());
			return;
		}
		if (userProfileAction.onClick) {
			userProfileAction.onClick();
		} else if (userProfileAction.linkPath) {
			history.push(userProfileAction.linkPath);
		}
	};

	const onLanguageClick = (): void => {
		setShowLanguageSelector(true);
	};

	const userProfileActions: IUserProfileActions[] = getUserProfilePageActions(onLanguageClick);
	const isRtl: boolean = translationService.getIsRtl();

	return (
		<div className={classes.profileContainer}>
			<section className={classes.userDescription}>
				<img src={userIcon} alt="" />
				<div className={classes.userName}>{userDetails?.name}</div>
			</section>
			<section className={classes.userLinks}>
				{userProfileActions.map((profileAction, index) => {
					if (profileAction.linkName === 'alerts' && !config.showNotificationsTab) {
						// feature flag
						return null;
					}
					if (
						profileAction.linkPath === AppRoutes.assignTag &&
						!userAccessLevelService.hasAccess('assignTag')
					) {
						return null;
					}
					const setBorder: boolean = index !== userProfileActions.length - 1;
					return (
						<div
							onClick={() => handleActionClick(profileAction)}
							key={`profileAction_${index}`}
							className={`${classes.userLink} ${setBorder && classes.border}`}
						>
							<div className={classes.userLink}>
								<img src={profileAction.icon} alt={profileAction.linkName} />
								<div>{translationService.get(profileAction.linkName)}</div>
							</div>
							<div>
								<p className={classes.secondText}>{profileAction.secondText}</p>
							</div>
							{profileAction.withArrow && (
								<img
									src={arrow}
									className={`${isRtl ? classes.leftArrow : classes.rightArrow} ${classes.arrow}`}
									alt=""
								/>
							)}
						</div>
					);
				})}
			</section>
			<LanguagesDrawer
				isOpen={showLanguageSelector}
				onClose={() => {
					setShowLanguageSelector(false);
				}}
			/>
		</div>
	);
};
export { ProfilePage };
