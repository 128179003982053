import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { ActivityPageSortOptions } from '@src/models/ActivityPageSortOptions';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { isUserPreferencesDefined } from '@utils/filter.utils';

export const filteredMainPageActivitiesByUserPreferences = (
	activities: IMainPageWidgetActivityData[],
	userPreferences: IUserPreferences
) => {
	if (!isUserPreferencesDefined(userPreferences)) {
		return activities;
	}

	const { floorIds, professionIds } = userPreferences.mobile!.filters;

	const activitiesFilteredByProfessions: IMainPageWidgetActivityData[] = !professionIds
		? activities
		: activities.filter(
				(activity) => !!userPreferences.mobile?.filters?.professionIds?.includes(activity.profession._id)
		  );

	return !floorIds
		? activitiesFilteredByProfessions
		: activitiesFilteredByProfessions.filter((activity) =>
				activity.floors.some((floor) => !!userPreferences.mobile?.filters?.floorIds?.includes(floor.floorId))
		  );
};

export const getMainPageActivitiesAfterUserPreferencesFilter = (
	activities: IMainPageWidgetActivityData[],
	userPreference: IUserPreferences | null,
	personalViewState: ActivityPageSortOptions
) => {
	if (
		!userPreference ||
		!(userPreference.mobile?.filters?.professionIds || userPreference.mobile?.filters?.floorIds)
	) {
		return activities;
	}

	if (personalViewState === ActivityPageSortOptions.PERSONAL_VIEW) {
		return filteredMainPageActivitiesByUserPreferences(activities, userPreference);
	}

	return activities;
};
