import React from 'react';
import { translationService } from '../../../index';
import classes from './styles.module.scss';
import { GeneralDialog } from '../GeneralDialog/GeneralDialog';

interface ICompleteConfirmationDialogProps {
	show: boolean;
	onClose: () => void;
	mainButtonAction: () => void;
	secondaryButtonAction: () => void;
	title: string;
	text: string;
	secondaryText?: React.ReactNode;
}

export const ActionConfirmationDialog = (props: ICompleteConfirmationDialogProps) => (
	<GeneralDialog
		show={props.show}
		close={props.onClose}
		title={props.title}
		mainButton={{
			text: translationService.get('yes'),
			show: true,
			click: props.mainButtonAction,
		}}
		secondaryButton={{
			text: translationService.get('cancel'),
			show: true,
			click: props.secondaryButtonAction,
		}}
		rootStyle={classes.rootDialog}
	>
		<div className={classes.dialogContent}>
			<p>{props.text}</p>
			{props.secondaryText ? props.secondaryText : <div></div>}
		</div>
	</GeneralDialog>
);
