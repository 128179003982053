import backButtonIcon from '@shared/assets/icons/ArrowRight.svg';
import React from 'react';
import classes from './styles.module.scss';
import { translationService } from '../../servicesInitializer';

interface IInnerPageHeaderProps {
	title: string;
	handleBackClick: () => void;
}

export const InnerPageHeader = (props: IInnerPageHeaderProps) => (
	<div className={classes.customizeHeader}>
		<img
			onClick={props.handleBackClick}
			src={backButtonIcon}
			className={translationService.getIsRtl() ? classes.rtl : ''}
			alt=""
		/>
		<div className={classes.customizeText}>{props.title}</div>
	</div>
);
