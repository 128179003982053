import { IProjectPackagesFeatureProhibitions } from '@shared/interfaces/IProjectPackagesFeatureProhibitions';
import { ProjectPackageTypeEnum } from '@shared/interfaces/ProjectPackageType.enum';

const featureNames = [
	'personnelPage',
	'activitiesPage',
	'issuesPage',
	'assignTag',
	'equipmentPage',
	'notifications-safety',
	'notifications-reports',
	'notifications-activities',
	'notifications-issues',
] as const;

export type ProjectPackagesFeaturesNamesType = (typeof featureNames)[number];
type IProjectPackagesFeatureProhibitionsMobile = IProjectPackagesFeatureProhibitions<ProjectPackagesFeaturesNamesType>;

export const projectPackagesFeaturesProhibitedList: IProjectPackagesFeatureProhibitionsMobile = {
	'personnelPage': [ProjectPackageTypeEnum.management],
	'activitiesPage': [ProjectPackageTypeEnum.monitor],
	'issuesPage': [ProjectPackageTypeEnum.monitor],
	'assignTag': [ProjectPackageTypeEnum.management],
	'equipmentPage': [ProjectPackageTypeEnum.management],
	'notifications-safety': [ProjectPackageTypeEnum.management],
	'notifications-reports': [ProjectPackageTypeEnum.management],
	'notifications-activities': [ProjectPackageTypeEnum.monitor],
	'notifications-issues': [ProjectPackageTypeEnum.monitor],
};
