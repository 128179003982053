import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { translationService } from '@src/servicesInitializer';
import classnames from 'classnames';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useFloorDataQuery } from '@src/hooks/queries/floors.queries.hooks';
import { useAreasDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { IFloor } from '@shared/interfaces/IFloor';
import { sortByAreas, sortFloors } from '@shared/utils/sort.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { useHistory } from 'react-router-dom';
import {
	IRecentSearchLocationItem,
	MonitorSearchRecentResultsLocalStorageService,
} from '@src/services/monitorSearchRecentResultsLocalStorage.service';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import {
	ITrusstorIconButtonSize,
	TrusstorIconButtonV2,
} from '@shared/components/buttons/TrusstorIconButtonV2/TrusstorIconButtonV2';
import { RecentSearchItems } from '@src/components/pages/MonitorPage/MonitorSearch/RecentSearchItems/RecentSearchItems';
import { selectProjectId } from '@store/slices/project.slice';
import { useSelector } from 'react-redux';
import classes from './styles.module.scss';
import {
	getFilteredAreasBySearchInput,
	getFilteredFloorsBySearchInput,
	addItemToRecentSearchResults,
} from '../monitorSearch.utils';
import { MonitorSearchCardItem } from '../MonitorSearchCardItem/MonitorSearchCardItem';

interface IMonitorSearchDrawerProps {
	closeSearch: () => void;
}

interface IFloorOrArea {
	floor?: IFloor;
	area?: IConfigArea;
}

export const MonitorSearchDrawer = (props: IMonitorSearchDrawerProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const projectFloors: IFloor[] = useFloorDataQuery();
	const projectAreas: IConfigArea[] = useAreasDataQuery();
	const sortOrder: SORT_ORDER = SORT_ORDER.ASCENDING;
	const sortedAreas: IConfigArea[] = sortByAreas(projectAreas, sortOrder);
	const relevantFloors = projectFloors.filter((floor) => !floor.isSpecialFloor);
	const sortedFloors: IFloor[] = sortFloors(relevantFloors, sortOrder);
	const [recentSearchItems, setRecentSearchItems] = useState<IRecentSearchLocationItem[]>();
	const [searchInput, setSearchInput] = useState<string>('');
	const isSearchInputNotExistent: boolean = searchInput === '';
	const inputRef = useRef<HTMLInputElement>(null);
	const history = useHistory();

	useEffect(() => {
		const recentSearchResults: IRecentSearchLocationItem[] =
			MonitorSearchRecentResultsLocalStorageService.getRecentSearchProjectResults(projectId);
		setRecentSearchItems(recentSearchResults);

		if (inputRef.current) {
			inputRef.current.focus();
		}
		return () => {
			setSearchInput('');
		};
	}, [inputRef]);

	const changeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchInput(e.target.value);
	};

	const clearSearch = () => {
		setSearchInput('');
	};

	const filteredAreas: IConfigArea[] = getFilteredAreasBySearchInput(sortedAreas, searchInput);
	const filteredFloors: IFloor[] = getFilteredFloorsBySearchInput(sortedFloors, searchInput);

	const navigateToRouteFromCardClick = ({ floor, area }: IFloorOrArea) => {
		if (floor) {
			history.push(`${AppRoutes.monitorFloor}/${floor.floorId}`);
		} else if (area) {
			history.push(`${AppRoutes.monitorArea}/${area.areaId}`);
		}
	};

	const onCardClick = ({ area, floor }: IFloorOrArea) => {
		navigateToRouteFromCardClick({ area, floor });
		addItemToRecentSearchResults(projectId, { area, floor }, recentSearchItems, setRecentSearchItems);
	};

	return (
		<div
			className={classnames(classes.MonitorSearchDrawer_container, {
				[classes.isRtl]: translationService.getIsRtl(),
			})}
			data-testid="MonitorSearchDrawer"
		>
			<div className={classes.searchContainer}>
				<TrusstorIcon
					iconName={translationService.getIsRtl() ? IconNames.arrowRight : IconNames.arrowLeft}
					color={IconColor.White}
					size={IconSize.LARGE}
					onClick={props.closeSearch}
				/>
				<div className={classes.content}>
					<TrusstorIcon iconName={IconNames.search} color={IconColor.Grey500} />
					<input
						ref={inputRef}
						className={classes.input}
						placeholder={translationService.get('monitorPage_searchPage_searchPlaceholder')}
						onChange={changeSearchInput}
						value={searchInput}
					/>
					{!isSearchInputNotExistent && (
						<TrusstorIconButtonV2
							buttonSize={ITrusstorIconButtonSize.SMALL}
							iconName={IconNames.close}
							onClick={clearSearch}
						/>
					)}
				</div>
			</div>
			<div className={classes.searchContentContainer}>
				{!isSearchInputNotExistent ? (
					<>
						{filteredAreas.map((area) => (
							<MonitorSearchCardItem area={area} onClick={() => onCardClick({ area })} />
						))}
						{filteredFloors.map((floor) => (
							<MonitorSearchCardItem floor={floor} onClick={() => onCardClick({ floor })} />
						))}
					</>
				) : (
					<div>
						{!!recentSearchItems?.length && (
							<>
								<RecentSearchItems
									recentSearchItems={recentSearchItems}
									onCardClick={onCardClick}
									projectAreas={projectAreas}
									relevantFloors={relevantFloors}
									setRecentSearchItems={setRecentSearchItems}
								/>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
