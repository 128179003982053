export enum AppRoutes {
	personnel = '/personnel/:mode?',
	personnelWithNoMode = '/personnel',
	workersWithNoMode = '/workers',
	workerWithEducation = '/workers/education',
	workers = '/workers/:mode?',
	equipment = '/equipment',
	preferences = '/preferences',
	setup = '/setup',
	projectNotReady = '/projectNotReady',
	activities = '/activities',
	workersOnSite = '/workersOnSite',
	myProfile = '/myProfile',
	logout = '/logout',
	assignTag = '/assignTag',
	notifications = '/notifications',
	notificationsSettings = '/notificationsSettings',
	issues = '/issues',
	monitor = '/monitor',
	monitorFloor = '/monitor/floor',
	monitorArea = '/monitor/area',
	monitorSearch = '/monitor/search',
}
