import { Loader } from '@shared/components/Loader/Loader';
import { NotificationCard } from '@shared/components/NotificationCard/NotificationCard';
import { INotification } from '@shared/interfaces/INotification';
import { isDateToday, isDateYesterday } from '@shared/utils/dateUtils';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { requestService, storageService, translationService } from '../../../servicesInitializer';
import { IRootState } from '../../../store/slices';
import { selectNotifications } from '../../../store/slices/notifications.slice';
import { selectProjectId, selectTimezone } from '../../../store/slices/project.slice';
import { markAllNotificationsAsViewed } from '../../../store/thunks/notifications.thunks';
import { notificationsPageAccess_BI } from '../../../utils/bi.utils';
import { NotificationsEmptyState } from './NotificationsEmptyState/NotificationsEmptyState';
import classes from './styles.module.scss';
import { PageHeader } from '../../PageHeader';

export const NotificationsPage = () => {
	useRenderMonitoring('NotificationsPage');
	const tz: string = useSelector(selectTimezone)!;
	const notifications: INotification[] | undefined = useSelector(selectNotifications);
	const projectId: string = useSelector(selectProjectId)!;
	const loggedUser: ILoggedUser | null = useSelector((state: IRootState) => state.loginReducer.loggedUser);
	const dispatch = useDispatch();
	useEffect(() => {
		notificationsPageAccess_BI();

		return () => {
			dispatch(markAllNotificationsAsViewed(projectId, loggedUser!.userDetails.username));
		};
	}, []);

	if (notifications === undefined) {
		return <Loader />;
	}

	const todayNotifications: INotification[] = notifications.filter((notification) =>
		isDateToday(notification.createdAt, tz)
	);
	const yesterdayNotifications: INotification[] = notifications.filter((notification) =>
		isDateYesterday(notification.createdAt, tz)
	);
	const earlierNotifications: INotification[] = notifications.filter((notification) =>
		moment.tz(notification.createdAt, tz).isBefore(moment.tz(tz).subtract(1, 'day'), 'day')
	);

	const getCardSection = (title: string, notificationsToDisplay: INotification[]) => (
		<>
			{notificationsToDisplay.length > 0 && (
				<div className={classes.cardsSection}>
					<div className={classes.title}>{title}</div>
					{notificationsToDisplay.map((notification) => (
						<>
							<NotificationCard
								storageService={storageService}
								notification={notification}
								translationService={translationService}
								tz={tz}
								requester={requestService}
								projectId={projectId}
								userDetails={loggedUser!.userDetails}
							/>
							<div className={classes.divider} />
						</>
					))}
				</div>
			)}
		</>
	);

	return (
		<div className={classes.notificationsContainer}>
			<PageHeader title={translationService.get('notifications')} />
			{!notifications.length ? (
				<div className={classes.emptyStateContainer}>
					<NotificationsEmptyState />
				</div>
			) : (
				<div className={classes.cardsContainer}>
					{getCardSection(translationService.get('today'), todayNotifications)}
					{getCardSection(translationService.get('yesterday'), yesterdayNotifications)}
					{getCardSection(translationService.get('earlier'), earlierNotifications)}
				</div>
			)}
		</div>
	);
};
