import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestService } from '../../index';
// eslint-disable-next-line import/no-cycle
import { setUserPreferences } from '../slices/userPreferences.slice';

export const updateUserPreferences =
	(userPreferences: Partial<IUserPreferences>, username: string) => async (dispatch) => {
		const updatedUserPreferences: IUserPreferences = await requestService.put(
			`/entities/userPreferences/${username}`,
			{
				body: userPreferences,
			}
		);
		dispatch(setUserPreferences({ userPreferences: updatedUserPreferences }));
	};

export const getUserPreferences = createAsyncThunk('getUserPreferences', async (username: string) => {
	const userPreferences: IUserPreferences | null = await requestService.get(`/entities/userPreferences/${username}`);

	return { userPreferences: userPreferences || null };
});
