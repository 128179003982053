// eslint-disable-next-line import/no-cycle
import { ILoggedUser } from '@shared/interfaces/IUser';
import { RESET_ALL_REDUCERS_ACTION_TYPE } from '../store';
import { projectService, userService } from '../../index';
import { setLoggedUser } from '../slices/login.slice';
import { updateUserProjects } from '../slices/project.slice';
import { fetchAndSetWorkingProject } from './project.thunks';
import { getUserPreferences } from './userPreferences.thunks';
// eslint-disable-next-line import/no-cycle
import { initServicesDependentByUserData } from '../../utils/userDataBootstrap.utils';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

export const logout = () => (dispatch) => {
	serviceWorkerRegistration.unregister();
	userService.removeLoggedUser();
	projectService.removeChosenProject();
	dispatch({ type: RESET_ALL_REDUCERS_ACTION_TYPE });
};

export const setLoggedUserThunk = (loggedUser: ILoggedUser) => async (dispatch) => {
	userService.setUserDetails(loggedUser.userDetails);
	userService.setLSUserData({
		accessToken: loggedUser.accessToken,
		refreshToken: loggedUser.refreshToken,
	});
	dispatch(setLoggedUser({ user: loggedUser }));
	dispatch(updateUserProjects({ userProjects: loggedUser.userProjects }));
	dispatch(
		fetchAndSetWorkingProject(projectService.getChosenProject()?.projectId || loggedUser.userProjects[0].projectId)
	);
	dispatch(getUserPreferences(loggedUser.userDetails.username) as any);
	initServicesDependentByUserData(
		loggedUser.userDetails,
		projectService.getChosenProject()?.projectId || loggedUser.userProjects[0].projectId
	);
};
