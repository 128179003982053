// @ts-ignore
import React, { useState, useRef } from 'react';
import { map, concat, compact } from 'lodash';
import { arrowDown, arrowDownWhite, arrowUp, arrowUpWhite } from '../../../assets/tradesBox';
import { COLORS } from '../../../constants/colors.constants';
import { useOutsideClick } from '../../../hooks/custom.hooks';
import classes from './styles.module.scss';

const CustomSelect = ({
	disabled,
	currentValue,
	placeholder,
	list,
	selectHandler,
	rootStyleClass,
	minClosedHeight,
	isBlackMenu,
	menuItemComponent,
	firstItem = null,
	fieldToDisplay,
	idField,
	errorMessage,
	isError,
	errorEnabled,
	headerContainerMarginTop = '14px',
}) => {
	const [listOpen, setListOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(currentValue);

	const headerStyle = {
		backgroundColor: isBlackMenu ? COLORS.black : COLORS.white,
		cursor: disabled ? 'inherit' : 'pointer',
	};
	const colorHeaderTitleStyle = { color: isBlackMenu ? '#fffefe' : COLORS.primaryColor };
	const superContainerStyle = { minHeight: minClosedHeight || 46 };
	const headerContainerMarginTopStyle = { marginTop: headerContainerMarginTop };
	const wrapperRef = useRef(null);

	useOutsideClick(wrapperRef, setListOpen, false);

	const openMenuClick = () => {
		setSelectedItem(currentValue);
		return setListOpen(true);
	};
	const closeMenuClick = () => setListOpen(false);

	const changeSelectedItem = (item) => {
		if (item === firstItem || item === placeholder) {
			setSelectedItem(null);
			selectHandler(null);
		} else {
			setSelectedItem(item);
			selectHandler(item);
		}
		setListOpen(false);
	};

	const constructList = () => (!selectedItem ? list : compact(concat(firstItem, list)));

	const FallbackMenuItem = ({
		item,
		selectedItem: fallbackMenuItemSelectedItem,
		changeSelected,
		fieldToDisplay: fallbackMenuItemFieldToDisplay,
	}) =>
		item === fallbackMenuItemSelectedItem ? null : (
			<div
				key={idField ? item[idField] : item}
				className={classes.listItemContainer}
				onClick={() => changeSelected(item)}
			>
				<p className={classes.listItem}>
					{fallbackMenuItemFieldToDisplay ? item[fallbackMenuItemFieldToDisplay] : item}
				</p>
			</div>
		);

	const getMenuItemComponent = (props) => (menuItemComponent ? menuItemComponent(props) : FallbackMenuItem(props));

	return (
		<div className={classes.container}>
			<div
				className={`${classes.superContainer} ${rootStyleClass} ${isError ? classes.containerError : ''}`}
				style={superContainerStyle}
			>
				{listOpen ? (
					<div ref={wrapperRef} className={classes.headerOpen} style={headerStyle}>
						<div className={classes.selectContainer}>
							<div
								className={classes.headerContainer}
								style={headerContainerMarginTopStyle}
								onClick={closeMenuClick}
							>
								<div className={classes.headerTitle} style={colorHeaderTitleStyle}>
									{currentValue || placeholder}
								</div>
								<img className={classes.arrow} src={isBlackMenu ? arrowUpWhite : arrowUp} alt="<" />
							</div>
							<div className={classes.listContainer}>
								{map(constructList(), (item, index) =>
									getMenuItemComponent({
										index,
										item,
										selectedItem,
										changeSelected: changeSelectedItem,
										isPlaceholder: item === placeholder || item === firstItem,
										idField,
										fieldToDisplay,
									})
								)}
							</div>
						</div>
					</div>
				) : (
					<div
						className={classes.headerClosed}
						style={headerStyle}
						onClick={!disabled ? openMenuClick : () => {}}
					>
						<div className={disabled ? classes.disabledTextInput : classes.headerTitle}>
							{currentValue || placeholder}
						</div>
						{!disabled && (
							<img className={classes.arrow} src={isBlackMenu ? arrowDownWhite : arrowDown} alt=">" />
						)}
					</div>
				)}
			</div>
			{errorEnabled && (
				<div className={classes.errorContainer}>
					{isError && <p className={classes.errorText}>{errorMessage}</p>}
				</div>
			)}
		</div>
	);
};

export { CustomSelect };
