import React from 'react';
import { INotificationCardSection } from '../NotificationsSettingsPage';
import classes from './styles.module.scss';
import { translationService } from '../../../../index';
import { NotificationCardSection } from '../NotificationCardSection';

interface INotificationCardProps {
	card: INotificationCardSection;
}

const NotificationSettingCard = (props: INotificationCardProps) => (
	<section className={classes.cardContainer}>
		<div className={classes.cardHeader}>
			<img src={props.card.icon} alt="" />
			<div className={classes.titleText}>{translationService.get(props.card.name)}</div>
		</div>
		<div className={classes.cardBody}>
			{props.card.cards.map((card, index: number) => (
				<NotificationCardSection card={card} index={index} />
			))}
		</div>
	</section>
);

export { NotificationSettingCard };
