import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TrusstorButton } from '@shared/components/buttons/TrusstorButton/TrusstorButton';
import { IProfession } from '@shared/interfaces/IProfession';
import { guestTradeId, unassignedTradeId } from '@shared/constants/professions.constants';
import GroupedAutoCompleteDropdown from '@shared/components/Dropdowns/GroupedAutoCompleteDropdown/GroupedAutoCompleteDropdown';
import { getProfessionBackgroundColor, getProfessionDisplayText } from '@shared/utils/professions.utils';
import { IToastMessage, IToastMessageTypes } from '@shared/interfaces/IToastMessages';
import { cloneDeep, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TrusstorTextInput } from '@shared/components/Inputs/TrusstorTextInput/TrusstorTextInput';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { IMergedTag } from '@interfaces/IMergedTag';
import { errorToast } from '@utils/toast.utils';
import { MobilePhoneInputField } from '../../Inputs/MobilePhoneInputField/MobilePhoneInputField';
import { IStaticTag } from '../../../interfaces/IStaticTag';
import { IWorkerStaticTag } from '../../../interfaces/IWorkerStaticTag';
import { useProfessionsQuery } from '../../../hooks/queries/professions.queries.hooks';
import { AppRoutes } from '../../../constants/appRoutes.enum';
import classes from './styles.module.scss';
import { requestService, translationService } from '../../../index';
import { setMessage } from '../../../store/slices/toastMessage.slice';
import { assignTagOptions } from '../../../constants/assignTag.constants';
import { useProjectStaticTagsQuery } from '../../../hooks/queries/tags.queries.hooks';
import { selectProjectId } from '../../../store/slices/project.slice';
import { InnerPageHeader } from '../../InnerPageHeader/InnerPageHeader';
import { getTradeGroupTranslation } from '../../../utils/translations.utils';
import { SingleDropdown } from '../../Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';

interface IMobileAssignTag {
	id: string;
	type: string;
	profession: IProfession | undefined;
	workerName: string;
	idNumber: string;
	phoneNumber: string;
}

const AssignTag = () => {
	useRenderMonitoring('AssignTag');
	const projectId: string = useSelector(selectProjectId)!;
	const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const projectStaticTags: IStaticTag[] = useProjectStaticTagsQuery();
	const projectProfessions: IProfession[] = useProfessionsQuery();
	const [countryCode, setCountryCode] = useState<string>('');
	const [tagFields, setTagFields] = useState<IMobileAssignTag>({
		id: '',
		type: assignTagOptions.worker,
		profession: undefined,
		workerName: '',
		idNumber: '',
		phoneNumber: '',
	});

	const changeToGuestProfessionAndClearUnRequiredFields = (): void => {
		const guestProfession: IProfession = projectProfessions.find(
			(profession) => profession.tradeId === guestTradeId
		)!;
		setTagFields({
			...tagFields,
			profession: guestProfession,
			workerName: '',
			idNumber: '',
			phoneNumber: '',
		});
	};

	useEffect(() => {
		if (tagFields.type === guestTradeId) {
			changeToGuestProfessionAndClearUnRequiredFields();
		}
	}, [tagFields.type]);

	const checkIfAssignTagButtonAvailable = (): boolean => {
		const phoneValidWithCountryCode: boolean = isValidPhoneNumber || tagFields.phoneNumber === countryCode;
		const isPhoneNumberExistAndValid: boolean = tagFields.phoneNumber.length > 0 ? phoneValidWithCountryCode : true;
		return !!(tagFields.type && tagFields.id && tagFields.profession && isPhoneNumberExistAndValid);
	};

	const tagNicks: string[] | undefined = useMemo(
		() =>
			projectStaticTags
				?.filter((tag) => (tag as IWorkerStaticTag)?.profession?.tradeId === unassignedTradeId)
				.map((t) => t.tagNick),
		[projectStaticTags]
	);

	const addPropertiesToTag = (tag: IStaticTag): IWorkerStaticTag => {
		const clonedTag = cloneDeep(tag as IWorkerStaticTag);
		clonedTag.profession = tagFields.profession!;
		clonedTag.name = tagFields.workerName;
		clonedTag.idNumber = tagFields.idNumber;
		clonedTag.phoneNumber = tagFields.phoneNumber === countryCode ? '' : tagFields.phoneNumber;
		return clonedTag;
	};

	const handleInputChanged = (value: any, fieldName: string): void => {
		setTagFields({ ...tagFields, [fieldName]: value });
	};

	const updateTag = async (updatedTagClone: IWorkerStaticTag, tagId: string): Promise<void> => {
		try {
			await requestService.put(`/siteNetIntegrator/static/tag/${tagId}`, {
				body: updatedTagClone,
			});
			const message: IToastMessage = {
				text: translationService.get('tagAssignedSuccessfully'),
				type: IToastMessageTypes.SUCCESS,
			};
			dispatch(setMessage({ message }));
		} catch (err) {
			const message: IToastMessage = {
				text: translationService.get('tagUpdateFailed'),
				type: IToastMessageTypes.WARNING,
			};
			dispatch(setMessage({ message }));
		} finally {
			history.push(AppRoutes.workers);
		}
	};

	const handleAssignClick = async (): Promise<void> => {
		try {
			const tagToUpdate: IStaticTag = projectStaticTags.find((staticTag) => staticTag.tagNick === tagFields.id)!;
			const mergedTag: IMergedTag | undefined = await requestService.get(
				`/siteNetIntegrator/mergedTags/${tagToUpdate.tagId}`
			);

			if (!mergedTag) {
				errorToast(dispatch, translationService.get('assignTagDialog_mobile_404_error'));
				return;
			}

			const updatedTagClone: IWorkerStaticTag = addPropertiesToTag(tagToUpdate);
			updateTag(updatedTagClone, tagToUpdate.tagId);
		} catch (err: any) {
			errorToast(dispatch, translationService.get('assignTagDialog_mobile_general_error'));
		}
	};

	const handleCancelClick = (): void => {
		history.push(AppRoutes.myProfile);
	};

	const isUserChoseGuestType = (): boolean => tagFields.type === assignTagOptions.guest;

	return (
		<>
			<InnerPageHeader title={translationService.get('assignTag')} handleBackClick={handleCancelClick} />
			<section className={classes.assignTagContainer}>
				<div className={classes.topDropdowns}>
					<SingleDropdown
						options={Object.values(assignTagOptions)}
						onChange={(value) => handleInputChanged(value, 'type')}
						getDisplayOption={(option) => translationService.get(option)}
						label={translationService.get('type')}
						required
						hideClearTextButton
						value={tagFields.type}
					/>
					<SingleDropdown
						getDisplayOption={(option: string) => option}
						options={tagNicks!}
						value={tagFields.id}
						required
						onChange={(value) => handleInputChanged(value, 'id')}
						label={translationService.get('tagsID')}
					/>
				</div>

				<>
					{tagFields.type !== guestTradeId && (
						<GroupedAutoCompleteDropdown
							getOptionLabel={(option: IProfession) =>
								getProfessionDisplayText(option, translationService)
							}
							options={orderBy(
								projectProfessions.filter(
									(profession) =>
										profession.tradeId !== guestTradeId && profession.tradeId !== unassignedTradeId
								),
								[
									(profession: IProfession) => profession.sortIndex,
									(profession: IProfession) => getTradeGroupTranslation(profession),
								],
								['asc', 'asc']
							)}
							groupByFunction={(option: IProfession) => getTradeGroupTranslation(option)}
							required
							value={tagFields.profession}
							onChange={(value) => handleInputChanged(value, 'profession')}
							label={translationService.get('profession')}
							optionGroupProperty={'tradeGroup'}
							optionKey={'_id'}
							optionColorPropertyFunction={(profession) =>
								getProfessionBackgroundColor(profession, projectId)
							}
							translationService={translationService}
							disabled={isUserChoseGuestType()}
						/>
					)}
				</>
				{tagFields.type !== guestTradeId && (
					<>
						<div className={classes.idAndNameSection}>
							<TrusstorTextInput
								value={tagFields.workerName}
								placeholder={translationService.get(`workerName`)}
								changeFunc={(value) => handleInputChanged(value, 'workerName')}
							/>
							<TrusstorTextInput
								value={tagFields.idNumber}
								placeholder={translationService.get('idNumber')}
								changeFunc={(value) => handleInputChanged(value, 'idNumber')}
							/>
						</div>
						<div className={classes.mobileInputContainer}>
							<MobilePhoneInputField
								phoneNumber={tagFields.phoneNumber}
								onChangeFunction={(value) => handleInputChanged(value, 'phoneNumber')}
								displayError={false}
								setIsValidPhoneNumber={setIsValidPhoneNumber}
								setCountryCode={setCountryCode}
							/>
						</div>
					</>
				)}
			</section>
			<section className={classes.buttonsContainer}>
				<TrusstorButton
					text={translationService.get('cancel')}
					className={classes.secondaryButton}
					handleClick={handleCancelClick}
				/>
				<TrusstorButton
					disabled={false}
					text={translationService.get('assign')}
					className={`${classes.primaryButton} ${
						checkIfAssignTagButtonAvailable() ? '' : classes.disabledButton
					}`}
					handleClick={handleAssignClick}
				/>
			</section>
		</>
	);
};

export { AssignTag };
