import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { create } from 'jss';
import rtl from 'jss-rtl';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import App from './App';
import { store } from './store/store';
import './styles/main.scss';
import { initializeBootstrapUtilities } from './utils/bootstrap.utils';
import { requestService, streamService, translationService } from './servicesInitializer';
import { ISharedServicesContext, SharedServicesContext } from '../../shared/hooks/sharedServices.context';

const queryClient = new QueryClient();
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

initializeBootstrapUtilities();

const rootElement: HTMLElement | null = document.getElementById('root');
if (!rootElement) {
	throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);

const sharedServiceContext: ISharedServicesContext = {
	requestService,
	translationService,
};

window.onbeforeunload = function () {
	streamService.closeAllStreams();
};

root.render(
	<Provider store={store}>
		<SharedServicesContext.Provider value={sharedServiceContext}>
			<QueryClientProvider client={queryClient}>
				<Router>
					<StylesProvider jss={jss}>
						<PostHogProvider client={posthog}>
							<App />
						</PostHogProvider>
					</StylesProvider>
				</Router>
			</QueryClientProvider>
		</SharedServicesContext.Provider>
	</Provider>
);

export * from './servicesInitializer';
