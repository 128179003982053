import React from 'react';
import { IMonitorResourcesData, useMonitorResources } from '@src/hooks/monitor.hooks';
import { Loader } from '@shared/components/Loader/Loader';
import { useFloorDataQuery } from '@src/hooks/queries/floors.queries.hooks';
import { IFloor } from '@shared/interfaces/IFloor';
import { sortFloors } from '@shared/utils/sort.utils';
import { SORT_ORDER } from '@shared/constants/constants';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { translationService } from '@src/index';
import { useHistory } from 'react-router';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import { getFloorCountByResourceFromResourcesCount } from '@src/components/pages/MonitorPage/MonitorPage.utils';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { useAreasDataQuery } from '@src/hooks/queries/areas.queries.hooks';
import { IResourceCountData } from '@src/hooks/queries/resourceCountByLocation.queries.hooks';
import { LocationCountSummaryCard } from './LocationCountSummaryCard/LocationCountSummaryCard';
import classes from './styles.module.scss';

interface ILocationsCountSummaryCardsSectionProps {
	sortOrder: SORT_ORDER;
	floorIds: string[];
}

export const LocationsCountSummaryCardsSection = (props: ILocationsCountSummaryCardsSectionProps) => {
	const history = useHistory();
	const monitorResourcesData: IMonitorResourcesData = useMonitorResources(props.floorIds);
	const floors: IFloor[] = useFloorDataQuery();
	const projectAreas: IConfigArea[] = useAreasDataQuery();

	const filteredFloorsByFloorIds: IFloor[] = props.floorIds.length
		? floors.filter((floor) => props.floorIds.includes(floor.floorId))
		: floors;

	const sortedFloors: IFloor[] = sortFloors(filteredFloorsByFloorIds, props.sortOrder);
	const regularFloors: IFloor[] = sortedFloors.filter((floor: IFloor) => !floor.isSpecialFloor);
	const specialFloors: IFloor[] = sortedFloors.filter((floor: IFloor) => floor.isSpecialFloor);

	if (!monitorResourcesData.areAllResourcesLoaded) {
		return <Loader />;
	}

	const onSummaryCardClick = (floor: IFloor) => {
		const floorAreas: IConfigArea[] = projectAreas.filter((area) => area.floorId === floor.floorId);
		const isMultiAreaFloor: boolean = floorAreas?.length > 1;
		if (isMultiAreaFloor) {
			history.push(`${AppRoutes.monitorFloor}/${floor.floorId}`);
			return;
		}
		if (floorAreas?.[0]) {
			history.push(`${AppRoutes.monitorArea}/${floorAreas[0].areaId}`);
		}
	};

	const getFloorLocationCountSummaryCardElement = (floor: IFloor) => {
		const floorCountByResource: Record<MonitorResourcesEnum, IResourceCountData> =
			monitorResourcesData.resourcesCount;
		return (
			<div className={classes.cardContainer}>
				<LocationCountSummaryCard
					floor={floor}
					onClick={() => onSummaryCardClick(floor)}
					countData={getFloorCountByResourceFromResourcesCount(floorCountByResource, floor.floorId)}
				/>
			</div>
		);
	};

	return (
		<div className={classes.locationsCountSummaryCardsSectionContainer}>
			{regularFloors.map(getFloorLocationCountSummaryCardElement)}
			{!!specialFloors?.length && (
				<div className={classes.specialFloorsHeader}>{translationService.get('specialAreas')}</div>
			)}
			{specialFloors.map(getFloorLocationCountSummaryCardElement)}
		</div>
	);
};
