import React, { useContext, useMemo } from 'react';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { WorkerPageViewTypes } from '@src/models/WorkerPageViewTypes';
import { SORT_ORDER } from '@shared/constants/constants';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { isUserPreferencesDefined } from '@utils/filter.utils';
import { translationService } from '@src/servicesInitializer';
import { MonitorMainPageContext } from '@src/components/pages/MonitorPage/MonitorMainPage/MonitorMainPage';
import { CustomizedFilterButton } from '@src/components/CostumizedFilterButton/CostumizedFilterButton';
import { LocationsCountSummaryCardsSection } from './LocationsCountSummaryCardsSection/LocationsCountSummaryCardsSection';
import classes from './styles.module.scss';

interface IMonitorPageProps {
	drawerSortOption: SORT_ORDER;
	setIsDrawerOpen: (value: boolean) => void;
}

export const MonitorPage = (props: IMonitorPageProps) => {
	const { isCustomizedSelected, openSearch } = useContext(MonitorMainPageContext)!;
	const drawerViewType: WorkerPageViewTypes = isCustomizedSelected
		? WorkerPageViewTypes.PERSONAL_VIEW
		: WorkerPageViewTypes.ALL_WORKERS;

	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const userPreferencesDefined: boolean = isUserPreferencesDefined(userPreferences);

	const filteredFloorIds: string[] | undefined = useMemo(() => {
		if (drawerViewType === WorkerPageViewTypes.ALL_WORKERS) {
			return undefined;
		}
		if (!userPreferencesDefined) {
			return undefined;
		}
		return userPreferences?.mobile?.filters?.floorIds?.length
			? userPreferences?.mobile?.filters?.floorIds
			: undefined;
	}, [drawerViewType]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.searchContainer}>
				<div className={classes.content} onClick={openSearch}>
					<TrusstorIcon iconName={IconNames.pinFilled} />
					<div className={classes.input}>
						{translationService.get('monitorPage_mainPage_searchPlaceholder')}
					</div>
				</div>
			</div>
			<div className={classes.monitorPageContainer}>
				<div className={classes.topSection}>
					<p className={classes.topText}>{translationService.get('locations')}</p>
					<CustomizedFilterButton
						isCustomizedSelected={isCustomizedSelected}
						onClick={() => props.setIsDrawerOpen(true)}
					/>
				</div>
				<LocationsCountSummaryCardsSection
					sortOrder={props.drawerSortOption}
					floorIds={filteredFloorIds || []}
				/>
			</div>
		</div>
	);
};
