import React from 'react';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { NotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import classes from './styles.module.scss';
import { NotificationCard } from './NotificationCard/NotificationCard';
import { RestrictedAreaSection } from './RestrictedAreaSection/RestrictedAreaSection';

interface INotificationCardSectionProps {
	card: INotificationCard;
	index: number;
}

const NotificationCardSection = (props: INotificationCardSectionProps) => (
	<div key={`notificationCard_${props.card.type}`}>
		{props.index !== 0 && <div className={classes.divider} />}
		{props.card.type === NotificationTypes.RestrictedArea ? (
			<RestrictedAreaSection />
		) : (
			<NotificationCard card={props.card} />
		)}
	</div>
);

export { NotificationCardSection };
