import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { selectProjectId } from '../../store/slices/project.slice';
import { requestService } from '../../index';

export const useAreasDataQuery = (): IConfigArea[] => {
	const projectId: string = useSelector(selectProjectId)!;
	const { data: areas } = useQuery<IConfigArea[]>(
		['areasData', projectId],
		() => requestService.get(`/projectConfig/area?projectId=${projectId}`),
		{
			useErrorBoundary: true,
			initialData: [],
			cacheTime: Infinity,
		}
	);
	return areas;
};

export const useAreasByFloorIdDataQuery = (floorId: string): IConfigArea[] | undefined => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: areas } = useQuery<IConfigArea[]>(
		['floorAreasData', projectId, floorId],
		() => requestService.get(`/projectConfig/area?projectId=${projectId}&floorId=${floorId}`),
		{
			initialData: [],
			useErrorBoundary: true,
			cacheTime: Infinity,
		}
	);
	return areas;
};

export const useAreaByAreaIdDataQuery = (areaId: string): { area: IConfigArea | undefined; isFetching } => {
	const projectId: string | undefined = useSelector(selectProjectId);
	const { data: area, isFetching } = useQuery<IConfigArea>(
		[QueryKeys.areaByAreaId, projectId, areaId],
		() =>
			requestService.get(`/projectConfig/area/${areaId}`, {
				params: {
					projectId,
				},
			}),
		{
			refetchOnWindowFocus: false,
			useErrorBoundary: true,
			cacheTime: Infinity,
		}
	);
	return { area, isFetching };
};
