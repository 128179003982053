import React from 'react';
import { IAreaSequenceItem } from '@shared/interfaces/IAreaSequenceItem';
import { IProfession } from '@shared/interfaces/IProfession';
import { IConfigArea } from '@shared/interfaces/IConfigArea';
import { IIssue } from '@shared/interfaces/IIssue';
import { AsiReviewDialogContent } from '@shared/components/ASIReviewDialogContent/ASIReviewDialogContent';
import { GeneralDialog } from '../Dialogs/GeneralDialog/GeneralDialog';
import { translationService } from '../../servicesInitializer';

interface IAsiReviewDialogProps {
	profession: IProfession;
	projectId: string;
	area: IConfigArea;
	sequenceItemDescription: string;
	show: boolean;
	onClose: () => void;
	onRejectSubmit: () => void;
	onAcceptSubmit: () => void;
	onCreateIssue?: (issue: IIssue) => void;
	asi: IAreaSequenceItem;
}

export const AsiReviewDialog = (props: IAsiReviewDialogProps) => (
	<GeneralDialog title={translationService.get('asiReviewDialog_title')} show={props.show} close={props.onClose}>
		<AsiReviewDialogContent
			projectId={props.projectId}
			area={props.area}
			sequenceItemDescription={props.sequenceItemDescription}
			asi={props.asi}
			handleRejectClick={props.onRejectSubmit}
			handleApproveClick={props.onAcceptSubmit}
			profession={props.profession}
		/>
	</GeneralDialog>
);
