import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { IProfession } from '@shared/interfaces/IProfession';
import { IIssueAssignee, IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { ongoingIssuesStatuses } from '@shared/constants/issues.constants';
import type { IRootState } from './index';

interface IFiltersReducerState {
	selectedFloors: IBaseFloor[];
	selectedProfessions: IProfession[];
	selectedAssignees: IIssueAssignee[];
	selectedStatuses: IssueStatus[];
	selectedPriorities: IssuePriority[];
}

const initialState: IFiltersReducerState = {
	selectedFloors: [],
	selectedProfessions: [],
	selectedAssignees: [],
	selectedStatuses: ongoingIssuesStatuses,
	selectedPriorities: [],
};

const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		addFloor: (state: IFiltersReducerState, action: PayloadAction<{ floor: IBaseFloor }>) => {
			state.selectedFloors = [...state.selectedFloors, action.payload.floor];
		},
		removeFloor: (state: IFiltersReducerState, action: PayloadAction<{ floor: IBaseFloor }>) => {
			state.selectedFloors = state.selectedFloors.filter(
				(floor) => floor.floorId !== action.payload.floor.floorId
			);
		},
		clearFloors: (state: IFiltersReducerState) => {
			state.selectedFloors = [];
		},
		addProfession: (state: IFiltersReducerState, action: PayloadAction<{ profession: IProfession }>) => {
			state.selectedProfessions = [...state.selectedProfessions, action.payload.profession];
		},
		removeProfession: (state: IFiltersReducerState, action: PayloadAction<{ profession: IProfession }>) => {
			state.selectedProfessions = state.selectedProfessions.filter(
				(profession) => profession._id !== action.payload.profession._id
			);
		},
		clearProfessions: (state: IFiltersReducerState) => {
			state.selectedProfessions = [];
		},
		addAssignee: (state: IFiltersReducerState, action: PayloadAction<{ assignee: IIssueAssignee }>) => {
			state.selectedAssignees = [...state.selectedAssignees, action.payload.assignee];
		},
		removeAssignee: (state: IFiltersReducerState, action: PayloadAction<{ assignee: IIssueAssignee }>) => {
			state.selectedAssignees = state.selectedAssignees.filter(
				(assignee) => assignee.username !== action.payload.assignee.username
			);
		},
		clearAssignees: (state: IFiltersReducerState) => {
			state.selectedAssignees = [];
		},
		addStatus: (state: IFiltersReducerState, action: PayloadAction<{ status: IssueStatus }>) => {
			state.selectedStatuses = [...state.selectedStatuses, action.payload.status];
		},
		removeStatus: (state: IFiltersReducerState, action: PayloadAction<{ status: IssueStatus }>) => {
			state.selectedStatuses = state.selectedStatuses.filter((status) => status !== action.payload.status);
		},
		clearStatuses: (state: IFiltersReducerState) => {
			state.selectedStatuses = [];
		},
		addPriority: (state: IFiltersReducerState, action: PayloadAction<{ priority: IssuePriority }>) => {
			state.selectedPriorities = [...state.selectedPriorities, action.payload.priority];
		},
		removePriority: (state: IFiltersReducerState, action: PayloadAction<{ priority: IssuePriority }>) => {
			state.selectedPriorities = state.selectedPriorities.filter(
				(priority) => priority !== action.payload.priority
			);
		},
		clearPriorities: (state: IFiltersReducerState) => {
			state.selectedPriorities = [];
		},
	},
});

export const selectSelectedFloors = (state: IRootState) => state.filterReducer.selectedFloors;
export const selectSelectedProfessions = (state: IRootState) => state.filterReducer.selectedProfessions;
export const selectSelectedAssignees = (state: IRootState) => state.filterReducer.selectedAssignees;
export const selectSelectedStatuses = (state: IRootState) => state.filterReducer.selectedStatuses;
export const selectSelectedPriorities = (state: IRootState) => state.filterReducer.selectedPriorities;

export const {
	addFloor,
	addPriority,
	addProfession,
	addStatus,
	addAssignee,
	removeFloor,
	removePriority,
	removeProfession,
	removeStatus,
	removeAssignee,
	clearFloors,
	clearPriorities,
	clearProfessions,
	clearStatuses,
	clearAssignees,
} = filtersSlice.actions;
export default filtersSlice.reducer;
