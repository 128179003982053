import { ActivityGroupStatus } from '@shared/interfaces/ActivityGroupStatus.enum';
import { IWorkplanWidgetRealtimeActivityReport } from '@shared/interfaces/IWorkplanWidgetRealtimeActivityReport';
import { Loader } from '@shared/components/Loader/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { SORT_ORDER } from '@shared/constants/constants';
import { useSelector } from 'react-redux';
import { sortFloors } from '@shared/utils/sort.utils';
import { IActivityRealtimeInfoByFloors } from '../../interfaces/IActivityRealtimeInfoByFloors';
import { useActivitiesRealtimeDataByFloorsQuery } from '../../hooks/queries/activities.queries.hooks';
import {
	addReportDataToActivitiesByFloors,
	filterActivityByFloorByUserPreferences,
} from '../../utils/activities.utils';
import { IActivityByFloor, IActivityByFloorData } from '../../interfaces/IActivityByFloor';
import { ActivitiesFloorCard } from '../pages/ActivitiesPage/ActivitiesFloorsCard/ActivitiesFloorCard';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import classes from './styles.module.scss';

interface ICompletedActivitiesByFloorProps {
	activitiesReportData?: IWorkplanWidgetRealtimeActivityReport[];
	isPersonalView: boolean;
	refetchAllData: () => Promise<void>;
}

export const CompletedActivitiesByFloor = (props: ICompletedActivitiesByFloorProps) => {
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const [activitiesByFloorToDisplay, setActivityByFloorToDisplay] = useState<IActivityByFloor | null>(null);
	const [selectedActivityForActions, setSelectedActivityForAction] = useState<IActivityByFloorData | null>(null);

	const {
		activitiesRealtimeByFloor,
		refetchFloors,
	}: {
		activitiesRealtimeByFloor: IActivityRealtimeInfoByFloors | undefined;
		refetchFloors: () => any;
	} = useActivitiesRealtimeDataByFloorsQuery([ActivityGroupStatus.complete]);

	useEffect(() => {
		if (!activitiesRealtimeByFloor) {
			return;
		}

		const activityByFloor: IActivityByFloor = addReportDataToActivitiesByFloors(
			activitiesRealtimeByFloor,
			props.activitiesReportData || []
		);

		const activitiesByFloorFiltered: IActivityByFloor = props.isPersonalView
			? filterActivityByFloorByUserPreferences(activityByFloor, userPreferences!)
			: activityByFloor;
		setActivityByFloorToDisplay(activitiesByFloorFiltered);
	}, [activitiesRealtimeByFloor, props.activitiesReportData, props.isPersonalView, userPreferences]);

	const refetchData = async () => {
		await Promise.all([props.refetchAllData(), refetchFloors()]);
	};

	const resetSelectedActivityForAction = useCallback(() => setSelectedActivityForAction(null), []);
	const changeSelectedActivityForAction = useCallback(
		(activity: IActivityByFloorData) => setSelectedActivityForAction(activity),
		[]
	);

	if (!activitiesByFloorToDisplay) {
		return <Loader />;
	}

	return (
		<div className={classes.activitiesCardsContainer}>
			{sortFloors<{ floor: IBaseFloor; activitiesData: IActivityByFloorData[] }>(
				Object.values(activitiesByFloorToDisplay),
				SORT_ORDER.DESCENDING,
				'floor.floorId'
			).map(({ floor, activitiesData }) => (
				<ActivitiesFloorCard
					floor={floor}
					activitiesData={activitiesData}
					key={floor.floorId}
					setSelectedActivityForAction={changeSelectedActivityForAction}
				/>
			))}
		</div>
	);
};
