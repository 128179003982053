import { ICreateIssue } from '@shared/interfaces/IIssue';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IssuesQueryKeysEnum } from '@src/hooks/queries/issues.queries.hooks';
import { requestService } from '../servicesInitializer';

export const useCreateIssueMutation = (projectId: string, username) => {
	const queryClient = useQueryClient();

	return useMutation(
		async (issue: ICreateIssue) =>
			requestService.post(`/issues`, {
				body: issue,
			}),
		{
			onMutate: async () => {
				await queryClient.cancelQueries([IssuesQueryKeysEnum.getIssuesByProject, projectId, username]);
			},
			onSuccess: (data) => {
				queryClient.invalidateQueries([IssuesQueryKeysEnum.getIssuesByProject, projectId, username]);
				return data;
			},
		}
	);
};
