import React from 'react';
import classnames from 'classnames';
import classes from './styles.module.scss';
import filledRadioButtonIcon from '../../../assets/filled_radio_button.svg';
import radioButtonIcon from '../../../assets/radio_button.svg';
import { translationService } from '../../../servicesInitializer';

interface ISetupCardProps {
	onCardClick: () => void;
	title: string;
	text: string;
	recommendedRoles: string[];
	isSelected: boolean;
}

const SetupCard = (props: ISetupCardProps) => (
	<div
		className={classnames(classes.setupCardContainer, { [classes.isSelected]: props.isSelected })}
		onClick={props.onCardClick}
	>
		<div className={classes.titleSection}>
			<img src={props.isSelected ? filledRadioButtonIcon : radioButtonIcon} alt="" />
			<div className={classes.title}>{props.title}</div>
		</div>
		<div className={classes.cardText}>{props.text}</div>
		<div className={classes.recommendedRoles}>
			{props.recommendedRoles.map((role) => (
				<div className={classes.role}>{translationService.get(role)}</div>
			))}
		</div>
	</div>
);

export { SetupCard };
