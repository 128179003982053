import React from 'react';
import classnames from 'classnames';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IDashboardTabItem } from '@interfaces/IDashboardTabItem';
import { Loader } from '@shared/components/Loader/Loader';
import classes from './styles.module.scss';

interface IDashboardTabProps extends IDashboardTabItem {
	isSelected: boolean;
	selectTab: () => void;
}

export const DashboardTab = (props: IDashboardTabProps) => (
	<div
		className={classnames(classes.tabContainer, { [classes.selectedItem]: props.isSelected })}
		onClick={props.selectTab}
	>
		<div className={classes.itemsContainer}>
			{props.items.map((item, index) => (
				<div key={index}>
					<div className={classes.item}>
						<div
							className={classnames(classes.iconValueContainer, {
								[classes.selectedItemText]: props.isSelected,
							})}
						>
							<TrusstorIcon iconName={item.icon} />
							{item.value === undefined ? <Loader /> : <p className={classes.valueText}>{item.value}</p>}
						</div>
					</div>
					{index !== props.items.length - 1 && <div className={classes.divider} />}
				</div>
			))}
		</div>
		<p className={classes.tabLabel}>{props.label}</p>
	</div>
);
