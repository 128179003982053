import React, { useContext, useState } from 'react';
import { projectAccessLevelService, translationService } from '@src/index';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { IIssue } from '@interfaces/IIssue';
import { BreadCrumbs, IBreadCrumbItem } from '@src/components/BreadCrumbs/BreadCrumbs';
import { useHistory } from 'react-router';
import { IUser } from '@shared/interfaces/IUser';
import { useSelector } from 'react-redux';
import { selectLoggedUserDetails } from '@store/slices/login.slice';
import { MonitorMainPageContext } from '@src/components/pages/MonitorPage/MonitorMainPage/MonitorMainPage';
import classes from './styles.module.scss';
import { IssueUpsertPage } from '../../IssuesPage/IssueUpsertPage/IssueUpsertPage';

interface ILocationMonitorHeaderProps {
	text: string | undefined;
	onIssueCreated: (issue: IIssue) => void;
	issueLocations?: IIssueLocation[];
	breadCrumbsItems: IBreadCrumbItem[];
}

export const LocationMonitorHeader = (props: ILocationMonitorHeaderProps) => {
	const { openSearch } = useContext(MonitorMainPageContext)!;
	const isRtl: boolean = translationService.getIsRtl();
	const history = useHistory();
	const [showCreateIssueModal, setShowCreateIssueModal] = useState<boolean>(false);
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const isLimitedUser: boolean = user.permissions.roleType === 'VIEWER';
	const isProjectAccessLevel: boolean = projectAccessLevelService.hasAccess('issuesPage');
	const shouldShowPlusIcon: boolean = !isLimitedUser && isProjectAccessLevel;

	const closeCreateModal = () => {
		setShowCreateIssueModal(false);
	};

	const backwardArrowIconName: IconNames = isRtl ? IconNames.arrowRight : IconNames.arrowLeft;
	return (
		<div className={classes.container}>
			<div className={classes.locationMonitorHeaderContainer}>
				<TrusstorIcon
					iconName={backwardArrowIconName}
					size={IconSize.LARGE}
					color={IconColor.White}
					onClick={() => history.goBack()}
				/>
				<p className={classes.headerText}>{props.text}</p>
				<div className={classes.endContainer}>
					<TrusstorIcon
						iconName={IconNames.search}
						color={IconColor.White}
						size={IconSize.LARGE}
						onClick={openSearch}
					/>
					{shouldShowPlusIcon && (
						<div className={classes.plusIconContainer} onClick={() => setShowCreateIssueModal(true)}>
							<TrusstorIcon iconName={IconNames.plus} color={IconColor.Black} size={IconSize.SMALL} />
						</div>
					)}
				</div>
				<IssueUpsertPage
					show={showCreateIssueModal}
					onClose={closeCreateModal}
					initialIssue={{ locations: props.issueLocations }}
					onIssueCreated={props.onIssueCreated}
				/>
			</div>
			<div className={classes.breadCrumbsItems}>
				<BreadCrumbs items={props.breadCrumbsItems} />
			</div>
		</div>
	);
};
