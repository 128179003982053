import { IBaseFloor } from '@shared/interfaces/IFloorBase';
import * as React from 'react';
import { translationService } from '../../index';
import classes from './styles.module.scss';

interface IFloorHeaderProps {
	floor: IBaseFloor;
	forceFloorHeader?: boolean;
}

export const FloorHeader = (props: IFloorHeaderProps) => {
	const floorText: string = translationService.get('floor');
	const doesStartsOrEndsWithFloor: boolean =
		props.floor.floorNick.toLowerCase().startsWith(floorText.toLowerCase()) ||
		props.floor.floorNick.toLowerCase().endsWith(floorText.toLowerCase());
	if (!doesStartsOrEndsWithFloor && !props.forceFloorHeader) {
		return (
			<div className={classes.textContainer}>
				<p className={classes.floorNickText}>{props.floor.floorNick}</p>
			</div>
		);
	}
	const isRtl: boolean = translationService.getIsRtl();
	const labelWithoutFloor: string = props.floor.floorNick.toLowerCase().replace(floorText.toLowerCase(), '');
	return (
		<div className={classes.textContainer}>
			<p className={`${classes.floorHeader} ${!isRtl ? classes.ltrFloorHeader : ''}`}>{floorText}</p>
			<p className={classes.floorLabel}>{labelWithoutFloor}</p>
		</div>
	);
};
