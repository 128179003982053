import React from 'react';
import { issuesIcon } from '@shared/assets/icons';
import { ProjectPackageTypeEnum } from '@shared/interfaces/ProjectPackageType.enum';
import { projectAccessLevelService } from '@src/servicesInitializer';
import { INavbarItemProps } from '@interfaces/INavbarItemProps';
import { AppRoutes } from '@src/constants/appRoutes.enum';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';
import NavbarItem from './NavbarItem/NavbarItem';
import WorkerIconThick from '../../assets/Navbar/WorkerThickIcon.svg';
import EquipmentThickIcon from '../../assets/Navbar/EquipmentThickIcon.svg';
import MoreThickIcon from '../../assets/Navbar/MoreThickIcon.svg';
import ActivitiesThickIcon from '../../assets/Navbar/ActivitiesThickIcon.svg';
import MonitorThickIcon from '../../assets/Navbar/MonitorThickIcon.svg';
import classes from './styles.module.scss';
import {
	INavbarItemWithCustomIconProps,
	NavbarItemWithCustomIcon,
} from './NavbarItemWithCustomIcon/NavbarItemWithCustomIcon';
import { NotificationImageElement } from './NotificationImageElement/NotificationImageElement';

const getNavbarItems = (isMobileMonitorEnabled: boolean): (INavbarItemProps | INavbarItemWithCustomIconProps)[] => [
	...(isMobileMonitorEnabled
		? [{ icon: MonitorThickIcon, name: 'mobileMonitor_monitor', pathName: AppRoutes.monitor }]
		: []),
	...(projectAccessLevelService.hasAccess('personnelPage')
		? [
				{
					icon: WorkerIconThick,
					name: 'Personnel',
					pathName: AppRoutes.personnelWithNoMode,
				},
			]
		: []),
	...(projectAccessLevelService.hasAccess('activitiesPage')
		? [
				{
					icon: ActivitiesThickIcon,
					name: 'Activities',
					pathName: AppRoutes.activities,
				},
			]
		: []),
	...(projectAccessLevelService.hasAccess('equipmentPage') &&
	projectAccessLevelService.isProjectPackageType(ProjectPackageTypeEnum.monitor)
		? [
				{
					icon: EquipmentThickIcon,
					name: 'Equipment',
					pathName: AppRoutes.equipment,
				},
			]
		: []),
	...(projectAccessLevelService.hasAccess('issuesPage')
		? [
				{
					icon: issuesIcon,
					name: 'issues',
					pathName: AppRoutes.issues,
				},
			]
		: []),
	{
		imageElement: NotificationImageElement,
		name: 'sideBar_notifications',
		pathName: AppRoutes.notifications,
	},
	{
		icon: MoreThickIcon,
		name: 'More',
		pathName: AppRoutes.myProfile,
	},
];

function Navbar() {
	const isMobileMonitorEnabled: boolean = useIsFeatureFlagEnabled(FeatureFlagNamesEnum.useMobileMonitor);
	const navbarItems = getNavbarItems(isMobileMonitorEnabled);

	return (
		<div className={classes.navbar}>
			{navbarItems.map((sideBarItem) =>
				'imageElement' in sideBarItem ? (
					<NavbarItemWithCustomIcon
						key={sideBarItem.pathName}
						imageElement={(sideBarItem as INavbarItemWithCustomIconProps).imageElement}
						name={sideBarItem.name}
						pathName={sideBarItem.pathName}
					/>
				) : (
					<NavbarItem
						key={sideBarItem.pathName}
						icon={(sideBarItem as INavbarItemProps).icon}
						name={sideBarItem.name}
						pathName={sideBarItem.pathName}
						className={sideBarItem.className}
					/>
				)
			)}
		</div>
	);
}

export default Navbar;
