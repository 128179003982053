import React from 'react';
import classnames from 'classnames';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import classes from './styles.module.scss';
import { TrusstorIcon } from '../../../../TrusstorIcon/TrusstorIcon';

interface ISelectSectionProps {
	iconName: IconNames;
	text: string;
	onClick: () => void;
	selectedComponent: JSX.Element | null;
	disabled?: boolean;
}

export const SelectSection = (props: ISelectSectionProps) => (
	<div
		className={classnames(classes.selectSection, { [classes.isDisabled]: props.disabled })}
		onClick={props.onClick}
	>
		<TrusstorIcon iconName={props.iconName} size={IconSize.MEDIUM} color={IconColor.Grey500} />
		{props.selectedComponent ? <>{props.selectedComponent}</> : <div className={classes.text}>{props.text}</div>}
	</div>
);
