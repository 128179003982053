import { IConfigFloor } from '@shared/interfaces/IConfigFloor';

import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IActiveManager } from '@shared/interfaces/IActiveManager';
import { requestService } from '../../index';
import { WorkerPageViewTypes } from '../../models/WorkerPageViewTypes';
import { IRootState } from '../../store/slices';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import FloorWorkerData from '../FloorWorkerData/FloorWorkerData';
import { PersonalViewEmptyState } from '../pages/PersonalViewEmptyState/PersonalViewEmptyState';
import { addInactiveFloors, filterFloorsByProfessionIdsPreferences } from './FloorsWorkersDataList.utils';
import { isUserPreferencesDefined } from '../../utils/filter.utils';
import { IFloorWorkerData } from '../../interfaces/IFloorWorkerData';
import { ActiveManagersList } from '../ActiveManagersList/ActiveManagersList';

interface IFloorWorkerDataListProps {
	viewType?: WorkerPageViewTypes;
	floorsData?: IFloorWorkerData[];
	activeManagers: IActiveManager[];
}

export const FloorsWorkersDataList = (props: IFloorWorkerDataListProps) => {
	const projectId: string | undefined = useSelector(
		(state: IRootState) => state.projectReducer.workingProject?.projectId
	);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);

	const { data: projectFloors } = useQuery<IConfigFloor[]>(
		['floorsData', projectId],
		() => requestService.get(`/projectConfig/floor?projectId=${projectId}`),
		{
			useErrorBoundary: true,
		}
	);

	const getEmptyPageComponent = () => {
		if (props.viewType === WorkerPageViewTypes.PERSONAL_VIEW) {
			return <PersonalViewEmptyState />;
		}
		return <Redirect to="/projectNotReady" />;
	};

	if (!props.floorsData || !projectFloors) {
		// implement loader here
		return <div></div>;
	}
	if (
		(props.viewType === WorkerPageViewTypes.PERSONAL_VIEW && !isUserPreferencesDefined(userPreferences)) ||
		projectFloors.length === 0
	) {
		return getEmptyPageComponent();
	}
	const doesPreferencesFloorIdsExists: boolean = !!(
		userPreferences?.mobile?.filters?.floorIds && userPreferences!.mobile?.filters?.floorIds.length > 0
	);
	const filteredConfigFloors: IConfigFloor[] =
		props.viewType === WorkerPageViewTypes.PERSONAL_VIEW && doesPreferencesFloorIdsExists
			? projectFloors.filter((floor) => userPreferences!.mobile?.filters?.floorIds?.includes(floor.floorId))
			: projectFloors;
	const sortedFloorsComponentData: IFloorWorkerData[] = addInactiveFloors(props.floorsData, filteredConfigFloors);
	const userPreferencesProfessionIds: string[] = userPreferences?.mobile?.filters?.professionIds || [];
	const filteredFloorsComponentData: IFloorWorkerData[] = filterFloorsByProfessionIdsPreferences(
		sortedFloorsComponentData,
		userPreferencesProfessionIds,
		props.viewType === WorkerPageViewTypes.PERSONAL_VIEW
	);

	if (sortedFloorsComponentData.length === 0) {
		return getEmptyPageComponent();
	}
	return (
		<React.Fragment>
			{filteredFloorsComponentData.map((floor: IFloorWorkerData) => (
				<FloorWorkerData activeManagers={props.activeManagers} floor={floor} key={floor.floor.floorId} />
			))}
			<ActiveManagersList viewType={props.viewType} />
		</React.Fragment>
	);
};
