import React from 'react';
import classnames from 'classnames';
import { translationService } from '@src/index';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { useHistory } from 'react-router';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';

export interface IBreadCrumbItem {
	title: string | undefined;
	link: string;
	isSelected?: boolean;
}

export interface IBreadCrumbsProps {
	items: IBreadCrumbItem[];
}

export const BreadCrumbs = (props: IBreadCrumbsProps) => {
	const isRtl: boolean = translationService.getIsRtl();
	const arrowName: IconNames = isRtl ? IconNames.chevronLeft : IconNames.chevronRight;
	const history = useHistory();

	return (
		<div className={classes.breadCrumbsContainer}>
			{props.items.map((item, index) => (
				<div key={index} className={classes.breadCrumbItem}>
					<p
						className={classnames(classes.linkTitle, {
							[classes.selected]: item.isSelected,
						})}
						onClick={() => {
							if (item.isSelected) {
								return;
							}
							history.push(item.link);
						}}
					>
						{item.title}
					</p>
					{index < props.items.length - 1 && <TrusstorIcon iconName={arrowName} color={IconColor.Grey200} />}
				</div>
			))}
		</div>
	);
};
