import React, { useEffect } from 'react';
import { translationService } from '@src/servicesInitializer';
import { Drawer } from '@material-ui/core';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import classnames from 'classnames';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { DateFlagDisplay } from '@shared/components/DateFlagDisplay/DateFlagDisplay';
import moment from 'moment';
import { getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import { useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { isAsiComplete } from '@shared/utils/asi.utils';
import { ActivityCardSequenceAreas } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardSequenceAreas/ActivityCardSequenceAreas';
import { ActivityCardAdHocFloors } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardAdHocFloors/ActivityCardAdHocFloors';
import { ActivityMenu } from '@src/components/ActivityMenu/ActivityMenu';
import { ActivityCardReviewChip } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardReviewChip/ActivityCardReviewChip';
import { IssueViewDrawer } from '@src/components/IssueViewDrawer/IssueViewDrawer';
import classes from './styles.module.scss';

interface IActivityViewProps {
	show: boolean;
	close: () => void;
	activity: IMainPageWidgetActivityData;
	onCreateIssueClick: () => void;
	handleBackButtonClick: () => void;
	queryKeys: any[];
}

export const ActivityView = (props: IActivityViewProps) => {
	const isRTL: boolean = translationService.getIsRtl();
	const tz: string = useSelector(selectTimezone)!;
	const projectId: string = useSelector(selectProjectId)!;
	const isDueDatePassed: boolean = moment.tz(props.activity.originalEndDate, tz).isBefore(getTimezoneStartOfDate(tz));
	const completedAreaSequenceItemCount: number = props.activity.areaSequenceItems.filter((areaSequenceItem) =>
		isAsiComplete(areaSequenceItem.status)
	).length;
	const [showIssueDrawer, setShowIssueDrawer] = React.useState<boolean>(false);

	useEffect(() => {
		const handleBackNavigation = (e: any) => {
			props.close();
		};

		window.addEventListener('popstate', handleBackNavigation);
		return () => {
			window.removeEventListener('popstate', handleBackNavigation);
		};
	}, []);

	return (
		<Drawer anchor={'bottom'} open={props.show} onClose={() => {}}>
			<IssueViewDrawer
				show={showIssueDrawer}
				groupId={props.activity.groupId}
				onClose={() => setShowIssueDrawer(false)}
			/>
			<div className={classes.drawerContainer} style={{ minHeight: '100dvh' }}>
				<div className={classes.header}>
					<TrusstorIcon
						className={classnames(classes.backArrow, { [classes.isRtl]: isRTL })}
						color={IconColor.White}
						iconName={isRTL ? IconNames.arrowRight : IconNames.arrowLeft}
						onClick={props.handleBackButtonClick}
					/>
					<DateFlagDisplay
						date={props.activity.originalEndDate}
						isOverdue={isDueDatePassed}
						tz={tz}
						isDarkTheme
					/>
					<ActivityMenu
						onCreateIssueClick={() => props.onCreateIssueClick()}
						groupId={props.activity.groupId}
						issues={props.activity.issues}
						menuIconColor={IconColor.White}
						onViewIssuesClick={() => setShowIssueDrawer(true)}
					/>
				</div>
				<div className={classes.content}>
					<div className={classes.descriptionHeader}>
						<div className={classes.description}>{props.activity.description}</div>
						<div>
							<ProfessionDisplayWithTradeIcon
								profession={props.activity.profession}
								projectId={projectId}
							/>
						</div>
					</div>
					<div className={classes.iconsHeader}>
						<div className={classes.startSection}>
							<ActivityCardReviewChip asis={props.activity.areaSequenceItems} />
						</div>
						<div className={classes.endSection}>
							{!!props.activity.issues?.length && (
								<div
									className={classes.issueIcon}
									onClick={(e) => {
										e.stopPropagation();
										setShowIssueDrawer(true);
									}}
									data-testid={'issuesButton'}
								>
									<TrusstorIcon
										iconName={IconNames.issues}
										size={IconSize.MEDIUM}
										color={IconColor.Grey600}
									/>
									<div data-testid={'issuesCount'}>{props.activity.issues?.length}</div>
								</div>
							)}
							{!props.activity.issues?.length && (
								<div
									className={classes.issueIcon}
									onClick={(e) => {
										e.stopPropagation();
										props.onCreateIssueClick();
									}}
									data-testid={'issuesButton'}
								>
									<TrusstorIcon
										iconName={IconNames.issues}
										size={IconSize.MEDIUM}
										color={IconColor.Grey600}
									/>
									<div data-testid={'issuesCount'}>
										{translationService.get('mobile_activities_addIssue')}
									</div>
								</div>
							)}

							{!!props.activity.areaSequenceItems.length && (
								<div className={classnames(classes.asisInfo)}>
									<TrusstorIcon
										iconName={IconNames.checkCircle}
										size={IconSize.MEDIUM}
										color={IconColor.FeedbackPositiveDark}
									/>
									<div data-testid={'completedCount'}>
										{`${completedAreaSequenceItemCount}/${props.activity.areaSequenceItems.length}`}
									</div>
								</div>
							)}
						</div>
					</div>
					{props.activity.areaSequenceItems.length ? (
						<ActivityCardSequenceAreas activity={props.activity} queryKeys={props.queryKeys} />
					) : (
						<ActivityCardAdHocFloors activity={props.activity} />
					)}
				</div>
			</div>
		</Drawer>
	);
};
