import { set } from 'lodash';
import { IOfflineBannerReducerState } from '../../../../shared/interfaces/IOfflineBannerReducerState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { IRootState } from './index';

const initialState: IOfflineBannerReducerState = {
	offlineText: null,
	offlineSubText: null,
	isOffline: false,
};

const offlineBannerSlice = createSlice({
	name: 'offlineBanner',
	initialState,
	reducers: {
		setOfflineText: (
			state: IOfflineBannerReducerState,
			action: PayloadAction<{ text: string | null; subText?: string | null }>
		) => {
			state.offlineText = action.payload.text;
			state.offlineSubText = action.payload.subText || null;
		},
		clearOfflineText: (state: IOfflineBannerReducerState) => {
			state.offlineText = null;
			state.offlineSubText = null;
		},
		setIsOffline: (state: IOfflineBannerReducerState, action: PayloadAction<boolean>) => {
			state.isOffline = action.payload;
		},
	},
});

export const selectOfflineText = (state: IRootState) => state.offlineBannerReducer.offlineText;
export const selectOfflineSubText = (state: IRootState) => state.offlineBannerReducer.offlineSubText;
export const selectIsOffline = (state: IRootState) => state.offlineBannerReducer.isOffline;
export const { setOfflineText, clearOfflineText, setIsOffline } = offlineBannerSlice.actions;
export default offlineBannerSlice.reducer;
