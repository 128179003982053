// This optional code is used to register a service worker.
// register() is not called by default.

import { IConfig } from './interfaces/IConfig';

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(
	vapidPublicKey: string,
	subscribeUserRequest: (subscription: PushSubscription) => Promise<void>,
	config: IConfig
) {
	if ('serviceWorker' in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return;
		}

		const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

		if (isLocalhost) {
			// This is running on localhost. Let's check if a service worker still exists or not.
			checkValidServiceWorker(swUrl, subscribeUserRequest, vapidPublicKey, config);

			// Add some additional logging to localhost, pointing developers to the
			// service worker/PWA documentation.
			navigator.serviceWorker.ready.then(() => {
				console.log(
					'This web app is being served cache-first by a service ' +
						'worker. To learn more, visit https://cra.link/PWA'
				);
			});
		} else {
			// Is not localhost. Just register service worker
			registerValidSW(swUrl, subscribeUserRequest, vapidPublicKey, config);
		}
	}
}

const urlBase64ToUint8Array = (base64String) => {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

	const rawData = atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
};

function registerValidSW(
	swUrl: string,
	subscribeUserRequest: (subscription: PushSubscription) => Promise<void>,
	vapidPublicKey: string,
	config: IConfig
) {
	navigator.serviceWorker
		.register(swUrl)
		.then((registration) => {
			if (!registration.active) {
				console.error('Service worker not active');
				if (registration.installing) {
					registration.installing.addEventListener('statechange', (event: any) => {
						if (event.target.state === 'activated') {
							registration.active!.postMessage({
								type: 'SET_CONFIG',
								config: { defaultRedirectUrl: config.mobileUrl },
							});
						}
					});
				}
			} else {
				registration.active.postMessage({
					type: 'SET_CONFIG',
					config: { defaultRedirectUrl: config.mobileUrl },
				});
			}
			registration.pushManager
				.getSubscription()
				.then((subscription) => {
					if (subscription) {
						return subscription;
					}
					const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
					return registration.pushManager.subscribe({
						userVisibleOnly: true,
						applicationServerKey: convertedVapidKey,
					});
				})
				.then((subscription) => {
					subscribeUserRequest(subscription);
				})
				.catch((error) => {
					console.error('Error during service worker registration:', error);
				});
		})
		.catch((error) => {
			console.error('Error during service worker registration:', error);
		});
}

function checkValidServiceWorker(swUrl, subscribeUserRequest, vapidPublicKey, config: IConfig) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl, {
		headers: { 'Service-Worker': 'script' },
	})
		.then((response) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType = response.headers.get('content-type');
			if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						window.location.reload();
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, subscribeUserRequest, vapidPublicKey, config);
			}
		})
		.catch(() => {
			console.log('No internet connection found. App is running in offline mode.');
		});
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready
			.then((registration) => {
				registration.unregister();
			})
			.catch((error) => {
				console.error(error.message);
			});
	}
}
