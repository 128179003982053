import React, { useEffect, useState } from 'react';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { INotificationTypes } from '@shared/interfaces/NotificationTypes.enum';
import { NotificationChannelTypes } from '@shared/interfaces/NotificationChannelTypes.enum';
import { IUserNotificationSettings } from '@shared/interfaces/INotificationSettings';
import { preDefinedFrequenciesMapping } from '@shared/constants/notifications.constants';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { translationService } from '../../../../index';
import frequencyIcon from '../../../../assets/Notifications/clock.svg';

interface INotificationFrequencyDropdownProps {
	handleSettingsChange: (
		value: string,
		notificationType: INotificationTypes,
		channelName?: NotificationChannelTypes,
		frequency?: boolean
	) => Promise<void>;
	card: INotificationCard;
	userSettings?: IUserNotificationSettings;
}

const getFrequencyByCron = (cron: string | undefined): string => {
	if (!cron) return '';
	for (const [key, value] of Object.entries(preDefinedFrequenciesMapping)) {
		if (value === cron) {
			return key;
		}
	}
	return '';
};

const NotificationFrequencyDropdown = (props: INotificationFrequencyDropdownProps) => {
	const [drownDownValue, setDrownDownValue] = useState<string>('');

	useEffect(() => {
		setDrownDownValue(getFrequencyByCron(props.userSettings?.settings?.[props.card.type]?.frequency));
	}, [props.userSettings, props.card.frequency]);

	const handleDropDownChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setDrownDownValue(event.target.value as string);
		props.handleSettingsChange(event.target.value as string, props.card.type, undefined, true);
	};

	return (
		<TextField
			variant={'outlined'}
			onChange={handleDropDownChange}
			select
			label={drownDownValue ? translationService.get('frequency') : undefined}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<img src={frequencyIcon} alt="icon" style={{ marginInlineEnd: '5px' }} />
						{!drownDownValue && <p>{translationService.get('frequency')} </p>}
					</InputAdornment>
				),
			}}
			value={drownDownValue || getFrequencyByCron(props.userSettings?.settings?.[props.card.type]?.frequency)}
		>
			{props.card.frequency!.map((frequency, index) => (
				<MenuItem key={index} value={frequency}>
					{translationService.get(frequency)}
				</MenuItem>
			))}
		</TextField>
	);
};

export { NotificationFrequencyDropdown };
