import emptyStarIcon from '@shared/assets/icons/emptyStar.svg';
import fullStartIcon from '@shared/assets/icons/fullStar.svg';
import { TrusstorIconButtonDeprecated } from '@shared/components/buttons/TrusstorIconButton/TrusstorIconButtonDeprecated';
import { IssueMobileDisplay } from '@shared/components/Issue/IssueMobileDisplay/IssueMobileDisplay';
import { saveIssueComment, saveIssueImage } from '@shared/components/Issue/issues.utils';
import { Loader } from '@shared/components/Loader/Loader';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { IActivityGroup } from '@shared/interfaces/IActivityGroup';
import { IssuePriority, IssueStatus } from '@shared/interfaces/IIssueShared';
import { IProfession } from '@shared/interfaces/IProfession';
import { IUser } from '@shared/interfaces/IUser';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getColorFromActivityGroupStatus } from '@shared/utils/colors.utils';
import { BackForwardSection } from '@shared/components/BackNextSection/BackForwardSection';
import { IssueReviewDialog } from '@shared/components/Issue/IssueReviewDialog/IssueReviewDialog';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { selectLoggedUserDetails } from '../../store/slices/login.slice';
import { useActivityGroupByIdQuery } from '../../hooks/queries/activities.queries.hooks';
import { useIssuesByGroupIdQuery } from '../../hooks/queries/issues.queries.hooks';
import { IIssue } from '../../interfaces/IIssue';
import { requestService, storageService, translationService } from '../../servicesInitializer';
import { selectTimezone } from '../../store/slices/project.slice';
import { goToActivitiesPage } from '../../utils/activities.utils';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';
import { errorToast } from '../../utils/toast.utils';

interface IMultiIssuesViewProps {
	backPath?: string;
	groupId?: string;
	close?: () => void;
}

export const MultiIssuesViewPage = (props: IMultiIssuesViewProps) => {
	const tz: string = useSelector(selectTimezone)!;
	const history = useHistory();
	const user: IUser = useSelector(selectLoggedUserDetails)!;
	const isLimitedUser: boolean = user.permissions.roleType === 'VIEWER';
	const { groupId: groupIdFromParams }: { groupId: string } = useParams();
	const groupId: string | undefined = props.groupId || groupIdFromParams;
	const issues: IIssue[] | undefined = useIssuesByGroupIdQuery(groupId);
	const [issuesToDisplay, setIssuesToDisplay] = useState<IIssue[] | undefined>(undefined);
	const [openReviewDialog, setOpenReviewDialog] = useState<boolean>(false);

	const activityGroup: IActivityGroup | undefined = useActivityGroupByIdQuery(groupId);
	const [starIcon, setStarIcon] = useState<string>(emptyStarIcon);
	const [issueIndex, setIssueIndex] = useState<number>(0);
	const dispatch = useDispatch();

	const currentIssue: IIssue | undefined = useMemo(
		() => issuesToDisplay?.[issueIndex],
		[issueIndex, issuesToDisplay]
	);

	useEffect(() => {
		if (issues) {
			setIssuesToDisplay(issues);
		}
	}, [issues]);

	const handleReviewCallback = () => {
		setOpenReviewDialog(true);
	};

	const toggleStarIcon = () => {
		setStarIcon((prev) => (prev === fullStartIcon ? emptyStarIcon : fullStartIcon));
	};

	const closePage = () => {
		if (props.close) {
			props.close();
			return;
		}

		if (props.backPath) {
			history.push(props.backPath);
			return;
		}
		goToActivitiesPage(history);
	};

	const updateIssue = async (issueId: string, issueToUpdate: Partial<IIssue>): Promise<IIssue> => {
		const issueBeforeUpdate: IIssue = issuesToDisplay?.find((issue) => issue._id === issueId)!;
		const statusUpdatedData: Partial<IIssue> = {
			statusUpdateDate: new Date(),
			statusUpdateUser: {
				name: user.name,
				username: user.username,
			},
		};
		const isStatusUpdated: boolean = !!(issueToUpdate.status && issueToUpdate.status !== issueBeforeUpdate?.status);

		const optimisticUpdatedIssue: IIssue = {
			...issueBeforeUpdate,
			...issueToUpdate,
			...(isStatusUpdated && statusUpdatedData),
			isInitial: false,
		};

		setIssuesToDisplay((prevIssues) =>
			prevIssues?.map((issue) => {
				if (issue._id === issueId) {
					return {
						...issue,
						...optimisticUpdatedIssue,
					};
				}
				return issue;
			})
		);

		try {
			const issueUpdated: IIssue = await requestService.put(`/issues/${issueId}`, {
				body: issueToUpdate,
			});
			return issueUpdated;
		} catch (e) {
			console.error(e);
			errorToast(dispatch, translationService.get('failedToUpdateGeneric'));
			setIssuesToDisplay((prevIssues) =>
				prevIssues!.map((issue) => {
					if (issue._id === issueId) {
						return {
							...issue,
							...issueBeforeUpdate,
						};
					}
					return issue;
				})
			);
			return {
				...issueBeforeUpdate,
			};
		}
	};

	const handlePriority = async (issue: IIssue, e?: React.MouseEvent): Promise<void> => {
		try {
			e?.stopPropagation();
			toggleStarIcon();
			await updateIssue(issue._id, {
				priority: issue.priority === IssuePriority.CRITICAL ? IssuePriority.REGULAR : IssuePriority.CRITICAL,
			});
		} catch (error) {
			toggleStarIcon();
			console.log(error);
		}
	};

	const handleStatusChange = async (updateStatus: IssueStatus) => {
		if (!currentIssue) {
			return;
		}
		try {
			await updateIssue(currentIssue._id, {
				status: updateStatus,
			});
		} catch (e) {
			console.log(e);
		}
	};

	const saveComment = async (comment: string) => {
		if (!currentIssue?._id) {
			return;
		}
		const { comments } = await saveIssueComment(currentIssue?._id, user, requestService, comment);
		setIssuesToDisplay((prevIssues) =>
			prevIssues!.map((issue) => {
				if (issue._id === currentIssue?._id) {
					return {
						...issue,
						comments,
					};
				}
				return issue;
			})
		);
	};

	const saveImage = async (image: File) => {
		if (!currentIssue?._id) {
			return;
		}
		await saveIssueImage(currentIssue?._id, user, requestService, image);
	};

	const handleRejectClick = async (comment: string) => {
		if (!currentIssue) return;
		setOpenReviewDialog(false);
		await saveComment(comment);
		await updateIssue(currentIssue._id, {
			status: IssueStatus.REJECTED,
		});
	};

	const handleApproveClick = async () => {
		if (!currentIssue) return;
		setOpenReviewDialog(false);
		await updateIssue(currentIssue._id, {
			status: IssueStatus.COMPLETED,
		});
	};

	if (!issuesToDisplay || !activityGroup) return <Loader />;

	return (
		<div className={classes.multiViewContainerPage}>
			<div className={classes.topSection}>
				<TrusstorIconButtonDeprecated
					onClick={closePage}
					iconClassName={classes.arrow}
					iconElement={<TrusstorIcon color={IconColor.Black} iconName={IconNames.close} />}
				/>
			</div>
			<div className={classes.activityHeader}>
				<div
					className={classes.statusBox}
					style={{ backgroundColor: getColorFromActivityGroupStatus(activityGroup.status) }}
				>
					{translationService.get(activityGroup.status)}
				</div>
				<div className={classes.infoSection}>
					<div className={classes.description}>{activityGroup.description}</div>
					{activityGroup.profession && (
						<div className={classes.professionSection}>
							<TrusstorIcon iconName={IconNames.helmet} />
							<div>
								<ProfessionDisplayWithTradeIcon
									profession={activityGroup.profession as IProfession}
									projectId={activityGroup.projectId}
								/>
							</div>
						</div>
					)}
				</div>
			</div>

			{openReviewDialog && currentIssue && (
				<IssueReviewDialog
					issue={currentIssue}
					handleRejectClick={handleRejectClick}
					handleApproveClick={handleApproveClick}
					onClose={() => setOpenReviewDialog(false)}
				/>
			)}
			<div className={classes.issuesContainer}>
				<BackForwardSection totalCount={issuesToDisplay.length} onIndexChange={setIssueIndex} />
				<div className={classes.issueContainer}>
					{currentIssue && (
						<IssueMobileDisplay
							handleReviewCallback={handleReviewCallback}
							isLimitedUser={isLimitedUser}
							requestService={requestService}
							issue={currentIssue}
							tz={tz}
							translationService={translationService}
							storageService={storageService}
							priorityIcon={starIcon}
							handlePriority={() => handlePriority(currentIssue!)}
							updateIssue={updateIssue}
							handleStatusChange={handleStatusChange}
							onClose={() => {
								if (props.backPath) {
									history.push(props.backPath);
									return;
								}
								goToActivitiesPage(history);
							}}
							saveComment={saveComment}
							saveImage={saveImage}
							hideHeader
							hideProfession
							projectId={activityGroup.projectId}
						/>
					)}
				</div>
			</div>
		</div>
	);
};
