import redWarning from '@shared/assets/icons/redWarning.svg';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { getDateDisplayFormat, getTimezoneStartOfDate } from '@shared/utils/dateUtils';
import moment from 'moment-timezone';
import React from 'react';
import { useSelector } from 'react-redux';
import { IActivityByProgress } from '../../interfaces/IActivityByProgress';
import { translationService } from '../../servicesInitializer';
import { selectTimezone } from '../../store/slices/project.slice';
import { ActivityMenu } from '../ActivityMenu/ActivityMenu';

import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import classes from './styles.module.scss';

interface IActivityDescriptionWithEndDateProps {
	activity: IActivityByProgress;
	onCreateIssueClick: () => void;
	styles?: React.CSSProperties;
}

export const ActivityDescriptionWithEndDate = (props: IActivityDescriptionWithEndDateProps) => {
	const tz: string = useSelector(selectTimezone)!;

	const isDueDatePassed: boolean = moment.tz(props.activity.endDate, tz).isBefore(getTimezoneStartOfDate(tz));
	const formmatedEndDate: string = getDateDisplayFormat(props.activity.endDate!, tz, translationService);

	return (
		<div className={classes.container}>
			<div className={classes.description}>
				{props.activity.dangerousActivity && <img alt="" src={redWarning} />}
				<div style={{ ...props.styles }}>{props.activity.description}</div>
			</div>
			<div className={classes.endAligmentPart}>
				<div className={classes.endDate}>
					<TrusstorIcon
						iconName={IconNames.flag}
						size={IconSize.SMALL}
						color={isDueDatePassed ? IconColor.Red900 : IconColor.Grey600}
					/>
					<div className={classes.date}>{formmatedEndDate}</div>
				</div>
				<ActivityMenu
					onCreateIssueClick={() => props.onCreateIssueClick()}
					groupId={props.activity.groupId}
					issues={props.activity.issues}
				/>
			</div>
		</div>
	);
};
