import React, { useState, useMemo, useEffect } from 'react';
import { groupBy, sortBy } from 'lodash';
import { IMergedEquipmentView } from '@shared/interfaces/Equipment/IMergedEquipmentView';
import { Loader } from '@shared/components/Loader/Loader';
import { IStaticEquipment } from '@shared/interfaces/Equipment/IStaticEquipment';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import { translationService } from '../../../index';
import { SearchBar } from '../../Inputs/SearchBar/SearchBar';
import classes from './styles.module.scss';
import { EquipmentCard } from './EquipmentCard/EquipmentCard';
import { useMergedEquipmentQuery, useStaticEquipmentQuery } from '../../../hooks/queries/equipment.queries.hooks';
import drillIcon from '../../../assets/Equipment/drill.svg';
import { EmptyStateComponent } from '../../EmptyStateComponent/EmptyStateComponent';
import { equipmentPageAccess_BI } from '../../../utils/bi.utils';
import { PageHeader } from '../../PageHeader';
import warningIcon from '../../../assets/warning.svg';

const EquipmentPage = () => {
	useRenderMonitoring('EquipmentPage');
	const [searchBarValue, setSearchBarValue] = useState<string>('');
	const [shouldHideTitle, setShouldHideTitle] = useState<boolean>(false);

	const {
		mergedEquipments,
		mergedEquipmentsLoading,
	}: { mergedEquipments: IMergedEquipmentView[]; mergedEquipmentsLoading: boolean } = useMergedEquipmentQuery();
	const {
		staticEquipments,
		staticEquipmentsLoading,
	}: { staticEquipments: IStaticEquipment[]; staticEquipmentsLoading: boolean } = useStaticEquipmentQuery();

	useEffect(() => {
		equipmentPageAccess_BI();
	}, []);

	const getMergedEquipmentByStaticEquipment = (staticEquipment): IMergedEquipmentView | undefined =>
		mergedEquipments.find((currMergedEquipment) => currMergedEquipment.equipmentId === staticEquipment.equipmentId);

	const staticEquipmentGroupedByType = useMemo((): { [key: string]: IStaticEquipment[] } => {
		const filteredEquipments: IStaticEquipment[] = !searchBarValue
			? staticEquipments
			: staticEquipments.filter(
					(equipment) =>
						equipment.serialNumber?.includes(searchBarValue) ||
						equipment.name.toLowerCase().includes(searchBarValue.toLowerCase())
			  );
		return groupBy(filteredEquipments, (equipment) => equipment.typeNick);
	}, [searchBarValue, staticEquipments]);

	if (mergedEquipmentsLoading || staticEquipmentsLoading) return <Loader />;
	if (!staticEquipments.length && !(mergedEquipmentsLoading || staticEquipmentsLoading)) {
		return <EmptyStateComponent text={translationService.get('noStaticEquipment')} icon={drillIcon} />;
	}

	return (
		<div className={classes.container}>
			<PageHeader title={translationService.get('equipment')} shouldHideTitle={shouldHideTitle}>
				<SearchBar
					onClick={setShouldHideTitle}
					placeholder={translationService.get('equipmentSearchBarPlaceHolder')}
					shouldHideTitle={shouldHideTitle}
					handleChangeInput={setSearchBarValue}
				/>
			</PageHeader>

			<div className={classes.cardsContainer}>
				{Object.keys(staticEquipmentGroupedByType).length ? (
					Object.entries(staticEquipmentGroupedByType).map(([groupName, groupEquipments]) => (
						<section className={classes.equipmentCardContainer} key={`equipGroup_${groupName}`}>
							<div className={classes.titleSection}>
								<p className={classes.groupTitle}>{groupName}</p>
								<p className={classes.groupLength}>{groupEquipments.length}</p>
							</div>
							{sortBy(groupEquipments, (groupEquipment) => [
								groupEquipment.name.toLowerCase(),
								groupEquipment.lastSeenDate?.getTime(),
							]).map((equipment, index: number) => (
								<>
									<EquipmentCard
										index={index}
										staticEquipment={equipment}
										mergedEquipment={getMergedEquipmentByStaticEquipment(equipment)}
										key={`card_${equipment.equipmentId}`}
									/>
									{index < groupEquipments.length - 1 && <div className={classes.divider}></div>}
								</>
							))}
						</section>
					))
				) : (
					<EmptyStateComponent text={translationService.get('noSearchEquipment')} icon={warningIcon} />
				)}
			</div>
		</div>
	);
};

export { EquipmentPage };
