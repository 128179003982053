import * as React from 'react';
import { useState } from 'react';
import { IFloorWithWorkersCount } from '@shared/interfaces/IFloorWithWorkersCount';
import { IProfessionWorkerData } from '@interfaces/IProfessionWorkerData';
import { ProfessionDisplayWithTradeIcon } from '@shared/components/ProfessionDisplayWithTradeIcon/ProfessionDisplayWithTradeIcon';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { WorkerPageFloorRow } from '../WorkerPageFloorRow/WorkerPageFloorRow';
import { WorkersIcon } from '../WorkersIcon/WorkersIcon';
import classes from './styles.module.scss';
import { sortFloorsInsideProfession } from './professionWorkerData.utils';
import { WorkerFloorDrawer } from '../WorkerFloorDrawer/WorkerFloorDrawer';

interface IProfessionWorkerDataProps {
	profession: IProfessionWorkerData;
}

export const ProfessionWorkerData = (props: IProfessionWorkerDataProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const [isFloorDrawerOpen, setIsFloorDrawerOpen] = useState<boolean>(false);
	const [currentFloorData, setCurrentFloorData] = useState<IFloorWithWorkersCount>();
	const currentFloorWorkers: IProfessionWorkerData['workers'] = props.profession.workers?.filter(
		(worker) => worker.closestAnchor.floorId === currentFloorData?.floor.floorId
	);
	const additionalClasses: string = props.profession.workers?.length === 0 ? classes.inactive : classes.borderStart;
	const sortedFloors: IProfessionWorkerData['floors'] = sortFloorsInsideProfession(props.profession.floors);
	const handleWorkersPageClick = (floor: IFloorWithWorkersCount) => {
		setCurrentFloorData(floor);
		setIsFloorDrawerOpen(true);
	};

	return (
		<div className={`${classes.professionWorkerDataContainer} ${additionalClasses}`}>
			<div className={classes.headers}>
				<div>
					<ProfessionDisplayWithTradeIcon profession={props.profession.profession} projectId={projectId} />
				</div>
				<WorkersIcon workersAmount={props.profession.workers?.length} />
			</div>
			{sortedFloors.map((floor) => (
				<WorkerPageFloorRow
					onClick={() => handleWorkersPageClick(floor)}
					key={floor.floor.floorId}
					floorNick={floor.floor.floorNick}
					workersCount={floor.workersCount}
				/>
			))}
			<WorkerFloorDrawer
				isOpen={isFloorDrawerOpen}
				profession={props.profession.profession}
				currentFloorWorkers={currentFloorWorkers}
				currentFloorData={currentFloorData?.floor}
				onClose={() => setIsFloorDrawerOpen(false)}
			/>
		</div>
	);
};

export default ProfessionWorkerData;
