import React from 'react';
import { getProfessionDisplayText } from '@shared/utils/professions.utils';
import { capitalizeFirstLetter } from '@shared/utils/text.utils';
import { IRestrictedAreaTrigger } from '../../../../models/IRestrictedAreaTrigger';
import classes from './styles.module.scss';
import { translationService } from '../../../../servicesInitializer';

interface IRestrictedAreaTitleProps {
	trigger: IRestrictedAreaTrigger;
	updateTriggersList: () => void;
}

export const RestrictedAreaTitleSection = (props: IRestrictedAreaTitleProps) => {
	const floorsLength: number | undefined = props.trigger.floors?.length;
	const areaLength: number | undefined = props.trigger.areas?.length;

	const getFloorsText = (): string => {
		if (!props.trigger.floors) return '';
		return floorsLength! > 1
			? `${floorsLength} ${translationService.get('floors')}`
			: `${capitalizeFirstLetter(props.trigger.floors[0].floorNick)}`;
	};

	const getAreasText = (): string => {
		if (!props.trigger.areas) return '';
		return areaLength! > 1
			? `${areaLength} ${translationService.get('areas')}`
			: `${capitalizeFirstLetter(props.trigger.areas[0].areaNick)}`;
	};

	const getLocationText = (): string => {
		if (floorsLength) return getFloorsText();
		return getAreasText();
	};

	const getProfessionText = (): string => {
		if (!props.trigger.profession) return '';
		const professionLength: number = props.trigger.profession?.professions?.length;
		const isAllSelected: boolean = props.trigger.profession?.isAllSelected;
		if (isAllSelected) return translationService.get('allProfessions');
		return professionLength! > 1
			? `${professionLength} ${translationService.get('professions')}`
			: `${getProfessionDisplayText(props.trigger.profession?.professions[0], translationService)}`;
	};

	return (
		<div className={classes.wrapper}>
			<div className={classes.title}>
				<strong>{getLocationText()}</strong>
				<div className={classes.isRestrictedText}>{translationService.get('isRestrictedFor')}</div>
			</div>
			<strong className={classes.title}>{getProfessionText()}</strong>
		</div>
	);
};
