import React, { useEffect, useState } from 'react';
import { Loader } from '@shared/components/Loader/Loader';
import { IssueItem } from '@shared/components/Issue/IssueItem';
import { IIssue } from '@interfaces/IIssue';
import { IIssueShared, IssueStatus } from '@shared/interfaces/IIssueShared';
import { issueComment_BI, issueStatusChange_BI } from '@utils/bi.utils';
import { IssueUpsertPage } from '@src/components/pages/IssuesPage/IssueUpsertPage/IssueUpsertPage';
import { requestService, storageService, translationService } from '@src/servicesInitializer';
import { IUser } from '@shared/interfaces/IUser';
import { IProfession } from '@shared/interfaces/IProfession';
import { errorToast } from '@utils/toast.utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectId, selectTimezone } from '@store/slices/project.slice';
import { sortIssuesByDueDateAndPriority } from '@shared/utils/issues.utils';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import classes from './styles.module.scss';

interface DisplayIssuesProps {
	issues: IIssue[];
	isIssuesLoading: boolean;
	deleteIssue: (issueId: string) => Promise<void>;
	updateIssue: (
		issueId: string,
		issue: Partial<IUpdateIssue>,
		updateBackend?: boolean
	) => Promise<IIssueShared<IProfession>>;
	user: IUser;
}

export const DisplayIssues = (props: DisplayIssuesProps) => {
	const projectId: string = useSelector(selectProjectId)!;
	const tz: string = useSelector(selectTimezone)!;
	const [issueToEdit, setIssueToEdit] = useState<IIssue | undefined>();
	const [showEditIssueModal, setShowEditIssueModal] = useState<boolean>(false);

	const dispatch = useDispatch();

	const handleSizeLimitImageError = () => {
		errorToast(dispatch, translationService.get('imageAboveTheSizeLimitError'));
	};

	useEffect(() => {
		if (issueToEdit) {
			setShowEditIssueModal(true);
		}
	}, [issueToEdit]);

	const closeEditIssueModal = () => {
		setIssueToEdit(undefined);
		setShowEditIssueModal(false);
	};
	if (props.isIssuesLoading) {
		return <Loader />;
	}
	const sortedIssues: IIssue[] = sortIssuesByDueDateAndPriority(props.issues);

	return (
		<>
			<div className={classes.issuesContainer}>
				{sortedIssues.map((issue) => (
					<div className={classes.issue} key={issue._id}>
						<IssueItem
							issueComment_BI={issueComment_BI}
							deleteIssue={props.deleteIssue}
							issue={issue}
							tz={tz}
							translationService={translationService}
							updateIssue={props.updateIssue}
							issueStatusChangeBiEvent={(status: IssueStatus, issueUpdated: IIssue) =>
								issueStatusChange_BI(issueUpdated, status)
							}
							isMobile
							storageService={storageService}
							requestService={requestService}
							user={props.user}
							imageSizeLimitCallback={handleSizeLimitImageError}
							setIssueToEdit={setIssueToEdit}
							projectId={projectId}
						/>
					</div>
				))}
			</div>
			<IssueUpsertPage
				show={showEditIssueModal}
				addIssueToState={() => {}}
				onClose={closeEditIssueModal}
				initialIssue={issueToEdit}
				handleIssueEdit={props.updateIssue}
			/>
		</>
	);
};
