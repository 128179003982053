import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import React from 'react';
import helmetIcon from '../../../assets/helmet.svg';
import floorsIcon from '../../../assets/floorsIcon.svg';
import classes from './styles.module.scss';

interface ICustomizedOptionSectionProps {
	userPreferences: IUserPreferences | null;
}

export const CustomizedOptionSection = (props: ICustomizedOptionSectionProps) =>
	props.userPreferences && (
		<>
			<div className={classes.container}>
				<div className={classes.wrapper}>
					<img src={helmetIcon} alt="helmet" />
					<div>{props.userPreferences.mobile?.filters?.professionIds?.length || 0}</div>
				</div>
				<div className={classes.wrapper}>
					<img src={floorsIcon} alt="floors" />
					<div>{props.userPreferences.mobile?.filters.floorIds?.length || 0}</div>
				</div>
			</div>
		</>
	);
