import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoggedUser } from '@shared/interfaces/IUser';
import { ILoginReducerState, ILoginRequestStatus, LoginRequestStatuses } from '@shared/interfaces/ILoginReducerState';
import { RoleType } from '@shared/interfaces/RoleType.enum';
import type { IRootState } from './index';

const initialState: ILoginReducerState = {
	loggedUser: null,
	loginRequestStatus: {
		state: LoginRequestStatuses.INITIAL,
	},
};

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		setLoggedUser: (state: ILoginReducerState, action: PayloadAction<{ user: ILoggedUser | null }>) => {
			state.loggedUser = action.payload.user;
		},
		setLoginStateData: (
			state: ILoginReducerState,
			action: PayloadAction<{ loginRequestStatus: ILoginRequestStatus }>
		) => {
			state.loginRequestStatus = action.payload.loginRequestStatus;
		},
	},
});

export const selectIsLimitedUser = (state: IRootState) =>
	state.loginReducer.loggedUser?.userDetails?.permissions.roleType === RoleType.VIEWER;
export const selectLoggedUserDetails = (state: IRootState) => state.loginReducer.loggedUser?.userDetails;
export const selectLoggedUserProjects = (state: IRootState) => state.loginReducer.loggedUser?.userProjects;
export const selectLoggedUser = (state: IRootState) => state.loginReducer.loggedUser;
export const { setLoggedUser, setLoginStateData } = loginSlice.actions;
export default loginSlice.reducer;
