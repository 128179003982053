import React from 'react';
import classnames from 'classnames';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { Loader } from '@shared/components/Loader/Loader';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { translationService } from '@src/index';
import { IconColor } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import classes from './styles.module.scss';

interface IResourceCountCardProps {
	icon: IconNames;
	text: string;
	link: string;
	isLoading: boolean;
	count?: number;
}

export const ResourceCountCard = (props: IResourceCountCardProps) => {
	const isDisabled: boolean = props.isLoading || !props.count;
	const isRtl: boolean = translationService.getIsRtl();
	const arrowIconName: IconNames = isRtl ? IconNames.chevronLeft : IconNames.chevronRight;
	const history = useHistory();

	const addDisabledClassIfNecessary = (initialClass: string): string =>
		classnames(initialClass, {
			[classes.disabled]: isDisabled,
		});

	const handleCardClick = () => {
		if (isDisabled) return;
		history.push(props.link);
	};

	return (
		<div className={addDisabledClassIfNecessary(classes.resourceCountCardContainer)} onClick={handleCardClick}>
			<div className={classes.innerContainer}>
				<TrusstorIcon iconName={props.icon} color={isDisabled ? IconColor.Grey400 : IconColor.DarkBlue} />
				<p className={addDisabledClassIfNecessary(classes.text)}>{props.text}</p>
			</div>
			<div className={classnames(classes.innerContainer, { [classes.isLoading]: props.isLoading })}>
				{props.isLoading ? (
					<Loader size={16} />
				) : (
					<p className={addDisabledClassIfNecessary(classes.text)}>{props.count ?? 0}</p>
				)}
				<TrusstorIcon iconName={arrowIconName} color={isDisabled ? IconColor.Grey400 : IconColor.DarkBlue} />
			</div>
		</div>
	);
};
