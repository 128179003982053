import React from 'react';
import classes from './styles.module.scss';
import notificationIcon from '../../../../assets/bell.svg';
import { translationService } from '../../../../servicesInitializer';

export const NotificationsEmptyState = () => (
	<div className={classes.emptyState}>
		<div className={classes.emptyStateIconContainer}>
			<div className={classes.notificationIcon}>
				<img src={notificationIcon} className={classes.emptyStateIcon} alt={''} />
				<div className={classes.dot}>
					<span className={classes.dotText}>0</span>
				</div>
			</div>
		</div>
		<div className={classes.emptyStateText}>{translationService.get('noNewNotifications')}</div>
	</div>
);
