import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { IUserPreferencesState } from '../../interfaces/IUserPreferencesState';
// eslint-disable-next-line import/no-cycle
import { getUserPreferences } from '../thunks/userPreferences.thunks';
import type { IRootState } from './index';

const initialState: IUserPreferencesState = {
	userPreferences: null,
	userPreferencesRequestStatus: HTTPRequestStatuses.idle,
};

const userPreferencesReducer = createSlice({
	name: 'userPreferences',
	initialState,
	reducers: {
		setUserPreferences: (
			state: IUserPreferencesState,
			action: PayloadAction<{ userPreferences: IUserPreferences | null }>
		) => {
			state.userPreferences = action.payload.userPreferences;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserPreferences.pending, (state) => {
				state.userPreferencesRequestStatus = HTTPRequestStatuses.pending;
			})
			.addCase(getUserPreferences.fulfilled, (state, action) => {
				state.userPreferences = action.payload.userPreferences;
				state.userPreferencesRequestStatus = HTTPRequestStatuses.success;
			});
	},
});

export const selectUserPreference = (state: IRootState): IUserPreferences | null =>
	state.userPreferencesReducer.userPreferences;
export const selectUserPreferenceRequestStatus = (state: IRootState): HTTPRequestStatuses =>
	state.userPreferencesReducer.userPreferencesRequestStatus;

export const { setUserPreferences } = userPreferencesReducer.actions;
export default userPreferencesReducer.reducer;
