import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { projectAccessLevelService } from '..';

export const getPermittedMonitorResources = (): MonitorResourcesEnum[] => {
	return [
		...(projectAccessLevelService.hasAccess('personnelPage') ? [MonitorResourcesEnum.PERSONNEL] : []),
		...(projectAccessLevelService.hasAccess('equipmentPage') ? [MonitorResourcesEnum.EQUIPMENT] : []),
		...(projectAccessLevelService.hasAccess('activitiesPage') ? [MonitorResourcesEnum.ACTIVITIES] : []),
		...(projectAccessLevelService.hasAccess('issuesPage') ? [MonitorResourcesEnum.ISSUES] : []),
	];
};
