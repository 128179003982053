import React from 'react';
import { IMonitorResourcesData, useMonitorResources } from '@src/hooks/monitor.hooks';
import { Loader } from '@shared/components/Loader/Loader';
import { MonitorResourcesEnum } from '@interfaces/MonitorResources.Enum';
import { IResourceCountData } from '@src/hooks/queries/resourceCountByLocation.queries.hooks';
import { getMonitorResourceTranslation, getResourceIcon, getResourceLink } from '../MonitorPage.utils';
import { ResourceCountCard } from '../ResourceCountCard/ResourceCountCard';
import classes from './styles.module.scss';

interface IResourceCountCardsSectionProps {
	addedIssuesCount: number;
	floorId: string;
}

export const ResourceCountCardsSection = (props: IResourceCountCardsSectionProps) => {
	const monitorResourcesData: IMonitorResourcesData = useMonitorResources([props.floorId]);

	if (!monitorResourcesData.areAllResourcesLoaded) {
		return <Loader />;
	}

	return (
		<div className={classes.ResourceCountCardsSectionContainer}>
			{Object.keys(monitorResourcesData.resourcesCount).map((resource: string) => {
				const resourceData: IResourceCountData = monitorResourcesData.resourcesCount[resource];
				const resourceCount: number =
					resourceData.countByLocation.floors[props.floorId]?.floorTotal ||
					0 ||
					(resource === MonitorResourcesEnum.ISSUES ? props.addedIssuesCount : 0);
				return (
					<div className={classes.resourceCountContainer}>
						<ResourceCountCard
							link={getResourceLink(props.floorId, resource as MonitorResourcesEnum)}
							icon={getResourceIcon(resource as MonitorResourcesEnum)}
							text={getMonitorResourceTranslation(resource)}
							isLoading={!resourceData.isLoaded}
							count={resourceCount}
						/>
					</div>
				);
			})}
		</div>
	);
};
