export class CustomizedViewLocalStorageService {
	static IS_CUSTOMIZED_VIEW: string = 'isCustomizedView';

	static getIsCustomizedViewDisabled(): boolean {
		const customizedFromLocalStorage: string | null = localStorage.getItem(this.IS_CUSTOMIZED_VIEW);
		if (customizedFromLocalStorage === 'false') {
			return true;
		}
		return false;
	}

	static setIsCustomizedView(value: boolean): void {
		localStorage.setItem(this.IS_CUSTOMIZED_VIEW, JSON.stringify(value));
	}
}
