import { IIssue } from '@interfaces/IIssue';
import { requestService, translationService } from '@src/servicesInitializer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { errorToast, successToast } from '@utils/toast.utils';
import { getOptimisticUpdatedIssue } from '@utils/issuesUpdate.utils';
import { IUser } from '@shared/interfaces/IUser';
import { IUpdateIssue } from '@shared/interfaces/IIssue';
import { IIssuesSortingSettings } from '@shared/interfaces/IIssuesSortingSettings';

export enum IssuesQueryKeysEnum {
	getIssuesByProject = 'getIssuesByProject',
	getIssuesByFloor = 'getIssuesByFloor',
	getIssuesByGroupId = 'getIssuesByGroupId',
}

export const useProjectIssuesQuery = (projectId: string, username: string): IIssue[] | undefined => {
	const { data: issues } = useQuery<IIssue[]>(
		[IssuesQueryKeysEnum.getIssuesByProject, projectId, username],
		() => requestService.get(`/issues/byProjectId/${projectId}?username=${username}`),
		{
			useErrorBoundary: true,
		}
	);
	return issues;
};

export interface UseGetIssuesProps {
	projectId: string;
	floorId?: string;
	areaId?: string;
	notCompleted?: boolean;
	excludeInitial?: boolean;
	professionIds?: string[] | null;
	queryKey: IssuesQueryKeysEnum;
}

const getIssueQueryKeys = (getIssuesProps: UseGetIssuesProps) => {
	const { queryKey, projectId, floorId, areaId, notCompleted, excludeInitial, professionIds } = getIssuesProps;
	return [queryKey, projectId, floorId, areaId, notCompleted, excludeInitial, professionIds];
};

export const useProjectIssuesByLocationQueryInvalidation = (getIssuesProps: UseGetIssuesProps): (() => void) => {
	const queryClient = useQueryClient();
	const issueQueryKeys = getIssueQueryKeys(getIssuesProps);
	return () => queryClient.invalidateQueries(issueQueryKeys);
};

export const useProjectIssuesByLocationQuery = (
	getIssuesProps: UseGetIssuesProps
): {
	issues: IIssue[];
	isLoading: boolean;
} => {
	const professionParams = new URLSearchParams();
	if (getIssuesProps.professionIds) {
		getIssuesProps.professionIds.forEach((id) => professionParams.append('professionIds[]', id));
	}
	const issueQueryKeys = getIssueQueryKeys(getIssuesProps);

	const { data: issues, isLoading } = useQuery<IIssue[]>(
		issueQueryKeys,
		() =>
			requestService.get(`/issues/byProjectId/${getIssuesProps.projectId}`, {
				params: {
					areaId: getIssuesProps.areaId,
					floorId: getIssuesProps.floorId,
					notCompleted: getIssuesProps.notCompleted,
					excludeInitial: getIssuesProps.excludeInitial,
					professionIds: getIssuesProps.professionIds,
				},
			}),
		{
			useErrorBoundary: true,
			initialData: [],
		}
	);
	return { issues, isLoading };
};

export const useProjectIssuesDeleteMutation = (projectId: string) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (issueId: string) => requestService.delete(`/issues/${issueId}`),
		onSuccess: () => {
			queryClient.invalidateQueries([IssuesQueryKeysEnum.getIssuesByProject, projectId]);
			queryClient.invalidateQueries(['getIssuesByFloor']);
		},
	});
};

export const useIssueCreatedQueryInvalidation = () => {
	const queryClient = useQueryClient();

	return () => Object.values(IssuesQueryKeysEnum).map((queryKey) => queryClient.invalidateQueries([queryKey]));
};

interface UseUpdateIssueProps {
	user: IUser;
	dispatch: any;
	getIssuesProps: UseGetIssuesProps;
}

export const useUpdateIssueMutation = ({ user, dispatch, getIssuesProps }: UseUpdateIssueProps) => {
	const queryClient = useQueryClient();
	const issueQueryKeys = getIssueQueryKeys(getIssuesProps);

	return useMutation({
		mutationFn: async ({
			issueId,
			issueBeforeUpdate,
			issueToUpdate,
			updateBackend = true,
		}: {
			issueId: string;
			issueBeforeUpdate: IIssue;
			issueToUpdate: Partial<IUpdateIssue>;
			updateBackend?: boolean;
		}): Promise<IIssue> => {
			const optimisticUpdatedIssue: IIssue = getOptimisticUpdatedIssue(issueBeforeUpdate, issueToUpdate, user);
			const isStatusUpdated: boolean = !!(
				issueToUpdate.status && issueToUpdate.status !== issueBeforeUpdate.status
			);

			if (!updateBackend) {
				return optimisticUpdatedIssue;
			}

			const updatedIssue: IIssue = await requestService.put(`/issues/${issueId}`, {
				body: issueToUpdate,
			});
			if (isStatusUpdated) {
				successToast(dispatch, translationService.get('issueStatusUpdatedSuccessfully'));
				return updatedIssue;
			}
			successToast(dispatch, translationService.get('issueUpdatedSuccessfully'));
			return updatedIssue;
		},

		onMutate: async ({ issueId, issueToUpdate }) => {
			await queryClient.cancelQueries(issueQueryKeys);
			const currentIssues: IIssue[] | undefined = queryClient.getQueryData(issueQueryKeys);
			if (!currentIssues) {
				errorToast(dispatch, translationService.get('issueUpdateError'));
				return;
			}
			const issueBeforeUpdate: IIssue = currentIssues.find((issue) => issue._id === issueId)!;
			const optimisticUpdatedIssue: IIssue = getOptimisticUpdatedIssue(issueBeforeUpdate, issueToUpdate, user);

			queryClient.setQueryData(issueQueryKeys, (prevIssues: IIssue[] | undefined) => {
				if (!prevIssues) {
					return [];
				}
				return prevIssues.map((issue) => {
					if (issue._id === issueId) {
						return {
							...issue,
							...optimisticUpdatedIssue,
						};
					}
					return issue;
				});
			});
			// eslint-disable-next-line consistent-return
			return issueBeforeUpdate;
		},
		onError: () => {
			errorToast(dispatch, translationService.get('issueUpdateError'));
		},
		onSettled: () => {
			queryClient.invalidateQueries(issueQueryKeys);
			queryClient.refetchQueries(issueQueryKeys);
		},
	});
};

export const useIssuesByGroupIdQuery = (groupId: string): IIssue[] | undefined => {
	const { data: issues } = useQuery<IIssue[]>(
		['getIssuesByGroupId', groupId],
		() => requestService.get(`/activities/groups/${groupId}/issues`),
		{
			useErrorBoundary: true,
		}
	);
	return issues;
};

export const useIssuesSortingSettingsQuery = (projectId: string) => {
	const { data: sortingSettings, isLoading: isIssuesSortingSettingsLoading } = useQuery<IIssuesSortingSettings>(
		['getSortingSettings', projectId],
		() => requestService.get(`/issues/sortingSettings/${projectId}`)
	);
	return { sortingSettings, isIssuesSortingSettingsLoading };
};
