import { RoleType } from '@shared/interfaces/RoleType.enum';
import { IUserFeatureProhibitions } from '@shared/interfaces/IUserFeatureProhibitions';

/*
 This is a list of features and the roles that don't have access to them.
*/

const featureNames = [
	'initialWelcomePageAccess',
	'activitiesActions',
	'assignTag',
	'safetyNotifications',
	'activitiesWidgetActions',
	'workplanActivityActions',
] as const;

export type UserFeaturesNamesType = (typeof featureNames)[number];
type IUserFeatureProhibitionsMobile = IUserFeatureProhibitions<UserFeaturesNamesType>;

export const userFeaturesProhibitedList: IUserFeatureProhibitionsMobile = {
	initialWelcomePageAccess: [RoleType.VIEWER],
	activitiesActions: [RoleType.VIEWER],
	assignTag: [RoleType.VIEWER, RoleType.MANAGER, RoleType.EXECUTIVE],
	safetyNotifications: [RoleType.VIEWER],
	activitiesWidgetActions: [RoleType.VIEWER],
	workplanActivityActions: [RoleType.VIEWER],
};
