import React from 'react';
import { translationService } from '../../servicesInitializer';
import classes from './styles.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { IconNames } from '../../../../shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '../../../../shared/components/TrusstorIconShared/TrusstorIconShared';
import { selectIsOffline, selectOfflineSubText, selectOfflineText } from '../../store/slices/offlineBanner.slice';

export const OfflineBanner = () => {
	const [showOnlineMessage, setShowOnlineMessage] = React.useState<boolean>(false);
	const offlineTextFromStore: string | null = useSelector(selectOfflineText);
	const offlineSubTextFromStore: string | null = useSelector(selectOfflineSubText);
	const isOffline: boolean = useSelector(selectIsOffline);

	React.useEffect(() => {
		const handleOnline = () => {
			setShowOnlineMessage(true);
			setTimeout(() => {
				setShowOnlineMessage(false);
			}, 3000);
		};

		window.addEventListener('online', handleOnline);

		return () => {
			window.removeEventListener('online', handleOnline);
		};
	}, []);

	const shouldShowBanner: boolean = isOffline || showOnlineMessage;

	if (!shouldShowBanner) {
		return null;
	}

	return (
		<div
			className={classNames({
				[classes.banner]: true,
				[classes.online]: !isOffline,
				[classes.offline]: isOffline,
			})}
		>
			<div className={classes.bannerMainText}>
				<div
					className={classNames({
						[classes.syncIcon]: !isOffline,
					})}
				>
					<TrusstorIcon
						iconName={!isOffline ? IconNames.refresh : IconNames.wifiNoConnection}
						size={IconSize.MEDIUM}
						color={IconColor.White}
					/>
				</div>
				{isOffline
					? offlineTextFromStore || translationService.get('offlineBanner')
					: translationService.get('onlineBanner')}
			</div>
			{isOffline && <div className={classes.bannerSubText}>{offlineSubTextFromStore || ''}</div>}
		</div>
	);
};
