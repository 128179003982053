import React, { useMemo, useState } from 'react';
import { compact, sortBy, uniqBy } from 'lodash';
import { IAssignedActivityArea } from '@shared/interfaces/IAssignedActivityArea';
import { SORT_ORDER } from '@shared/constants/constants';
import { Loader } from '@shared/components/Loader/Loader';
import { IFloor } from '@shared/interfaces/IFloor';
import { sortFloors } from '@shared/utils/sort.utils';
import classes from './styles.module.scss';
import { IActivityByProgress, IActivityRealTimeFloorInfoMobile } from '../../../interfaces/IActivityByProgress';
import { translationService } from '../../../index';
import { IMergedAreaSequenceItem } from '../../../interfaces/IMergedAreaSequenceItem';
import { SequenceProgressCard } from './SequenceProgressCard/SequenceProgressCard';
import { useAreaSequenceItemsByGroupIdQuery } from '../../../hooks/queries/sequenceItems.hooks';

interface IProgressCardsProps {
	activity: IActivityByProgress;
	refetchDataCallback: () => Promise<void>;
}

const ProgressCards = (props: IProgressCardsProps) => {
	const [isShowMore, setIsShowMore] = useState<boolean>(false);
	const allAreas: IAssignedActivityArea[] = compact(
		sortBy(uniqBy(props.activity.floors.map((floor) => floor.areas).flat(), 'areaId'), 'areaId')
	);
	const { areaSequenceItems, refetchAreaSequenceItems } = useAreaSequenceItemsByGroupIdQuery(props.activity.groupId);

	const isSequenceCards: boolean = !!props.activity.linkedSequenceType;
	const maxCards: number = isSequenceCards ? 5 : 7;

	const handleShowMore = () => {
		setIsShowMore(true);
	};

	const getGridColumns = (array: any[], dense = false): string => {
		if (array.length === 2) return '40%';
		if (array.length === 3) return dense ? '30%' : '25%';
		if (array.length > 3) return dense ? '20%' : '25%';
		return '100%';
	};

	const sortedFloors: IActivityRealTimeFloorInfoMobile[] = useMemo(
		() =>
			sortFloors<IActivityRealTimeFloorInfoMobile>(props.activity.floors, SORT_ORDER.ASCENDING, 'floor.floorId'),
		[props.activity.floors.length]
	);

	const getLimitToSlice = (isExactMaxCards: boolean): undefined | number => {
		if (isShowMore) return undefined;
		return isExactMaxCards ? maxCards + 1 : maxCards;
	};
	const sequenceProgressCards = () => {
		const displayShowMoreButton: boolean = allAreas.length > maxCards && !isShowMore;
		const isExactMaxCards: boolean = allAreas.length === maxCards + 1;

		return (
			<div
				className={classes.sequenceProgressCardsContainer}
				style={{ gridTemplateColumns: `repeat(auto-fill, ${getGridColumns(allAreas)})` }}
			>
				{allAreas.slice(0, getLimitToSlice(isExactMaxCards)).map((area: IAssignedActivityArea) => {
					const areaSequenceItem: IMergedAreaSequenceItem | undefined = areaSequenceItems.find(
						(areaSequenceItemToFind) => areaSequenceItemToFind._id === area.areaSequenceItemId
					);
					return (
						areaSequenceItem && (
							<SequenceProgressCard
								refetchDataCallback={props.refetchDataCallback}
								profession={props.activity.profession}
								description={props.activity.description}
								areaSequenceItem={areaSequenceItem}
								area={area}
								refetchAreaSequenceItems={refetchAreaSequenceItems}
							/>
						)
					);
				})}
				{displayShowMoreButton && !isExactMaxCards && (
					<div className={classes.areaSequenceItemContainer} onClick={handleShowMore}>
						<div className={classes.areaText}>{`${allAreas.length - maxCards}+`}</div>
						<div className={classes.timeText}>{translationService.get('more')}</div>
					</div>
				)}
			</div>
		);
	};

	const activityProgressCards = () => {
		const displayShowMoreButton: boolean = props.activity.floors.length > maxCards && !isShowMore;
		const isExactMaxCards: boolean = props.activity.floors.length === maxCards + 1;
		return (
			<div
				className={classes.activityProgressCardsContainer}
				style={{ gridTemplateColumns: `repeat(auto-fill, ${getGridColumns(props.activity.floors, true)})` }}
			>
				{sortedFloors
					.slice(0, getLimitToSlice(isExactMaxCards))
					.map((floor: IActivityRealTimeFloorInfoMobile) => (
						<div className={classes.activityProgressCard}>
							<div className={classes.floorText}>
								{(floor.floor as IFloor).shortFloorNick || floor.floor.floorNick}
							</div>
							<div className={classes.timeText}>
								{floor.todayHours}
								{translationService.get('h')}
							</div>
						</div>
					))}
				{displayShowMoreButton && !isExactMaxCards && (
					<div className={classes.activityProgressCard} onClick={handleShowMore}>
						<div className={classes.floorText}>{props.activity.floors.length - maxCards}+</div>
						<div className={classes.timeText}>{translationService.get('more')}</div>
					</div>
				)}
			</div>
		);
	};

	if (isSequenceCards && !areaSequenceItems.length) return <Loader />;
	return (
		<section className={classes.progressCardsContainer}>
			{isSequenceCards ? sequenceProgressCards() : activityProgressCards()}
		</section>
	);
};

export { ProgressCards };
