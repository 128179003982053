import React from 'react';
import classnames from 'classnames';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { IconColor, IconSize } from '@shared/components/TrusstorIconShared/TrusstorIconShared';
import classes from './styles.module.scss';

interface ICustomizedFilterButtonProps {
	isCustomizedSelected: boolean;
	onClick: () => void;
}

export const CustomizedFilterButton = (props: ICustomizedFilterButtonProps) => (
	<div
		className={classnames(classes.costumizedButtonContainer, {
			[classes.costumizedSelected]: props.isCustomizedSelected,
		})}
	>
		<TrusstorIcon
			iconName={IconNames.settingsSliders}
			size={IconSize.LARGE}
			onClick={props.onClick}
			color={!props.isCustomizedSelected ? IconColor.Grey500 : undefined}
		/>
	</div>
);
