import React from 'react';
import classnames from 'classnames';
import { IManagerMergedTag } from '@shared/interfaces/IManagerMergedTag';
import { TrusstorIcon } from '@src/components/TrusstorIcon/TrusstorIcon';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import { translationService } from '@src/servicesInitializer';
import classes from './styles.module.scss';

interface IManagerTagsCardProps {
	managersTags: IManagerMergedTag[];
}

export const ManagersTagsCard = (props: IManagerTagsCardProps) => (
	<div className={classes.Manager_container} data-testid="ManagersTagsCard">
		<div className={classes.title}>
			<div className={classes.managerTitle}>
				<TrusstorIcon iconName={IconNames.manager} />
				<div>{translationService.get('Managers')}</div>
			</div>

			<div className={classes.length}>{props.managersTags.length}</div>
		</div>
		<div className={classes.content}>
			{props.managersTags.map((tag, index) => (
				<div key={tag._id} className={classnames(classes.tagRow)}>
					<div className={classes.name}>{tag.manager.name}</div>
					<div className={classes.tagNick}>{tag.tagNick}</div>
				</div>
			))}
		</div>
	</div>
);
