import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { INotificationCard } from '@shared/interfaces/NotificationSettings/INotificationCard';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import classes from './styles.module.scss';
import { translationService } from '../../../index';
import { NotificationSettingCard } from './NotificationCard/NotificationSettingCard';
import { notificationSettingsTabAccess_BI } from '../../../utils/bi.utils';
import { InnerPageHeader } from '../../InnerPageHeader/InnerPageHeader';
import { getMobileNotificationsCards, isPushNotificationsEnabledOnDevice } from '../../../utils/notifications.utils';
import { PushNotificationsEnableCard } from '../../PushNotificationsEnableCard/PushNotificationsEnableCard';
import { useIsFeatureFlagEnabled } from '@shared/hooks/useFeatureFlag';
import { FeatureFlagNamesEnum } from '@shared/constants/featureFlags.enum';

export interface INotificationCardSection {
	name: string;
	icon: string;
	cards: INotificationCard[];
}

export const NotificationsSettingsPage = () => {
	const [showPushNotificationEnableCard, setShowPushNotificationEnableCard] = useState<boolean>(
		isPushNotificationsEnabledOnDevice() && Notification.permission === 'default'
	);
	const isPushNotificationsFeatureFlagEnabled: boolean = useIsFeatureFlagEnabled(
		FeatureFlagNamesEnum.usePushNotifications
	);
	useRenderMonitoring('NotificationsSettingsPage');
	const history = useHistory();

	useEffect(() => {
		notificationSettingsTabAccess_BI();
	}, []);

	const onPushNotificationPermissionSet = () => {
		setShowPushNotificationEnableCard(false);
	};

	const mobileNotificationsCards = getMobileNotificationsCards();

	return (
		<section className={classes.notificationsPageContainer}>
			<InnerPageHeader handleBackClick={history.goBack} title={translationService.get('alerts')} />
			<div className={classes.cardsContainer}>
				{showPushNotificationEnableCard && isPushNotificationsFeatureFlagEnabled && (
					<div className={classes.enablePushNotificationsCard}>
						<PushNotificationsEnableCard onPermissionSet={onPushNotificationPermissionSet} />
					</div>
				)}
				{mobileNotificationsCards.map((cardSection: INotificationCardSection) => (
					<NotificationSettingCard card={cardSection} key={`notificationCard_${cardSection.name}`} />
				))}
			</div>
		</section>
	);
};
