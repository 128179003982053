import React, { useState } from 'react';
import { IconNames } from '@shared/components/TrusstorIconShared/IconNames.enum';
import classname from 'classnames';
import classes from './styles.module.scss';
import { TrusstorIcon } from '../TrusstorIcon/TrusstorIcon';
import { translationService } from '../../servicesInitializer';

interface ICollapsableFilterSectionProps {
	headerText: string;
	headerTextClassName?: string;
	onClear?: () => void;
	children?: any;
}

export const CollapsableContainer = (props: ICollapsableFilterSectionProps) => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	const handleClear = (e) => {
		e.stopPropagation();
		props.onClear?.();
	};

	return (
		<div>
			<div className={classes.header} onClick={() => setIsCollapsed((prev) => !prev)}>
				<div className={classes.headerTextContainer}>
					<TrusstorIcon iconName={isCollapsed ? IconNames.chevronDown : IconNames.chevronUp} />
					<span className={classname(props.headerTextClassName)}>{props.headerText}</span>
				</div>
				{props.onClear && (
					<div onClick={handleClear} className={classes.clear}>
						{translationService.get('clear')}
					</div>
				)}
			</div>
			{!isCollapsed && props.children}
		</div>
	);
};
