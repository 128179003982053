import React, { useEffect, useState } from 'react';
import { IFloor } from '@shared/interfaces/IFloor';
import { IProfession } from '@shared/interfaces/IProfession';
import { useDispatch, useSelector } from 'react-redux';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { HTTPRequestStatuses } from '@shared/interfaces/HTTPRequestStatuses.enum';
import { Loader } from '@shared/components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'src/store/slices';
import { ILoggedUser, IUser } from '@shared/interfaces/IUser';
import classnames from 'classnames';
import { useRenderMonitoring } from '@shared/hooks/monitoring.hooks';
import classes from './styles.module.scss';
import { translationService } from '../../../index';
import { ProfessionsSelectionSection } from '../../ProfessionsSelectionSection/ProfessionsSelectionSection';
import { getUserPreferences, updateUserPreferences } from '../../../store/thunks/userPreferences.thunks';
import { selectLoggedUser } from '../../../store/slices/login.slice';
import { selectUserPreference, selectUserPreferenceRequestStatus } from '../../../store/slices/userPreferences.slice';
import { useFloorDataQuery } from '../../../hooks/queries/floors.queries.hooks';
import { areArrayOfStringsEqual } from '../../../utils/compare.utils';
import { myInterestsDefinitions_BI, preferencesPageAccess_BI } from '../../../utils/bi.utils';
import { InnerPageHeader } from '../../InnerPageHeader/InnerPageHeader';
import { BottomActionButton } from '../../BottomActionButton/BottomActionButton';
import { FloorsSelectionSection } from '../../FloorsSelectionSection/FloorsSelectionSection';

export const PreferencesPage = () => {
	useRenderMonitoring('PreferencesPage');
	const [updatedFloors, setUpdatedFloors] = useState<IFloor[]>([]);
	const [selectedProfessions, setSelectedProfessions] = useState<IProfession[]>([]);
	const dispatch = useDispatch();
	const username: string = useSelector(selectLoggedUser)!.userDetails.username;
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const userPreferencesRequestStatus: HTTPRequestStatuses = useSelector(selectUserPreferenceRequestStatus);
	const loggedUser: ILoggedUser | null = useSelector((state: IRootState) => state.loginReducer.loggedUser);
	const history = useHistory();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isProfessionSelection, setIsProfessionSelection] = useState<boolean>(true);

	const floors: IFloor[] = useFloorDataQuery();

	useEffect(() => {
		const userPreferencesFloors: IFloor[] = floors.filter((floor) =>
			userPreferences?.mobile?.filters?.floorIds?.includes(floor.floorId)
		);
		setUpdatedFloors(userPreferencesFloors);
	}, [userPreferences?.mobile?.filters?.floorIds, floors]);

	useEffect(() => {
		dispatch(getUserPreferences(username));
		setIsLoading(false);
		preferencesPageAccess_BI();
	}, []);

	const userPreferencesUpdated: Partial<IUserPreferences> = {
		username,
		mobile: {
			filters: {
				floorIds: updatedFloors.length === 0 ? null : updatedFloors.map((floor) => floor.floorId),
				professionIds:
					selectedProfessions.length === 0 ? null : selectedProfessions.map((profession) => profession._id),
			},
		},
	};

	const updateActionFunction = () => {
		dispatch(updateUserPreferences(userPreferencesUpdated, username));
		const userDetails: IUser = loggedUser?.userDetails || ({} as IUser);
		myInterestsDefinitions_BI({
			...userDetails,
			floors: !!userPreferences?.mobile?.filters?.floorIds,
			professions: !!userPreferences?.mobile?.filters?.professionIds,
		});

		history.goBack();
	};

	const shouldUpdateBeDisabled: boolean =
		areArrayOfStringsEqual(
			userPreferencesUpdated.mobile?.filters?.professionIds || [],
			userPreferences?.mobile?.filters?.professionIds || []
		) &&
		areArrayOfStringsEqual(
			userPreferencesUpdated.mobile?.filters?.floorIds || [],
			userPreferences?.mobile?.filters?.floorIds || []
		);

	const handleBackClick = () => {
		history.goBack();
	};

	if (userPreferencesRequestStatus === HTTPRequestStatuses.pending || isLoading) return <Loader />;
	return (
		<div className={classes.pageWrapper}>
			{userPreferencesRequestStatus === HTTPRequestStatuses.success && (
				<>
					<InnerPageHeader handleBackClick={handleBackClick} title={translationService.get('preferences')} />
					<div className={classes.selectorWrapper}>
						<div
							onClick={() => setIsProfessionSelection(true)}
							className={classnames(classes.sectionTitle, {
								[classes.isSelected]: isProfessionSelection,
							})}
						>
							{translationService.get('professions')}
						</div>
						<div
							onClick={() => setIsProfessionSelection(false)}
							className={classnames(classes.sectionTitle, {
								[classes.isSelected]: !isProfessionSelection,
							})}
						>
							{translationService.get('floors')}
						</div>
					</div>
					<div
						className={classnames(classes.selectionSection, { [classes.isVisible]: isProfessionSelection })}
					>
						<ProfessionsSelectionSection setSelectedProfessions={setSelectedProfessions} />
					</div>
					<div
						className={classnames(classes.selectionSection, {
							[classes.isVisible]: !isProfessionSelection,
						})}
					>
						<FloorsSelectionSection
							shouldSetInitialSelectionType
							setSelectedFloors={setUpdatedFloors}
							selectedFloors={updatedFloors}
						/>
					</div>
				</>
			)}
			<BottomActionButton
				text={translationService.get('save')}
				handleClick={updateActionFunction}
				isDisabled={shouldUpdateBeDisabled}
			/>
		</div>
	);
};
