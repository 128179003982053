interface PushPermissionLocalStorageData {
	[username: string]: boolean;
}

export class PushPermissionLocalStorageService {
	static isPushPermissionDrawerShownKey: string = 'isPushPermissionDrawerShown';

	static getIsPushPermissionDrawerShown(username?: string): boolean {
		if (!username) {
			return true;
		}
		const data: PushPermissionLocalStorageData = JSON.parse(
			localStorage.getItem(this.isPushPermissionDrawerShownKey) || '{}'
		);
		return data[username] || false;
	}

	static setIsPushPermissionDrawerShown(username: string | undefined, value: boolean): void {
		if (!username) {
			return;
		}
		const data: PushPermissionLocalStorageData = JSON.parse(
			localStorage.getItem(this.isPushPermissionDrawerShownKey) || '{}'
		);
		data[username] = value;
		localStorage.setItem(this.isPushPermissionDrawerShownKey, JSON.stringify(data));
	}
}
