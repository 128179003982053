import React, { useCallback } from 'react';
import classes from './styles.module.scss';
import { useHistory } from 'react-router-dom';
import { translationService } from '../../../servicesInitializer';
import { SelectOptions } from '../../TrusstorDrawer/SelectOption/SelectOptions';
import { WorkerPageViewTypes } from '../../../models/WorkerPageViewTypes';
import { WorkersPageSortOptions } from '../../../models/WorkerPageSortOptions';
import { viewSettingsUpdate_BI } from '../../../utils/bi.utils';

interface IPreferencesDrawerProps {
	userPreferencesDefined: boolean;
	setViewType: (value: WorkerPageViewTypes | undefined) => void;
	viewType: WorkerPageViewTypes | undefined;
	setSortOption: (value: WorkersPageSortOptions | undefined) => void;
	sortOption: WorkersPageSortOptions | undefined;
}

const PreferencesDrawerContent = (props: IPreferencesDrawerProps) => {
	const history = useHistory();
	const goToPreferencesPage = () => {
		history.push('/preferences');
	};
	const handleViewChange = (value: string): void => {
		viewSettingsUpdate_BI('view', props.viewType as string, value);
		props.setViewType(value as WorkerPageViewTypes);
	};

	const handleGroupChange = (value: string): void => {
		viewSettingsUpdate_BI('groupBy', props.sortOption as string, value);
		props.setSortOption(value as WorkersPageSortOptions);
	};
	return (
		<div className={classes.container}>
			<SelectOptions
				title={translationService.get('view')}
				handleChange={handleViewChange}
				options={[
					{
						actionButton: {
							text: translationService.get(props.userPreferencesDefined ? 'edit' : 'create'),
							onClick: goToPreferencesPage,
						},
						isSelected: props.viewType === WorkerPageViewTypes.PERSONAL_VIEW,
						value: WorkerPageViewTypes.PERSONAL_VIEW,
						title: translationService.get('customized'),
					},
					{
						isSelected: props.viewType === WorkerPageViewTypes.ALL_WORKERS,
						value: WorkerPageViewTypes.ALL_WORKERS,
						title: translationService.get('entireSite'),
					},
				]}
			/>
			<SelectOptions
				title={translationService.get('groupBy')}
				handleChange={handleGroupChange}
				options={[
					{
						isSelected: props.sortOption === WorkersPageSortOptions.BY_PROFESSIONS,
						value: WorkersPageSortOptions.BY_PROFESSIONS,
						title: translationService.get('professions'),
					},
					{
						isSelected: props.sortOption === WorkersPageSortOptions.BY_FLOORS,
						value: WorkersPageSortOptions.BY_FLOORS,
						title: translationService.get('floors'),
					},
				]}
			/>
		</div>
	);
};

export { PreferencesDrawerContent };
