import { PageHeader } from '@src/components/PageHeader';
import { translationService } from '@src/servicesInitializer';
import { ActivityPageSortOptions } from '@src/models/ActivityPageSortOptions';
import customizedFilterOn from '@assets/customizedFilterOn.svg';
import customizedFilterOff from '@assets/customizedFilterOff.svg';
import React, { useCallback, useState } from 'react';
import { activitiesViewSettingsOpen_BI } from '@utils/bi.utils';
import { ActivitiesPagePreferenceDrawer } from '@src/components/pages/ActivitiesPageV2/ActivitiesPagePreferenceDrawer/ActivitiesPagePreferenceDrawer';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { useSelector } from 'react-redux';
import { selectUserPreference } from '@store/slices/userPreferences.slice';
import { WidgetActivityFilters } from '@shared/interfaces/WidgetActivityFilters';
import { QuickFilterButton } from '@shared/components/buttons/QuickFilterButton/QuickFilterButton';
import classes from './styles.module.scss';

interface IFilterItem {
	id: string;
	text: string;
	number?: number;
	onClick: () => void;
}

interface IActivitiesPageHeaderProps {
	personalViewSortOption: ActivityPageSortOptions;
	changePersonalViewState: (viewType: string) => void;
	openActivitiesCount: number | undefined;
	noProgressActivitiesCount: number | undefined;
	forReviewActivitiesCount: number | undefined;
	completedActivitiesCount: number | undefined;
	inProgressActivitiesCount: number | undefined;
	toDoActivities: number | undefined;
	todayProgressActivitiesCount: number | undefined;
	setWidgetActivityFilters: (tab: WidgetActivityFilters) => void;
	selectedQuickFilter: WidgetActivityFilters;
}

export const ActivitiesPageHeader = (props: IActivitiesPageHeaderProps) => {
	const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState<boolean>(false);
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);

	const handleActivitiesViewSettingsClicked = useCallback(() => {
		activitiesViewSettingsOpen_BI();
		setIsFiltersDrawerOpen(true);
	}, []);

	const quickFilters: IFilterItem[] = [
		{
			id: WidgetActivityFilters.toDo,
			text: translationService.get('activities_widget_quick_filter_toDo'),
			number: props.toDoActivities,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.toDo);
			},
		},
		{
			id: WidgetActivityFilters.inProgress,
			text: translationService.get('activities_widget_quick_filter_inProgress'),
			number: props.inProgressActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.inProgress);
			},
		},
		{
			id: WidgetActivityFilters.todayProgress,
			text: translationService.get('mobile_activities_widget_quick_filter_todayProgress'),
			number: props.todayProgressActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.todayProgress);
			},
		},
		{
			id: WidgetActivityFilters.noProgress,
			text: translationService.get('activities_widget_quick_filter_noProgress'),
			number: props.noProgressActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.noProgress);
			},
		},
		{
			id: WidgetActivityFilters.forReview,
			text: translationService.get('activities_widget_quick_filter_for_review'),
			number: props.forReviewActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.forReview);
			},
		},
		{
			id: WidgetActivityFilters.completed,
			text: translationService.get('completed'),
			number: props.completedActivitiesCount,
			onClick: () => {
				props.setWidgetActivityFilters(WidgetActivityFilters.completed);
			},
		},
	];

	const handleFilterClick = (filter: IFilterItem): void => {
		filter.onClick();
	};

	return (
		<div className={classes.activitiesPageHeader}>
			<PageHeader title={translationService.get('activities')} shouldHideBottomLine>
				<div onClick={handleActivitiesViewSettingsClicked}>
					<img
						src={
							props.personalViewSortOption === ActivityPageSortOptions.PERSONAL_VIEW
								? customizedFilterOn
								: customizedFilterOff
						}
					/>
				</div>
			</PageHeader>
			<div className={classes.quickFiltersContainer}>
				{quickFilters.map((filter) => {
					const text: string =
						filter.number !== undefined ? `${filter.text} (${filter.number})` : filter.text;
					const isSelected: boolean = props.selectedQuickFilter === filter.id;
					return (
						<QuickFilterButton
							testId={`quickFilterButton&type=${filter.id}`}
							disabled={filter.number === 0}
							key={filter.id}
							text={text}
							handleClick={() => handleFilterClick(filter)}
							selected={isSelected}
						/>
					);
				})}
			</div>
			<hr className={classes.hr} />
			{isFiltersDrawerOpen && (
				<ActivitiesPagePreferenceDrawer
					userPreferences={userPreferences}
					handleClose={() => setIsFiltersDrawerOpen(false)}
					sortOptionState={props.personalViewSortOption}
					setPersonalViewSortOrderState={props.changePersonalViewState}
				/>
			)}
		</div>
	);
};
