import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserPreferences } from '@shared/interfaces/IUserPreferences';
import { IManager } from '@shared/interfaces/IManager';
import classnames from 'classnames';
import { IProfessionWorkerData } from '@shared/interfaces/IProfessionWorkerData';
import { CustomizedFilterButton } from '@src/components/CostumizedFilterButton/CostumizedFilterButton';
import classes from './styles.module.scss';
import { useProjectWorkersQuery } from '../../hooks/queries/professions.queries.hooks';
import { selectUserPreference } from '../../store/slices/userPreferences.slice';
import customizedFilterOn from '../../assets/customizedFilterOn.svg';
import customizedFilterOff from '../../assets/customizedFilterOff.svg';
import { translationService } from '../../servicesInitializer';
import { useActiveManagersQuery } from '../../hooks/queries/managers.queries.hooks';
import { isUserPreferencesDefined } from '../../utils/filter.utils';
import { PreferencesDrawerContent } from './PreferencesDrawer/PreferencesDrawerContent';
import { TrusstorDrawer } from '../TrusstorDrawer/TrusstorDrawer';
import { WorkerPageViewTypes } from '../../models/WorkerPageViewTypes';
import { WorkersPageSortOptions } from '../../models/WorkerPageSortOptions';
import { CustomizeInfo } from './CustomizeInfo/CustomizeInfo';
import { EducationPopup } from './EducationPopup/EducationPopup';
import { viewSettingsOpen_BI } from '../../utils/bi.utils';

interface IViewPreferencesProps {
	setViewType: (value: WorkerPageViewTypes | undefined) => void;
	viewType: WorkerPageViewTypes | undefined;
	setSortOption: (value: WorkersPageSortOptions | undefined) => void;
	sortOption: WorkersPageSortOptions | undefined;
	showEducation: boolean;
	setShowEducation: (value: boolean) => void;
	professionData: IProfessionWorkerData[] | undefined;
}

const ViewPreferences = (props: IViewPreferencesProps) => {
	const [isPreferencesDrawerOpen, setIsPreferencesDrawerOpen] = useState<boolean>(false);
	const activeManagers: IManager[] = useActiveManagersQuery();
	const userPreferences: IUserPreferences | null = useSelector(selectUserPreference);
	const userPreferencesDefined: boolean = isUserPreferencesDefined(userPreferences);
	const isCustomizedView: boolean = props.viewType === WorkerPageViewTypes.PERSONAL_VIEW;
	const projectWorkersProfessionData: IProfessionWorkerData[] = useProjectWorkersQuery();

	const handlePreferencesIconClick = () => {
		viewSettingsOpen_BI();
		if (props.showEducation) {
			props.setShowEducation(false);
		}
		setIsPreferencesDrawerOpen(true);
	};

	const filteredWorkersSum: number = props.professionData
		? props.professionData.reduce((acc, curr) => acc + curr.workers.length, 0)
		: 0;
	const fullWorkersSum: number = projectWorkersProfessionData.reduce((acc, curr) => acc + curr.workers.length, 0);
	return (
		<>
			<div
				className={classnames(classes.viewContainer, {
					[classes.makeSpace]: isCustomizedView,
					[classes.increaseLayer]: props.showEducation,
				})}
			>
				<div className={classes.preferencesContentContainer}>
					<section className={classes.countSection}>
						<div className={classes.countNumber}>
							{isCustomizedView ? filteredWorkersSum || 0 : fullWorkersSum}{' '}
							{isCustomizedView && (
								<div className={classes.outOfText}>
									{translationService.get('outOf')} {fullWorkersSum}
								</div>
							)}
						</div>
						<div className={classes.countText}>{translationService.get('Workers')}</div>
					</section>
					<section className={classes.countSection}>
						<div className={classes.countNumber}>{activeManagers.length}</div>
						<div className={classes.countText}>{translationService.get('managers')}</div>
					</section>
					<div className={classes.iconContainer}>
						<CustomizedFilterButton
							isCustomizedSelected={isCustomizedView}
							onClick={handlePreferencesIconClick}
						/>
					</div>
				</div>
				{isCustomizedView && (
					<CustomizeInfo userPreferences={userPreferences} setShowEducation={props.setShowEducation} />
				)}
			</div>
			<TrusstorDrawer
				isOpen={isPreferencesDrawerOpen}
				onClose={() => setIsPreferencesDrawerOpen(false)}
				topSection={translationService.get('viewSettings')}
			>
				<PreferencesDrawerContent
					userPreferencesDefined={userPreferencesDefined}
					setViewType={props.setViewType}
					viewType={props.viewType}
					setSortOption={props.setSortOption}
					sortOption={props.sortOption}
				/>
			</TrusstorDrawer>
			{props.showEducation && <EducationPopup setShowEducation={props.setShowEducation} />}
		</>
	);
};

export { ViewPreferences };
