import { IProfession } from '@shared/interfaces/IProfession';
import { compact, orderBy } from 'lodash';
import { IFloorWithWorkersCount } from '@shared/interfaces/IFloorWithWorkersCount';
import { IProfessionWorkerData } from '@interfaces/IProfessionWorkerData';

export const sortProfessions = (professionsData: IProfessionWorkerData[]): IProfessionWorkerData[] =>
	orderBy(professionsData, (profession: IProfessionWorkerData) => profession.workers.length, ['desc']);

export const addInactiveProfessions = (
	professionsData: IProfessionWorkerData[],
	projectProfessions: IProfession[],
	userPreferencesProfessionIds: string[]
) => {
	const professionsDataIds: string[] = professionsData.map((p) => p.profession._id);
	const inactiveProfessionsIds: string[] = userPreferencesProfessionIds.filter(
		(p) => !professionsDataIds.includes(p)
	);
	const inactiveProfessionsData: IProfessionWorkerData[] = compact(
		inactiveProfessionsIds.map((professionId) => {
			const profession = projectProfessions.find((p) => p._id === professionId);
			if (profession) {
				return {
					floors: [],
					profession: projectProfessions.find((p) => p._id === professionId)!,
					workers: [],
				};
			}
			return null;
		})
	);

	if (inactiveProfessionsData) {
		return [...professionsData, ...inactiveProfessionsData];
	}
	return professionsData;
};

export const filterFloorsByPreferences = (
	professionsDataList: IProfessionWorkerData[],
	userPreferencesFloorIds: string[]
): IProfessionWorkerData[] =>
	compact(
		professionsDataList.map((professionData) => {
			const filteredFloors: IFloorWithWorkersCount[] = professionData.floors.filter((floor) =>
				userPreferencesFloorIds.includes(floor.floor.floorId)
			);
			const filteredWorkers = professionData.workers.filter((workers) =>
				userPreferencesFloorIds.includes(workers.closestAnchor.floorId)
			);
			if (!filteredFloors.length || !filteredWorkers.length) {
				return null;
			}
			return { ...professionData, floors: filteredFloors, workers: filteredWorkers };
		})
	);
