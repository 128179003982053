import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';

export const getStatusesToUpdateTo = (
	status: AreaSequenceItemStatus,
	userHasAccessToActivityActions: boolean
): AreaSequenceItemStatus[] => {
	if (!userHasAccessToActivityActions) {
		if (status === AreaSequenceItemStatus.readyForReview) {
			return [AreaSequenceItemStatus.planned];
		}
		if (status === AreaSequenceItemStatus.complete) {
			return [];
		}
		return [AreaSequenceItemStatus.readyForReview];
	}
	if (status === AreaSequenceItemStatus.readyForReview) {
		return [AreaSequenceItemStatus.complete];
	}
	const inProgressStatuses = [
		AreaSequenceItemStatus.inProgress,
		AreaSequenceItemStatus.delayed,
		AreaSequenceItemStatus.delayedOverdue,
		AreaSequenceItemStatus.overdue,
	];
	if (inProgressStatuses.includes(status)) {
		return [AreaSequenceItemStatus.unplanned, AreaSequenceItemStatus.complete];
	}
	const allStatusesToUpdateTo = [
		AreaSequenceItemStatus.unplanned,
		AreaSequenceItemStatus.planned,
		AreaSequenceItemStatus.inProgress,
		AreaSequenceItemStatus.complete,
	];
	return allStatusesToUpdateTo.filter((s) => s !== status);
};
