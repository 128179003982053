import React from 'react';
import { IMainPageWidgetActivityData } from '@interfaces/IMainPageWidgetActivityData';
import { ActivityCardDetails } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardDetails/ActivityCardDetails';
import { ActivityCardDetailsHeader } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityCardDetailsHeader/ActivityCardDetailsHeader';
import { IssueUpsertPage } from '@src/components/pages/IssuesPage/IssueUpsertPage/IssueUpsertPage';
import { useMainPageActivityLocationsQuery } from '@src/hooks/queries/activities.queries.hooks';
import { IIssueLocation } from '@shared/interfaces/IIssueShared';
import { IBaseAreaBaseFloor } from '@shared/interfaces/IBaseAreaBaseFloor';
import { ActivityView } from '@src/components/pages/ActivitiesPageV2/ActivityCard/ActivityView/ActivityView';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { selectProjectId } from '@store/slices/project.slice';
import { QueryKeys } from '@shared/constants/QueryKeys.enum';
import { IssueViewDrawer } from '@src/components/IssueViewDrawer/IssueViewDrawer';
import { IIssue } from '@shared/interfaces/IIssue';
import classes from './styles.module.scss';

interface IActivityCardProps {
	activity: IMainPageWidgetActivityData;
	onIssueCreated?: (issue: IIssue) => void;
	queryKeys: any[];
}

export const ActivityCard = (props: IActivityCardProps) => {
	const [showCreateIssueModal, setShowCreateIssueModal] = React.useState<boolean>(false);
	const [showActivityView, setShowActivityView] = React.useState<boolean>(false);
	const [showIssueDrawer, setShowIssueDrawer] = React.useState<boolean>(false);
	const { activityLocations } = useMainPageActivityLocationsQuery(props.activity);
	const issueLocations: IIssueLocation[] = activityLocations.map((location: IBaseAreaBaseFloor) => ({
		area: {
			areaId: location.areaId,
			areaNick: location.areaNick,
		},
		floor: {
			floorId: location.floorId,
			floorNick: location.floorNick,
		},
	}));
	const queryClient = useQueryClient();
	const projectId: string = useSelector(selectProjectId)!;

	const onCreateIssueClick = () => {
		setShowCreateIssueModal(true);
	};

	const handleCardClick = () => {
		setShowActivityView(true);
	};

	const handleShowIssuePage = () => {
		setShowIssueDrawer(true);
	};

	const refetchActivities = () => {
		queryClient.refetchQueries([QueryKeys.mobileMainPageActivities, projectId]);
	};

	return (
		<>
			<IssueViewDrawer
				show={showIssueDrawer}
				groupId={props.activity.groupId}
				onClose={() => setShowIssueDrawer(false)}
			/>
			<IssueUpsertPage
				show={showCreateIssueModal}
				onClose={() => setShowCreateIssueModal(false)}
				onIssueCreated={(issueCreated: IIssue) => {
					props.onIssueCreated?.(issueCreated);
					refetchActivities();
				}}
				initialIssue={{
					profession: props.activity.profession,
					locations: issueLocations,
					linkedActivityGroupId: props.activity.groupId,
				}}
			/>
			<ActivityView
				queryKeys={props.queryKeys}
				handleBackButtonClick={() => setShowActivityView(false)}
				show={showActivityView}
				close={() => setShowActivityView(false)}
				activity={props.activity}
				onCreateIssueClick={onCreateIssueClick}
			/>

			<div
				className={classes.activityCard}
				data-testid={`activityCard&activityId=${props.activity.groupId}`}
				onClick={handleCardClick}
			>
				<ActivityCardDetailsHeader
					activity={props.activity}
					onCreateIssueClick={onCreateIssueClick}
					onViewIssuesClick={handleShowIssuePage}
				/>
				<ActivityCardDetails
					activity={props.activity}
					showCreateIssuePage={onCreateIssueClick}
					showViewIssuesPage={handleShowIssuePage}
				/>
			</div>
		</>
	);
};
