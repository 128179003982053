import React, { useEffect, useState } from 'react';
import { AreaSequenceItemStatus } from '@shared/interfaces/AreaSequenceItemStatus.enum';
import { requestService, translationService } from '../../../index';
import { ActivityDialogDisclaimer } from '../ActivityDialogDisclaimer/ActivityDialogDisclaimer';
import { ActionConfirmationDialog } from '../ActionConfirmationDialog/ActionConfirmationDialog';

interface IActivityMarkAsCompleteActionDialogProps {
	show: boolean;
	onClose: () => void;
	activityGroupId: string;
	showNoWorkDetectedDisclaimerText?: boolean;
	disclaimerPoints?: string[];
	onAction?: () => void;
	sendActionBiEventOnSubmit: (type: AreaSequenceItemStatus) => void;
}

export const ActivityMarkAsCompleteActionDialog = (props: IActivityMarkAsCompleteActionDialogProps) => {
	const [totalDisclaimerPoints, setTotalDisclaimerPoints] = useState<string[]>([]);

	const completeAction = async () => {
		await requestService.put(`/activities/groups/${props.activityGroupId}/complete`);
		props.onAction?.();
		props.onClose();
		props.sendActionBiEventOnSubmit(AreaSequenceItemStatus.complete);
	};

	useEffect(() => {
		const points: string[] = [
			...(props.disclaimerPoints || []),
			...(!props.showNoWorkDetectedDisclaimerText ? [] : [translationService.get('noWorkDetectedInAllFloors')]),
		];

		setTotalDisclaimerPoints(points);
	}, [props.disclaimerPoints, props.showNoWorkDetectedDisclaimerText]);

	const getDisclaimerElement = (): JSX.Element | undefined => {
		if (!totalDisclaimerPoints.length) {
			return undefined;
		}

		return <ActivityDialogDisclaimer disclaimerPoints={totalDisclaimerPoints} />;
	};

	return (
		<ActionConfirmationDialog
			show={props.show}
			onClose={props.onClose}
			mainButtonAction={completeAction}
			secondaryButtonAction={props.onClose}
			title={translationService.get('markActivityComplete')}
			text={translationService.get('isActivityCompleted')}
			secondaryText={getDisclaimerElement()}
		/>
	);
};
