import { Languages } from '@shared/constants/languages';
import { Environments } from '@shared/constants/environments';
import configData from './config.json';
import { IConfig } from '../interfaces/IConfig';

const apiGateway = configData.apiGateway;
const isLocal: boolean =
	configData.environment === Environments.compose ||
	configData.environment === Environments.dev ||
	configData.environment === Environments.development;
// UPDATE TO HTTPS!!!!!
const mobileUrl = isLocal ? 'https://localhost:8080' : configData.desktopUrl.replace(`://`, `://m.`);

export const config: IConfig = {
	...configData,
	defaultLanguage: configData.defaultLanguage as Languages,
	environment: configData.environment as Environments,
	apiGateway,
	mobileUrl,
};
