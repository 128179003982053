import { IFloor } from '@shared/interfaces/IFloor';
import { IBaseFloor } from '@shared/interfaces/IBaseFloor';
import { TranslationService } from '@shared/services/translation.service';
import { IFloorChipData } from '@shared/interfaces/IFloorChipData';
import {
	getFloorChipFromRangeFloors,
	getFloorsChipFromFloorsList,
	getFloorsListFromFloorsChips,
} from '@shared/utils/floorsSelection.utils';
import plusBtn from '@shared/assets/TrusstorCountInput/plusBtn.svg';
import { SimpleChip } from '@shared/components/SimpleChip/SimpleChip';
import { COLORS } from '@shared/constants/colors.constants';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { compact } from 'lodash';
import classes from './styles.module.scss';
import { translationService } from '../../../servicesInitializer';
import { SingleDropdown } from '../../Dropdowns/DesignSystem/SingleDropdown/SingleDropdown';

interface IMobileFloorsSelectionProps {
	floors: IFloor[];
	selectedFloors?: IBaseFloor[];
	defaultFloorInputValue?: IFloor;
	isFloorSectionEnabled: boolean;
	changeFunction: (data: IFloor[]) => void;
	translationService: TranslationService;
	deleteChipOnClick?: boolean;
}

const MobileFloorsSelection = (props: IMobileFloorsSelectionProps) => {
	const [rangeFloors, setRangeFloors] = useState<[IFloor | undefined, IFloor | undefined]>([
		props.defaultFloorInputValue,
		props.defaultFloorInputValue,
	]);
	const [floorChips, setFloorChips] = useState<IFloorChipData[] | null>(
		props.selectedFloors?.length ? getFloorsChipFromFloorsList(props.selectedFloors) : null
	);
	const [isToFloorDisabled, setIsToFloorDisabled] = useState<boolean>(true);
	const [shouldShowChips, setShouldShowChips] = useState<boolean>(true);

	const toFloorRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

	useEffect(() => {
		const currFloorChips: IFloorChipData[] = floorChips || [];
		const allFloors: IFloor[] = getFloorsListFromFloorsChips(
			compact([...currFloorChips, getFloorChipFromRangeFloors(rangeFloors)]),
			props.floors
		);
		props.changeFunction(allFloors);
	}, [floorChips?.length, rangeFloors]);

	useEffect(() => {
		setIsToFloorDisabled((!rangeFloors[0] && !rangeFloors[1]) || Number.isNaN(Number(rangeFloors[0]?.floorId)));
	}, [rangeFloors]);

	useEffect(() => {
		if (!isToFloorDisabled) {
			toFloorRef.current?.focus();
			toFloorRef.current?.select();
		}
	}, [isToFloorDisabled]);

	useEffect(() => {
		if (!props.selectedFloors?.length) {
			return;
		}
		setFloorChips(getFloorsChipFromFloorsList(props.selectedFloors));
	}, [props.selectedFloors]);

	const addFloors = (): void => {
		if (!rangeFloors[0] || !rangeFloors[1]) return;
		setFloorChips((prevState) => {
			const prevFloorChips = prevState ?? [];
			return compact([...prevFloorChips, getFloorChipFromRangeFloors(rangeFloors)]);
		});
		setRangeFloors([undefined, undefined]);
		!shouldShowChips && setShouldShowChips(true);
	};

	const handleDeleteFloorChip = (chipKey: string): void => {
		setFloorChips((chips) => chips && chips.filter((chip) => chip.key !== chipKey));
	};

	const onChangeFromFloor = (value: IFloor | null) => {
		const toFloorValue: IFloor | null | undefined =
			Number(value?.floorId) && Number(rangeFloors[1]?.floorId) ? rangeFloors[1] : value;
		setRangeFloors([value || undefined, toFloorValue || undefined]);
	};

	const deleteChip = (chip: IFloorChipData) => {
		props.isFloorSectionEnabled ? handleDeleteFloorChip(chip.key) : undefined;
	};

	return (
		<div className={`${classes.floorsSection} ${props.isFloorSectionEnabled ? '' : classes.opacity}`}>
			{props.isFloorSectionEnabled && (
				<div className={classes.floorsInputRow}>
					<div className={classes.fromFloorInput}>
						<SingleDropdown
							required
							value={rangeFloors[0]}
							options={props.floors}
							getDisplayOption={(option) => option.floorNick}
							onChange={onChangeFromFloor}
							testId="floors-from-dropdown"
						/>
					</div>
					<div className={classes.toText}>{translationService.get('to')}</div>
					<div
						className={`${classes.row} ${classes.rowMobile}`}
						style={{ opacity: isToFloorDisabled ? '0.7' : '' }}
					>
						<div className={classes.toFloorInput}>
							<SingleDropdown
								inputRef={toFloorRef}
								required
								disabled={isToFloorDisabled}
								value={rangeFloors[1]}
								options={props.floors.filter((floor) => !Number.isNaN(Number(floor.floorId)))}
								getDisplayOption={(option) => option.floorNick}
								onChange={(value) => {
									setRangeFloors((prevState) => [prevState[0], value || undefined]);
								}}
								testId="floors-to-dropdown"
							/>
						</div>
					</div>
				</div>
			)}
			<div className={classes.container}>
				{!props.isFloorSectionEnabled && (
					<div className={classes.label}>{`${props.translationService.get('floors')}*`}</div>
				)}
				<div className={`${classes.chipsList} ${classes.mobile}`}>
					{floorChips &&
						shouldShowChips &&
						floorChips.map((chip) => (
							<SimpleChip
								click={props.deleteChipOnClick ? () => deleteChip(chip) : () => {}}
								key={chip.key}
								chipColor={COLORS.white}
								chipTextColor={COLORS.primaryColor}
								chipText={chip.label}
								chipHeight={40}
								isMobile
								capitalizeText
								onDeleteClick={() => deleteChip(chip)}
								border={true}
								testId="floor-chip"
							/>
						))}
				</div>
			</div>
			<div className={classes.addRange} onClick={addFloors}>
				<img src={plusBtn} alt="" />
				{translationService.get('addRange')}
			</div>
		</div>
	);
};

export { MobileFloorsSelection };
